import axios from "axios";

const api = `${process.env.REACT_APP_BACKEND_URL}/test-ad-id/`;

const authToken = localStorage.getItem("token");
const headers = {
  Authorization: `Bearer ${authToken}`,
  "Content-Type": "application/json",
};
const headersWithForm = {
  Authorization: `Bearer ${authToken}`,
  "Content-Type": "multipart/form-data",
};

const APIlist = () => {
  return axios
    .get(`${api}get-test-ad-id`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIadd = (data, id) => {
  return axios
    .put(`${api}manage-test-ad-id/${id}`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

export { APIlist, APIadd };
