import SearchIcon from "@mui/icons-material/Search";
import Card from "react-bootstrap/Card";
import NoDataImg from "../../images/g8.png";
import getCurrentUser from "../../utils/getCurrentUser.util";
import {
  APIdelete,
  APIlist,
  APIplatformList,
} from "../../api-wrapper/ApiPublishPlatform";
import { Toast, PermissionCheck, Link } from "../../helper/links/Link";
import moment from "moment";
import Add from "./Add";
import DeleteModal from "../../helper/modal/DeleteModal";
import React, { useState, useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";
import editSvg from "../../icons/svgs/edit-line.svg";
import deleteSvg from "../../icons/svgs/delete-bin-line.svg";
import ReactPaginate from "react-paginate";
function AppPublishPlatform() {
  const [date, setDate] = useState([
    new Date(moment().startOf("month")),
    new Date(),
  ]);
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);

  const [totalRecords, setTotalRecords] = useState(0);
  const [visible, setVisible] = useState(false);
  const [editData, setEditData] = useState();
  const [deleteId, setDeleteId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [activeCard, setActiveCard] = useState(null);
  const [appPlatformData, setAppPlatformData] = useState([]);
  const [cardData, setCardData] = useState();

  const [totalData, setTotalData] = useState(5);
  const [ListcurrentPage, setListCurrentPage] = useState(1);

  const getData = () => {
    if (PermissionCheck("App Publish Platform", "View Only"))
      APIlist()
        .then((e) => {
          if (e?.isSuccess) {
            setAppPlatformData(e?.data);
            setTotalRecords(e.totalRecords);
          } else {
            Toast.error(e?.message);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
  };

  useEffect(() => {
    getData();
  }, []);

  const deleteHandler = () => {
    APIdelete(deleteId)
      .then((res) => {
        if (res.isSuccess) {
          setActiveCard(null);
          setCardData();
          Toast.success(res.message);
          getData();
        } else {
          Toast.error(res.message);
        }
        setDeleteId();
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  const handlePlatformList = (id, index, no) => {
    setActiveCard(index);
    let data = {
      search: search,
      pageNo: no ? no : ListcurrentPage,
      perPage: 5,
    };
    APIplatformList(data, id)
      .then((res) => {
        if (res.isSuccess) {
          setCardData(res);
          setTotalData(res.totalRecords);
          setListCurrentPage(res.currentPageNo);
        } else {
          Toast.error(res?.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  const handlePageChange = (event) => {
    setListCurrentPage(event.selected + 1);
    handlePlatformList(
      cardData?.dataPlatform?._id,
      activeCard,
      event.selected + 1
    );
  };

  return (
    <>
      <Card className="rounded-3 p-1">
        <Card.Header className="align-items-center gap-4 flex-wrap padding-header">
          <h5 className="card-title">
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="large" />}
              aria-label="breadcrumb"
            >
              <Typography key="1" color="text.primary" className="platform">
                Platform
              </Typography>
              <Typography
                key="2"
                color="text.primary"
                className="sub-platform"
                style={{ color: "#A5A5A5" }}
              >
                App Publish Platform
              </Typography>
            </Breadcrumbs>
          </h5>
          <div className="d-flex gap-4 flex-wrap">
            {PermissionCheck("App Publish Platform", "Add Platform") ? (
              <button
                class="btn bg-primary add-ref-btn"
                onClick={() => {
                  setVisible(true);
                  setEditData();
                }}
              >
                Add Platform
              </button>
            ) : null}
          </div>
        </Card.Header>
        {PermissionCheck("App Publish Platform", "View Only") && (
          <Card.Body>
            <div className="d-flex gap-6 flex-wrap app-publish-div">
              {appPlatformData?.length > 0 ? (
                appPlatformData.map((appPlatform, index) => (
                  <div
                    className={`d-block rounded main-div position-relative ${
                      activeCard === index ? "selectedDiv" : ""
                    }`}
                    onClick={() => {
                      handlePlatformList(appPlatform._id, index);
                    }}
                  >
                    <div className="main-content-div">
                      <div className="img bg-white text-align-center img-platform-div d-flex justify-content-center align-items-center ">
                        {appPlatform.image ? (
                          <img
                            src={appPlatform.image}
                            alt="play store"
                            className="img-platform"
                          />
                        ) : (
                          <div className="img-platform default_image_platform">
                            {" "}
                            {appPlatform.name.charAt(0)}
                          </div>
                        )}
                      </div>
                      <div className="content">
                        <p className="h5 text-center fw-semibold ">
                          {appPlatform.name}
                        </p>
                      </div>
                    </div>
                    <div className="over-img d-flex rounded flex-wrap justify-content-center">
                      <div className="d-flex flex-wrap justify-content-center align-items-center gap-4">
                        {PermissionCheck(
                          "App Publish Platform",
                          "Edit Platform"
                        ) ? (
                          <img
                            src={editSvg}
                            alt="edit svg"
                            className="hover-icon text-white"
                            onClick={() => {
                              setVisible(true);
                              setEditData(appPlatform);
                            }}
                          />
                        ) : null}

                        {PermissionCheck(
                          "App Publish Platform",
                          "Remove Platform"
                        ) ? (
                          <img
                            src={deleteSvg}
                            alt="delete svg"
                            className="hover-icon text-white"
                            onClick={() => {
                              setShowDeleteModal(true);
                              setDeleteId(appPlatform._id);
                            }}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="d-flex w-100 justify-content-center no-data-img">
                  <img src={NoDataImg} alt="no-data" className="w-30" />
                </div>
              )}
            </div>
          </Card.Body>
        )}
      </Card>

      {activeCard != null && (
        <Card className="rounded-3 p-1 mt-3">
          <Card.Header className="align-items-center gap-4 flex-wrap padding-header">
            <h5 class="card-title">{cardData?.dataPlatform?.name} Console</h5>
            <div className="align-items-center">
              <div className="input-group mr-3">
                <input
                  type="text"
                  className="form-control search-box"
                  placeholder="Search"
                  onChange={(e) => setSearch(e.target.value)}
                />

                <button
                  className="btn btn-outline-secondary border search-btn-icon"
                  type="button"
                  onClick={() =>
                    handlePlatformList(cardData?.dataPlatform?._id, activeCard)
                  }
                >
                  <SearchIcon />
                </button>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <div className="app-publish-cosole-div margin-cust1">
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5 gy-3 g-2 gy-3">
                {cardData?.dataPlayConsole?.length > 0 ? (
                  cardData?.dataPlayConsole?.map((data, index) => (
                    <Link to={"/all-apps"} state={data} className="col">
                      <div className="text-center card-box mb-0">
                        <div className="member-card pb-2 pt-2">
                          {data.image ? (
                            <div
                              className="thumb-lg member-thumb mx-auto"
                              style={{ backgroundImage: `url(${data.image})` }}
                            ></div>
                          ) : (
                            <div className="thumb-lg member-thumb mx-auto default_image_platform">
                              {" "}
                              {data.pcName.charAt(0)}
                            </div>
                          )}

                          <div className="mt-3">
                            <h5>{data.pcName}</h5>
                          </div>

                          <div className="mt-3">
                            <div className="row row-cols-4 g-1">
                              <div className="col col-ex-small">
                                <div className="inner-div">
                                  <h4> {data?.countInReview}</h4>
                                  <p className="mb-0 text-muted">In-Review </p>
                                </div>
                              </div>
                              <div className="col col-ex-small">
                                <div className="inner-div">
                                  <h4>{data?.countReject}</h4>
                                  <p className="mb-0 text-muted">Reject</p>
                                </div>
                              </div>
                              <div className="col col-ex-small">
                                <div className="inner-div">
                                  <h4>{data?.countLive}</h4>
                                  <p className="mb-0 text-muted">Live</p>
                                </div>
                              </div>
                              <div className="col col-ex-small">
                                <div className="inner-div">
                                  <h4>{data?.countSuspend}</h4>
                                  <p className="mb-0 text-muted">Suspend</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))
                ) : (
                  <div className="d-flex w-100 justify-content-center no-data-img">
                    <img src={NoDataImg} alt="no-data" className="w-35" />
                  </div>
                )}
              </div>

              {cardData?.dataPlayConsole?.length != 0 && (
                <ReactPaginate
                  previousLabel="<<"
                  nextLabel=">>"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  pageCount={totalData / 5}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageChange}
                  containerClassName="pagination platform_pagination"
                  activeClassName="active"
                />
              )}
            </div>
          </Card.Body>
        </Card>
      )}

      <Add
        visible={visible}
        setVisible={setVisible}
        editData={editData}
        getData={getData}
      />

      {/* delete modal */}

      <DeleteModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        deleteHandler={deleteHandler}
      />
    </>
  );
}

export default AppPublishPlatform;
