import React, { useEffect } from "react";
import { Formik } from "formik";
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
  CFormLabel,
  CFormInput,
} from "@coreui/react";
import mainLogo from "../../images/mainlogo.png";
import { formatSchema } from "../../utility/Validator";
import { Toast, CIcon, cilTrash } from "../../helper/links/Link";
import {
  APImonetiseFormat,
  APIaddMonetize,
  APIdeleteMonetize,
} from "../../api-wrapper/ApiMonetizePlatform";
import { useState } from "react";

function AddFormate({ visible, setVisible, getData }) {
  const [format, setFormat] = useState([]);

  const handleSubmit = (values) => {
    let data = {
      name: values.name,
    };

    APIaddMonetize(data)
      .then((e) => {
        if (e?.isSuccess) {
          setVisible(false);
          Toast.success(e?.message);
          getData();
        } else {
          Toast.error(e.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  const handleFormat = () => {
    APImonetiseFormat()
      .then((res) => {
        if (res.isSuccess) {
          setFormat(res.data);
        } else {
          Toast.error(res.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };
  useEffect(() => {
    if (visible) {
      handleFormat();
    }
  }, [visible]);

  const handleDelete = (id) => {
    APIdeleteMonetize(id)
      .then((res) => {
        if (res.isSuccess) {
          handleFormat();
          Toast.success();
        } else {
          Toast.error(res.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };
  return (
    <>
      <CModal
        visible={visible}
        alignment="center"
        scrollable
        keyboard={false}
        backdrop="static"
        onClose={() => {
          setVisible(false);
        }}
      >
        <Formik
          validationSchema={formatSchema}
          initialValues={{
            name: "",
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <>
              <CModalHeader>
                <CModalTitle className="">
                  <p className="h4 pt-2 fw-bold mt-auto text-16">Format</p>

                </CModalTitle>
              </CModalHeader>
              <CModalBody>
                <ul className="list-group list-group-numbered">
                  {format?.map((adFormat, index) => (
                    <div className="d-flex mb-1">
                      <li className="list-group-item" style={{ width: "100%" }}>
                        {" "}
                        {adFormat?.name}
                      </li>
                      <button
                        className="btn  bg-white border border-secondary-subtle ms-2"
                        onClick={() => {
                          handleDelete(adFormat._id);
                        }}
                      >
                        <CIcon
                          icon={cilTrash}
                          className="icon-trash text-red"
                          color="red"
                        />
                      </button>
                    </div>
                  ))}
                </ul>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3 pt-3">
                    <CFormLabel>Format Name</CFormLabel>

                    <CFormInput
                      type="text"
                      name="name"
                      aria-label="default input example"
                      value={values.name}
                      onChange={handleChange}
                    />
                    <p className="error">
                      {errors.name && touched.name && errors.name}
                    </p>
                  </div>
                </form>
              </CModalBody>
              <CModalFooter className="add-play-console-modal-footer d-flex justify-content-center gap-2">
                <button
                  className=" btn close-btn bg-white text-black border-danger"
                  onClick={() => {
                    setVisible(false);
                  }}
                >
                  Close
                </button>
                <button
                  className="btn bg-primary text-light create-apikey-btn"
                  onClick={(e) => handleSubmit(e)}
                  type="submit"
                >
                  save
                </button>
              </CModalFooter>
            </>
          )}
        </Formik>
      </CModal>
    </>
  );
}

export default AddFormate;
