import React, { useEffect } from "react";
import { Formik } from "formik";
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
  CFormLabel,
  CFormInput,
  CFormSelect,
} from "@coreui/react";
import mainLogo from "../../images/mainlogo.png";
import { DeveloperSchema } from "../../utility/Validator";
import { Toast } from "../../helper/links/Link";
import { APIadd, APIedit } from "../../api-wrapper/ApiDeveloper";
import { useState } from "react";
function Add({ visible, setVisible, editData, getData }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [changeImg, setchangeImg] = useState(null);

  const handleSubmit = (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("mobile", values.mobile);
    formData.append("technology", values.technology);
    if (changeImg) {
      formData.append("image", changeImg);
    }
    if (editData._id) {
      formData.append("status", values.status == "Active" ? true : false);
      APIedit(formData, editData._id)
        .then((e) => {
          if (e?.isSuccess) {
            Toast.success(e?.message);
            setVisible(false);
            setSelectedImage(null);
            setchangeImg(null);
            getData();
          } else {
            Toast.error(e.message);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong!!");
        });
    } else {
      APIadd(formData)
        .then((e) => {
          if (e?.isSuccess) {
            setVisible(false);
            Toast.success(e?.message);
            setSelectedImage(null);
            setchangeImg(null);
            getData();
          } else {
            Toast.error(e.message);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong!!");
        });
    }
  };

  useEffect(() => {
    setSelectedImage(editData?.image);
  }, [editData]);

  return (
    <>
      <CModal
        visible={visible}
        alignment="center"
        scrollable
        keyboard={false}
        backdrop="static"
        onClose={() => {
          setVisible(false);
        }}
      >
        <Formik
          validationSchema={DeveloperSchema}
          initialValues={{
            technology: editData?.technology,
            mobile: editData?.mobile,
            name: editData?.name,
            image: editData?.image,
            status: editData?.status == true ? "Active" : "Deactive",
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <>
              <CModalHeader>
                <CModalTitle className="">
                  <p className="h4 pt-2 fw-bold mt-auto text-16">Developer</p>
                </CModalTitle>
              </CModalHeader>
              <CModalBody>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <CFormLabel>Developer Name</CFormLabel>
                    <CFormInput
                      type="text"
                      name="name"
                      value={values.name}
                      onBlur={handleBlur}
                      aria-label="default input example"
                      onChange={handleChange}
                    />
                    <p className="error">
                      {errors.name && touched.name && errors.name}
                    </p>
                  </div>
                  <div className="mb-3">
                    <CFormLabel>Developer Technology</CFormLabel>
                    <CFormInput
                      type="text"
                      name="technology"
                      value={values.technology}
                      onBlur={handleBlur}
                      aria-label="default input example"
                      onChange={handleChange}
                    />
                    <p className="error">
                      {errors.technology &&
                        touched.technology &&
                        errors.technology}
                    </p>
                  </div>

                  <div className="mb-3">
                    <CFormLabel>Developer Mobile Number</CFormLabel>
                    <CFormInput
                      type="text"
                      name="mobile"
                      aria-label="default input example"
                      value={values.mobile}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <p className="error">
                      {errors.mobile && touched.mobile && errors.mobile}
                    </p>
                  </div>
                  {editData?._id && (
                    <div className="mb-3">
                      <CFormLabel>Developer Status</CFormLabel>
                      <CFormSelect
                        aria-label="Default select example"
                        name="status"
                        value={values.status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      >
                        <option key="Live" value="Active">
                          Active
                        </option>
                        <option key="Suspended" value="Deactive">
                          Deactive
                        </option>
                      </CFormSelect>
                    </div>
                  )}

                  <div className="mb-3 row">
                    <div className="col-12  col-sm-6">
                      <CFormLabel>Developer Profile</CFormLabel>
                      <input
                        type="file"
                        className="form-control-file d-block"
                        accept="image/*"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          const imageFile = e.target.files[0];
                          setSelectedImage(null);
                          setchangeImg(imageFile);
                          setFieldValue("image", imageFile);
                        }}
                      />
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="d-flex flex-column gap-4 flex-wrap upload-ref-img-div align-items-center align-items-md-end  pe-4 mt-2 mt-md-0">
                        {selectedImage ? (
                          <img
                            src={selectedImage}
                            alt="reference-img"
                            className="upload-ref-img"
                          />
                        ) : (
                          changeImg && (
                            <img
                              src={URL.createObjectURL(changeImg)}
                              className="upload-ref-img"
                            />
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </CModalBody>
              <CModalFooter className="add-play-console-modal-footer d-flex justify-content-center gap-2">
                <button
                  className=" btn close-btn bg-white text-black border-danger"
                  onClick={() => {
                    setVisible(false);
                    setchangeImg(null);
                  }}
                >
                  Close
                </button>
                <button
                  className="btn bg-primary text-light create-apikey-btn"
                  onClick={(e) => handleSubmit(e)}
                  type="submit"
                >
                  Save
                </button>
              </CModalFooter>
            </>
          )}
        </Formik>
      </CModal>
    </>
  );
}

export default Add;
