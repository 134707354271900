import axios from "axios";
const other = `${process.env.REACT_APP_BACKEND_URL}`;
const authToken = localStorage.getItem("token");
const headers = {
  Authorization: `Bearer ${authToken}`,
  "Content-Type": "application/json",
};

const APIInnerlist = (data, id) => {
  return axios
    .post(`${other}/custom-ad/view-assigned-custom-ad-list/${id}`, data, {
      headers,
    })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIselectlist = (id) => {
  return axios
    .get(`${other}/custom-ad/get-remaining-custom-ad/${id}`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIadd = (data) => {
  return axios
    .put(`${other}/custom-ad/assign-custom-ad-to-app`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIdelete = (appId, id) => {
  return axios
    .delete(
      `${other}/custom-ad/remove-assigned-custom-ad-list/${appId}/${id}`,
      { headers }
    )
    .then((res) => res.data)
    .catch((res) => res.data);
};

export { APIInnerlist, APIselectlist, APIadd, APIdelete };
