import React from "react";
import { Group4149Icon } from "./group4149Icon";
import { Group4150Icon } from "./group4150Icon.js";
import { Group4151Icon } from "./group4151Icon.js";
import { Group4152Icon } from "./group4152Icon.js";
import { IconOutlineArrowUpIcon2 } from "./iconOutlineArrowUpIcon2.js";
import { IconOutlineArrowUpIcon } from "./iconOutlineArrowUpIcon.js";
import "./userVisit.css";
import { APIlistVisit } from "../../api-wrapper/ApiDashbord";
import { useEffect } from "react";
import { Toast } from "../../helper/links/Link";
import { useState } from "react";
const UserVisit = () => {
  const [visitData, setVisitData] = useState();
  useEffect(() => {
    APIlistVisit()
      .then((res) => {
        if (res.isSuccess) {
          setVisitData(res.data);
        } else {
          Toast.error(res.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  }, []);
  return (
    <>
      <div className="container d-flex justify-content-center">
        <div className="row">
          <div className="stats col-lg-12 col-md-6 col-sm-6 mt-3">
            <div className="statsCard1">
              
              <div className="todaySTotalUserVisit">
                Today’s Total user Visit
              </div>
              <div className="bottom">
                <div className="price">
                  <div className="_12426">{visitData?.countTodayUserVisit}</div>
                </div>
                <div className="growth">
                  <div
                    className={`_36 ${
                      visitData?.todayPercentage >= 0
                        ? "text-success"
                        : "text-success"
                    }`}
                  >
                    {visitData?.todayIncDec === "Increased"
                      ? "+"
                      : visitData?.todayIncDec === "No Change"
                      ? "+"
                      : ""}
                    &nbsp;&nbsp;
                    {visitData?.todayPercentage.toFixed(2)}%
                  </div>
                  <div className="iconOutlineArrowUp">
                    {visitData?.todayIncDec === "Increased" ? (
                      <IconOutlineArrowUpIcon className="icon" />
                    ) : visitData?.todayIncDec === "No Change" ? (
                      <IconOutlineArrowUpIcon className="icon" />
                    ) : (
                      <IconOutlineArrowUpIcon2 className="icon" />
                    )}
                  </div>
                </div>
              </div>
              <div className="group4149">
                <Group4149Icon className="icon2" />
              </div>
            </div>
          </div>
          <div className="stats2 col-lg-12 col-md-6 col-sm-6 mt-3 ">
            <div className="statsCard12 ">
              <div className="yesterdayTotalUserVisit">
                Yesterday Total user Visit
              </div>
              <div className="bottom2">
                <div className="price2">
                  <div className="_124262">
                    {visitData?.countYesterdayUserVisit}
                  </div>
                </div>
                <div className="growth">
                  <div
                    className={`_362 ${
                      visitData?.yesterdayPercentage >= 0
                        ? "text-success"
                        : "text-danger"
                    }`}
                  >
                    {" "}
                    {visitData?.yesterdayIncDec === "Increased"
                      ? "+"
                      : visitData?.yesterdayIncDec === "No Change"
                      ? "+"
                      : ""}
                    &nbsp;&nbsp;
                    {visitData?.yesterdayPercentage.toFixed(2)}%
                  </div>
                  <div className="iconOutlineArrowUp">
                    {visitData?.yesterdayIncDec === "Increased" ? (
                      <IconOutlineArrowUpIcon className="icon" />
                    ) : visitData?.yesterdayIncDec === "No Change" ? (
                      <IconOutlineArrowUpIcon className="icon" />
                    ) : (
                      <IconOutlineArrowUpIcon2 className="icon" />
                    )}
                  </div>
                </div>
              </div>
              <div className="group4150">
                <Group4150Icon className="icon4" />
              </div>
            </div>
          </div>
          <div className="stats3 col-lg-12 col-md-6 col-sm-6 mt-3">
            <div className="statsCard13">
              <div className="last7DaysUserVisit">Last 7 Days user Visit</div>
              <div className="bottom3">
                <div className="price3">
                  <div className="_124263">
                    {" "}
                    {visitData?.countLastSevenDaysUserVisit}
                  </div>
                </div>
                <div className="growth">
                  <div
                    className={`_363 ${
                      visitData?.lastSevenDaysPercentage >= 0
                        ? "text-success"
                        : "text-danger"
                    }`}
                  >
                    {visitData?.lastSevenDaysIncDec === "Increased"
                      ? "+"
                      : visitData?.lastSevenDaysIncDec === "No Change"
                      ? "+"
                      : ""}
                    &nbsp;&nbsp;
                    {visitData?.lastSevenDaysPercentage.toFixed(2)}%
                  </div>
                  <div className="iconOutlineArrowUp">
                    {visitData?.lastSevenDaysIncDec === "Increased" ? (
                      <IconOutlineArrowUpIcon className="icon" />
                    ) : visitData?.lastSevenDaysIncDec === "No Change" ? (
                      <IconOutlineArrowUpIcon className="icon" />
                    ) : (
                      <IconOutlineArrowUpIcon2 className="icon" />
                    )}
                  </div>
                </div>
              </div>
              <div className="group4151">
                <Group4151Icon className="icon6" />
              </div>
            </div>
          </div>
          <div className="stats4 col-lg-12 col-md-6 col-sm-6 mt-3   ">
            <div className="statsCard14">
              <div className="last30DaysTotalUserVisit">
                Last 30 Days Total user Visit
              </div>
              <div className="bottom4">
                <div className="price4">
                  <div className="_124264">
                    {visitData?.countLastThirtyDaysUserVisit}
                  </div>
                </div>
                <div className="growth">
                  <div
                    className={`_364 ${
                      Number(visitData?.lastThirtyDaysPercentage) >= 0
                        ? "text-success"
                        : "text-danger"
                    }`}
                  >
                    {visitData?.lastThirtyDaysIncDec === "Increased"
                      ? "+"
                      : visitData?.lastThirtyDaysIncDec === "No Change"
                      ? "+"
                      : ""}
                    &nbsp;&nbsp;
                    {visitData?.lastThirtyDaysPercentage.toFixed(2)}%
                  </div>
                  <div className="iconOutlineArrowUp">
                    {visitData?.lastThirtyDaysIncDec === "Increased" ? (
                      <IconOutlineArrowUpIcon className="icon" />
                    ) : visitData?.lastThirtyDaysIncDec === "No Change" ? (
                      <IconOutlineArrowUpIcon className="icon" />
                    ) : (
                      <IconOutlineArrowUpIcon2 className="icon" />
                    )}
                  </div>
                </div>
              </div>
              <div className="group4152">
                <Group4152Icon className="icon8" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserVisit;
