import React, { useEffect, useState } from "react";
import TotalBlock from "./TotalBlock";
import {
  APIlist,
  APIPlayConsole,
  APIDevloper,
  APIReference,
  APIdelete,
} from "../../../api-wrapper/ApiAppList";
import { Link, Toast, PermissionCheck } from "../../../helper/links/Link";
import Card from "react-bootstrap/Card";
import { DateRangePicker } from "rsuite";
import { predefinedRanges } from "../../../helper/calendarValues/calendarValues";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import NoDataImg from "../../../images/g8.png";
import DataTable from "react-data-table-component";
import viewLineSvg from "../../../icons/svgs/eye.svg";
import CIcon from "@coreui/icons-react";
import { cilPlus, cilTrash, cilColorBorder, cibCassandra } from "@coreui/icons";
import getCurrentUser from "../../../utils/getCurrentUser.util";
import DeleteModal from "../../../helper/modal/DeleteModal";
import { cilBell } from "@coreui/icons";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";
function NotificationAppWise() {
  const [totalData, seTotalData] = useState();
  const [filter, setFilter] = useState({ playConsole: "" });
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);
  const curretUser = getCurrentUser();
  const [totalRecords, setTotalRecords] = useState(0);
  const [playConsole, setPlayConsole] = useState([]);
  const [developer, setDeveloper] = useState([]);
  const [reference, setReference] = useState([]);
  const [deleteId, setDeleteId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [date, setDate] = useState([
    new Date(moment().startOf("month")),
    new Date(),
  ]);

  const [currentPage, setcurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const tHeader = [
    {
      name: "Application Logo",
      selector: "appLogo",
      cell: (row) => {
        if (row.appLogo) return <img src={row.appLogo} className="ref-image" />;
        else
          return <div className="default_image"> {row.appName.charAt(0)}</div>;
      },
    },
    {
      name: "Application",
      selector: "appList",
      cell: (item) => {
        return (
          <div>
            <div className="d-block">
              <p className=" text-center m-0">{item.appName}</p>
            </div>
            <div className=" d-block ">
              <p className="m-0 text-center" style={{ color: "darkgray" }}>
                {item.packageName}
              </p>
            </div>
          </div>
        );
      },
    },
    {
      name: "Download",
      selector: "downloadCount",
    },
    {
      name: "Other Details",
      selector: "otherDetails",
      sortable: (row) => row.otherDetails,
      cell: (row) => (
        <div className=" d-block  ">
          <p style={{ textWrap: "wrap" }} className="platform-text m-0">
            Platform:{" "}
            {row?.playConsoleId?.platformId
              ? row?.playConsoleId?.platformId?.name
              : " Not assigned Yet"}
          </p>
        </div>
      ),
    },
    {
      name: "Status",
      selector: "status",
      cell: (item) => (
        <div className="d-block">
          {item?.oneSignalStatus === "Done" ? (
            <button className="btn active-btn fs-6">
              {item.oneSignalStatus}
            </button>
          ) : item?.oneSignalStatus === "Pending" ? (
            <button className="btn text-black inactive-btn fs-6">
              {item.oneSignalStatus}
            </button>
          ) : null}
        </div>
      ),
    },
    {
      name: "Actions",
      selector: "actions",
      cell: (row) => (
        <div className="d-flex gap-2 justify-content-center ">
          {PermissionCheck("Notification App Wise", "Send Notification") && (
            <Link
              to={{ pathname: `/notification-app-wise/${row?._id}` }}
              state={row}
            >
              <button className="btn bell_icon" style={{ color: "white" }}>
                <CIcon icon={cilBell} />
              </button>
            </Link>
          )}
        </div>
      ),
    },
  ];

  const HandleList = (page, perPage) => {
    let data = {
      pageNo: page || 1,
      perPage: perPage || rowsPerPage,
      playConsoleId: filter.playConsole,
      search: search,
    };
    if (PermissionCheck("Notification App Wise", "View Only"))
      APIlist(data)
        .then((res) => {
          if (res.isSuccess) {
            setTableData(res.data);
            seTotalData(res);
            setcurrentPage(res.currentPageNo);
            setTotalRecords(res.totalRecords);
          } else {
            Toast.error(res.message);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
  };
  useEffect(() => {
    HandleList();
  }, [filter]);

  useEffect(() => {
    let data = [];
    APIPlayConsole()
      .then((res) => {
        if (res.isSuccess) {
          res.data.map((el) => {
            data.push({
              label: el.pcName,
              value: el._id,
            });
          });
          setPlayConsole(data);
        } else {
          Toast.error(res?.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  }, []);

  const deleteHandler = () => {
    APIdelete(deleteId)
      .then((res) => {
        if (res.isSuccess) {
          Toast.success(res.message);
          HandleList();
        } else {
          Toast.error(res.message);
        }
        setDeleteId();
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  return (
    <>
      {/* <TotalBlock totalData={totalData} /> */}
      <Card className="rounded-3 p-1 mt-3">
        <Card.Header className="align-items-center gap-4 flex-wrap padding-header">
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="large" />}
            aria-label="breadcrumb"
          >
            <Typography key="1" color="text.primary" className="platform">
              Push Notification
            </Typography>
            <Typography
              key="2"
              color="text.primary"
              className=""
              style={{ color: "#A5A5A5" }}
            >
              Notification App Wise
            </Typography>
          </Breadcrumbs>
          <div className="d-flex gap-3">
            <div className="select-div">
              <select
                className="form-select h-100"
                aria-label="Default select example"
                onChange={(e) =>
                  setFilter({ ...filter, playConsole: e.target.value })
                }
              >
                <option value="" selected>
                  All Play Console
                </option>
                {playConsole?.map((data, index) => (
                  <option key={data.value} value={data.value}>
                    {data.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="align-items-center">
              <div className="input-group mr-3 h-100">
                <input
                  type="text"
                  className="form-control search-box"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />

                <button
                  className="btn btn-outline-secondary border search-btn-icon"
                  type="button"
                  onClick={() => HandleList()}
                >
                  <SearchIcon />
                </button>
              </div>
            </div>
          </div>
        </Card.Header>
        {PermissionCheck("Notification App Wise", "View Only") && (
          <Card.Body>
            {tableData?.length < 1 ? (
              <div className="d-flex w-100 justify-content-center">
                <img src={NoDataImg} alt="no-data" className="w-35" />
              </div>
            ) : (
              <DataTable
                columns={tHeader}
                data={tableData}
                pagination
                paginationServer
                paginationPerPage={rowsPerPage}
                onChangeRowsPerPage={(event) => {
                  setRowsPerPage(parseInt(event));
                  HandleList(currentPage, event);
                }}
                onChangePage={(page) => {
                  HandleList(page);
                }}
                paginationDefaultPage={currentPage}
                paginationTotalRows={totalRecords}
                fixedHeader
              />
            )}
          </Card.Body>
        )}
      </Card>
      {/* delete modal */}

      <DeleteModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        deleteHandler={deleteHandler}
      />
    </>
  );
}

export default NotificationAppWise;
