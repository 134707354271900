import React, { useState } from "react";
import { useEffect } from "react";
import { APIselectlist, APIadd } from "../../api-wrapper/ApiAppWiseAdvertise";
import { Toast } from "../../helper/links/Link";
import {
	CFormLabel,
	CModal,
	CModalBody,
	CModalFooter,
	CModalHeader,
	CModalTitle,
} from "@coreui/react";
import { Formik } from "formik";
import mainLogo from "../../images/mainlogo.png";
import { useParams } from "react-router-dom";
import { AppWiseSchema } from "../../utility/Validator";
import Select from "react-select";
function Add({ visible, setVisible, getData }) {
	let { appId } = useParams();
	const [accountData, setaccountData] = useState([]);
	const [ids, setIDs] = useState([]);
	
	const handleSubmit = (values) => {
		let data = {
			appId: appId,
			customAdIds: ids,
		};
		APIadd(data)
			.then((e) => {
				if (e?.isSuccess) {
					setVisible(false);
					Toast.success(e?.message);
					getData();
				} else {
					Toast.error(e?.message);
				}
			})
			.catch((err) => {
				Toast.error("Something went wrong, please try again!");
			});
	};

	useEffect(() => {
		if (visible) {
			let data = [];

			APIselectlist(appId)
				.then((res) => {
					if (res.isSuccess) {
						res.data.map((el) => {
							data.push({
								label: el.assetsName,
								value: el._id,
							});
						});
						setaccountData(data);
					}
				})
				.catch((err) => {
					Toast.error("Something went wrong, please try again!");
				});
		}
	}, [visible]);


	return (
		<>
			<CModal
				visible={visible}
				alignment="center"
				scrollable
				keyboard={false}
				backdrop="static"
				onClose={() => {
					setVisible(false);
				}}
			>
				<Formik
					validationSchema={AppWiseSchema}
					initialValues={{
						customAdIds: [],
					}}
					onSubmit={(values) => {
						handleSubmit(values);
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						setFieldValue,
					}) => (
						<>
							<CModalHeader>
								<CModalTitle className="">
									<div className="d-flex justify-content-between gap-4">
										<div className="img-div-res">
											<img src={mainLogo} alt="logo" className="img-res" />
										</div>
										<div>
											<p className="h4 pt-2 fw-bold mt-auto text-16">
												Assign To Application
											</p>
										</div>
									</div>
								</CModalTitle>
							</CModalHeader>
							<CModalBody>
								<form onSubmit={handleSubmit}>
									<div className="mb-3">
										<CFormLabel>Select Custom Ad</CFormLabel>
										<Select
											classNamePrefix="form_dropdown"
											placeholder="Select Permision"
											options={accountData}
											name="customAdIds"
											isMulti
											//   value={ids.map((value) => ({
											//     value,
											//     label: accountData.find(
											//       (option) => option.value === value
											//     )?.label,
											//   }))}
											value={ids.find(x => accountData.includes(x.value))}
											onChange={(e) => {
												
												setIDs(e.map((x) => x.value));
												setFieldValue("customAdIds", e);
											}}
										/>
										<p className="error">
											{errors.customAdIds &&
												touched.customAdIds &&
												errors.customAdIds}
										</p>
									</div>
								</form>
							</CModalBody>
							<CModalFooter className="add-play-console-modal-footer d-flex justify-content-center gap-2">
								<button
									className=" btn close-btn bg-white text-black border-danger"
									onClick={() => {
										setVisible(false);
									}}
								>
									Close
								</button>
								<button
									className="btn bg-primary text-light create-apikey-btn"
									onClick={(e) => handleSubmit(e)}
									type="submit"
								>
									save
								</button>
							</CModalFooter>
						</>
					)}
				</Formik>
			</CModal>
		</>
	);
}

export default Add;
