import axios from "axios";

const api = `${process.env.REACT_APP_BACKEND_URL}/notification/`;
const apiOther = `${process.env.REACT_APP_BACKEND_URL}/`;
const authToken = localStorage.getItem("token");
const headers = {
  Authorization: `Bearer ${authToken}`,
  "Content-Type": "application/json",
};
const headersWithForm = {
  Authorization: `Bearer ${authToken}`,
  "Content-Type": "multipart/form-data",
};

const APIaddNotification = (data) => {
  return axios
    .post(`${api}send-app-notification`, data, { headers: headersWithForm })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIaddSetting = (data) => {
  return axios
    .put(`${apiOther}app/manage-one-signal-api-cred`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIlistSetting = (data, id) => {
  return axios
    .post(`${api}get-notification-for-app/${id}`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIdelete = (id) => {
  return axios
    .delete(`${api}delete-app-notification/${id}`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIdeleteMany = (data) => {
  return axios
    .delete(`${api}delete-many-app-notification`, {
      headers: headers,
      data: data,
    })
    .then((res) => res.data)
    .catch((res) => res.data);
};
export {
  APIaddNotification,
  APIaddSetting,
  APIlistSetting,
  APIdelete,
  APIdeleteMany,
};
