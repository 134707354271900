import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiClient } from "../../utils/request.util";
import { Toast } from "@coreui/coreui";

const ViewAppResponse = () => {
  const { appId } = useParams();
  const [jsonData, setJsonData] = useState({});

  useEffect(() => {
    const fetchBackendData = async () => {
      try {
        const response = await apiClient
          .get(`/app/view-app-response/${appId}`)
          .then((res) => {
            const data = res.data;
            setJsonData(data);
          })
          .catch((err) => {
            Toast.error("Something went wrong, please try again!");
          });
      } catch (error) {
        console.error("Error fetching data from the backend:", error);
      }
    };

    fetchBackendData();
  }, [appId]);
  return <pre>{JSON.stringify(jsonData, null, 2)}</pre>;
};

export default ViewAppResponse;
