import React, { useEffect } from "react";
import { Formik } from "formik";
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
  CFormLabel,
  CFormInput,
  CFormSelect,
} from "@coreui/react";
import mainLogo from "../../../images/mainlogo.png";
import { AdminAccessSchema } from "../../../utility/Validator";
import { Toast } from "../../../helper/links/Link";
import { APIadd } from "../../../api-wrapper/ApiAdminAccess";
import { useState } from "react";

function Add({ visible, setVisible, editData, getData }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [changeImg, setchangeImg] = useState(null);

  const handleSubmit = (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("email", values.email);
    formData.append("password", values.password);
    formData.append("role", values.role);
    if (changeImg) {
      formData.append("image", changeImg);
    }
    
    APIadd(formData)
      .then((e) => {

        if (e?.isSuccess) {
          setVisible(false);
          Toast.success(e?.message);
          setSelectedImage(null);
          setchangeImg(null);
          getData();
        } else {
          Toast.error(e?.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  useEffect(() => {
    setSelectedImage(editData?.image);
  }, [editData]);

  return (
    <>
      <CModal
        visible={visible}
        alignment="center"
        scrollable
        keyboard={false}
        backdrop="static"
        onClose={() => {
          setVisible(false);
        }}
      >
        <Formik
          validationSchema={AdminAccessSchema}
          initialValues={{
            name: "",
            email: "",
            password: "",
            role: "",
            image: "",
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <>
              <CModalHeader>
                <CModalTitle className="">

                  <p className="h4 pt-2 fw-bold mt-auto text-16">
                    Admin User
                  </p>

                </CModalTitle>
              </CModalHeader>
              <CModalBody>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <CFormLabel> Name</CFormLabel>

                    <CFormInput
                      type="text"
                      name="name"
                      aria-label="default input example"
                      onChange={handleChange}
                      value={values.name}
                    />
                    <p className="error">
                      {errors.name && touched.name && errors.name}
                    </p>
                  </div>

                  <div className="mb-3">
                    <CFormLabel>Email</CFormLabel>
                    <CFormInput
                      type="text"
                      name="email"
                      aria-label="default input example"
                      onChange={handleChange}
                      value={values.email}
                    />
                    <p className="error">
                      {errors.email && touched.email && errors.email}
                    </p>
                  </div>
                  <div className="mb-3">
                    <CFormLabel>Password</CFormLabel>
                    <CFormInput
                      type="password"
                      name="password"
                      aria-label="default input example"
                      onChange={handleChange}
                      value={values.password}
                    />
                    <p className="error">
                      {errors.password && touched.password && errors.password}
                    </p>
                  </div>
                  <div className="mb-3">
                    <CFormLabel>Role</CFormLabel>
                    <CFormSelect
                      aria-label="Default select example"
                      name="role"
                      onChange={handleChange}
                      value={values.role}
                    >
                      <option key="" value="">
                        Select Role
                      </option>

                      <option key="1" value="1">
                        Admin
                      </option>
                      <option key="2" value="2">
                        Partner
                      </option>
                      <option key="3" value="3">
                        Developer
                      </option>
                    </CFormSelect>
                    <p className="error">
                      {errors.role && touched.role && errors.role}
                    </p>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-12  col-sm-6">
                      <CFormLabel>Upload Image</CFormLabel>
                      <input
                        type="file"
                        className="form-control-file d-block"
                        accept="image/*"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          const imageFile = e.target.files[0];
                          setSelectedImage(null);
                          setchangeImg(imageFile);
                          setFieldValue("image", imageFile);
                        }}
                      />
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="d-flex flex-column gap-4 flex-wrap upload-ref-img-div align-items-center align-items-md-end  pe-4 mt-2 mt-md-0">
                        {selectedImage ? (
                          <img
                            src={selectedImage}
                            alt="reference-img"
                            className="upload-ref-img"
                          />
                        ) : (
                          changeImg && (
                            <img
                              src={URL.createObjectURL(changeImg)}
                              className="upload-ref-img"
                              alt=""
                            />
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </CModalBody>
              <CModalFooter className="add-play-console-modal-footer d-flex justify-content-center gap-2">
                <button
                  className=" btn close-btn bg-white text-black border-danger"
                  onClick={() => {
                    setVisible(false);
                    setchangeImg(null);
                  }}
                >
                  Close
                </button>
                <button
                  className="btn bg-primary text-light create-apikey-btn"
                  onClick={(e) => handleSubmit(e)}
                  type="submit"
                >
                  save
                </button>
              </CModalFooter>
            </>
          )}
        </Formik>
      </CModal>
    </>
  );
}

export default Add;
