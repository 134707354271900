import moment from "moment";
import React from "react";
import { useState } from "react";
import { DateRangePicker } from "rsuite";
import { predefinedRanges } from "../../helper/calendarValues/calendarValues";
import {
  APIlistCategoryChart,
  APIlistConsoleChart,
} from "../../api-wrapper/ApiDashbord";
import { useEffect } from "react";
import NoDataImg from "../../images/g8.png";
import Toast from "../../helper/toast/Toast";
import ReactApexChart from "react-apexcharts";
import "apexcharts/dist/apexcharts.css";
import Accordion from "react-bootstrap/Accordion";
function MonthlyChart() {
  const [date, setDate] = useState([]);
  const [cateDate, setCateDate] = useState([]);
  const [consoleChart, setConsoleChart] = useState([]);
  const [categoryChart, setCategoryChart] = useState([]);
  const consoleOptions = {
    labels: [
      "Live Application",
      "Live Play Console",
      "Reject Applicatoin",
      "Suspended Application",
      "Suspended Play Console",
    ],
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            show: false,
          },
        },
      },
    ],
  };
  const categoryOptions = {
    labels: categoryChart.map((x) => x.categoryName),
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            show: false,
          },
        },
      },
    ],
  };



  useEffect(() => {
    let data = {
      fromDate: date.length == 0 ? "" : moment(date?.[0]).format("YYYY-MM-DD"),
      toDate: date.length == 0 ? "" : moment(date?.[1]).format("YYYY-MM-DD"),
    };
    APIlistConsoleChart(data)
      .then((res) => {
        if (res.isSuccess) {
          setConsoleChart(res.data);
        } else {
          Toast.error(res.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  }, [date]);

  useEffect(() => {
    let data = {
      fromDate:
        cateDate.length == 0 ? "" : moment(cateDate?.[0]).format("YYYY-MM-DD"),
      toDate:
        cateDate.length == 0 ? "" : moment(cateDate?.[1]).format("YYYY-MM-DD"),
    };
    APIlistCategoryChart(data)
      .then((res) => {
        if (res.isSuccess) {
          setCategoryChart(res.data);
        } else {
          Toast.error(res.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  }, [cateDate]);
  return (
    <div className="row mt-4">
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header className="fw-semibold">
            Monthly New App Live
          </Accordion.Header>
          <Accordion.Body>
            <div className="row border-top border-secondary-subtle">
              <div className="col-12 col-lg-auto col-xl-6 border-end border-secondary-subtle cust-col-6-app-status">

                <p className="p-2 fw-semibold fs-6">
                  Application & Play Console
                </p>
                <div className="row d-flex justify-content-end ">
                  <div className="col-5">
                    <DateRangePicker
                      className={`rangeDate custom-date-range-picker px-lg-2 pb-1`}
                      ranges={predefinedRanges}
                      showOneCalendar
                      value={date}
                      placeholder="Select Date Range"
                      onChange={(e) => setDate(e)}
                      placement="right"
                      format="yyyy-MM-dd"
                      cleanable={false}
                      style={{ width: "100%", display: "inline-block" }}
                    />
                  </div>
                </div>
                {
                  consoleChart.length == 0 ?
                    <div className="d-flex w-100 justify-content-center no-data-img">
                      <img src={NoDataImg} alt="no-data" className="w-50" />
                    </div>
                    :
                    <div className=" mt-4">
                      <ReactApexChart
                        options={consoleOptions}
                        series={Object.values(consoleChart)}
                        type="donut"
                        width="100%"
                        height="300px"
                      />
                    </div>
                }


              </div>
              <div className="col-12 col-lg-auto col-xl-6">

                <p className="p-2 fw-semibold fs-6">Categories</p>
                <div className="row d-flex justify-content-end ">
                  <div className="col-5">
                    <DateRangePicker
                      className={`rangeDate custom-date-range-picker px-lg-2 pb-1`}
                      ranges={predefinedRanges}
                      showOneCalendar
                      value={cateDate}
                      placeholder="Select Date Range"
                      onChange={(e) => setCateDate(e)}
                      placement="bottomEnd"
                      format="yyyy-MM-dd"
                      cleanable={false}
                      style={{ width: "100%", display: "inline-block" }}
                    />
                  </div>
                </div>
                {
                  categoryChart.length == 0 ?
                    <div className="d-flex w-100 justify-content-center no-data-img">
                      <img src={NoDataImg} alt="no-data" className="w-50" />
                    </div>
                    :
                    <div className="mt-4">
                      <ReactApexChart
                        options={categoryOptions}
                        series={categoryChart.map((x) => x.downloadCount)}
                        type="donut"
                        width="100%"
                        height="300px"
                      />
                    </div>
                }



              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default MonthlyChart;
