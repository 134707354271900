import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";
import getCurrentUser from "../../utils/getCurrentUser.util";
import { Link, Toast, PermissionCheck } from "../../helper/links/Link";
import { useLocation, useParams } from "react-router-dom";
import ViewDetail from "./ViewDetail";
import { APIDetail } from "../../api-wrapper/ApiAppList";
import { useEffect } from "react";
import { useState } from "react";
import ViewBlock from "./ViewBlock";
import ViewChart from "./ViewChart";
import ViewPlacement from "./ViewPlacement";
import ViewAppStatus from "./ViewAppStatus";
import ViewField from "./ViewField";
import ViewMonetizeSetting from "./ViewMonetizeSetting";
function ViewList() {
  const location = useLocation();
  let { id } = useParams();
  const { state } = location;
  const [allData, setallData] = useState();
  const [modalStatus, setModalStatus] = useState(false);
  const [modalField, setModalField] = useState(false);
  const [viewMode, setviewMode] = useState(false);

  const getData = () => {
    if (id) {
      APIDetail(id)
        .then((res) => {
          setallData(res.data);
        })
        .catch((err) => {
          Toast.error("Something went wrong!!");
        });
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="row">
        <div className="d-flex flex-wrap gap-5 cust-gap-5 justify-content-between">
          <h5 className="card-title-1">
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="large" />}
              aria-label="breadcrumb"
            >
              <Typography key="1" color="text.primary" className="platform">
                All Apps
              </Typography>
              <Typography
                key="2"
                className="platform"
                style={{ color: "#bebebe" }}
              >
                View
              </Typography>
              <Typography
                key="2"
                className="platform"
                style={{ color: "#bebebe" }}
              >
                Application
              </Typography>
            </Breadcrumbs>
          </h5>
          <div className="d-flex gap-4 flex-wrap align-items-center">
            <div>
              {PermissionCheck("All App List", "Change App Status") ? (
                <button
                  className="btn border border-primary text-primary"
                  onClick={() => setModalStatus(true)}
                >
                  Change App Status
                </button>
              ) : null}
            </div>
            {PermissionCheck("All App List", "View App Response") && (
              <div>
                <Link to={`/view-app-response/${allData?._id}`} target="_blank">
                  <button className="btn border border-primary text-primary">
                    App Response
                  </button>
                </Link>
              </div>
            )}

            {PermissionCheck("All App List", "Change More Field") ? (
              <div>
                <button
                  className="btn h-100 border border-primary text-primary"
                  onClick={() => {
                    setModalField(true);
                  }}
                >
                  More Field
                </button>
              </div>
            ) : null}
            {PermissionCheck("All App List", "Edit App") ? (
              <div>
                <button
                  className="btn border border-primary text-primary"
                  onClick={() => {
                    setviewMode(true);
                  }}
                >
                  Edit
                </button>
              </div>
            ) : null}

            <div className="">
              <Link className="btn btn-primary" to="/all-apps">
                Back to list
              </Link>
            </div>
          </div>
        </div>
      </div>

      <ViewDetail allData={allData} getData={getData} />
      <ViewBlock allData={allData} />
      {PermissionCheck("All App List", "Date Wise App Download") && (
        <ViewChart allData={allData} />
      )}

      <ViewPlacement allData={allData} getData={getData} />
      {/* status modal */}
      <ViewAppStatus
        allData={allData}
        modalStatus={modalStatus}
        setModalStatus={setModalStatus}
        getData={getData}
      />

      {/* view modal */}
      <ViewField
        getData={getData}
        allData={allData}
        modalField={modalField}
        setModalField={setModalField}
      />

      <ViewMonetizeSetting
        allData={allData}
        viewMode={viewMode}
        setviewMode={setviewMode}
        getData={getData}
        flag={"application"}
      />
    </>
  );
}

export default ViewList;
