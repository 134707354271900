import React, { useState } from "react";
import { useEffect } from "react";
import NoDataImg from "../../images/g8.png";
import { APIlistTrash, APIrestoreTrash } from "../../api-wrapper/ApiCategory";
import mainLogo from "../../images/mainlogo.png";
import {
  SearchIcon,
  Card,
  DataTable,
  Toast,
  CModal,
  CModalHeader,
  CModalBody,
  CModalTitle,
} from "../../helper/links/Link";
function Restore({ visible, setVisible, getData }) {
  const [search, setSearch] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [arg, setArg] = useState({ pageNo: 1, perPage: 10 });

  const tHeader = [
    {
      name: "Category Name",
      selector: "name",
      sortable: false,
    },

    {
      name: "Status",
      selector: "status",
      cell: (row) => {
        if (row.status)
          return <button className="btn active-btn fs-6">Active</button>;
        else
          return (
            <button className="btn text-black inactive-btn fs-6">
              Deactive{" "}
            </button>
          );
      },
    },
    {
      name: "Actions",
      selector: "actions",
      cell: (row) => (
        <button
          className="btn border border-secondary-subtle btn-success"
          onClick={() => {
            handleRestore(row._id);
          }}
        >
          Restore
        </button>
      ),
    },
  ];

  const handleRestore = (id) => {
    APIrestoreTrash(id)
      .then((e) => {
        if (e?.isSuccess) {
          getListData();
          getData();
          Toast.success(e?.message);
        } else {
          Toast.error(e?.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  const getListData = () => {
    if (visible) {
      let data = {
        ...arg,
        search: search,
      };
      APIlistTrash(data)
        .then((e) => {
          if (e?.isSuccess) {
            setTableData(e?.data);
            setTotalRecords(e.totalRecords);
          } else {
            Toast.error(e?.message);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
    }
  };
  useEffect(() => {
    getListData();
  }, [arg, visible]);
  return (
    <>
      <CModal
        visible={visible}
        alignment="center"
        scrollable
        keyboard={false}
        backdrop="static"
        onClose={() => {
          setVisible(false);
        }}
        size="xl"
      >
        <CModalHeader>
          <CModalTitle className="">
            <p className="h4 pt-2 fw-bold mt-auto text-16">
              Category
            </p>
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <Card className="rounded-3 p-1">
            <Card.Header className="align-items-center gap-4 flex-wrap padding-header">
              <h5 class="card-title">Trash Category</h5>
              <div className="d-flex gap-3 flex-wrap">
                <div className="align-items-center">
                  <div className="input-group mr-3">
                    <input
                      type="text"
                      className="form-control search-box"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />

                    <button
                      className="btn btn-outline-secondary border search-btn-icon"
                      type="button"
                      onClick={() => getListData()}
                    >
                      <SearchIcon />
                    </button>
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              {tableData.length < 1 ? (
                <div className="d-flex w-100 justify-content-center">
                  <img src={NoDataImg} alt="no-data" className="w-50" />
                </div>
              ) : (
                <DataTable
                  columns={tHeader}
                  data={tableData}
                  pagination
                  paginationServer
                  paginationPerPage={10}
                  onChangePage={(page) =>
                    setArg({ pageNo: page, perPage: arg.perPage })
                  }
                  onChangeRowsPerPage={(newPerPage, page) =>
                    setArg({ pageNo: page, perPage: newPerPage })
                  }
                  paginationTotalRows={totalRecords}
                  fixedHeader
                />
              )}
            </Card.Body>
          </Card>
        </CModalBody>
      </CModal>
    </>
  );
}

export default Restore;
