import { memo } from "react";

const Group4152Icon = (props) => (
  <svg
    preserveAspectRatio="none"
    viewBox="0 0 223 253"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={126.5} cy={176.5} r={76.5} fill="white" fillOpacity={0.3} />
    <circle cx={146.5} cy={96.5} r={76.5} fill="white" fillOpacity={0.3} />
    <circle
      cx={76.5}
      cy={76.5}
      r={76.5}
      stroke="white"
      strokeOpacity={0.2}
      strokeWidth={2}
    />
  </svg>
);
const Memo = memo(Group4152Icon);
export { Memo as Group4152Icon };
