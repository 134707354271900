import axios from "axios";

const api = `${process.env.REACT_APP_BACKEND_URL}/platform/`;

const authToken = localStorage.getItem("token");
const headers = {
  Authorization: `Bearer ${authToken}`,
  "Content-Type": "application/json",
};
const headersWithForm = {
  Authorization: `Bearer ${authToken}`,
  "Content-Type": "multipart/form-data",
};

const APIlist = () => {
  return axios
    .get(`${api}get-platforms`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIadd = (data) => {
  return axios
    .post(`${api}add-platform`, data, { headers: headersWithForm })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIedit = (data, id) => {
  return axios
    .put(`${api}modify-platform/${id}`, data, { headers: headersWithForm })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIdelete = (id) => {
  return axios
    .delete(`${api}delete-platform/${id}`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIplatformList = (data, id) => {
  return axios
    .post(`${api}get-platform-wise-playconsoles/${id}`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

export { APIlist, APIadd, APIedit, APIdelete, APIplatformList };
