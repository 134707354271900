import React from "react";

const CopyrightIcon = (props) => (
  <svg
    preserveAspectRatio="none"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.4302 5.94218C10.7304 4.77881 9.4556 4 8 4C5.79199 4 4 5.79199 4 8C4 10.208 5.79199 12 8 12C9.4556 12 10.7304 11.2212 11.4302 10.0578L10.0583 9.23536C9.63864 9.93328 8.87416 10.4 8 10.4C6.674 10.4 5.6 9.326 5.6 8C5.6 6.674 6.674 5.6 8 5.6C8.8744 5.6 9.63928 6.06709 10.0588 6.76544L11.4302 5.94218ZM16 8C16 3.58398 12.416 0 8 0C3.58398 0 0 3.58398 0 8C0 12.416 3.58398 16 8 16C12.416 16 16 12.416 16 8ZM1.6 8C1.6 4.46397 4.46397 1.6 8 1.6C11.536 1.6 14.4 4.46397 14.4 8C14.4 11.536 11.536 14.4 8 14.4C4.46397 14.4 1.6 11.536 1.6 8Z"
      fill="#A5A5A5"
    />
  </svg>
);

export default CopyrightIcon;
