import axios from "axios";
const apiOther = `${process.env.REACT_APP_BACKEND_URL}/`;
const api = `${process.env.REACT_APP_BACKEND_URL}/playConsole/`;

const authToken = localStorage.getItem("token");
const headers = {
  Authorization: `Bearer ${authToken}`,
  "Content-Type": "application/json",
};
const headersWithForm = {
  Authorization: `Bearer ${authToken}`,
  "Content-Type": "multipart/form-data",
};

const APIlist = (data) => {
  return axios
    .post(`${api}get-playConsoles`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIadd = (data) => {
  return axios
    .post(`${api}add-playConsole`, data, { headers: headersWithForm })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIedit = (data, id) => {
  return axios
    .put(`${api}modify-playConsole/${id}`, data, { headers: headersWithForm })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIdelete = (id) => {
  return axios
    .delete(`${api}delete-playConsole/${id}`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIlistPlatform = () => {
  return axios
    .get(`${apiOther}platform/get-platforms`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIView = (id) => {
  return axios
    .get(`${api}get-playConsole/${id}`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

export { APIView, APIlistPlatform, APIlist, APIadd, APIedit, APIdelete };
