import React from "react";
import { Formik } from "formik";
import mainLogo from "../../images/mainlogo.png";
import { CategoryNameSchema } from "../../utility/Validator";
import { APIadd, APIedit } from "../../api-wrapper/ApiCategory";
import {
  CModalFooter,
  Toast,
  CFormSelect,
  CModal,
  CModalHeader,
  CModalBody,
  CModalTitle,
  CFormLabel,
  CFormInput,
} from "../../helper/links/Link";
function Add({ visible, setVisible, editData, getData }) {
  const handleSubmit = (values) => {
    let formData = {
      name: values.name,
    };
    if (editData._id) {
      formData.status = values.status == "Active" ? true : false;
      APIedit(formData, editData._id)
        .then((e) => {
          if (e?.isSuccess) {
            Toast.success(e?.message);
            setVisible(false);
            getData();
          } else {
            Toast.error(e?.message);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
    } else {
      APIadd(formData)
        .then((e) => {
          if (e?.isSuccess) {
            setVisible(false);
            Toast.success(e?.message);
            getData();
          } else {
            Toast.error(e?.message);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
    }
  };

  return (
    <>
      <CModal
        visible={visible}
        alignment="center"
        scrollable
        keyboard={false}
        backdrop="static"
        onClose={() => {
          setVisible(false);
        }}
      >
        <Formik
          validationSchema={CategoryNameSchema}
          initialValues={{
            name: editData?.name,
            status: editData?.status == true ? "Active" : "Deactive",
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <>
              <CModalHeader>
                <CModalTitle className="">

                  <p className="h4 pt-2 fw-bold mt-auto text-16">
                    Category
                  </p>

                </CModalTitle>
              </CModalHeader>
              <CModalBody>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <CFormLabel>Category Name</CFormLabel>

                    <CFormInput
                      type="text"
                      name="name"
                      aria-label="default input example"
                      value={values.name}
                      onChange={handleChange}
                    />
                    <p className="error">
                      {errors.name && touched.name && errors.name}
                    </p>
                  </div>
                  {editData?._id && (
                    <div className="mb-3">
                      <CFormLabel>Developer Status</CFormLabel>
                      <CFormSelect
                        aria-label="Default select example"
                        name="status"
                        value={values.status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      >
                        <option key="Live" value="Active">
                          Active
                        </option>
                        <option key="Suspended" value="Deactive">
                          Deactive
                        </option>
                      </CFormSelect>
                    </div>
                  )}
                </form>
              </CModalBody>
              <CModalFooter className="add-play-console-modal-footer d-flex justify-content-center gap-2">
                <button
                  className=" btn close-btn bg-white text-black border-danger"
                  onClick={() => {
                    setVisible(false);
                  }}
                >
                  Close
                </button>
                <button
                  className="btn bg-primary text-light create-apikey-btn"
                  onClick={(e) => handleSubmit(e)}
                  type="submit"
                >
                  save
                </button>
              </CModalFooter>
            </>
          )}
        </Formik>
      </CModal>
    </>
  );
}

export default Add;
