import React from "react";
import MainDiv from "../../components/FirstDiv/MainDiv";
import FixItDiv from "../../components/FixItDiv/FixItDiv";
import UserVisit from "../../components/userVisit/userVisit";
import Chart from "./Chart";
import MonthlyChart from "./MonthlyChart";
import DateWiseTable from "./DateWiseTable";
import AccountTable from "./AccountTable";
import { Toast, PermissionCheck } from "../../helper/links/Link";
import NoDataImg from "../../images/g8.png";
function dashboard() {
  return (
    <>
      {(
        PermissionCheck("Dashboard", "Platform Images") == true ||
        PermissionCheck("Dashboard", "Fixit App") == true ||
        PermissionCheck("Dashboard", "User Visitor's Info") == true ||
        PermissionCheck("Dashboard", "Download Chart") == true ||
        PermissionCheck("Dashboard", "Monthly New App Live") == true ||
        PermissionCheck("Dashboard", "Date Wise App Download") == true ||
        PermissionCheck("Dashboard", "Top Perfomance Account") == true
      ) ? (
        <>
          {PermissionCheck("Dashboard", "Platform Images") && <MainDiv />}
          {PermissionCheck("Dashboard", "Fixit App") && <FixItDiv />}

          <div className="row">
            {PermissionCheck("Dashboard", "User Visitor's Info") && (
              <div className="col-lg-3 col-md-12 col-sm-12 p-0">
                <UserVisit />
              </div>
            )}
            {PermissionCheck("Dashboard", "Download Chart") && (
              <div className="col-lg-9 col-md-12 col-sm-12">
                <Chart />
              </div>
            )}
          </div>

          {PermissionCheck("Dashboard", "Monthly New App Live") && <MonthlyChart />}

          <div className="row">
            {PermissionCheck("Dashboard", "Date Wise App Download") && (
              <div className="col-lg-6 col-md-12 py-0">
                <DateWiseTable />
              </div>
            )}
            {PermissionCheck("Dashboard", "Top Perfomance Account") && (
              <div className="col-lg-6 col-md-12 py-0">
                <AccountTable />
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="d-flex w-100 justify-content-center no-data-img">
          <img src={NoDataImg} alt="no-data" className="w-50" />
        </div>
      )}
    </>




  );
}

export default dashboard;
