import React, { useState, useEffect } from "react";
import PrivacyPolicyIcon from "../../icons/privacyPolicy.icon";
import TermsAndConditionIcon from "../../icons/termsAndCondition.icon";
import CopyrightIcon from "../../icons/copyRight.icon";

import "./login.css";
import logo from "../../icons/svgs/Vector.svg";
import { Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import ErrorMessageAlert from "../../utils/alert.util";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import sliderimg1 from "../../images/sliderimg1.png";
import sliderimg2 from "../../images/sliderimg2.png";
import sliderimg3 from "../../images/sliderimg3.png";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";
import { CFormCheck } from "@coreui/react";
import { Toast, useDispatch } from "../../helper/links/Link";
import Loader from "../../helper/loader/Loader";
import { useSelector } from "react-redux";
import { APIlogin } from "../../api-wrapper/ApiLogin";

const schema = Yup.object().shape({
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email format"),
  password: Yup.string().required("Password is required"),
});

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    key: 1,
    imgPath: sliderimg1,
  },
  {
    key: 2,
    imgPath: sliderimg2,
  },
  { key: 3, imgPath: sliderimg3 },
];

const Login = () => {
  const navigate = useNavigate();
  const loader = useSelector((state) => state.data.loader);
  let dispatch = useDispatch();
  const [validatedObject, setValidatedObject] = useState({
    isWarning: false,
    message: "",
  });

  const [adminValue, setAdminValue] = useState({
    email: "",
    password: "",
  });

  const [loadingObject, setLoadingObject] = useState({
    isLoading: false,
    isDisable: false,
  });
  const [rememberMe, setRememberMe] = useState(false);
  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };
  const theme = useTheme();

  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const initialValues = {
    email: "",
    password: "",
  };

  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    const storedPassword = localStorage.getItem("password");
    const storedRememberMe = localStorage.getItem("rememberMe");

    if (storedRememberMe === "true") {
      initialValues.email = storedEmail;
      initialValues.password = storedPassword;
      setRememberMe(true);
    }
  }, []);

  const submitLoginForm = async (values) => {
    let { email, password } = values;
    setValidatedObject({ isWarning: false, message: "" });

    APIlogin(values)
      .then((data) => {
        if (data.isSuccess) {
          const accessToken = data.data;

          if (rememberMe) {
            localStorage.setItem("email", email);
            localStorage.setItem("password", password);
            localStorage.setItem("rememberMe", rememberMe);
          }

          localStorage.setItem("token", accessToken);
          Toast.success(data.message);
          // navigate("/dashboard");

          // window.location.reload();
          window.location.href = "/dashboard";
        } else {
          Toast.error(data.message);
        }
      })
      .catch((err) => {
        Toast.error("Something Went Wrong!!");
      });
  };

  return (
    <>
      {loader == true && <Loader />}
      <div className="root-div py-0 p-md-3 p-lg-5 py-lg-0 py-xl-5">
        <div className="row row-cols-1 row-cols-lg-2 p-0 p-sm-3 p-md-4 py-lg-0 py-xl-5 spacing position-relative">
          <div className="col py-1 d-none d-sm-block left-side">
            <div className="frame19 m-auto">
              <div className="frame18">
                <div className="frame5">
                  <div className="welcomeToAppsManager">
                    Welcome to Apps Manager
                  </div>
                  <div className="ifYouWantToSurviveThenGoWithYo">
                    If you want to survive, then Go with your solution. If you
                    want to scale then, go with Apps Manager.
                  </div>
                </div>
                <div className="d-none d-lg-block">
                  <Box sx={{ maxWidth: 700, flexGrow: 1 }}>
                    <AutoPlaySwipeableViews
                      axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                      index={activeStep}
                      onChangeIndex={handleStepChange}
                      enableMouseEvents
                    >
                      {images.map((step, index) => (
                        <div key={index}>
                          {Math.abs(activeStep - index) <= 2 ? (
                            <Box
                              key={index}
                              component="img"
                              sx={{
                                height: 500,
                                display: "flex",
                                maxWidth: 1000,
                                overflow: "hidden",
                                width: "100%",
                              }}
                              className="slider_images"
                              src={step.imgPath}
                            />
                          ) : null}
                        </div>
                      ))}
                    </AutoPlaySwipeableViews>
                    <MobileStepper
                      key={maxSteps}
                      steps={maxSteps}
                      position="static"
                      activeStep={activeStep}
                      nextButton={
                        <Button
                          className="login_slider"
                          size="small"
                          onClick={handleNext}
                          disabled={activeStep === maxSteps - 1}
                        >
                          {theme.direction === "rtl" ? (
                            <KeyboardArrowLeft />
                          ) : (
                            <KeyboardArrowRight />
                          )}
                        </Button>
                      }
                      backButton={
                        <Button
                          className="login_slider"
                          size="small"
                          onClick={handleBack}
                          disabled={activeStep === 0}
                        >
                          {theme.direction === "rtl" ? (
                            <KeyboardArrowRight />
                          ) : (
                            <KeyboardArrowLeft />
                          )}
                        </Button>
                      }
                    />
                  </Box>
                </div>
              </div>
            </div>
            <div className="frame20">
              <div className="frame6  d-none d-lg-flex">
                <div className="_30767091">
                  <PrivacyPolicyIcon className="icon54" />
                </div>
                <div className="privacyPolicy">Privacy Policy</div>
              </div>
              <div className="frame7 ms-2 ms-md-4 d-none d-lg-flex">
                <div className="_384263620011">
                  <TermsAndConditionIcon className="icon55" />
                </div>
                <div className="termsCondition">Terms &amp; Condition</div>
              </div>
            </div>
          </div>
          <div className="col align-self-center pb-5 pt-5 pt-sm-0 pt-lg-5 right-side">
            <div className="" id="form">
              <Formik
                validationSchema={schema}
                initialValues={initialValues}
                onSubmit={(values) => {
                  submitLoginForm(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <div className="frame17 pt-5 pt-sm-3 pt-lg-4">
                    <div className="frame16">
                      <div className="frame2">
                        <div className="frame">
                          <img src={logo} alt="logo" className="icon57 " />
                        </div>
                        <div className="appsManager">
                          <p className="labelWrapper">
                            <span className="label">Apps</span>
                            <span className="label2"> Manager</span>
                          </p>
                        </div>
                      </div>
                      <div className="aSatisfiedSolutionIsAKeyToEver">
                        <div className="textBlock">
                          A Satisfied Solution is a Key to Every{" "}
                        </div>
                        <div className="textBlock2">Successfull Business</div>
                      </div>
                    </div>

                    {validatedObject.isWarning && (
                      <ErrorMessageAlert
                        message={validatedObject.message}
                      ></ErrorMessageAlert>
                    )}

                    <Form onSubmit={handleSubmit}>
                      <div className="frame15 px-0 px-lg-5">
                        <div className="frame14">
                          <div className="frame12">
                            <Form.Label className="emailAddress">
                              Email Address
                            </Form.Label>
                            <div className="frame9">
                              <Form.Control
                                type="text"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="emailAddress2"
                                id="email"
                              />
                            </div>
                            <p className="error">
                              {errors.email && touched.email && errors.email}
                            </p>
                          </div>
                          <div className="frame13">
                            <Form.Label className="password">
                              Password
                            </Form.Label>
                            <div className="frame10">
                              <Form.Control
                                className="password2"
                                name="password"
                                value={values.password}
                                type="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                            <p className="error">
                              {errors.password &&
                                touched.password &&
                                errors.password}
                            </p>
                          </div>
                          <div className="d-flex justify-content-space-between gap-3 mt-2 mb-2">
                            <CFormCheck
                              inline
                              type="checkbox"
                              label="Remember Me"
                              name={"rememberMe"}
                              id={"rememberMe"}
                              checked={rememberMe || false}
                              onChange={handleRememberMeChange}
                            />
                          </div>
                        </div>
                        <div className="frame11 text-center py-0 py-lg-3 pt-3 pt-lg-5 py-xl-0">
                          <Button
                            variant="primary"
                            type="submit"
                            className="w-50 mb-3 mb-lg-5"
                          >
                            Login
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                )}
              </Formik>
              <div className="frame8">
                <div className="copyright">Copyright </div>
                <div className="vector">
                  <CopyrightIcon className="icon56" />
                </div>
                <div className="_2023AppsManagerAllRightsReserv">
                  2023 Apps Manager. All Rights Reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingObject.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer
        // position="top-right"
        // autoClose={900}
        // hideProgressBar={false}
        newestOnTop={false}
        // closeOnClick
        rtl={false}
        // pauseOnFocusLoss
        // draggable
        // pauseOnHover
        // theme="light"
      />
      <ToastContainer />
    </>
  );
};

export default Login;
