import React from "react";
import privacyPolicySvg from "../../icons/svgs/privacy-policy-link.svg";
import createDatesvg from "../../icons/svgs/created-date-svg.svg";
import dateSvg from "../../icons/svgs/date-svg.svg";
import appUserSvg from "../../icons/svgs/app-user-svg.svg";
import checkSvg from "../../icons/svgs/check-svg.svg";
import crossSvg from "../../icons/svgs/cross-svg.svg";
import { Form } from "react-bootstrap";
import { useState } from "react";
import { useEffect } from "react";
import { APIchangeStatus } from "../../api-wrapper/ApiAppList";
import { useParams } from "react-router-dom";
import { moment, Card, Toast } from "../../helper/links/Link";
function ViewDetail({ allData, getData }) {
  let { id } = useParams();
  const [arr1, setArr1] = useState([]);
  const [arr2, setArr2] = useState([]);

  useEffect(() => {
    setArr1([
      {
        label: "Need Internet Compulsory?",
        name: "needInternetCompulsory",
        status: allData?.needInternetCompulsory,
      },
      {
        label: "Version Update Dialog?",
        name: "versionUpdateDialog",
        status: allData?.versionUpdateDialog,
      },
      {
        label: "Show Ad in App?",
        name: "showAdInApp",
        status: allData?.showAdInApp,
      },
      {
        label: "Show Test Ad in Debug App?",
        name: "showTestAdInDebugApp",
        status: allData?.showTestAdInDebugApp,
      },
    ]);
  }, [allData]);

  useEffect(() => {
    setArr2([
      {
        label: "Location Wise Report?",
        name: "locationWiseReport",
        status: allData?.locationWiseReport,
      },
      {
        label: "Show Dialog Before Ad Show?",
        name: "showDialogBeforeAdShow",
        status: allData?.showDialogBeforeAdShow,
      },
      { label: "More App?", name: "moreApp", status: allData?.moreApp },
      {
        label: "Redirect Other App?",
        name: "redirectToOtherApp",
        status: allData?.redirectToOtherApp,
      },
    ]);
  }, [allData]);

  const HandleChnage = (e) => {
    let data = {
      [e.target.name]: e.target.checked,
    };
    APIchangeStatus(data, id)
      .then((res) => {
        if (res.isSuccess) {
          Toast.success(res.message);
          getData();
        } else {
          Toast.error(res?.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  return (
    <div className="row view_detail m-1 pt-3 pb-3">
      <Card className="rounded-3 p-1">
        <Card.Body>
          <div className="row justify-content-center align-items-center">
            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 p-2">
              <div className="app-bg-img d-flex flex-wrap justify-content-center">
                {allData?.appLogo ? (
                  <div
                    className="app-bg d-flex flex-wrap rounded-3"
                    style={{
                      backgroundImage: `url(${allData?.appLogo})`,
                      width: "110px",
                      height: "110px",
                    }}
                  ></div>
                ) : (
                  <div
                    className="default_image"
                    style={{
                      width: "110px",
                      height: "110px",
                      fontSize: "29px",
                    }}
                  >
                    {" "}
                    {allData?.appName?.charAt(0)}
                  </div>
                )}
              </div>
              <div className="app-status p-3 ms-0 d-flex flex-wrap justify-content-center">
                <button className="btn bg-primary text-light">
                  {allData?.appStatus}
                </button>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 p-2">
              <div>
                <div className="app-name pt-0">
                  <p className="h4 fw-semibold">{allData?.appName}</p>
                </div>
                <div className="app-package-name pt-1">
                  <p>{allData?.packageName}</p>
                </div>
                <div className="px-0">
                  <div className="app-name-details d-flex flex-wrap pt-2">
                    <div className="col-1 col-md-2 rs-col-2">
                      <div>
                        <img src={privacyPolicySvg} />
                      </div>
                    </div>
                    <div className="col-10 rs-col-10">
                      <a href={allData?.appPrivacyPolicyLink} target="_blank">
                        Privacy Policy Link
                      </a>
                    </div>
                  </div>
                  <div className="app-name-details d-flex flex-wrap pt-2">
                    <div className="col-1 col-md-2 rs-col-2">
                      <div>
                        <img src={createDatesvg} />
                      </div>
                    </div>
                    <div className="col-10 rs-col-10">
                      <p className="mb-0">
                        Created Date:{" "}
                        {moment(allData?.createdAt).format(
                          "DD-MM-YYYY hh:ss A"
                        )}
                      </p>
                    </div>
                  </div>
                  {allData?.publishStatusDate && (
                    <div className="app-name-details d-flex flex-wrap pt-2">
                      <div className="col-1 col-md-2 rs-col-2">
                        <div>
                          <img src={dateSvg} />
                        </div>
                      </div>

                      <div className="col-10 rs-col-10">
                        <p className="mb-0">
                          Publish Date:{" "}
                          {moment(allData?.publishStatusDate).format(
                            "DD-MM-YYYY hh:ss A"
                          )}
                        </p>
                      </div>
                    </div>
                  )}

                  <div className="app-name-details d-flex flex-wrap pt-2">
                    <div className="col-1 col-md-2 rs-col-2">
                      <div>
                        <img src={appUserSvg} />
                      </div>
                    </div>
                    <div className="col-10 rs-col-10">
                      <p>User: {allData?.developerId?.name}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row app_border col-xl-7 col-lg-12">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 p-2">
                <div className="main-cont-div-1 p-2 ">
                  {arr1?.map((el) => {
                    return (
                      <div className="d-flex flex-wrap p-2 justify-content-between">
                        <div className="col-8 col-md-9 col-lg-10">
                          <p className="fs-6">{el.label}</p>
                        </div>
                        <div className="col-4 col-md-3 col-lg-2">
                          <div className="row">
                            <div className="col-4">
                              <img src={el?.status ? checkSvg : crossSvg} />
                            </div>
                            <div className="col-8">
                              <Form>
                                <Form.Check
                                  className="ms-3"
                                  type="switch"
                                  id={el.name}
                                  name={el.name}
                                  checked={el.status}
                                  onChange={(e) => HandleChnage(e)}
                                />
                              </Form>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 p-2 ps-lg-4">
                <div className="main-cont-div-1 p-2 ">
                  {arr2?.map((el) => {
                    return (
                      <div className="d-flex flex-wrap p-2 justify-content-between">
                        <div className="col-8 col-md-9 col-lg-10">
                          <p className="fs-6">{el.label}</p>
                        </div>
                        <div className="col-4 col-md-3 col-lg-2">
                          <div className="row">
                            <div className="col-4">
                              <img src={el?.status ? checkSvg : crossSvg} />
                            </div>
                            <div className="col-8">
                              <Form>
                                <Form.Check
                                  className="ms-3"
                                  type="switch"
                                  id={el.name}
                                  name={el.name}
                                  checked={el.status}
                                  onChange={(e) => HandleChnage(e)}
                                />
                              </Form>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ViewDetail;
