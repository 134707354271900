import React, { useEffect, useState } from "react";
import {
  CModalFooter,
  moment,
  cilTrash,
  SearchIcon,
  CIcon,
  cilPlus,
  Card,
  DataTable,
  Toast,
  CFormSelect,
  Typography,
  NavigateNextIcon,
  Breadcrumbs,
  Link,
  CModal,
  CModalHeader,
  CModalBody,
  CModalTitle,
  CFormLabel,
  CFormInput,
} from "../../helper/links/Link";
import mainLogo from "../../images/mainlogo.png";
import { APIView } from "../../api-wrapper/ApiPlayConsole";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import NoDataImg from "../../images/g8.png";
import Box from "@mui/material/Box";
function View({ setviewMode, viewMode, setviewId, viewId }) {
  const [addPlayConsoleData, setAddPlayConsoleData] = useState();
  const [tabValue, setTabValue] = useState("Rejected");

  const [inReviewApp, setInReviewApp] = useState([]);
  const [rejectedAppApp, setrejectedAppApp] = useState([]);
  const [suspendedApp, setsuspendedApp] = useState([]);
  const [liveApp, setliveApp] = useState([]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const tHeader = [
    {
      name: "Logo",
      selector: "appLogo",
      cell: (row) => {
        if (row?.appLogo)
          return <img src={row?.appLogo} className="ref-image" />;
        else
          return (
            <div className="default_image"> {row?.appName?.charAt(0)}</div>
          );
      },
    },
    {
      name: "Name",
      selector: "appName",
    },
    {
      name: "Developer Name",
      selector: (row) => row?.developerId?.name,
    },
    {
      name: "Reference By",
      selector: (row) => row?.referenceId?.name,
    },
    {
      name: "Version",
      selector: "version",
    },
  ];
  useEffect(() => {
    if (viewId) {
      APIView(viewId)
        .then((e) => {
          if (e?.isSuccess) {
            setAddPlayConsoleData(e.data);
            setInReviewApp(e.data.InReviewApp);
            setrejectedAppApp(e.data.RejectApp);
            setsuspendedApp(e.data.SuspendApp);
            setliveApp(e.data.LiveApp);
          } else {
            Toast.error(e?.message);
          }
        })
        .catch((e) => {
          Toast.error("Something Went Wrong!!");
        });
    }
  }, [viewMode, viewId]);

  return (
    <>
      <CModal
        visible={viewMode}
        alignment="center"
        scrollable
        keyboard={false}
        backdrop="static"
        onClose={() => {
          setviewMode(false);
        }}
        size="xl"
      >
        <CModalHeader className="px-1 px-md-3">
          <CModalTitle>
            <div className="d-flex justify-content-center gap-cust-4 gap-4-cust">
              <div className="img-div-res">
                <img src={mainLogo} alt="logo" className="img-res" />
              </div>
              <div>
                <p className="h4 pt-2 fw-bold mt-auto text-16">
                  View App Details
                </p>
              </div>
            </div>
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div className="row row-cols-1 row-cols-lg-2  justify-content-center mb-2">
            <div className="row row-cols-2 align-items-center">
              <div className="col ps-5">
                {addPlayConsoleData?.image ? (
                  <img
                    src={addPlayConsoleData?.image}
                    className="console-bg-img rounded-3"
                  />
                ) : (
                  <div
                    className="default_image rounded-3"
                    style={{ height: "80px", width: "80px" }}
                  >
                    {" "}
                    {addPlayConsoleData?.pcName.charAt(0)}
                  </div>
                )}
              </div>
              <div className="col second-pc-name-div">
                <p className="pc-name mb-0">
                  <b>{addPlayConsoleData?.pcName}</b>
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="mobile-border"></div>
              <div className="col-12 row-view-app">
                <div className="row p-1">
                  <div className="col-5 total-apps-text  ps-4 ps-lg-5">
                    <p className="details-text  ps-0  ps-xl-5">Device Name</p>
                  </div>
                  <div className="col-7 total-apps-no">
                    <p className="details-text text-break">
                      <span className="pe-2 pe-lg-5">:</span>
                      {addPlayConsoleData?.deviceName}
                    </p>
                  </div>
                </div>
                <div className="row p-1">
                  <div className="col-5 total-apps-text ps-4 ps-lg-5">
                    <p className="details-text ps-0  ps-xl-5">Mobile No</p>
                  </div>
                  <div className="col-7 total-apps-no">
                    <span className="pe-2 pe-lg-5">:</span>
                    <span className="details-text text-break">
                      {addPlayConsoleData?.mobile}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 second-border row-view-app">
                <div className="row p-1">
                  <div className="col-5 total-apps-text ps-4 ps-lg-5 ">
                    <p className="details-text ps-0  ps-xl-5">Email</p>
                  </div>
                  <div className="col-7 total-apps-no">
                    <span className="pe-2 pe-lg-5 h-100">:</span>
                    <p
                      className="details-text text-break font-italic d-inline"
                      style={{ fontSize: "14px", textOverflow: "ellipsis" }}
                    >
                      {addPlayConsoleData?.email}
                    </p>
                  </div>
                </div>
                <div className="row p-1">
                  <div className="col-5 total-apps-text ps-4 ps-lg-5">
                    <p className="details-text text-break ps-0  ps-xl-5">
                      Password
                    </p>
                  </div>
                  <div className="col-7 total-apps-no">
                    <p className="details-text text-break">
                      <span className="pe-2 pe-lg-5">:</span>
                      {addPlayConsoleData?.password}
                    </p>
                  </div>
                </div>
                <div className="row p-1">
                  <div className="col-5 total-apps-text ps-4 ps-lg-5">
                    <p className="details-text text-break  ps-0  ps-xl-5">
                      URL
                    </p>
                  </div>
                  <div className="col-7 total-apps-no">
                    <p className="details-text text-break ">
                      <span className="pe-2 pe-lg-5">:</span>{" "}
                      {addPlayConsoleData?.url ? (
                        <Link to={`${addPlayConsoleData?.url}`} target="_blank">
                          <p className="d-inline">{addPlayConsoleData?.url}</p>
                        </Link>
                      ) : (
                        "--------"
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col all_apps_list">
              <div className="row g-2">
                <div className="col-6 ">
                  <div className=" block_1 border-12 ">
                    <h4 className="text-h4-apps pt-2">
                      {addPlayConsoleData?.countInReview || 0}
                    </h4>
                    <p className="mb-0 text-center pb-2">App In-Review</p>
                  </div>
                </div>
                <div className="col-6">
                  <div className="block_1 border-12 ">
                    <h4 className="text-h4-apps pt-2">
                      {addPlayConsoleData?.countReject || 0}
                    </h4>
                    <p className="mb-0 text-center pb-2">App Rejected</p>
                  </div>
                </div>
                <div className="col">
                  <div className="block_1 border-12 ">
                    <h4 className="text-h4-apps  pt-2">
                      {addPlayConsoleData?.countLive || 0}
                    </h4>
                    <p className="mb-0 text-center pb-2">App Live</p>
                  </div>
                </div>
                <div className="col">
                  <div className="block_1 border-12 ">
                    <h4 className="text-h4-apps pt-2">
                      {addPlayConsoleData?.countSuspend || 0}
                    </h4>
                    <p className="mb-0 text-center pb-2">App Suspend</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <Box sx={{ typography: "body1" }}>
              <TabContext value={tabValue}>
                <Box
                  sx={{
                    width: "100%",
                    borderBottom: 1,
                    borderColor: "divider",
                  }}
                >
                  <TabList
                    onChange={handleTabChange}
                    aria-label="lab API tabs example"
                    className="app-tab-list"
                  >
                    <Tab label="App InReview" value="InReview" />
                    <Tab label="App Rejected" value="Rejected" />
                    <Tab label="App Suspend" value="Suspended" />
                    <Tab label="App Live" value="Updates" />
                  </TabList>
                </Box>

                <TabPanel value="InReview">
                  {inReviewApp.length == 0 ? (
                    <div className="d-flex w-100 justify-content-center no-data-img">
                      <img src={NoDataImg} alt="no-data" className="w-50" />
                    </div>
                  ) : (
                    <DataTable columns={tHeader} data={inReviewApp} />
                  )}
                </TabPanel>
                <TabPanel value="Rejected">
                  {rejectedAppApp.length < 1 ? (
                    <div className="d-flex w-100 justify-content-center no-data-img">
                      <img src={NoDataImg} alt="no-data" className="w-50" />
                    </div>
                  ) : (
                    <DataTable columns={tHeader} data={rejectedAppApp} />
                  )}
                </TabPanel>
                <TabPanel value="Suspended">
                  {suspendedApp.length < 1 ? (
                    <div className="d-flex w-100 justify-content-center no-data-img">
                      <img src={NoDataImg} alt="no-data" className="w-50" />
                    </div>
                  ) : (
                    <DataTable columns={tHeader} data={suspendedApp} />
                  )}
                </TabPanel>
                <TabPanel value="Updates">
                  {liveApp.length < 1 ? (
                    <div className="d-flex w-100 justify-content-center no-data-img">
                      <img src={NoDataImg} alt="no-data" className="w-50" />
                    </div>
                  ) : (
                    <DataTable columns={tHeader} data={liveApp} />
                  )}
                </TabPanel>
              </TabContext>
            </Box>
          </div>
        </CModalBody>
      </CModal>
    </>
  );
}

export default View;
