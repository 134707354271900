import React, { useEffect } from "react";
import { Formik } from "formik";
import {
	CModal,
	CModalHeader,
	CModalBody,
	CModalFooter,
	CModalTitle,
	CFormLabel,
	CFormInput,
	CFormSelect,
} from "@coreui/react";
import mainLogo from "../../images/mainlogo.png";

import { Toast } from "../../helper/links/Link";
import { APIedit } from "../../api-wrapper/ApiCreateApi";
import { useState } from "react";

function Edit({ setEditModal, editModal, showData, getData }) {
	const [selectedImage, setSelectedImage] = useState(null);
	const [changeImg, setchangeImg] = useState(null);
	const [jsonResponse, setJsonResponse] = useState();

	console.log(showData, "showData")
	const handleSubmit = (values) => {
		let data = {
			jsonResponse: jsonResponse,

		};

		APIedit(data, showData._id)
			.then((e) => {

				if (e?.isSuccess) {
					setEditModal(false);
					Toast.success(e?.message);
					getData();
				} else {
					Toast.error(e?.message);
				}
			})
			.catch((err) => {
				Toast.error("Something went wrong, please try again!");
			});
	};

	useEffect(() => {
		setJsonResponse(showData?.jsonResponse)
	}, [showData]);
	return (
		<>
			<CModal
				size="xl"
				visible={editModal}
				alignment="center"
				scrollable
				keyboard={false}
				backdrop="static"
				onClose={() => {
					setEditModal(false);
				}}
			>
				<Formik
					initialValues={{
						name: "",
						email: "",
						password: "",
						role: "",
						image: "",
					}}
					onSubmit={(values) => {
						handleSubmit(values);
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						setFieldValue,
					}) => (
						<>
							<CModalHeader>
								<CModalTitle className="">
									<p className="h4 pt-2 fw-bold mt-auto text-16">
										Edit Response
									</p>
								</CModalTitle>
							</CModalHeader>
							<CModalBody>
								<form onSubmit={handleSubmit}>
									<div className="mb-3">
										<CFormLabel> Response</CFormLabel>
										<br />

										<textarea
											rows="10"
											style={{ width: "100%" }}
											name="jsonResponse"
											onChange={(e) => setJsonResponse(e.target.value)}
											value={jsonResponse}
										/>
									</div>
								</form>
							</CModalBody>
							<CModalFooter className="add-play-console-modal-footer d-flex justify-content-center gap-2">
								<button
									className=" btn close-btn bg-white text-black border-danger"
									onClick={() => {
										setEditModal(false);
									}}
								>
									Close
								</button>
								<button
									className="btn bg-primary text-light create-apikey-btn"
									onClick={(e) => handleSubmit(e)}
									type="submit"
								>
									save
								</button>
							</CModalFooter>
						</>
					)}
				</Formik>
			</CModal>
		</>
	);
}

export default Edit;
