import React, { useEffect, useState } from "react";
import TotalBlock from "./TotalBlock";
import {
  APIlist,
  APIPlayConsole,
  APIDevloper,
  APIReference,
  APIdelete,
} from "../../api-wrapper/ApiAppList";
import { DateRangePicker } from "rsuite";
import { predefinedRanges } from "../../helper/calendarValues/calendarValues";
import NoDataImg from "../../images/g8.png";
import { cilViewQuilt } from "@coreui/icons";
import getCurrentUser from "../../utils/getCurrentUser.util";
import DeleteModal from "../../helper/modal/DeleteModal";
import {
  moment,
  cilTrash,
  SearchIcon,
  CIcon,
  Card,
  DataTable,
  Toast,
  cilColorBorder,
  PermissionCheck,
  Link,
} from "../../helper/links/Link";
import ViewMonetizeSetting from "./ViewMonetizeSetting";
import { APIDetail } from "../../api-wrapper/ApiAppList";
import { useLocation } from "react-router-dom";

function AppList() {
  const location = useLocation();
  const { state } = location;

  const [totalData, seTotalData] = useState();
  const [date, setDate] = useState([]);
  const [filter, setFilter] = useState({
    playConsole: "",
    developer: "",
    reference: "",
  });
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);
  const curretUser = getCurrentUser();

  const [totalRecords, setTotalRecords] = useState(0);
  const [playConsole, setPlayConsole] = useState([]);
  const [developer, setDeveloper] = useState([]);
  const [reference, setReference] = useState([]);
  const [deleteId, setDeleteId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [viewMode, setviewMode] = useState(false);
  const [allData, setallData] = useState();
  const [status, setStatus] = useState("Live");
  const [currentPage, setcurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [playValue, setPlayValue] = useState(state?._id || "");

  const tHeader = [
    {
      name: "Logo",
      selector: "appLogo",
      width: "90px",
      cell: (row) => {
        if (row.appLogo) return <img src={row.appLogo} className="ref-image" />;
        else
          return <div className="default_image"> {row.appName.charAt(0)}</div>;
      },
    },
    {
      name: "Application List",
      selector: "appList",
      cell: (item) => {
        return (
          <div>
            <div className="d-block ">
              <p className=" m-0 text-center">{item.appName}</p>
            </div>
            {PermissionCheck("All App List", "Hide Package Name") && (
              <div className=" d-block pt-1">
                <p className="m-0 text-center" style={{ color: "darkgray" }}>
                  {item.packageName}
                </p>
              </div>
            )}
          </div>
        );
      },
    },
    {
      name: "Download",
      selector: "download",
      cell: (e) => (
        <div>
          <div className=" d-block ">
            <p className=" m-0">
              {" "}
              {moment(e?.createdAt).format("MM/DD/YYYY ")}
            </p>
          </div>
          <div className=" d-block pt-1">
            <p className="m-0 text-center" style={{ color: "darkgray" }}>
              {e?.downloadCount}
            </p>
          </div>
        </div>
      ),
    },
    {
      name: "Other Details",
      selector: "otherDetails",
      sortable: (row) => row.otherDetails,
      cell: (row) => (
        <div className=" d-block p-1 ">
          <p style={{ textWrap: "wrap" }} className="platform-text m-0">
            Platform:{" "}
            {row?.playConsoleId?.platformId
              ? row?.playConsoleId?.platformId?.name
              : " Not assigned Yet"}
          </p>
        </div>
      ),
    },
    {
      name: "Status",
      selector: "status",
      with: "150px",
      cell: (item) => (
        <>
          {item?.appStatus == "Live" ? (
            <button className="btn active-btn fs-6 ">{item.appStatus}</button>
          ) : item?.appStatus == "InReview" ? (
            <button className="btn text-black inactive-btn fs-6">
              {item.appStatus}
            </button>
          ) : item?.appStatus == "Rejected" ? (
            <button className="btn text-black reject-btn fs-6">
              {item.appStatus}
            </button>
          ) : item?.appStatus == "Suspend" ? (
            <button className="btn text-black remove-btn fs-6">
              {item.appStatus}
            </button>
          ) : (
            <button className="btn text-black reject-btn fs-6">
              {item.appStatus}
            </button>
          )}
        </>
      ),
    },
    {
      name: "Actions",
      selector: "actions",
      width: "200px",
      cell: (row) => (
        <div className="d-flex gap-2 justify-content-center ">
          <Link
            className="btn bell_icon"
            to={`/all-apps/${row._id}`}
            state={row}
          >
            <CIcon icon={cilViewQuilt} />
          </Link>

          {PermissionCheck("All App List", "Edit App") ? (
            <button
              onClick={() => {
                getData(row?._id);
              }}
              className="btn  edit_icon"
            >
              <CIcon icon={cilColorBorder} />
            </button>
          ) : null}
          {PermissionCheck("All App List", "Remove App") ? (
            <button
              className="btn  delete_icon"
              onClick={() => {
                setShowDeleteModal(true);
                setDeleteId(row._id);
              }}
            >
              <CIcon icon={cilTrash} className="icon-trash text-red" />
            </button>
          ) : null}
        </div>
      ),
    },
  ];

  const getData = (id) => {
    if (id)
      APIDetail(id)
        .then((res) => {
          setallData(res.data);
          setviewMode(true);
        })
        .catch((err) => {
          Toast.error("Something went wrong!!");
        });
  };

  const HandleList = (page, perPage) => {
    let data = {
      pageNo: page || 1,
      perPage: perPage || rowsPerPage,
      playConsoleId: playValue,
      developerId: filter.developer,
      referenceId: filter.reference,
      search: search,
      fromDate: date.length == 0 ? "" : moment(date?.[0]).format("YYYY-MM-DD"),
      toDate: date.length == 0 ? "" : moment(date?.[1]).format("YYYY-MM-DD"),
      appStatus: status,
    };
    APIlist(data)
      .then((res) => {
        if (res.isSuccess) {
          setTableData(res.data);
          seTotalData(res);
          setcurrentPage(res.currentPageNo);
          setTotalRecords(res.totalRecords);
        } else {
          Toast.error(res.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };
  useEffect(() => {
    HandleList();
  }, [filter, date, status]);

  useEffect(() => {
    let data = [];
    APIPlayConsole()
      .then((res) => {
        if (res.isSuccess) {
          res.data.map((el) => {
            data.push({
              label: el.pcName,
              value: el._id,
            });
          });
          setPlayConsole(data);
        } else {
          Toast.error(res?.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  }, []);

  useEffect(() => {
    let data = [];
    APIDevloper()
      .then((res) => {
        if (res.isSuccess) {
          res.data.map((el) => {
            data.push({
              label: el.name,
              value: el._id,
            });
          });
          setDeveloper(data);
        } else {
          Toast.error(res?.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  }, []);
  useEffect(() => {
    let data = [];
    APIReference()
      .then((res) => {
        if (res.isSuccess) {
          res.data.map((el) => {
            data.push({
              label: el.name,
              value: el._id,
            });
          });

          setReference(data);
        } else {
          Toast.error(res?.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  }, []);

  const deleteHandler = () => {
    APIdelete(deleteId)
      .then((res) => {
        if (res.isSuccess) {
          Toast.success(res.message);
          HandleList();
        } else {
          Toast.error(res.message);
        }
        setDeleteId();
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  // useEffect(() => {
  //   let item;
  //   if (state?._id) {
  //     setPlayValue(state?._id)
  //     HandleList()
  //   }

  // }, [state?._id]);
  return (
    <>
      {PermissionCheck("All App List", "Counting App") && (
        <TotalBlock totalData={totalData} />
      )}

      <Card className="rounded-3 p-1 mt-3">
        <Card.Header className="">
          <div className="list_item">
            <div className="">
              <h5 class="card-title"> Application</h5>
            </div>
            <div className="">
              <DateRangePicker
                className={`rangeDate custom-date-range-picker `}
                ranges={predefinedRanges}
                showOneCalendar
                value={date}
                placeholder="Select Date Range"
                onChange={(e) => setDate(e)}
                placement="bottomEnd"
                format="yyyy-MM-dd"
                cleanable={false}
              />
            </div>
            <div className="">
              <select
                className="form-select h-100"
                aria-label="Default select example"
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value=""> All App Status</option>
                <option value="Live" selected>
                  {" "}
                  Live
                </option>
                <option value="InReview"> InReview</option>
                <option value="Rejected"> Rejected</option>
                <option value="Suspend"> Suspend</option>
              </select>
            </div>
            <div className="">
              <select
                className="form-select h-100"
                aria-label="Default select example"
                onChange={(e) => {
                  setFilter({ ...filter, playConsole: e.target.value });
                  setPlayValue(e.target.value);
                }}
                value={playValue}
              >
                <option value="" selected>
                  All Play Console
                </option>
                {playConsole?.map((data, index) => (
                  <option key={data.value} value={data.value}>
                    {data.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="">
              <select
                className="form-select h-100"
                aria-label="Default select example"
                onChange={(e) =>
                  setFilter({ ...filter, developer: e.target.value })
                }
              >
                <option value="" selected>
                  All Developer
                </option>
                {developer?.map((data, index) => (
                  <option key={data.value} value={data.value}>
                    {data.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="">
              <select
                className="form-select h-100"
                aria-label="Default select example"
                onChange={(e) =>
                  setFilter({ ...filter, reference: e.target.value })
                }
              >
                <option value="" selected>
                  All Reference
                </option>
                {reference?.map((data, index) => (
                  <option key={data.value} value={data.value}>
                    {data.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control search-box"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />

                <button
                  className="btn btn-outline-secondary border search-btn-icon"
                  type="button"
                  onClick={() => HandleList()}
                >
                  <SearchIcon />
                </button>
              </div>
            </div>
          </div>
        </Card.Header>
        {PermissionCheck("All App List", "View Only") && (
          <Card.Body>
            {tableData?.length < 1 ? (
              <div className="d-flex w-100 justify-content-center">
                <img src={NoDataImg} alt="no-data" className="w-35" />
              </div>
            ) : (
              <DataTable
                columns={tHeader}
                data={tableData}
                pagination
                paginationServer
                paginationPerPage={rowsPerPage}
                onChangeRowsPerPage={(event) => {
                  setRowsPerPage(parseInt(event));
                  HandleList(currentPage, event);
                }}
                onChangePage={(page) => {
                  HandleList(page);
                }}
                paginationDefaultPage={currentPage}
                paginationTotalRows={totalRecords}
                fixedHeader
              />
            )}
          </Card.Body>
        )}
      </Card>
      {/* delete modal */}

      <DeleteModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        deleteHandler={deleteHandler}
      />
      <ViewMonetizeSetting
        allData={allData}
        viewMode={viewMode}
        setviewMode={setviewMode}
        getData={HandleList}
        flag={"application"}
      />
    </>
  );
}

export default AppList;
