import React from "react";
import { CModal, CModalBody } from "@coreui/react";

function DeleteModal({
  showDeleteModal,
  setShowDeleteModal,
  deleteHandler,
  name,
}) {
  const handleClose = () => setShowDeleteModal(false);

  return (
    <>
      <CModal
        alignment="center"
        visible={showDeleteModal}
        keyboard={false}
        backdrop="static"
        onClose={() => setShowDeleteModal(false)}
        onHide={handleClose}
      >
        <CModalBody>
          <div>
            <div>
              <p className="h5 fw-semibold  text-16">
                Are You Sure Want To Remove?
              </p>
            </div>
            <div className="mt-3 d-flex gap-3 justify-content-end">
              <button
                className="btn bg-success text-white"
                onClick={() => {
                  deleteHandler();
                  handleClose();
                }}
              >
                Yes
              </button>
              <button
                className="btn bg-danger text-white border border-danger"
                onClick={() => handleClose()}
              >
                No
              </button>
            </div>
          </div>
        </CModalBody>
      </CModal>
    </>
  );
}

export default DeleteModal;
