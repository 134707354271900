import axios from "axios";

const api = `${process.env.REACT_APP_BACKEND_URL}/more-app-group/`;
const other = `${process.env.REACT_APP_BACKEND_URL}`;
const authToken = localStorage.getItem("token");
const headers = {
  Authorization: `Bearer ${authToken}`,
  "Content-Type": "application/json",
};

const APIlist = (data) => {
  return axios
    .post(`${api}view-more-app-group-list`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIadd = (data) => {
  return axios
    .post(`${api}create-more-app-group`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIedit = (data, id) => {
  return axios
    .put(`${api}modify-more-app-group/${id}`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIapp = () => {
  return axios
    .get(`${other}/app/get-without-pagination-apps`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APINewapp = (id) => {
  return axios
    .get(
      `${other}/more-app-group/get-without-pagination-more-group-apps/${id}`,
      { headers }
    )
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIdelete = (id) => {
  return axios
    .delete(`${api}delete-more-app-group/${id}`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIdeleteMany = (data) => {
  return axios
    .delete(`${api}delete-many-more-app-group`, {
      headers: headers,
      data: data,
    })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIviewlist = (data, id) => {
  return axios
    .post(`${api}view-all-assign-apps/${id}`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIaccountList = () => {
  return axios
    .get(`${other}/playConsole/get-without-pagination-playConsoles`, {
      headers,
    })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIapplicationList = (id) => {
  return axios
    .get(`${other}/playConsole/get-playConsole-wise-apps/${id}`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIaddList = (data, id) => {
  return axios
    .put(`${api}assign-app-list/${id}`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIaccountlist = (data, id) => {
  return axios
    .post(`${api}view-all-accounts/${id}`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIaddAccount = (data, id) => {
  return axios
    .put(`${api}assign-account-list/${id}`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIdeleteApplication = (data, id) => {
  return axios
    .put(`${api}remove-assign-app-list/${id}`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIdeleteAccount = (data, id) => {
  return axios
    .put(`${api}remove-account-list/${id}`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

export {
  APINewapp,
  APIdeleteAccount,
  APIdeleteApplication,
  APIaddAccount,
  APIaccountlist,
  APIaddList,
  APIlist,
  APIadd,
  APIedit,
  APIapp,
  APIdelete,
  APIdeleteMany,
  APIviewlist,
  APIaccountList,
  APIapplicationList,
};
