import React, { useState } from "react";
import {
  DataTable,
  CModal,
  CModalHeader,
  CModalBody,
  CModalTitle,
} from "../../helper/links/Link";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import NoDataImg from "../../images/g8.png";
import mainLogo from "../../images/mainlogo.png";
function AppCount({ setAppFlag, appFlag, app, setApp }) {
  const [tabValue, setTabValue] = useState("Rejected");

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const tHeaderApps = [
    {
      name: "Logo",
      selector: "appLogo",
      cell: (row) => {
        if (row?.appLogo) return <img src={row?.appLogo} className="ref-image" />;
        else
          return <div className="default_image"> {row?.appName?.charAt(0)}</div>;
      },
    },
    {
      name: "Name",
      selector: "appName",
    },
    {
      name: "Developer Name",
      selector: (row) => row?.developerId?.name,
    },
    {
      name: "Reference By",
      selector: (row) => row?.referenceId?.name,
    },
    {
      name: "Version",
      selector: "version",
    },
  ];

  return (
    <CModal
      visible={appFlag}
      alignment="center"
      scrollable
      onClose={() => {
        setAppFlag(false);
      }}
      size="xl"
      keyboard={false}
      backdrop="static"
    >
      <CModalHeader className="px-1 px-md-3">
        <CModalTitle>
          <p>Apps</p>
        </CModalTitle>
      </CModalHeader>
      <CModalBody>
        <Box>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleTabChange}
                aria-label="lab API tabs example"
                className="app-tab-list"
              >
                <Tab label="App InReview" value="InReview" />
                <Tab label="App Rejected" value="Rejected" />
                <Tab label="App Suspend" value="Suspended" />
                <Tab label="App Live" value="Updates" />
              </TabList>
            </Box>
            <TabPanel value="InReview">
              {app?.InReviewApp?.length < 1 ? (
                <div className="d-flex w-100 justify-content-center no-data-img">
                  <img src={NoDataImg} alt="no-data" className="w-50" />
                </div>
              ) : (
                <DataTable
                  columns={tHeaderApps}
                  data={app?.InReviewApp}
                  pagination={10}
                  fixedHeader
                />
              )}
            </TabPanel>
            <TabPanel value="Rejected">
              {app?.RejectApp?.length < 1 ? (
                <div className="d-flex w-100 justify-content-center no-data-img">
                  <img src={NoDataImg} alt="no-data" className="w-50" />
                </div>
              ) : (
                <DataTable
                  columns={tHeaderApps}
                  data={app?.RejectApp}
                  pagination={10}
                  fixedHeader
                />
              )}
            </TabPanel>
            <TabPanel value="Suspended">
              {app?.SuspendApp?.length < 1 ? (
                <div className="d-flex w-100 justify-content-center no-data-img">
                  <img src={NoDataImg} alt="no-data" className="w-50" />
                </div>
              ) : (
                <DataTable
                  columns={tHeaderApps}
                  data={app?.SuspendApp}
                  pagination={10}
                  fixedHeader
                />
              )}
            </TabPanel>
            <TabPanel value="Updates">
              {app?.LiveApp?.length < 1 ? (
                <div className="d-flex w-100 justify-content-center no-data-img">
                  <img src={NoDataImg} alt="no-data" className="w-50" />
                </div>
              ) : (
                <DataTable
                  columns={tHeaderApps}
                  data={app?.LiveApp}
                  pagination={10}
                  fixedHeader
                />
              )}
            </TabPanel>
          </TabContext>
        </Box>
      </CModalBody>
    </CModal>
  );
}

export default AppCount;
