import axios from "axios";

const api = `${process.env.REACT_APP_BACKEND_URL}/developer/`;
const other = `${process.env.REACT_APP_BACKEND_URL}`;
const authToken = localStorage.getItem("token");
const headers = {
  Authorization: `Bearer ${authToken}`,
  "Content-Type": "application/json",
};

const APIdeveloperlist = () => {
  return axios
    .get(`${api}get-without-pagination-developers`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIlist = (data) => {
  return axios
    .post(`${other}/app/get-app-bugs`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIadd = (data) => {
  return axios
    .post(`${other}/app/add-app-bug`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIedit = (data, id) => {
  return axios
    .put(`${other}/app/modify-app-bug/${id}`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

export { APIlist, APIdeveloperlist, APIadd, APIedit };
