import axios from "axios";

const api = `${process.env.REACT_APP_BACKEND_URL}/app/`;

const authToken = localStorage.getItem("token");
const headers = {
  Authorization: `Bearer ${authToken}`,
  "Content-Type": "application/json",
};

const APIgraph = (data) => {
  return axios
    .post(`${api}fetch-all-ip-countries`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APILocarionList = (data) => {
  return axios
    .post(`${api}fetch-all-ip-location`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

export { APIgraph, APILocarionList };
