import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet,
} from "react-router-dom";

import Login from "./pages/login/login";
import Dashboard from "./pages/dashboard/dashboard";
import DefaultLayout from "./layout/defaultLayout";
import "./scss/style.scss";
import getCurrentUser from "./utils/getCurrentUser.util";
import routes from "./routes";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ViewAppResponse from "./pages/appList/viewAppResponse";
const App = () => {
  const curretUser = getCurrentUser();
  function RequireAuth() {
    const token = localStorage.getItem("token");

    let location = useLocation();
    if (!token) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
    return <Outlet />;
  }



  return (
    <>

      <ToastContainer />
      <Router>
        <Routes>

          <Route path="/login" element={<Login />} />
          <Route path="/view-app-response/:appId" element={<ViewAppResponse />} />
          <Route exact path="/" element={<RequireAuth />}>
            {routes?.map((pages, i) => {
              return (
                <Route exact path="/" element={<DefaultLayout />}>
                  <Route
                    path={pages.path}
                    exact
                    key={i}
                    // element={pages.element}
                    render={(props) => (

                      <pages.element {...props} />
                    )}
                  />
                </Route>
              );
            })}
          </Route>
        </Routes>
      </Router>
    </>
  );
};

export default App;
