import axios from "axios";

const api = `${process.env.REACT_APP_BACKEND_URL}/reference/`;

const authToken = localStorage.getItem("token");
const headers = {
  Authorization: `Bearer ${authToken}`,
  "Content-Type": "application/json",
};
const headersWithForm = {
  Authorization: `Bearer ${authToken}`,
  "Content-Type": "multipart/form-data",
};

const APIlist = (data) => {
  return axios
    .post(`${api}get-references`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIadd = (data) => {
  return axios
    .post(`${api}add-reference`, data, { headers: headersWithForm })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIedit = (data, id) => {
  return axios
    .put(`${api}modify-reference/${id}`, data, { headers: headersWithForm })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIdelete = (id) => {
  return axios
    .delete(`${api}delete-reference/${id}`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIappCount = (id) => {
  return axios
    .get(`${api}get-reference/${id}`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
export { APIappCount, APIlist, APIadd, APIedit, APIdelete };
