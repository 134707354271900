import React, { useState } from "react";
import { CFormInput } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilCloudDownload } from "@coreui/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { apiClient } from "../../utils/request.util";
import Card from "react-bootstrap/Card";
import DataTable from "react-data-table-component";
import NoDataImg from "../../images/g8.png";
import { useEffect } from "react";
import * as xlsx from "xlsx";
import { useDropzone } from "react-dropzone";
import { CSVLink } from "react-csv";
import moment from "moment";
import getCurrentUser from "../../utils/getCurrentUser.util";
import { Toast, PermissionCheck } from "../../helper/links/Link";

const dropzoneStyles = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};

const ROIReport = () => {
  const [incomeData, setIncomeData] = useState(null);
  const [expenseData, setExpenseData] = useState(null);
  const [getRoiReportData, setRoiReportData] = useState([]);
  const [getAllRoiReport, setGetAllRoiReportData] = useState([]);
  const [expenseFile, setExpenseFile] = useState();
  const [incomeFile, setIncomeFile] = useState();
  const [exchangeRate, setExchangeRate] = useState();
  const [loadingObject, setLoadingObject] = useState({
    isLoading: false,
    isDisable: false,
  });
  const [incomeFiles, setIncomeFiles] = useState([]);
  const [expenseFiles, setExpenseFiles] = useState([]);

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };


  const tHeader = [
    {
      name: "Country",
      selector: (row) => row.country,
      cell: (row) => (
        <>
          <p className="fs-6 fw-semibold">{row?.country}</p>
        </>
      ),
      sortable: true,
    },
    {
      name: "ECPM",
      selector: (row) => row.ecpm,
      cell: (row) => (
        <>
          <p className="fs-6 fw-semibold">${row?.ecpm}</p>
        </>
      ),
      sortable: true,
    },
    {
      name: "Total Revenue",
      selector: (row) => row.totalRevenue,
      cell: (row) => (
        <>
          <p className="fs-6 fw-semibold">₹{row?.totalRevenue}</p>
        </>
      ),
      sortable: true,
    },
    {
      name: "Total Cost",
      selector: (row) => row.totalCost,
      cell: (row) => (
        <>
          <p className="fs-6 fw-semibold">₹{row?.totalCost}</p>
        </>
      ),
      sortable: true,
    },
    {
      name: "Total Profit",
      selector: (row) => row.totalProfit,
      cell: (row) => (
        <>
          <p className="fs-6 fw-semibold">₹{row?.totalProfit}</p>
        </>
      ),
      sortable: true,
    },
    {
      name: "ROI%",
      selector: (row) => row.roi,
      cell: (row) => (
        <>
          <p className="fs-6 fw-semibold">{row?.roi}%</p>
        </>
      ),
      sortable: true,
    },
  ];

  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["Developers"],
    queryFn: () => {
      apiClient
        .get("/roi-report/get-roi-report", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          const response = res.data.data;
          setGetAllRoiReportData(response);

          const alldeveloperData = response.map((item) => ({
            ...item,
          }));
          setRoiReportData(alldeveloperData);
        })
        .catch((err) => { });
    },
    enabled: false,
    staleTime: Infinity,
  });

  function removeSpacesFromKeys(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = key.replace(/\s+/g, "");
        if (key !== newKey) {
          obj[newKey] = obj[key];
          delete obj[key];
        }
      }
    }
    return obj;
  }

  const onDropIncome = (acceptedFiles) => {
    let mergedData = [];

    let updatedFiles = [...incomeFiles];

    acceptedFiles.forEach((file, index) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const fileContent = e.target.result;
        const workbook = xlsx.read(fileContent, { type: "string" });

        const sheetName = workbook.SheetNames[0];
        const sheetData = xlsx.utils.sheet_to_json(workbook.Sheets[sheetName]);
        const cleanedDataArray = sheetData.map(removeSpacesFromKeys);
        mergedData = incomeData
          ? incomeData?.concat(cleanedDataArray)
          : mergedData.concat(cleanedDataArray);
        updatedFiles = updatedFiles.concat(file);
        if (index === acceptedFiles.length - 1) {
          setIncomeData(mergedData);
          setIncomeFiles(updatedFiles);
        }
      };

      reader.readAsText(file, "UTF-8");
    });
  };

  const onDropExpense = (acceptedFiles) => {
    let mergedData = [];

    let updatedFiles = [...expenseFiles];
    acceptedFiles.forEach((file, index) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileContent = e.target.result;
        const workbook = xlsx.read(fileContent, { type: "string" });

        const sheetName = workbook.SheetNames[0];
        const sheetData = xlsx.utils.sheet_to_json(workbook.Sheets[sheetName]);

        const cleanedDataArray = sheetData.map(removeSpacesFromKeys);

        mergedData = expenseData
          ? expenseData?.concat(cleanedDataArray)
          : mergedData.concat(cleanedDataArray);
        updatedFiles = updatedFiles.concat(file);

        if (index === acceptedFiles.length - 1) {
          setExpenseData(mergedData);
          setExpenseFiles(updatedFiles);
        }
      };

      reader.readAsText(file, "UTF-8");
    });
  };

  let validateRequest = () => {
    if (
      expenseData === "" ||
      expenseData === null ||
      expenseData === undefined
    ) {
      Toast.error("Please Upload Expense File!");
      return false;
    } else if (
      incomeData === "" ||
      incomeData === null ||
      incomeData === undefined
    ) {
      Toast.error("Please Upload Income File!");
      return false;
    } else if (
      exchangeRate === "" ||
      exchangeRate === null ||
      exchangeRate === undefined
    ) {
      Toast.error("Please Add Exchange Rate");
      return false;
    }
    return true;
  };

  const generateROIMutation = useMutation({
    mutationFn: (developerData) => {
      return apiClient.post("/roi-report/generate-roi-report", developerData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    },
    onSuccess: () => {
      setExpenseData(null);
      setIncomeData(null);
      setExpenseFile();
      setExpenseFiles([]);
      setIncomeFiles([]);
      setIncomeFile();
      setExchangeRate("");

      Toast.success("ROI Report Generated Successfully!");
      refetch();
    },
    onError: () => { },
  });

  const deleteRoiReportMutation = useMutation({
    mutationFn: (developerData) => {
      return apiClient.delete("/roi-report/delete-roi-report", developerData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    },
    onSuccess: () => {
      setExpenseData(null);
      setIncomeData(null);
      setExpenseFile();
      setExpenseFiles([]);
      setIncomeFiles([]);
      setIncomeFile();
      setExchangeRate("");

      Toast.success("ROI Report Deleted Successfully!");
      refetch();
    },
    onError: () => { },
  });

  const handleGenerateROIMutation = () => {
    let validate = validateRequest();

    if (validate) {
      generateROIMutation.mutate({
        expenseData: expenseData,
        incomeData: incomeData,
        exchangerate: exchangeRate,
      });
    }
  };

  const handleDeleteRoiReportMutation = () => {
    deleteRoiReportMutation.mutate();
  };

  const filteredData = getAllRoiReport.map((record) => ({
    Country: record.country,
    ECPM: record.ecpm ? record.ecpm : 0,
    TotalRevenue: record.totalRevenue ? record.totalRevenue : 0,
    TotalCost: record.totalCost ? record.totalCost : 0,
    TotalProfit: !isNaN(record.totalProfit) ? record.totalProfit : 0,
    ROI: record.roi ? record.roi : 0,
  }));

  const {
    getRootProps: getIncomeRootProps,
    getInputProps: getIncomeInputProps,
  } = useDropzone({
    onDrop: onDropIncome,
    accept: ".csv",
  });

  const {
    getRootProps: getExpenseRootProps,
    getInputProps: getExpenseInputProps,
  } = useDropzone({
    onDrop: onDropExpense,
    accept: ".csv",
  });

  const handleDownloadForSampleExpenseFile = async (fileName) => {
    try {
      const cloudinaryUrl =
        "https://res.cloudinary.com/df9bzt824/raw/upload/v1696918821/sample-expense-2.xlsx";
      const response = await fetch(cloudinaryUrl);
      if (!response.ok) {
        throw new Error("Failed to download data");
      }

      const data = await response.blob();
      const url = URL.createObjectURL(data);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading data:", error);
    }
  };

  const handleDownloadForSampleIncomeFile = async (fileName) => {
    try {
      const cloudinaryUrl =
        "https://res.cloudinary.com/df9bzt824/raw/upload/v1696918821/sample-income-2xlsx";
      const response = await fetch(cloudinaryUrl);

      if (!response.ok) {
        throw new Error("Failed to download data");
      }

      const data = await response.blob();

      const url = URL.createObjectURL(data);

      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      URL.revokeObjectURL(url);
    } catch (error) { }
  };

  useEffect(() => {
    refetch();
  }, []);

  const clearAll = () => {
    setExpenseData(null);
    setIncomeData(null);
    setExpenseFile();
    setExpenseFiles([]);
    setIncomeFiles([]);
    setIncomeFile();
    setExchangeRate("");
    deleteRoiReportMutation.mutate();
  };
  return (
    <>
      <Card className="rounded-3 p-1">
        <Card.Header className="align-items-center gap-4 flex-wrap padding-header">
          <h5 class="card-title">Return On Investment</h5>
        </Card.Header>
        {
          PermissionCheck("ROI Report", "Generate & Download ROI Report")
        }
        <Card.Body>

          <>
            <div className="row">
              <div className="col-6 cust-col-6">
                <div className="d-flex flex-wrap justify-content-center">
                  <button
                    color="primary"
                    className="btn bg-success text-light"
                    onClick={() =>
                      handleDownloadForSampleExpenseFile(
                        "Sample-expense-file.csv"
                      )
                    }
                  >
                    Download Sample Expense File
                  </button>
                </div>
                <div
                  {...getExpenseRootProps()}
                  style={dropzoneStyles}
                  className="mt-3"
                >
                  <input {...getExpenseInputProps()} />

                  {expenseFiles.length > 0 ? (
                    <div>
                      <p className="fs-5 fw-semibold">
                        Uploaded Expense Files:
                      </p>
                      <ul>
                        {expenseFiles.map((file, index) => (
                          <p className="fs-6 fw-semibold" key={index}>{`${index + 1
                            }. ${file.path}`}</p>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <p className="fs-5 fw-medium">Drag & Drop Expense Here</p>
                  )}
                </div>
              </div>

              <div className="col-6 cust-col-6">
                <div className="d-flex flex-wrap justify-content-center">
                  <button
                    color="primary"
                    className="btn bg-success text-light"
                    onClick={() =>
                      handleDownloadForSampleIncomeFile(
                        "Sample-income-file.csv"
                      )
                    }
                  >
                    Download Sample Income File
                  </button>
                </div>

                <div
                  {...getIncomeRootProps()}
                  style={dropzoneStyles}
                  className="mt-3"
                >
                  <input {...getIncomeInputProps()} />

                  {incomeFiles.length > 0 ? (
                    <div>
                      <p className="fs-5 fw-semibold">
                        Uploaded Income Files:
                      </p>
                      <ul>
                        {incomeFiles.map((file, index) => (
                          <p className="fs-6 fw-semibold" key={index}>{`${index + 1
                            }. ${file.path}`}</p>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <p className="fs-5 fw-medium">Drag & Drop Income Here</p>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-3 row">
              <div className="col-6 cust-col-6">
                <div className="d-flex flex-wrap justify-content-center gap-3  w-100">
                  <CFormInput
                    type="number"
                    name="name"
                    value={exchangeRate}
                    className="w-50"
                    placeholder="Enter Exchange Rate"
                    aria-label="default input example"
                    onChange={(e) => setExchangeRate(e.target.value)}
                  />
                  <button
                    color="primary"
                    className="btn bg-primary text-light"
                    onClick={handleGenerateROIMutation}
                  >
                    Generate ROI
                  </button>
                  <button
                    color="primary"
                    className="btn bg-primary text-light"
                    onClick={clearAll}
                  >
                    Clear All
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-3 p-2 d-flex flex-wrap justify-content-end">
              {getAllRoiReport?.length > 0 && (
                <CSVLink
                  data={filteredData}
                  onClick={handleDeleteRoiReportMutation}
                  filename={`ROI-Report ${moment().format("DD-MM-YYYY")}.csv`}
                >
                  <button className="btn border border-primary text-black">
                    <CIcon icon={cilCloudDownload} />
                    Download
                  </button>
                </CSVLink>
              )}
            </div>
          </>


          <div className="pt-1 pb-3 mt-1">
            {getRoiReportData?.length < 1 ? (
              <div className="d-flex w-100 justify-content-center no-data-img">
                <img src={NoDataImg} alt="no-data" className="w-35" />
              </div>
            ) : (
              <DataTable
                columns={tHeader}
                data={getRoiReportData}
                pagination={20}
                fixedHeader
                paginationComponentOptions={paginationOptions}
                paginationRowsPerPageOptions={[10, 25, 50, 100, 200]}
              />
            )}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default ROIReport;
