
import React from 'react'
import { useContext } from 'react';
import getCurrentUser from '../../utils/getCurrentUser.util';

function PermissionCheck(page, type) {

    let role = getCurrentUser();
    if (role?.adminRole == 0) {
        return true
    }
    else {
        const pagePermission = JSON.parse(localStorage.getItem('permission'))
        const campaignsPage = pagePermission?.find(item => item.pageName === page)?.allocatedActions;
        const subCondition = campaignsPage?.find(action => action.actionType === type)?.status;

        return subCondition
    }

}


export { PermissionCheck }

