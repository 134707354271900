import axios from "axios";

const backend_url = process.env.REACT_APP_BACKEND_URL;
export const apiClient = axios.create({
  baseURL: backend_url,
  headers: {
    "Content-type": "application/json",
    Authorization: ` Bearer ${localStorage.getItem("token")}`,
  },
});
