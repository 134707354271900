import React, { useEffect } from "react";
import { Formik } from "formik";
import Card from "react-bootstrap/Card";
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
  CFormLabel,
  CFormInput,
  CFormSelect,
} from "@coreui/react";
import { PushNotificationSchema } from "../../../utility/Validator";
import { useState } from "react";
import { APIaddSetting } from "../../../api-wrapper/ApiNotification";
import { useParams } from "react-router-dom";
import Toast from "../../../helper/toast/Toast";

function Setting({ state }) {
  let { appId } = useParams();

  const handleSendNotificationData = (value) => {
    const formData = new FormData();
    formData.append("oneSignalRestAPIKey", value.oneSignalRestAPIKey);
    formData.append("oneSignalAppId", value.oneSignalAppId);
    formData.append("appId", appId);
    APIaddSetting(formData)
      .then((e) => {
        if (e?.isSuccess) {
          Toast.success(e?.message);
        }
      })
      .catch((e) => {
        Toast.error("Something Went Wrong!!");
      });
  };
  return (
    <Card.Body>
      <Formik
        initialValues={{
          oneSignalAppId: state.oneSignalAppId,
          oneSignalRestAPIKey: state.oneSignalRestAPIKey,
        }}
        onSubmit={(values) => {
          handleSendNotificationData(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
        }) => (
          <>
            <div className="row mt-2 ">
              <div className="col-6 title-col-6 p-1">
                <div>
                  <CFormLabel>One Signal App ID</CFormLabel>
                  <CFormInput
                    type="text"
                    name="oneSignalAppId"
                    value={values.oneSignalAppId}
                    aria-label="default input example"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-6 title-col-6 p-1">
                <div>
                  <CFormLabel>One Signal Rest API Key</CFormLabel>
                  <CFormInput
                    type="text"
                    name="oneSignalRestAPIKey"
                    value={values.oneSignalRestAPIKey}
                    aria-label="default input example"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div className=" row row-save-w-50 mt-2 p-2 d-flex justify-content-center">
              <div className="col-12">
                <div className="d-flex flex-wrap justify-content-end w-100">
                  <button
                    color="primary"
                    className="btn bg-primary text-light create-apikey-btn "
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </Formik>
    </Card.Body>
  );
}

export default Setting;
