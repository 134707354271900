import React, { useState } from "react";
import CIcon from "@coreui/icons-react";
import SearchIcon from "@mui/icons-material/Search";
import DataTable from "react-data-table-component";
import Card from "react-bootstrap/Card";
import { useEffect } from "react";
import NoDataImg from "../../../images/g8.png";
import getCurrentUser from "../../../utils/getCurrentUser.util";
import { APIlist } from "../../../api-wrapper/ApiBackupPending";
import { Toast, PermissionCheck } from "../../../helper/links/Link";
import { Breadcrumbs, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { cilCloudUpload, cilCloudDownload } from "@coreui/icons";
import Add from "./Add";
import { saveAs } from "file-saver";
import {
	APIPlayConsole,

} from "../../../api-wrapper/ApiAppList";
function BackupPendingList() {
	const [search, setSearch] = useState("");
	const [tableData, setTableData] = useState([]);
	const [totalRecords, setTotalRecords] = useState(0);
	const [visible, setVisible] = useState(false);
	const [catID, setCatID] = useState();
	const [currentPage, setcurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [filter, setFilter] = useState("");
	const [playConsole, setPlayConsole] = useState([]);
	const [status, setStatus] = useState("Live");
	useEffect(() => {
		let data = [];
		APIPlayConsole()
			.then((res) => {
				if (res.isSuccess) {
					res.data.map((el) => {
						data.push({
							label: el.pcName,
							value: el._id,
						});
					});
					setPlayConsole(data);
				} else {
					Toast.error(res?.message);
				}
			})
			.catch((err) => {
				Toast.error("Something went wrong, please try again!");
			});
	}, []);

	const tHeader = [
		{
			name: "Application Logo",
			selector: "appLogo",
			width: "150px",
			cell: (row) => {
				if (row.appLogo)
					return <img src={row.appLogo} className="ref-image" alt="" />;
				else
					return (
						<div className="default_image"> {row?.appName?.charAt(0)}</div>
					);
			},
		},
		{
			name: "Application Name",
			selector: "appName",
			sortable: false,
		},
		{
			name: "Status",
			selector: "status",
			width: "200px",
			cell: (row) => (
				<div className="d-block">
					{row?.backupCodeStatus ? (
						<div className="d-block">
							<button className="btn btn-success">Done</button>
						</div>
					) : (
						<div className="d-block">
							<button className="btn btn-dark">Pending</button>
						</div>
					)}
				</div>
			),
		},
		{
			name: "Upload File",
			selector: "actions",
			width: "230px",
			cell: (row) => (
				<div className="d-flex gap-2 justify-content-center ">
					<button
						onClick={() => {
							setCatID(row?._id);
							setVisible(true);
						}}
						className="btn border border-primary text-black"
					>
						<CIcon icon={cilCloudUpload} />
						Upload Zip
					</button>
				</div>
			),
		},
		{
			name: "Download Backup",
			selector: "download",
			width: "150px",
			cell: (row) => (
				<div className="d-flex gap-2 justify-content-center ">
					<a
						href={row.backupCode}
						target="_blank"
						className="btn border border-primary text-black"
					>
						<CIcon icon={cilCloudDownload} />
						Download
					</a>

				</div>
			),
		},
		{
			name: "SourceCode File",
			selector: "appSourceCodeFile",
			width: "150px",
			cell: (row) => (

				row?.appSourceCodeFile ?
					<div className="d-flex gap-2 justify-content-center ">
						<a
							href={row.appSourceCodeFile}
							target="_blank"
							
							className="btn border border-primary text-black"
						>
							<CIcon icon={cilCloudDownload} />
							Download
						</a>

					</div>
					: '-'

			),
		},
		{
			name: "Design File",
			selector: "appDesignFile",
			width: "150px",
			cell: (row) => (
				row?.appDesignFile ?
					<div className="d-flex gap-2 justify-content-center ">
						<a
							href={row.appDesignFile}
							target="_blank"
						
							className="btn border border-primary text-black"
						>
							<CIcon icon={cilCloudDownload} />
							Download
						</a>

					</div>
					:
					'-'
			),
		},
		{
			name: "JKS File",
			selector: "appJKSFile",
			width: "150px",
			cell: (row) => (
				row?.appJKSFile ?
					<div className="d-flex gap-2 justify-content-center ">

						<a
							href={row.appJKSFile}
							target="_blank"
							
							className="btn border border-primary text-black"
						>
							<CIcon icon={cilCloudDownload} />
							Download
						</a>
					</div>
					:
					"-"
			),
		},
	];

	const getData = (page, perPage) => {
		let data = {
			pageNo: page || 1,
			perPage: perPage || rowsPerPage,
			search: search,
			playConsoleId: filter,
			appStatus: status,
		};
		if (PermissionCheck("Backup Pending List", "View Only"))
			APIlist(data)
				.then((e) => {
					if (e?.isSuccess) {
						setTableData(e?.data);
						setcurrentPage(e.currentPageNo)
						setTotalRecords(e.totalRecords);
					} else {
						Toast.error(e?.message);
					}
				})
				.catch((err) => {
					Toast.error("Something went wrong, please try again!");
				});
	};

	useEffect(() => {
		getData();
	}, [filter, status]);

	const handleDownload = async (codeUrl, fileName) => {
		const cloudinaryUrl = codeUrl;
		const fileNameWithExtension = cloudinaryUrl.split("/").pop();
		const baseFileName = fileNameWithExtension.slice(
			0,
			fileNameWithExtension.lastIndexOf(".")
		);

		const authToken = localStorage.getItem("token");
		try {
			const response = await fetch(codeUrl, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			});

			if (!response.ok) {
				throw new Error("Failed to download file");
			}

			const blob = await response.blob();
			saveAs(blob, baseFileName);
		} catch (error) {
			console.error("Error downloading file:", error);
		}
	};



	return (
		<>
			<Card className="rounded-3 p-1">


				<Card.Header className="">
					<h5 className="card-title">
						<Breadcrumbs
							separator={<NavigateNextIcon fontSize="large" />}
							aria-label="breadcrumb"
						>
							<Typography key="1" color="text.primary" className="platform">
								Settings
							</Typography>
							<Typography
								key="2"
								color="text.primary"
								className=""
								style={{ color: "#A5A5A5" }}
							>
								Backup Pending List
							</Typography>
						</Breadcrumbs>
					</h5>

					<div className="history_item grid_console">
						<div className="">
							<select
								className="form-select h-100"
								aria-label="Default select example"
								onChange={(e) =>
									setFilter(e.target.value)
								}
							>
								<option value="" selected>
									All Play Console
								</option>
								{playConsole?.map((data, index) => (
									<option key={data.value} value={data.value}>
										{data.label}
									</option>
								))}
							</select>
						</div>
						<div className="">
							<select
								className="form-select h-100"
								aria-label="Default select example"
								onChange={(e) => setStatus(e.target.value)}
							>
								<option value="" selected>
									All App Status
								</option>

								<option value="Live"> Live</option>
								<option value="InReview"> InReview</option>
								<option value="Rejected"> Rejected</option>
								<option value="Suspend"> Suspend</option>
							</select>
						</div>
						<div className="">
							<div className="input-group mr-3 h-100">
								<input
									type="text"
									className="form-control search-box"
									placeholder="Search"
									value={search}
									onChange={(e) => setSearch(e.target.value)}
								/>

								<button
									className="btn btn-outline-secondary border search-btn-icon"
									type="button"
									onClick={() => getData()}
								>
									<SearchIcon />
								</button>
							</div>
						</div>
					</div>
				</Card.Header>
				{
					PermissionCheck("Backup Pending List", "View Only") && (
						<Card.Body>
							{tableData?.length < 1 ? (
								<div className="d-flex w-100 justify-content-center no-data-img">
									<img src={NoDataImg} alt="no-data" className="w-35" />
								</div>
							) : (
								<DataTable
									columns={tHeader}
									data={tableData}
									pagination
									paginationServer
									paginationPerPage={rowsPerPage}
									onChangeRowsPerPage={(event) => {
										setRowsPerPage(parseInt(event));
										getData(currentPage, event);
									}}
									onChangePage={(page) => {
										getData(page);
									}}
									paginationDefaultPage={currentPage}
									paginationTotalRows={totalRecords}
									fixedHeader
								/>
							)}
						</Card.Body>
					)
				}

			</Card>

			<Add
				visible={visible}
				setVisible={setVisible}
				getData={getData}
				catID={catID}
			/>
		</>
	);
}

export default BackupPendingList;
