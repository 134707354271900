import Card from "react-bootstrap/Card";
import getCurrentUser from "../../../utils/getCurrentUser.util";
import { APIlist, APIadd } from "../../../api-wrapper/ApiTestAd";
import { Toast, PermissionCheck } from "../../../helper/links/Link";
import React, { useState, useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";
import { RadioGroup } from "rsuite";
import { CFormInput, CFormLabel } from "@coreui/react";
import { FormLabel } from "react-bootstrap";
import { FormControlLabel, Radio } from "@mui/material";

function TestAdId() {
  const [alldata, setAlldata] = useState([]);
  const [otherData, setOtherData] = useState();
  const [loadAdIds, setloadAdIds] = useState("Fix");



  const getData = () => {
    if (PermissionCheck("Test Ad ID", "View Only"))
      APIlist()
        .then((res) => {
          if (res.isSuccess) {
            setloadAdIds(res.data.loadAdIds);
            setOtherData(res.data);
            setAlldata(res.data.testAdIdData);
          } else {
            Toast.error(res.message);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleInputChange = (e, index, subindex) => {
    const { value } = e.target;
    const updatedData = [...alldata];
    updatedData[index].monitizeFormatIds[subindex].value = value;
    setAlldata(updatedData);
  };

  const handleSubmit = () => {
    let data = {
      loadAdIds: loadAdIds,
      testAdIdData: alldata,
    };
    APIadd(data, otherData._id)
      .then((res) => {
        if (res.isSuccess) {
          Toast.success(res.message);
          getData();
        } else {
          Toast.error(res.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="large" />}
        aria-label="breadcrumb"
      >
        <Typography key="1" color="text.primary" className="platform">
          Settings
        </Typography>
        <Typography
          key="2"
          color="text.primary"
          className=""
          style={{ color: "#A5A5A5" }}
        >
          Test Ad ID
        </Typography>
      </Breadcrumbs>

      <Card className="rounded-3 mt-3 ">
        {
          PermissionCheck("Test Ad ID", "View Only") && (
            <Card.Body>
              <div className="row ">
                <div className="load-ad-ids-main-div">
                  <div className="">
                    <div className="apply-tex">
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        <b>Load Ad Ids?</b>
                      </FormLabel>
                    </div>
                    <div>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="loadAdIds"
                      >
                        <div className="d-flex">
                          <FormControlLabel
                            value="random"
                            control={<Radio />}
                            label="Random"
                            checked={loadAdIds === "Random"}
                            onChange={(e) => setloadAdIds("Random")}
                          />
                          <FormControlLabel
                            value="fix"
                            control={<Radio />}
                            label="Fix"
                            checked={loadAdIds === "Fix"}
                            onChange={(e) => setloadAdIds("Fix")}
                          />
                        </div>
                      </RadioGroup>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          )
        }

      </Card>
      {
        PermissionCheck("Test Ad ID", "View Only") &&
        <div>
          {alldata?.map((data, index) => (
            <>
              <Card className="rounded-3 p-1 mt-4" key={data?._id}>
                <Card.Header className="align-items-center gap-4 flex-wrap padding-header">
                  <h5 class="card-title">{data?.name}</h5>
                </Card.Header>
                <Card.Body>
                  <div className="row mt-2">
                    <div className="main-inputs-div">
                      <div className="inputs-div">
                        {data?.monitizeFormatIds?.map((format, subindex) => (
                          <div className="input-res-div">
                            <CFormLabel className="fs-6 cust-fs-5 ">
                              {format?.name}
                            </CFormLabel>
                            <CFormInput
                              type="text"
                              name={format?.name}
                              value={format?.value}
                              aria-label="default input example"
                              onChange={(e) =>
                                handleInputChange(e, index, subindex)
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </>
          ))}

          <div className="row mt-3">
            <div className="d-flex flex-wrap gap-2 justify-content-end">
              <button
                className="btn bg-primary"
                onClick={handleSubmit}
                style={{ color: "white" }}
              >
                Save
              </button>
            </div>
          </div>

        </div>
      }

    </>
  );
}

export default TestAdId;
