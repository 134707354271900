import * as Yup from "yup";
export const RefrenceSchema = Yup.object().shape({
  email: Yup.string()
    .required("Company email is required")
    .matches(/^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/, "Invalid email id"),
  name: Yup.string().required("company Name is required"),
  mobile: Yup.string()
    .required("Company mobile number is required")
    .matches(/^\d{10}$/, "Invalid mobile number"),
});

export const DeveloperSchema = Yup.object().shape({
  name: Yup.string().required("Developer name is required"),
  mobile: Yup.string()
    .required("Developer mobile number is required")
    .matches(/^\d{10}$/, "Invalid mobile number"),
  technology: Yup.string().required("Developer technology is required"),
});

export const PublishPlatformSchema = Yup.object().shape({
  name: Yup.string().required("Platform name is required"),
  url: Yup.string()
    .url("Please add valid URL")
    .required("Platform URL is required"),
});

export const ApprovalSchema = Yup.object().shape({
  playConsoleId: Yup.string().required("Play Console is required"),
  categoryId: Yup.string().required("Category is required"),
  developerId: Yup.string().required("Developer is required"),
  referenceId: Yup.string().required("Reference is required"),
});

export const formatSchema = Yup.object().shape({
  name: Yup.string().required("Format name is required"),
});

export const Monetizeschema = Yup.object().shape({
  name: Yup.string().required("Platform name is required"),
  url: Yup.string()
    .url("Please enter a valid URL")
    .required("Platform URL is required"),
  checkedItems: Yup.array()
    .of(Yup.string())
    .min(1, "Please select at least one ad format"),
});

export const CategoryNameSchema = Yup.object().shape({
  name: Yup.string().required("Category name is required"),
});

export const AdminAccessSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),

  email: Yup.string()
    .required("Email is required")
    .matches(/^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/, "Invalid email id"),
  password: Yup.string()
    .min("4", "Password must be greater than or equals to 4 digits")
    .required("Password is required"),
  role: Yup.string().required("Role is required"),
});

export const PlayConsoleSchema = Yup.object().shape({
  pcName: Yup.string().required("Play Console name is required"),
  deviceName: Yup.string().required("Device name is required"),
  mobile: Yup.string()
    .required("Mobile number is required")
    .matches(/^\d{10}$/, "Invalid mobile number"),
  email: Yup.string()
    .required("Email is required")
    .matches(/^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/, "Invalid email id"),
  password: Yup.string()
    .min("4", "Password must be greater than or equals to 4 digits")
    .required("Password is required"),
  platformId: Yup.string().required("Platform type is required"),
  status: Yup.string().required("Status is required"),
});

export const MoreAddGroupSchema = Yup.object().shape({
  name: Yup.string().required("Group name is required"),
});

export const PushNotificationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  message: Yup.string().required("Message is required"),
});

export const GroupwiseSchema = Yup.object().shape({
  playConsoleId: Yup.string().required("Account name is required"),
  appIds: Yup.array()
    .min(1, "At least one App Id is required")
    .required("At least one App Id is required"),
  groupName: Yup.string().required("group name is required"),
  oneSignalRestAPIKey: Yup.string().required("One Signal API Key is required"),
  oneSignalAppId: Yup.string().required("One Signal App ID is required"),
  groupDetails: Yup.string().required("Group details is required"),
});

export const SendNotificationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  message: Yup.string().required("Message is required"),
});


export const CodeSchema = Yup.object().shape({
  file: Yup.string().required("file is required"),
  detail: Yup.string().required("detail is required"),
})

export const SatusSchema = Yup.object().shape({
  status: Yup.string().required("staus is required"),
})


export const BackupSchema = Yup.object().shape({
  file: Yup.string().required("file is required"),
})

export const CustomAdSchema = Yup.object().shape({
  assetsName: Yup.string().required("Name is required"),
  actionButtonName: Yup.string().required("ButtonName is required"),
  assetsRating: Yup.number().nullable(false).required("Rating is required").max(5, "Rating must be at most 5"),
  assetsDownload: Yup.string().required("Download is required"),

})

export const AppWiseSchema = Yup.object().shape({
  customAdIds: Yup.array()
    .min(1, "At least one Custom Id is required")
    .required("At least one Custom Id is required"),
})

export const BugSchema = Yup.object().shape({
  playConsoleId: Yup.string().required("play console is required"),
  appId: Yup.string().required("app is required"),
  developerId: Yup.string().required("developer is required"),
  bugDescription: Yup.string().required("bug Description is required"),
  status: Yup.string().required("status is required"),
})

export const TabFirstSchema = Yup.object().shape({
  appAccountLink: Yup.string()
    .url("Please add valid URL"),
  appPrivacyPolicyLink: Yup.string()
    .url("Please add valid URL"),
  redirectToOtherAppText: Yup.string()
    .url("Please add valid URL"),
});