import * as types from "./actionType";
const istate = {
  loader: false,
  sidebarShow: true,
};

const usersReducer = (state = istate, action) => {
  switch (action.type) {
    case types.GET_LOADER:
      return { ...state, loader: action.value };

    case types.SIDEBAR_SHOW:
      return { ...state, sidebarShow: action.value };

    default:
      return state;
  }
};

export default usersReducer;
