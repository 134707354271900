import React from "react";
import { PermissionCheck } from "../../helper/permission/PermissionCheck";
function ViewBlock({ allData }) {
  return (
    <>
      <div className="row px-3 pb-4 ">
        <div className="d-flex p-0 all_apps_list">
          {
            PermissionCheck("All App List", "Total Downloads") &&
            <div className="w-50 remove-div linear-gradiant-bg  border-12  h-auto p-2 text-center block_1 p-4">
              <h5 className="">Total Downloads</h5>
              <p className="m-0 ">{allData?.downloadCount || 0}</p>
            </div>
          }

          {
            PermissionCheck("All App List", "Active Users") &&
            <div className="w-50 ms-1 remove-div linear-gradiant-bg  border-12  h-auto p-2 text-center block_1 p-4">
              <h5 className="">Active Users</h5>
              <p className="m-0 ">{allData?.activeUserCount || 0}</p>
            </div>
          }

        </div>
      </div>
    </>
  );
}

export default ViewBlock;
