import {
  CButton,
  CFormInput,
  CFormLabel,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { Formik } from "formik";
import React from "react";
import mainLogo from "../../../images/mainlogo.png";
import { BackupSchema } from "../../../utility/Validator";
import { useState } from "react";
import Toast from "../../../helper/toast/Toast";
import { APIadd } from "../../../api-wrapper/ApiBackupPending";
function Add({ visible, setVisible, editData, getData, catID }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [changeImg, setchangeImg] = useState(null);
  const handleSubmit = (values) => {
    const formData = new FormData();
    formData.append("uploadFile", changeImg);
    APIadd(formData, catID)
      .then((e) => {
        if (e?.isSuccess) {
          setVisible(false);
          Toast.success(e?.message);
          setSelectedImage(null);
          setchangeImg(null);
          getData();
        } else {
          Toast.error(e.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong!!");
      });
  };

  return (
    <>
      <CModal
        visible={visible}
        alignment="center"
        scrollable
        keyboard={false}
        backdrop="static"
        onClose={() => {
          setVisible(false);
        }}
      >
        <Formik
          validationSchema={BackupSchema}
          initialValues={{
            file: "",
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
          }) => (
            <>
              <CModalHeader>
                <CModalTitle className="">
                  <div className="d-flex justify-content-between gap-4">
                    <div>
                      <img src={mainLogo} alt="logo" />
                    </div>
                    <div>
                      <p className="h4 pt-2 fw-bold mt-auto">Application</p>
                    </div>
                  </div>
                </CModalTitle>
              </CModalHeader>
              <CModalBody>
                <form>
                  <div className="mb-3">
                    <CFormLabel>Code Backup (.zip file)</CFormLabel>
                    <CFormInput
                      type="file"
                      name="file"
                      accept={".zip, application/zip"}
                      className="form-control-file d-block"
                      onChange={(e) => {
                        const imageFile = e.target.files[0];
                        setSelectedImage(null);
                        setchangeImg(imageFile);
                        setFieldValue("file", imageFile);
                      }}
                    ></CFormInput>
                    <p className="error">
                      {errors.file && touched.file && errors.file}
                    </p>
                  </div>
                  <div className="col-12 text-center m-auto">
                    <CButton
                      color="primary"
                      className="w-30 create-apikey-btn btn"
                      onClick={handleSubmit}
                    >
                      Upload zip
                    </CButton>
                  </div>
                </form>
              </CModalBody>
            </>
          )}
        </Formik>
      </CModal>
    </>
  );
}

export default Add;
