import Card from "react-bootstrap/Card";
import getCurrentUser from "../../../utils/getCurrentUser.util";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";
import CIcon from "@coreui/icons-react";
import {
  APIlist,
  APIdelete,
} from "../../../api-wrapper/ApiNotificationGroupWise";
import React, { useState } from "react";
import { cilPlus, cilTrash, cilBell, cilColorBorder } from "@coreui/icons";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
  CFormLabel,
  CFormSelect,
  CFormInput,
} from "@coreui/react";
import { Link, Toast, PermissionCheck } from "../../../helper/links/Link";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import NoDataImg from "../../../images/g8.png";
import Add from "./Add";
import AddNotification from "./AddNotification";
import DeleteModal from "../../../helper/modal/DeleteModal";
function NotificationGroupWise() {
  const [tableData, setTableData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [visible, setVisible] = useState(false);
  const [editData, setEditData] = useState();
  const [deleteId, setDeleteId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [notiVisible, setNotiVisible] = useState(false);
  const [notiId, setNotiId] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const getData = (page, perPage) => {
    let data = {
      pageNo: page || 1,
      perPage: perPage || rowsPerPage,
    };
    if (PermissionCheck("Notification Group wise", "View Only"))
      APIlist(data)
        .then((e) => {
          if (e?.isSuccess) {
            setTableData(e?.data);
            setcurrentPage(e.currentPageNo)
            setTotalRecords(e.totalRecords);
          } else {
            Toast.error(e?.message);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
  };

  useEffect(() => {
    getData();
  }, []);

  const tHeader = [
    {
      name: "Applications",
      selector: (row) => (
        <div className="p-2 border" style={{ background: "#D9D9D900" }}>
          {row?.appIds?.map((appIds, index) => (
            <span key={appIds._id}>
              {index > 0 ? ", " : ""}
              {appIds.appName}
            </span>
          ))}
        </div>
      ),
      sortable: false,
    },
    {
      name: "Group Name",
      selector: (row) => row.groupName,
    },
    {
      name: "One Signal Details",
      selector: "oneSignalDetails",
      cell: (row) => (
        <div>
          <div className="d-block ">
            <p className=" text-center text-dark m-0 pb-1">
              {row.oneSignalRestAPIKey}
            </p>
          </div>
          <div className="d-block " style={{ color: "darkgray" }}>
            <p className="text-center m-0">{row.oneSignalAppId}</p>
          </div>
        </div>
      ),
    },
    {
      name: "Group Details",
      selector: "groupDetails",
      sortable: false,
    },

    {
      name: "Actions",
      selector: "actions",
      cell: (row) => (
        <div className="d-flex gap-3 w-100 justify-content-center">
          {PermissionCheck("Notification Group wise", "Edit Group") ? (
            <button
              onClick={() => {
                setEditData(row);
                setVisible(true);
              }}
              className="btn  edit_icon"
              style={{ color: "white" }}
            >
              <CIcon icon={cilColorBorder} />
            </button>
          ) : null}
          {PermissionCheck("Notification Group wise", "Send Notification") ? (
            <button
              onClick={() => {
                setNotiVisible(true);
                setNotiId(row._id);
              }}
              className="btn  bell_icon"
            >
              <CIcon icon={cilBell} />
            </button>
          ) : null}
          {PermissionCheck("Notification Group wise", "Remove Group") ? (
            <button
              className="btn  delete_icon"
              onClick={() => {
                setShowDeleteModal(true);
                setDeleteId(row._id);
              }}
            >
              <CIcon
                icon={cilTrash}
                className="icon-trash text-red"
                color="red"
              />
            </button>
          ) : null}
        </div>
      ),
    },
  ];

  const deleteHandler = () => {
    APIdelete(deleteId)
      .then((res) => {
        if (res.isSuccess) {
          Toast.success(res.message);
          getData();
        } else {
          Toast.error(res.message);
        }
        setDeleteId();
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  return (
    <>
      <Card className="rounded-3 p-1">
        <Card.Header className="align-items-center gap-4 flex-wrap padding-header">
          <h5 className="card-title">
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="large" />}
              aria-label="breadcrumb"
            >
              <Typography key="1" color="text.primary" className="platform">
                Push Notification
              </Typography>
              <Typography
                key="2"
                color="text.primary"
                className=""
                style={{ color: "#A5A5A5" }}
              >
                Notification Group Wise
              </Typography>
            </Breadcrumbs>
          </h5>
          {PermissionCheck("Notification Group wise", "Add Notification Group") ? (
            <div className="d-flex gap-3 " style={{ width: "200px" }}>
              <CButton
                onClick={() => {
                  setVisible(true);
                  setEditData("");
                }}
                className="add-btn"
              >
                Add Notification Group
              </CButton>
            </div>
          ) : null}
        </Card.Header>
        {
          PermissionCheck("Notification Group wise", "View Only") && (
            <Card.Body>
              {tableData?.length < 1 ? (
                <div className="d-flex w-100 justify-content-center no-data-img">
                  <img src={NoDataImg} alt="no-data" className="w-35" />
                </div>
              ) : (
                <DataTable
                  columns={tHeader}
                  data={tableData}
                  pagination
                  paginationServer
                  paginationPerPage={rowsPerPage}
                  onChangeRowsPerPage={(event) => {
                    setRowsPerPage(parseInt(event));
                    getData(currentPage, event);
                  }}
                  onChangePage={(page) => {
                    getData(page);
                  }}
                  paginationDefaultPage={currentPage}
                  paginationTotalRows={totalRecords}
                  fixedHeader
                />
              )}
            </Card.Body>
          )
        }

      </Card>
      <Add
        visible={visible}
        setVisible={setVisible}
        editData={editData}
        getData={getData}
      />

      <AddNotification
        notiVisible={notiVisible}
        setNotiVisible={setNotiVisible}
        getData={getData}
        notiId={notiId}
      />

      {/* delete modal */}

      <DeleteModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        deleteHandler={deleteHandler}
      />
    </>
  );
}

export default NotificationGroupWise;
