import { Chart } from "react-google-charts";
import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import DataTable from "react-data-table-component";
import Card from "react-bootstrap/Card";
import { useEffect } from "react";
import NoDataImg from "../../images/g8.png";
import { DateRangePicker } from "rsuite";
import getCurrentUser from "../../utils/getCurrentUser.util";
import { predefinedRanges } from "../../helper/calendarValues/calendarValues";
import moment from "moment";
import {
  Toast,
  Typography,
  NavigateNextIcon,
  Breadcrumbs,
  PermissionCheck
} from "../../helper/links/Link";
import {
  APIaccountList,
  APIapplicationList,
} from "../../api-wrapper/ApiMoreAppGroup";
import Add from "./Add";
import { APIgraph } from "../../api-wrapper/ApiLocationWise";
function LocationwiseUsers() {
  const [application, setapplication] = useState([]);
  const [country, setcountry] = useState([]);
  const [date, setDate] = useState([]);
  const [playConsole, setPlayConsole] = useState([]);
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);
  const [arg, setArg] = useState({ pageNo: 1, perPage: 10 });
  const [totalRecords, setTotalRecords] = useState(0);
  const [appId, setappId] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const tHeader = [
    {
      name: "Application Logo",
      selector: "appLogo",
      width: "170px",
      cell: (row) => {
        if (row.appLogo)
          return <img src={row.appLogo} className="ref-image" alt="" />;
        else
          return <div className="default_image"> {row?.appName.charAt(0)}</div>;
      },
    },
    {
      name: "Application Name",
      selector: "appName",
    },
    {
      name: "Country",
      selector: "country",
    },
    {
      name: "Download/Active User",
      selector: "downloadAndActive",
      cell: (row) => <p>{`${row?.countDownload} / ${row?.countActive}`}</p>,
    },
  ];

  const getData = (page, perPage) => {
    let arr = [["Country", "Downloads"]];
    let data = {
      pageNo: page || 1,
      perPage: perPage || rowsPerPage,
      fromDate: date.length === 0 ? "" : moment(date?.[0]).format("YYYY-MM-DD"),
      toDate: date.length === 0 ? "" : moment(date?.[1]).format("YYYY-MM-DD"),
      search: search,
      appId: appId,
    };
    if (PermissionCheck("Location Wise Users", "View Only"))
      APIgraph(data)
        .then((e) => {
          if (e?.isSuccess) {
            setTableData(e?.locationData);
            setcurrentPage(e.currentPageNo)
            setTotalRecords(e.totalRecords);

            e.chartData?.map((el) => {
              arr.push([el.country, el.countDownload]);
            });
            setcountry(arr);
          } else {
            Toast.error(e?.message);
            setcountry([["Country", "Downloads"]])
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
  };

  useEffect(() => {
    getData();
  }, [date, appId]);

  useEffect(() => {
    let data = [];
    APIaccountList()
      .then((res) => {
        if (res.isSuccess) {
          res.data.map((el) => {
            data.push({
              label: el.pcName,
              value: el._id,
            });
          });
          setPlayConsole(data);
        } else {
          setPlayConsole([]);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  }, []);

  const handleSubAccount = (val) => {
    let data = [];
    setapplication([]);
    APIapplicationList(val)
      .then((res) => {
        if (res.isSuccess) {
          res.data.map((el) => {
            data.push({
              label: el.appName,
              value: el._id,
            });
          });

          setapplication(data);
        }
        else {

        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };


  return (
    <>
      <Card className="rounded-3 p-1">
        <Card.Header className="align-items-center gap-4 flex-wrap padding-header">
          <h5 className="card-title">
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="large" />}
              aria-label="breadcrumb"
            >
              <Typography key="1" color="text.primary" className="platform">
                Analytics
              </Typography>
              <Typography
                key="2"
                color="text.primary"
                className=""
                style={{ color: "#A5A5A5" }}
              >
                Country Wise Download Chart
              </Typography>
            </Breadcrumbs>
          </h5>
          {
            PermissionCheck("Location Wise Users", "View Only") &&
            <div className="d-flex gap-4 flex-wrap justify-content-center">

              <div>
                <button
                  class="btn bg-primary add-ref-btn"
                  onClick={() => {
                    setVisible(true);
                  }}
                >
                  Location List
                </button>
              </div>


              <div>
                <p className="pt-1 text-dark">Last 30 Days</p>
              </div>
            </div>
          }

        </Card.Header>
        {
          PermissionCheck("Location Wise Users", "View Only") && (
            <Card.Body>
              <div className="grid_monetize">
                <div className="">
                  <select
                    className="form-select py-2"
                    aria-label="Default select example"
                    onChange={(e) => {
                      handleSubAccount(e.target.value);
                    }}
                  >
                    <option value="" selected>
                      {" "}
                      All Play Console
                    </option>
                    {playConsole?.map((data, index) => (
                      <option key={data.value} value={data.value}>
                        {data.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="">
                  <select
                    className="form-select py-2"
                    aria-label="Default select example"
                    onChange={(e) => setappId(e.target.value)}
                  >
                    <option value="" selected>
                      All Application
                    </option>
                    {application?.map((data, index) => (
                      <option key={data.value} value={data.value}>
                        {data.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="">
                  <DateRangePicker
                    className={`rangeDate custom-date-range-picker`}
                    ranges={predefinedRanges}
                    showOneCalendar
                    value={date}
                    placeholder="Select Date Range"
                    onChange={(e) => setDate(e)}
                    placement="bottomEnd"
                    format="yyyy-MM-dd"
                    cleanable={false}
                    style={{ width: "100%" }}
                  />
                </div>

                <div className="">
                  <div className="input-group mr-3">
                    <input
                      type="text"
                      className="form-control search-box"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <button
                      className="btn btn-outline-secondary border search-btn-icon"
                      type="button"
                      onClick={() => getData()}
                    >
                      <SearchIcon />
                    </button>
                  </div>
                </div>
              </div>

              <div className="row position-relative z-0 user-download-map-div p-4">
                <Chart
                  chartType="GeoChart"
                  loader={<div>Loading Chart</div>}
                  data={country}
                  options={{
                    legend: "none",
                    sizeAxis: { minValue: 1, maxSize: 12 },
                    colorAxis: { colors: "#512888" },
                    tooltip: {
                      isHtml: true,
                      trigger: "both",
                    },
                    width: "100%",
                    height: "420px",
                  }}
                  rootProps={{ "data-testid": "2" }}
                />
              </div>
            </Card.Body>
          )
        }

      </Card>

      <Card className="rounded-3 p-1 mt-4">
        <Card.Header className="align-items-center gap-4 flex-wrap padding-header">
          <h5 className="card-title">
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="large" />}
              aria-label="breadcrumb"
            >
              <Typography key="1" color="text.primary" className="platform">
                Location Wise Users
              </Typography>
            </Breadcrumbs>
          </h5>
          <div className="d-flex gap-4 flex-wrap justify-content-center">
            <div className="align-items-center "></div>
          </div>
        </Card.Header>
        <Card.Body>
          {tableData?.length < 1 ? (
            <div className="d-flex w-100 justify-content-center no-data-img">
              <img src={NoDataImg} alt="no-data" className="w-35" />
            </div>
          ) : (
            <DataTable
              columns={tHeader}
              data={tableData}
              pagination
              paginationServer
              paginationPerPage={rowsPerPage}
              onChangeRowsPerPage={(event) => {
                setRowsPerPage(parseInt(event));
                getData(currentPage, event);
              }}
              onChangePage={(page) => {
                getData(page);
              }}
              paginationDefaultPage={currentPage}
              paginationTotalRows={totalRecords}
              fixedHeader
            />
          )}
        </Card.Body>
      </Card>
      <Add visible={visible} setVisible={setVisible} />
    </>
  );
}

export default LocationwiseUsers;
