import React, { useEffect } from "react";
import { Formik } from "formik";
import mainLogo from "../../images/mainlogo.png";
import { BugSchema } from "../../utility/Validator";
import { APIadd, APIedit } from "../../api-wrapper/ApiApplicationBug";
import { useState } from "react";
import {
  APIaccountList,
  APIapplicationList,
} from "../../api-wrapper/ApiMoreAppGroup";
import { APIDevloper } from "../../api-wrapper/ApiAppList";
import {
  CModalFooter,
  Toast,
  CFormSelect,
  CModal,
  CModalHeader,
  CModalBody,
  CModalTitle,
  CFormLabel,
} from "../../helper/links/Link";
function Add({ visible, setVisible, editData, getData }) {
  const [playConsole, setPlayConsole] = useState([]);
  const [application, setapplication] = useState([]);
  const [developer, setDeveloper] = useState([]);

  useEffect(() => {
    let data = [];
    APIaccountList()
      .then((res) => {
        if (res.isSuccess) {
          res.data.map((el) => {
            data.push({
              label: el.pcName,
              value: el._id,
            });
          });
          setPlayConsole(data);
        } else {
          setPlayConsole([]);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  }, []);

  const handleSubAccount = (val) => {
    let data = [];
    setapplication([]);
    APIapplicationList(val)
      .then((res) => {
        if (res.isSuccess) {
          res.data.map((el) => {
            data.push({
              label: el.appName,
              value: el._id,
            });
          });

          setapplication(data);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  useEffect(() => {
    let data = [];
    APIDevloper()
      .then((res) => {
        if (res.isSuccess) {
          res.data.map((el) => {
            data.push({
              label: el.name,
              value: el._id,
            });
          });
          setDeveloper(data);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  }, []);

  const handleSubmit = (values) => {
    let data = {
      playConsoleId: values?.playConsoleId,
      appId: values?.appId,
      developerId: values?.developerId,
      bugDescription: values?.bugDescription,
      status: values?.status == "Updated" ? true : false,
    };
    if (editData?._id) {
      let updateData = {
        status: values?.status == "Updated" ? true : false,
      };
      APIedit(updateData, editData?._id)
        .then((res) => {
          if (res?.isSuccess) {
            Toast.success(res?.message);
            setVisible(false);
            getData();
          } else {
            Toast.error(res?.message);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
    } else {
      APIadd(data)
        .then((res) => {
          if (res?.isSuccess) {
            setVisible(false);
            Toast.success(res?.message);
            getData();
          } else {
            Toast.error(res?.message);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
    }
  };

  return (
    <>
      <CModal
        visible={visible}
        alignment="center"
        scrollable
        keyboard={false}
        backdrop="static"
        onClose={() => {
          setVisible(false);
        }}
      >
        <Formik
          validationSchema={BugSchema}
          initialValues={{
            playConsoleId: editData?.playConsoleId._id,
            appId: editData?.appId._id,
            developerId: editData?.developerId._id,
            bugDescription: editData?.bugDescription,
            status: editData?.status == true ? "Updated" : "Pending",
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <>
              <CModalHeader>
                <CModalTitle className="">

                  <p className="h4 pt-2 fw-bold mt-auto text-16">
                    Application Bug Info
                  </p>

                </CModalTitle>
              </CModalHeader>
              <CModalBody>
                <form onSubmit={handleSubmit}>
                  {editData?._id == undefined && (
                    <>
                      <div className="mb-3">
                        <CFormLabel>Play Console</CFormLabel>
                        <CFormSelect
                          aria-label="Default select example"
                          name="playConsoleId"
                          value={values.playConsoleId}
                          //   onBlur={handleBlur}
                          onChange={(e) => {
                            handleSubAccount(e.target.value);
                            setFieldValue("playConsoleId", e.target.value);
                          }}
                        >
                          <option key="All" value="All" selected disabled>
                            Select Play Console
                          </option>
                          {playConsole?.map((data, index) => (
                            <option key={data.value} value={data.value}>
                              {data.label}
                            </option>
                          ))}
                        </CFormSelect>
                        <p className="error">
                          {errors.playConsoleId &&
                            touched.playConsoleId &&
                            errors.playConsoleId}
                        </p>
                      </div>
                      <div className="mb-3">
                        <CFormLabel>Application</CFormLabel>
                        <CFormSelect
                          aria-label="Default select example"
                          name="appId"
                          value={values.appId}
                          //   onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          <option key="category">Select Application</option>
                          {application?.map((data, index) => (
                            <option key={data.value} value={data.value}>
                              {data.label}
                            </option>
                          ))}
                        </CFormSelect>
                        <p className="error">
                          {errors.appId && touched.appId && errors.appId}
                        </p>
                      </div>

                      <div className="mb-3">
                        <CFormLabel>Developer</CFormLabel>
                        <CFormSelect
                          aria-label="Default select example"
                          name="developerId"
                          value={values.developerId}
                          onChange={handleChange}
                        //   onBlur={handleBlur}
                        >
                          <option key="developer" selected disabled>
                            Select Developer
                          </option>
                          {developer?.map((data, index) => (
                            <option key={data.value} value={data.value}>
                              {data.label}
                            </option>
                          ))}
                        </CFormSelect>
                        <p className="error">
                          {errors.developerId &&
                            touched.developerId &&
                            errors.developerId}
                        </p>
                      </div>

                      <div className="mb-3">
                        <CFormLabel>Bug Description</CFormLabel>
                        <textarea
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="6"
                          name="bugDescription"
                          onChange={handleChange}
                          value={values?.bugDescription}
                        ></textarea>
                        <p className="error">
                          {errors.bugDescription &&
                            touched.bugDescription &&
                            errors.bugDescription}
                        </p>
                      </div>
                    </>
                  )}

                  <div className="mb-3">
                    <CFormLabel>Status</CFormLabel>
                    <CFormSelect
                      aria-label="Default select example"
                      name="status"
                      value={values.status}
                      onChange={handleChange}
                    >
                      <option value="Pending">Pending</option>
                      <option value="Updated">Updated</option>
                    </CFormSelect>
                    <p className="error">
                      {errors.status && touched.status && errors.status}
                    </p>
                  </div>
                </form>
              </CModalBody>
              <CModalFooter className="add-play-console-modal-footer d-flex justify-content-center gap-2">
                <button
                  className=" btn close-btn bg-white text-black border-danger"
                  onClick={() => {
                    setVisible(false);
                  }}
                >
                  Close
                </button>
                <button
                  className="btn bg-primary text-light create-apikey-btn"
                  onClick={(e) => handleSubmit(e)}
                  type="submit"
                >
                  save
                </button>
              </CModalFooter>
            </>
          )}
        </Formik>
      </CModal>
    </>
  );
}

export default Add;
