import React from "react";
import ReactApexChart from "react-apexcharts";
import "apexcharts/dist/apexcharts.css";
import { APIGraph } from "../../api-wrapper/ApiAppList";
import { useEffect } from "react";
import { useState } from "react";
import { Card, Toast, PermissionCheck } from "../../helper/links/Link";
function ViewChart({ allData }) {
  const [chartdata, setChartData] = useState([]);
  const [dates, setdates] = useState([]);
  useEffect(() => {
    let data = [];
    if (allData?._id)
      if (PermissionCheck("All App List", "Date Wise App Download"))
        APIGraph(allData?._id)
          .then((res) => {
            if (res.isSuccess) {
              setChartData(res.data);
              data.push(
                res.data?.activeUsersCounts?.map((el) => el.currentDate)
              );
              data.push(res.data?.downloadCounts?.map((el) => el.currentDate));
              setdates(data);
            } else {
              Toast.error(res?.message);
            }
          })
          .catch((err) => {
            Toast.error("Something went wrong, please try again!");
          });
  }, [allData?._id]);

  const chartOptions = {
    chart: {
      id: "line-chart",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: dates,
    },
    yaxis: {
      title: {
        text: "Daily Downloads",
      },
      format: function (value) {
        return value.toFixed(0);
      },
    },
  };
  const chartSeries = [
    {
      name: "App Downloads",
      data: chartdata?.downloadCounts?.map((el) => el.download),
    },
    {
      name: "Active Users",
      data: chartdata?.activeUsersCounts?.map((el) => el.activeUser),
    },
  ];
  return (
    <div className="row px-3 pb-4">
      <Card className="rounded-3 ">
        <p className="h6 p-3 fw-semibold">Date Wise App Download</p>
        <ReactApexChart
          options={chartOptions}
          series={chartSeries}
          type="line"
          height={350}
        />
      </Card>
    </div>
  );
}

export default ViewChart;
