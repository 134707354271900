
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import axios from "axios";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { StyledEngineProvider } from "@mui/material/styles";
import { handleLoader } from "./helper/redux/action";
import store from "./helper/redux/store";
import Toast from "./helper/toast/Toast";


const queryClient = new QueryClient();

const { dispatch } = store;
let requestCount = 0;

axios.interceptors.request.use(
  (config) => {

    requestCount++;
    dispatch(handleLoader(true));

    return config;
  },
  (error) => {

    requestCount--;
    if (requestCount === 0) {
      dispatch(handleLoader(false));
      Toast.error(error?.message);

    }
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {


    requestCount--;

    if (requestCount === 0) {
      dispatch(handleLoader(false));

    }


    if (
      response.data.isSuccess === false &&
      response.data.message === "Invalid token"
    ) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    } else if (
      response.data.isSuccess === false &&
      response.data.message === "Token Expired"
    ) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    } else {
      return response;
    }
  },
  (error) => {

    requestCount--;
    if (requestCount === 0) {
      dispatch(handleLoader(false));
      Toast.error(error?.message);
    }

    if (error.response) {
      return Promise.reject(error);
    } else if (error.request) {
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <App />
      </StyledEngineProvider>
    </Provider>
  </QueryClientProvider>
);

reportWebVitals();
