import axios from "axios";

const api = `${process.env.REACT_APP_BACKEND_URL}/app/`;

const authToken = localStorage.getItem("token");
const headers = {
  Authorization: `Bearer ${authToken}`,
  "Content-Type": "application/json",
};

const APIlist = (data) => {
  return axios
    .post(`${api}get-app-update-histories`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIview = (id) => {
  return axios
    .get(`${api}get-app-update-history/${id}`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

export { APIlist, APIview };
