import React, { useState } from "react";
import CIcon from "@coreui/icons-react";
import { cilPlus, cilTrash, cilColorBorder } from "@coreui/icons";
import SearchIcon from "@mui/icons-material/Search";
import DataTable from "react-data-table-component";
import Card from "react-bootstrap/Card";
import { useEffect } from "react";
import NoDataImg from "../../images/g8.png";
import { DateRangePicker } from "rsuite";
import getCurrentUser from "../../utils/getCurrentUser.util";
import { APIdeleteMany, APIlist } from "../../api-wrapper/ApiMoreAppGroup";
import { predefinedRanges } from "../../helper/calendarValues/calendarValues";
import { Toast, useNavigate, PermissionCheck } from "../../helper/links/Link";
import moment from "moment";
import Add from "./Add";
import DeleteModal from "../../helper/modal/DeleteModal";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";
import { APIdelete } from "../../api-wrapper/ApiMoreAppGroup";
function MoreAppGroup() {
  let Navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [visible, setVisible] = useState(false);
  const [editData, setEditData] = useState();
  const [deleteId, setDeleteId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDataIds, setSelectedDataIds] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const getData = (page, perPage) => {
    let data = {
      pageNo: page || 1,
      perPage: perPage || rowsPerPage,
      search: search,
    };
    if (PermissionCheck("More App Group", "View Only"))
      APIlist(data)
        .then((e) => {
          if (e?.isSuccess) {
            setTableData(e?.data);
            setcurrentPage(e.currentPageNo)
            setTotalRecords(e.totalRecords);
          } else {
            Toast.error(e?.message);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
  };

  useEffect(() => {
    getData();
  }, []);

  const deleteHandler = () => {
    APIdelete(deleteId)
      .then((res) => {
        if (res.isSuccess) {
          Toast.success(res.message);
          getData();
        } else {
          Toast.error(res.message);
        }
        setDeleteId();
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  const tHeader = [
    {
      name: "Group Name",
      selector: "groupName",
      cell: (row) => row.groupName,
    },
    {
      name: "Inner More Apps",
      selector: (row) => row.innerAppIds,
      cell: (row) => (
        <div>
          {row?.innerAppIds?.length == 0
            ? "-"
            : row?.innerAppIds?.map((appIds, index) => (
              <span key={appIds._id}>
                {index > 0 ? ", " : ""}
                {appIds.appName}{" "}
              </span>
            ))}
        </div>
      ),
    },

    {
      name: "Splash More Apps",
      selector: "splashMoreAppIds",
      cell: (row) => (
        <div>
          {row?.splashMoreAppIds?.length == 0
            ? "-"
            : row?.splashMoreAppIds?.map((appIds, index) => (
              <span key={appIds._id}>
                {index > 0 ? ", " : ""}
                {appIds.appName}{" "}
              </span>
            ))}
        </div>
      ),
    },
    {
      name: "Exit More Apps",
      selector: "exitMoreAppIds",
      cell: (row) => (
        <div>
          {row?.exitMoreAppIds?.length == 0
            ? "-"
            : row?.exitMoreAppIds?.map((appIds, index) => (
              <span key={appIds._id}>
                {index > 0 ? ", " : ""}
                {appIds.appName}{" "}
              </span>
            ))}
        </div>
      ),
    },
    {
      name: "Actions",
      selector: "actions",
      cell: (row) => (
        <div className="d-flex gap-2 w-100 justify-content-center">
          {PermissionCheck("More App Group", "Assign List") ? (
            <button
              onClick={() => {
                Navigate(`/more-app-group/${row._id}`);
              }}
              className="btn  add_icon"
            >
              <CIcon icon={cilPlus} />
            </button>
          ) : null}
          {PermissionCheck("More App Group", "Edit More App Group") ? (
            <button
              onClick={() => {
                setEditData(row);
                setVisible(true);
              }}
              className="btn  edit_icon"
            >
              <CIcon icon={cilColorBorder} />
            </button>
          ) : null}
          {PermissionCheck("More App Group", "Remove More App Group") ? (
            <button
              className="btn delete_icon"
              onClick={() => {
                setShowDeleteModal(true);
                setDeleteId(row._id);
              }}
            >
              <CIcon
                icon={cilTrash}
                className="icon-trash text-red"
                color="red"
              />
            </button>
          ) : null}
        </div>
      ),
    },
  ];
  const handleSelectedRowsChange = (state) => {
    const ids = state?.selectedRows.map((row) => row._id);
    setSelectedDataIds(ids);
  };

  const deleteAll = () => {
    let data = {
      moreAppGroupIds: selectedDataIds,
    };
    APIdeleteMany(data)
      .then((res) => {
        if (res.isSuccess) {
          Toast.success(res.message);
          setSelectedDataIds([]);
          getData();
        } else {
          Toast.error(res.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  return (
    <>
      <Card className="rounded-3 p-1">
        <Card.Header className="align-items-center gap-4 flex-wrap padding-header">
          <h5 className="card-title">
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="large" />}
              aria-label="breadcrumb"
            >
              <Typography key="1" color="text.primary" className="platform">
                More App Group
              </Typography>
              <Typography
                key="2"
                color="text.primary"
                className=""
                style={{ color: "#A5A5A5" }}
              >
                Group List
              </Typography>
            </Breadcrumbs>
          </h5>
          <div className="d-flex gap-3 flex-wrap">
            {selectedDataIds.length !== 0 ? (
              <button
                onClick={() => deleteAll()}
                className="btn bg-white border border-secondary-subtle text-danger delete-btn"
              >
                Delete
                <CIcon icon={cilTrash} className="icon-trash-all" color="red" />
              </button>
            ) : null}

            {PermissionCheck("More App Group", "Add More App Group") ? (
              <button
                class="btn bg-primary add-ref-btn"
                onClick={() => {
                  setVisible(true);
                  setEditData("");
                }}
              >
                Add Group
              </button>
            ) : null}

            <div className="align-items-center">
              <div className="input-group mr-3 h-100">
                <input
                  type="text"
                  className="form-control search-box"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <button
                  className="btn btn-outline-secondary border search-btn-icon"
                  type="button"
                  onClick={() => getData()}
                >
                  <SearchIcon />
                </button>
              </div>
            </div>
          </div>
        </Card.Header>
        {
          PermissionCheck("More App Group", "View Only") && (
            <Card.Body>
              {tableData?.length < 1 ? (
                <div className="d-flex w-100 justify-content-center no-data-img">
                  <img src={NoDataImg} alt="no-data" className="w-35" />
                </div>
              ) : (
                <DataTable
                  columns={tHeader}
                  data={tableData}
                  pagination
                  paginationServer
                  paginationPerPage={rowsPerPage}
                  onChangeRowsPerPage={(event) => {
                    setRowsPerPage(parseInt(event));
                    getData(currentPage, event);
                  }}
                  onChangePage={(page) => {
                    getData(page);
                  }}
                  paginationDefaultPage={currentPage}
                  paginationTotalRows={totalRecords}
                  fixedHeader
                  selectableRows
                  onSelectedRowsChange={handleSelectedRowsChange}
                  selectableRowsNoSelectAll={false}
                />
              )}
            </Card.Body>
          )
        }

      </Card>

      <Add
        visible={visible}
        setVisible={setVisible}
        editData={editData}
        getData={getData}
      />

      {/* delete modal */}
      <DeleteModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        deleteHandler={deleteHandler}
      />
    </>
  );
}

export default MoreAppGroup;
