import React, { useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";
import Card from "react-bootstrap/Card";
import { useLocation } from "react-router-dom";
import PushNotification from "./PushNotification";
import Setting from "./Setting";
import NotificationList from "./NotificationList";

function SendAppNotification() {
  const location = useLocation();
  const { state } = location;
  const [flag, setflag] = useState(false);
  return (
    <>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="large" />}
        aria-label="breadcrumb"
      >
        <Typography key="1" color="text.primary" className="platform">
          Push Notification
        </Typography>
        <Typography key="2" className="" style={{ color: "#A5A5A5" }}>
          Notification App Wise
        </Typography>
        <Typography
          key="3"
          color="text.primary"
          className=""
          style={{ color: "#A5A5A5" }}
        >
          Notification
        </Typography>
      </Breadcrumbs>
      <div className="d-flex flex-wrap gap-2 align-items-center">
        <div className="app-bg-img p-2">
          {state?.appLogo ? (
            <div
              className="app-bg rounded-3"
              style={{
                backgroundImage: `url(${state?.appLogo})`,
                width: "110px",
                height: "110px",
              }}
            ></div>
          ) : (
            <div
              className="default_image rounded-3 "
              style={{ width: "110px", height: "110px", fontSize: "25px" }}
            >
              {" "}
              {state?.appName?.charAt(0)}
            </div>
          )}
        </div>
        <div className="">
          <h4>
            <b>{state?.appName}</b>
          </h4>
        </div>
      </div>
      <Card className="rounded-3 p-1 mt-4">
        <Card.Header className="align-items-center gap-4 flex-wrap padding-header">
          <div className="d-flex flex-wrap gap-2">
            <div className="pt-2 fw-semibold">
              <p>One Signal Setting</p>
            </div>
          </div>
        </Card.Header>
        <Setting state={state} />
      </Card>
      <Card className="rounded-3 p-1 mt-4">
        <Card.Header className="align-items-center gap-4 flex-wrap padding-header">
          <div className="d-flex flex-wrap gap-2">
            <div className="pt-2 fw-semibold">
              <p>Sending Push Notification</p>
            </div>
          </div>
        </Card.Header>
        <PushNotification setflag={setflag} />
      </Card>
      <NotificationList flag={flag} />
    </>
  );
}

export default SendAppNotification;
