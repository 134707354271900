import React, { useEffect } from "react";
import { Formik } from "formik";
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
  CFormLabel,
  CFormInput,
  CFormSelect,
} from "@coreui/react";
import mainLogo from "../../images/mainlogo.png";
import {
  DeveloperSchema,
  PlayConsoleSchema,
  PublishPlatformSchema,
} from "../../utility/Validator";
import { Toast } from "../../helper/links/Link";
import {
  APIadd,
  APIedit,
  APIlistPlatform,
} from "../../api-wrapper/ApiPlayConsole";
import { useState } from "react";
function Add({ visible, setVisible, editData, getData }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [changeImg, setchangeImg] = useState(null);
  const [platform, setPlatform] = useState([]);
  const handleSubmit = (values) => {
    const formData = new FormData();
    formData.append("pcName", values.pcName);
    formData.append("deviceName", values.deviceName);
    formData.append("mobile", values.mobile);
    formData.append("email", values.email);
    formData.append("password", values.password);
    formData.append("platformId", values.platformId);
    formData.append("status", values.status);

    if (changeImg) {
      formData.append("image", changeImg);
    }
    if (editData?._id) {
      APIedit(formData, editData?._id)
        .then((e) => {
          if (e?.isSuccess) {
            Toast.success(e?.message);
            setVisible(false);
            setSelectedImage(null);
            setchangeImg(null);
            getData();
          } else {
            Toast.error(e?.message);
          }
        })
        .catch((e) => {
          Toast.error("Something Went Wrong!!");
        });
    } else {
      APIadd(formData)
        .then((e) => {
          if (e?.isSuccess) {
            setVisible(false);
            Toast.success(e?.message);
            setSelectedImage(null);
            setchangeImg(null);
            getData();
          } else {
            Toast.error(e?.message);
          }
        })
        .catch((e) => {
          Toast.error("Something Went Wrong!!");
        });
    }
  };

  useEffect(() => {
    setSelectedImage(editData?.image);
  }, [editData]);

  useEffect(() => {
    let data = [];
    APIlistPlatform()
      .then((res) => {
        if (res.isSuccess) {
          res.data.map((el) => {
            data.push({
              label: el.name,
              value: el._id,
            });
          });
          setPlatform(data);
        } else {
          setPlatform([]);
        }
      })
      .catch((e) => {
        Toast.error("Something went wrong, please try again!");
      });
  }, []);

  return (
    <>
      <CModal
        visible={visible}
        alignment="center"
        scrollable
        keyboard={false}
        backdrop="static"
        onClose={() => {
          setVisible(false);
        }}
      >
        <Formik
          validationSchema={PlayConsoleSchema}
          initialValues={{
            pcName: editData?.pcName,
            deviceName: editData?.deviceName,
            mobile: editData?.mobile,
            email: editData?.email,
            password: editData?.password,
            platformId: editData?.platformId,
            image: editData?.image,
            status: editData?.status,
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <>
              <CModalHeader>
                <CModalTitle className="">
                  <p className="h4 pt-2 fw-bold mt-auto text-16">
                    Play Console
                  </p>
                </CModalTitle>
              </CModalHeader>
              <CModalBody>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <CFormLabel>Play Console Name</CFormLabel>

                    <CFormInput
                      type="text"
                      name="pcName"
                      aria-label="default input example"
                      onChange={handleChange}
                      value={values.pcName}
                    />
                    <p className="error">
                      {errors.pcName && touched.pcName && errors.pcName}
                    </p>
                  </div>
                  <div className="mb-3">
                    <CFormLabel>Console Device Name</CFormLabel>
                    <CFormInput
                      type="text"
                      name="deviceName"
                      aria-label="default input example"
                      onChange={handleChange}
                      value={values.deviceName}
                    />
                    <p className="error">
                      {errors.deviceName &&
                        touched.deviceName &&
                        errors.deviceName}
                    </p>
                  </div>

                  <div className="mb-3">
                    <CFormLabel>Console Status</CFormLabel>
                    <CFormSelect
                      aria-label="Default select example"
                      name="status"
                      value={values.status}
                      onChange={handleChange}
                    >
                      <option selected disabled>
                        Select Status
                      </option>
                      <option key="Live" value="Live">
                        Live
                      </option>
                      <option key="Suspend" value="Suspend">
                        Suspend
                      </option>
                    </CFormSelect>
                    <p className="error">
                      {errors.status && touched.status && errors.status}
                    </p>
                  </div>
                  <div className="mb-3">
                    <CFormLabel>Mobile Number</CFormLabel>
                    <CFormInput
                      type="text"
                      name="mobile"
                      aria-label="default input example"
                      onChange={handleChange}
                      value={values.mobile}
                    />
                    <p className="error">
                      {errors.mobile && touched.mobile && errors.mobile}
                    </p>
                  </div>
                  <div className="mb-3">
                    <CFormLabel>Console Email</CFormLabel>
                    <CFormInput
                      type="text"
                      name="email"
                      aria-label="default input example"
                      onChange={handleChange}
                      value={values.email}
                    />
                    <p className="error">
                      {errors.email && touched.email && errors.email}
                    </p>
                  </div>
                  <div className="mb-3">
                    <CFormLabel>Console Password</CFormLabel>
                    <CFormInput
                      type="password"
                      name="password"
                      aria-label="default input example"
                      onChange={handleChange}
                      value={values.password}
                    />
                    <p className="error">
                      {errors.password && touched.password && errors.password}
                    </p>
                  </div>
                  {!editData?._id && (
                    <div className="mb-3">
                      <CFormLabel>Publish Platform Type</CFormLabel>
                      <CFormSelect
                        aria-label="Default select example"
                        name="platformId"
                        onChange={handleChange}
                        value={values.platformId}
                      >
                        <option selected disabled>
                          Select Publish Platform Type
                        </option>

                        {platform?.map((data, index) => (
                          <option key={index} value={data.value}>
                            {data.label}
                          </option>
                        ))}
                      </CFormSelect>
                      <p className="error">
                        {errors.platformId &&
                          touched.platformId &&
                          errors.platformId}
                      </p>
                    </div>
                  )}

                  <div className="mb-3 row">
                    <div className="col-12  col-sm-6">
                      <CFormLabel>Upload Image</CFormLabel>
                      <input
                        type="file"
                        className="form-control-file d-block"
                        accept="image/*"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          const imageFile = e.target.files[0];
                          setSelectedImage(null);
                          setchangeImg(imageFile);
                          setFieldValue("image", imageFile);
                        }}
                      />
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="d-flex flex-column gap-4 flex-wrap upload-ref-img-div align-items-center align-items-md-end  pe-4 mt-2 mt-md-0">
                        {selectedImage ? (
                          <img
                            src={selectedImage}
                            alt="reference-img"
                            className="upload-ref-img"
                          />
                        ) : (
                          changeImg && (
                            <img
                              src={URL.createObjectURL(changeImg)}
                              className="upload-ref-img"
                            />
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </CModalBody>
              <CModalFooter className="add-play-console-modal-footer d-flex justify-content-center gap-2">
                <button
                  className=" btn close-btn bg-white text-black border-danger"
                  onClick={() => {
                    setVisible(false);
                    setchangeImg(null);
                  }}
                >
                  Close
                </button>
                <button
                  className="btn bg-primary text-light create-apikey-btn"
                  onClick={(e) => handleSubmit(e)}
                  type="submit"
                >
                  save
                </button>
              </CModalFooter>
            </>
          )}
        </Formik>
      </CModal>
    </>
  );
}

export default Add;
