import axios from "axios";

const api = `${process.env.REACT_APP_BACKEND_URL}/custom-ad/`;
const other = `${process.env.REACT_APP_BACKEND_URL}`;
const authToken = localStorage.getItem("token");
const headers = {
  Authorization: `Bearer ${authToken}`,
  "Content-Type": "application/json",
};
const headersWithForm = {
  Authorization: `Bearer ${authToken}`,
  "Content-Type": "multipart/form-data",
};

const APIlist = (data) => {
  return axios
    .post(`${api}get-all-custom-ads`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APImonitizelist = () => {
  return axios
    .get(`${other}/monitize/get-without-pagination-monitize-format`, {
      headers,
    })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIadd = (data) => {
  return axios
    .post(`${api}create-custom-ad`, data, { headers: headersWithForm })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIedit = (data, id) => {
  return axios
    .put(`${api}modify-custom-ad/${id}`, data, { headers: headersWithForm })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIdelete = (id) => {
  return axios
    .delete(`${api}delete-custom-ad/${id}`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIclone = (id) => {
  return axios
    .get(`${api}create-clone-custom-ad/${id}`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

export { APIlist, APIadd, APIedit, APIdelete, APImonitizelist, APIclone };
