import Dashboard from "./pages/dashboard/dashboard";
import Login from "./pages/login/login";
import AppApprovelList from "./pages/appApprovelList/appApprovelList";
import TestAdId from "./pages/settings/testAdId/testAdId";
import PlayConsole from "./pages/playConsole/playConsole";
import AppCategoryList from "./pages/appCategoryList/appCategoryList";
import DeveloperPage from "./pages/developer/developer";
import ReferencesPage from "./pages/references/references";
import AppPublishPlatform from "./pages/appPublishPlatform/appPublishPlatform";
import AppMonitizePlatform from "./pages/appMonitizePlatform/appMonitizePlatform";
import AppList from "./pages/appList/appList";
import NotificationAppWise from "./pages/pushNotification/notificationAppWise/notificationAppWise";
import SendAppNotification from "./pages/pushNotification/sendAppNotification/sendAppNotification";
import NotificationGroupWise from "./pages/pushNotification/notificationGroupWise/notificationGroupWise";
import MoreAppGroup from "./pages/moreAppGroup/moreAppGroup";
import AssignList from "./pages/moreAppGroup/assignList";
import CreateCustomAd from "./pages/createCustomAd/createCustomAd";
import AppWiseCustomAd from "./pages/appWiseCustomAd/appWiseCustomAd";
import AssignAd from "./pages/appWiseCustomAd/assignAd";
import LocationWiseReport from "./pages/locationWiseUsers/locationwiseUsers";
import BackupPendingList from "./pages/settings/backupPendingList/backupPendingList";
import ApplicationBug from "./pages/applicationBug/applicationBug";
import AppUpdateHistory from "./pages/appUpdateHistory/appUpdateHistory";
import AdminAccess from "./pages/settings/adminAccess/adminAccess";
import CreateNewAPI from "./pages/createNewAPI/createNewAPI";
import APIList from "./pages/apiList/apiList";
import ROIReport from "./pages/roiReport/roiReport";
import ViewList from "./pages/appList/ViewList";

const routes = [
  {
    path: "/dashboard",
    exact: true,
    name: "Dashboard",
    element: Dashboard,
  },
  {
    path: "/references",
    exact: true,
    name: "References",
    element: ReferencesPage,
  },
  { path: "/login", exact: true, name: "Login", element: Login },
  {
    path: "/app-approve-list",
    exact: true,
    name: "App Approve List",
    element: AppApprovelList,
  },
  {
    path: "/test-ad-id",
    exact: true,
    name: "Test-Ad-Id",
    element: TestAdId,
  },
  {
    path: "/play-console",
    exact: true,
    name: "Play-Console",
    element: PlayConsole,
  },
  {
    path: "/all-apps",
    exact: true,
    name: "All-App-List",
    element: AppList,
  },
  {
    path: "/all-apps/:id",
    exact: true,
    name: "All-App-List-view",
    element: ViewList,
  },

  {
    path: "/app-category-list",
    exact: true,
    name: "App-Category-List",
    element: AppCategoryList,
  },
  {
    path: "/developer",
    exact: true,
    name: "Devloper",
    element: DeveloperPage,
  },

  {
    path: "/app-publish-platform",
    exact: true,
    name: "App Publish Platform",
    element: AppPublishPlatform,
  },
  {
    path: "/app-monitize-platform",
    exact: true,
    name: "App Monitize Platform",
    element: AppMonitizePlatform,
  },
  {
    path: "/notification-app-wise",
    exact: true,
    name: "Notification App Wise",
    element: NotificationAppWise,
  },
  {
    path: "/notification-app-wise/:appId",
    exact: true,
    name: "Send App Notification",
    element: SendAppNotification,
  },
  {
    path: "/notification-group-wise",
    exact: true,
    name: "Send Group Notification",
    element: NotificationGroupWise,
  },
  {
    path: "/more-app-group",
    exact: true,
    name: "More App Group",
    element: MoreAppGroup,
  },
  {
    path: "/more-app-group/:moreAppGroupId",
    exact: true,
    name: "Assign List",
    element: AssignList,
  },
  {
    path: "/create-custom-ad",
    exact: true,
    name: "Create Custom Ad",
    element: CreateCustomAd,
  },

  {
    path: "/app-wise-custom-ad",
    exact: true,
    name: "App Wise Custom Ad",
    element: AppWiseCustomAd,
  },
  {
    path: "/app-wise-custom-ad/:appId",
    exact: true,
    name: "Assign Custom Ad",
    element: AssignAd,
  },
  {
    path: "/location-wise-users",
    exact: true,
    name: "Location Wise Users",
    element: LocationWiseReport,
  },
  {
    path: "/backup-pending-list",
    exact: true,
    name: "Backup Pending List",
    element: BackupPendingList,
  },
  {
    path: "/app-bug",
    exact: true,
    name: "Application Bug",
    element: ApplicationBug,
  },
  {
    path: "/app-update-history",
    exact: true,
    name: "Application Update History",
    element: AppUpdateHistory,
  },
  {
    path: "/admin-access",
    exact: true,
    name: "Admin Access",
    element: AdminAccess,
  },
  {
    path: "/create-new-api",
    exact: true,
    name: "Create New API",
    element: CreateNewAPI,
  },
  {
    path: "/api-list",
    exact: true,
    name: "API List",
    element: APIList,
  },
  {
    path: "/roi-report",
    exact: true,
    name: "ROI Report",
    element: ROIReport,
  },
];

export default routes;
