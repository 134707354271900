import React, { useState } from "react";
import "./style.css";
import { CButton } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilX } from "@coreui/icons";
import { useNavigate } from "react-router-dom";
import { APIlistFixIt } from "../../api-wrapper/ApiDashbord";
import { useEffect } from "react";
import { Toast } from "../../helper/links/Link";
import moment from "moment";
const FixItDiv = () => {
  const navigate = useNavigate();

  const [isBgVisible, setIsBgVisible] = useState(true);
  const [fixItData, setFixItData] = useState(false);

  const toggleBgVisibility = () => {
    setIsBgVisible(!isBgVisible);
  };

  useEffect(() => {
    APIlistFixIt()
      .then((res) => {
        if (res.isSuccess) {
          if (res.data == null) {
            setIsBgVisible(false);
          } else {
            setFixItData(res.data);
            setIsBgVisible(true);
          }
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  }, []);

  return (
    <>
      {isBgVisible ? (
        <div className="bG">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col col-lg-10">
              <div className="row d-flex flex-wrap gap-4">
                <div className="group4153 col col-12 col-sm-6 col-md-4 col-lg-auto position-relative">
                  {fixItData?.appLogo ? (
                    <img
                      src={fixItData?.appLogo}
                      alt="logo"
                      height={"60px"}
                      width={"60px"}
                      className="rounded-circle"
                    />
                  ) : (
                    <div className="default_image dash_img">
                      {" "}
                      {fixItData?.appName?.charAt(0)}
                    </div>
                  )}

                  <div
                    className="line-3 d-none d-md-block"
                    style={{ height: "116%" }}
                  ></div>
                </div>

                <div className="text col col-12 col-sm-6 col-md-4 col-lg-auto position-relative">
                  <div className="textBlock">Application Name</div>
                  <div className="textBlock2">
                    <p className="labelWrapper">
                      <span className="label">{fixItData?.appName}</span>
                    </p>
                  </div>
                  <div className="line-3 d-none d-md-block"></div>
                </div>

                <div className=" col col-12 col-sm-6 col-md-4 col-lg-auto text-center position-relative">
                  <div className="textBlock3">Current Date</div>
                  <div className="textBlock4">
                    <p className="labelWrapper2">
                      <span className="label2">
                        {" "}
                        {moment(fixItData?.createdAt).format("DD/MM/YYYY")}
                      </span>
                    </p>
                  </div>
                  <div className="line-3 d-none d-lg-block"></div>
                </div>

                <div className="text3 col col-12 col-sm-6 col-md-4 col-lg-auto position-relative">
                  <div className="textBlock5">Package Name</div>
                  <div className="textBlock6">
                    <p className="labelWrapper3">
                      <span className="label3">{fixItData?.packageName}</span>
                    </p>
                  </div>
                </div>

                <div className="text4 col col-12 col-sm-6 col-md-4 col-lg-auto position-relative">
                  <div className="textBlock7">Application Mode</div>
                  <div className="line-4 d-none d-md-block"></div>
                  <div className="textBlock8">
                    <p className="labelWrapper4">
                      <span className="label4">{fixItData?.appMode} Mode</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-12 col-lg-2 d-flex align-items-center gap-2 justify-content-center">
              <div className=" ms-md-auto">
                <CButton
                  className="fixit-btn"
                  style={{ height: "46px", width: "100px" }}
                  onClick={() => navigate("/app-approve-list")}
                >
                  Fixit
                </CButton>
              </div>
              <div className="frame4143">
                <CButton
                  className="cross-btn"
                  onClick={() => toggleBgVisibility()}
                  style={{ height: "46px", width: "40px" }}
                >
                  <CIcon icon={cilX} className="cross-icon" />
                </CButton>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default FixItDiv;
