import axios from "axios";

const api = `${process.env.REACT_APP_BACKEND_URL}/app/`;
const other = `${process.env.REACT_APP_BACKEND_URL}`;
const authToken = localStorage.getItem("token");
const headers = {
  Authorization: `Bearer ${authToken}`,
  "Content-Type": "application/json",
};
const headersWithForm = {
  Authorization: `Bearer ${authToken}`,
  "Content-Type": "multipart/form-data",
};

const APIlist = (data) => {
  return axios
    .post(`${api}get-all-apps`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIPlayConsole = () => {
  return axios
    .get(`${other}/playConsole/get-without-pagination-playConsoles`, {
      headers,
    })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIDevloper = () => {
  return axios
    .get(`${other}/developer/get-without-pagination-developers`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIReference = () => {
  return axios
    .get(`${other}/reference/get-without-pagination-references`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIdelete = (id) => {
  return axios
    .delete(`${api}delete-app/${id}`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIDetail = (id) => {
  return axios
    .get(`${api}get-app-details/${id}`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIGraph = (id) => {
  return axios
    .get(`${other}/devices/get-downolad-active-users/${id}`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIchangeStatus = (data, id) => {
  return axios
    .put(`${api}modify-app-settings/${id}`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIsaveStatus = (data, id) => {
  return axios
    .put(`${api}modify-app-details-settings/${id}`, data, {
      headers: headersWithForm,
    })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIStatusChange = (data, id) => {
  return axios
    .put(`${api}modify-app-placement-status/${id}`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIjskCode = (data, id) => {
  return axios
	  .put(`${api}manage-app-JKS/${id}`, data, { headers: headersWithForm })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIsourceCode = (data, id) => {
  return axios
	  .put(`${api}manage-app-source-code/${id}`, data, { headers: headersWithForm })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIdesignCode = (data, id) => {
  return axios
	  .put(`${api}manage-app-design/${id}`, data, { headers: headersWithForm })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIfieldSave = (data, id) => {
  return axios
    .put(`${api}manage-app-more-fields/${id}`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIsequenceMonitize = () => {
  return axios
    .get(`${other}/monitize/get-without-pagination-monitizes`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIsaveMonitize = (data, id) => {
  return axios
    .put(`${api}/modify-app-monitize-details-settings/${id}`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

export {
  APIsaveMonitize,
  APIsequenceMonitize,
  APIfieldSave,
  APIdesignCode,
  APIsourceCode,
  APIjskCode,
  APIStatusChange,
  APIsaveStatus,
  APIchangeStatus,
  APIGraph,
  APIDetail,
  APIlist,
  APIPlayConsole,
  APIDevloper,
  APIReference,
  APIdelete,
};
