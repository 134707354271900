import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import CIcon from "@coreui/icons-react";
import { cilPlus, cilTrash } from "@coreui/icons";
import {
  APIaccountlist,
  APIdeleteAccount,
} from "../../api-wrapper/ApiMoreAppGroup";
import { Toast } from "../../helper/links/Link";
import { useParams } from "react-router-dom";
import NoDataImg from "../../images/g8.png";
import DataTable from "react-data-table-component";
import AddAccount from "./AddAccount";
import DeleteModal from "../../helper/modal/DeleteModal";

function AssignAccount() {
  const { moreAppGroupId } = useParams();
  const [arg, setArg] = useState({ pageNo: 1, perPage: 10 });
  const [search, setSearch] = useState("");
  const [applicationData, setApplicationData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [visible, setVisible] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const tHeaderAssignAppList = [
    {
      name: "Account Name",
      selector: "pcName",
    },
    {
      name: "Platform Name",
      cell: (row) => row.platformId?.name,
    },
    {
      name: "Actions",
      selector: "actions",
      cell: (row) => (
        <button
          className="btn bg-white border border-secondary-subtle"
          onClick={() => {
            setShowDeleteModal(true);
            setDeleteId(row._id);
          }}
        >
          <CIcon icon={cilTrash} className="icon-trash text-red" color="red" />
        </button>
      ),
    },
  ];

  const getData = () => {
    let data = {
      ...arg,
      search: search,
    };
    APIaccountlist(data, moreAppGroupId)
      .then((e) => {
        if (e?.isSuccess) {
          setApplicationData(e?.data);
          setTotalRecords(e.totalRecords);
        } else {
          Toast.error(e?.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  useEffect(() => {
    getData();
  }, [arg]);

  const deleteHandler = () => {
    let data = {
      appId: deleteId,
    };
    APIdeleteAccount(data, moreAppGroupId)
      .then((res) => {
        if (res.isSuccess) {
          Toast.success(res.message);
          getData();
        } else {
          Toast.error(res.message);
        }
        setDeleteId();
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };
  return (
    <>
      <Card className="rounded-3 mt-4 p-1">
        <Card.Header className="align-items-center gap-4 flex-wrap padding-header">
          <div className="row col-12 align-items-center">
            <div className="col-9">
              <h5 className="card-title">
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="large" />}
                  aria-label="breadcrumb"
                >
                  <Typography key="1" color="text.primary" className="platform">
                    Assign Account List
                  </Typography>
                </Breadcrumbs>
              </h5>
            </div>

            <div className="col-2">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control search-box"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <button
                  className="btn btn-outline-secondary border search-btn-icon"
                  type="button"
                  onClick={() => getData()}
                >
                  <SearchIcon />
                </button>
              </div>
            </div>
            <div className="col-1 p-0">
              <button
                class="btn btn-primary "
                onClick={() => {
                  setVisible(true);
                }}
              >
                <CIcon icon={cilPlus} />
              </button>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          {applicationData?.length < 1 ? (
            <div className="d-flex w-100 h-75 justify-content-center no-data-img">
              <img src={NoDataImg} alt="no-data" className="w-35" />
            </div>
          ) : (
            <DataTable
              columns={tHeaderAssignAppList}
              data={applicationData}
              pagination
              paginationServer
              paginationPerPage={10}
              onChangePage={(page) =>
                setArg({ pageNo: page, perPage: arg.perPage })
              }
              onChangeRowsPerPage={(newPerPage, page) =>
                setArg({ pageNo: page, perPage: newPerPage })
              }
              paginationTotalRows={totalRecords}
              fixedHeader
            />
          )}
        </Card.Body>
      </Card>

      <AddAccount visible={visible} setVisible={setVisible} getData={getData} />
      {/* delete modal */}

      <DeleteModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        deleteHandler={deleteHandler}
      />
    </>
  );
}

export default AssignAccount;
