import axios from "axios";

const api = `${process.env.REACT_APP_BACKEND_URL}/category/`;

const authToken = localStorage.getItem("token");
const headers = {
  Authorization: `Bearer ${authToken}`,
  "Content-Type": "application/json",
};

const APIlist = (data) => {
  return axios
    .post(`${api}get-categories`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIadd = (data) => {
  return axios
    .post(`${api}add-category`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIedit = (data, id) => {
  return axios
    .put(`${api}modify-category/${id}`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIdelete = (id) => {
  return axios
    .delete(`${api}delete-category/${id}`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIlistTrash = (data) => {
  return axios
    .post(`${api}get-trash-categories`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIrestoreTrash = (id) => {
  return axios
    .get(`${api}restore-category/${id}`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
export { APIlist, APIadd, APIedit, APIdelete, APIlistTrash, APIrestoreTrash };
