import axios from "axios";

const api = `${process.env.REACT_APP_BACKEND_URL}/app/`;

const authToken = localStorage.getItem("token");
const headers = {
  Authorization: `Bearer ${authToken}`,
  "Content-Type": "application/json",
};
const headersWithForm = {
  Authorization: `Bearer ${authToken}`,
  "Content-Type": "multipart/form-data",
};

const APIlist = (data) => {
  return axios
    .post(`${api}get-all-apps`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIadd = (data, id) => {
  return axios
    .put(`${api}update-app-code-backup/${id}`, data, {
      headers: headersWithForm,
    })
    .then((res) => res.data)
    .catch((res) => res.data);
};

export { APIlist, APIadd };
