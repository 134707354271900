import jwtDecode from "jwt-decode";

const getCurrentUser = () => {
  const token = localStorage.getItem("token");

  if (token) {
    const decodedToken = jwtDecode(token);
    const expiresAt = new Date(decodedToken.exp * 1000);

    if (new Date() > expiresAt) {
      return localStorage.removeItem("token");
    } else {
      return decodedToken;
    }
  } else console.log("No token found");
};

export default getCurrentUser;
