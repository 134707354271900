import React, { useState } from "react";
import mainLogo from "../../images/mainlogo.png";
import { Formik } from "formik";
import { CodeSchema } from "../../utility/Validator";
import {
	APIjskCode,
	APIsourceCode,
	APIdesignCode,
} from "../../api-wrapper/ApiAppList";
import { useParams } from "react-router-dom";
import {
	Toast,
	CModal,
	CModalHeader,
	CModalBody,
	CModalTitle,
	CFormLabel,
	CFormInput,
} from "../../helper/links/Link";
function ViewCode({ viewAppJKS, setViewAppJKs, name, getData }) {
	let { id } = useParams();
	const [selectedImage, setSelectedImage] = useState(null);
	const [changeImg, setchangeImg] = useState(null);

	const handleSave = (values) => {
		if (name == "JKS") {
			let data = {
				appJKSDetails: values.detail,
				uploadFile: changeImg,
			};
			const formData = new FormData();
			formData.append("appJKSDetails", values.detail);
			formData.append("uploadFile", changeImg);
			APIjskCode(formData, id)

				.then((res) => {
					if (res.isSuccess) {
						Toast.success(res.message);
						getData();
						setViewAppJKs(false);
					} else {
						Toast.error(res?.message);
					}
				})
				.catch((err) => {
					Toast.error("Something went wrong, please try again!");
				});
		}

		if (name == "Source Code") {

			const formData = new FormData();
			formData.append("appSourceCodeDetails", values.detail);
			formData.append("uploadFile", changeImg);


			APIsourceCode(formData, id)
				.then((res) => {
					if (res.isSuccess) {
						Toast.success(res.message);
						getData();
						setViewAppJKs(false);
					} else {
						Toast.error(res?.message);
					}
				})
				.catch((err) => {
					Toast.error("Something went wrong, please try again!");
				});
		}

		if (name == "Design") {


			const formData = new FormData();
			formData.append("appDesignDetails", values.detail);
			formData.append("uploadFile", changeImg);

			APIdesignCode(formData, id)
				.then((res) => {
					if (res.isSuccess) {
						Toast.success(res.message);
						getData();
						setViewAppJKs(false);
					} else {
						Toast.error(res?.message);
					}
				})
				.catch((err) => {
					Toast.error("Something went wrong, please try again!");
				});
		}
	};

	return (
		<CModal
			visible={viewAppJKS}
			alignment="center"
			scrollable
			keyboard={false}
			backdrop="static"
			onClose={() => {
				setViewAppJKs(false);
			}}
		>
			<CModalHeader>
				<CModalTitle>

					<p className="h5 p-2 fw-bold mt-auto cust-h4 d-flex justify-content-center">
						App {name}
					</p>

				</CModalTitle>
			</CModalHeader>
			<CModalBody>
				<Formik
					validationSchema={CodeSchema}
					initialValues={{
						file: "",
						detail: "",
					}}
					onSubmit={(values) => {
						handleSave(values);
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						setFieldValue,
					}) => (
						<>
							<div className="mb-3 row">
								<CFormLabel>Upload {name} File</CFormLabel>
								<CFormInput
									type="file"
									name="file"
									accept={name == "JKS" ? ".txt" : ".zip, application/zip"}
									className="form-control-file d-block"
									onChange={(e) => {
										const imageFile = e.target.files[0];
										setSelectedImage(null);
										setchangeImg(imageFile);
										setFieldValue("file", imageFile);
									}}
								></CFormInput>
								<p className="error">
									{errors.file && touched.file && errors.file}
								</p>
							</div>
							<div className="mb-3 row">
								<CFormLabel> {name} Details</CFormLabel>
								<textarea
									class="form-control"
									id="exampleFormControlTextarea1"
									rows="6"
									name="detail"
									onChange={handleChange}
									value={values?.detail}
								></textarea>
								<p className="error">
									{errors.detail && touched.detail && errors.detail}
								</p>
							</div>

							<div className="row">
								<div className="col-12">
									<div className="d-flex flex-wrap justify-content-center w-100">
										<button
											className="btn close-btn bg-white text-dark border-danger me-2"
											onClick={() => {
												setViewAppJKs(false);
											}}
										>
											Close
										</button>
										<button
											className="btn bg-primary"
											onClick={handleSubmit}
											style={{ width: "200px", color: "white" }}
										>
											Save
										</button>
									</div>
								</div>
							</div>
						</>
					)}
				</Formik>
			</CModalBody>
		</CModal>
	);
}

export default ViewCode;
