import React, { useState } from "react";
import { Card } from "react-bootstrap";
import downloadSvg from "../../icons/svgs/download-svg.svg";
import ViewMonetizeSetting from "./ViewMonetizeSetting";
import moment from "moment";
import { APIStatusChange } from "../../api-wrapper/ApiAppList";
import { useParams } from "react-router-dom";
import Toast from "../../helper/toast/Toast";
import ViewCode from "./ViewCode";
import NoDataImg from "../../images/g8.png";
import { PermissionCheck } from "../../helper/links/Link";
function ViewPlacement({ allData, getData }) {
  const [viewMode, setviewMode] = useState(false);
  let { id } = useParams();
  const [viewAppJKS, setViewAppJKs] = useState(false);
  const [name, setName] = useState("");
  const HandleEnabled = (status, moId) => {
    let data = {
      monitizeId: moId,
      status: status ? false : true,
    };
    APIStatusChange(data, id)
      .then((res) => {
        if (res.isSuccess) {
          Toast.success(res.message);
          getData();
        } else {
          Toast.error(res?.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };
  return (
    <>
      <div className="row px-3">
        <Card className="rounded-3 ">
          <Card.Body>
            <div className="row">
              <div className="col-6 cust-col-12 p-2">
                <div className="row  me-0">
                  <div className="col-6">
                    <div className="text-start fw-semibold">
                      <p className="fs-5 cust-fs-3 mb-0">App Placement</p>
                    </div>
                  </div>
                  {PermissionCheck("All App List", "Monitize Settings") ? (
                    <div className="col-6 pe-0">
                      <div className="text-end">
                        <button
                          className="btn h-100 border border-primary text-primary"
                          onClick={() => setviewMode(true)}

                        >
                          Monitize Settings
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
                    <div className="row">
                      
                    </div>
                {allData?.monitizes.length != 0 && allData?.monitizes != undefined ? (
                  allData?.monitizes?.map((data, index) => (

                    <div className="col mt-3 mb-2 border border-secondary-subtle p-2 d-flex align-items-center">

                      <div className="col text-center">
                        <div className="d-flex justify-content-center">
                          {data?.image ? (
                            <img src={data?.image} className="ref-image" />
                          ) : (
                            <div className="default_image">
                              {" "}
                              {data?.name?.charAt(0)}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col text-center pt-2 pt-md-0">
                        <div className="">
                          <p className="fs-5 fw-semibold mb-0 cust-fs-5">
                            {data.name}
                          </p>
                        </div>
                      </div>
                      <div className="col text-center">
                        <div className="app_list">
                          <button
                            className={`${data.status
                              ? "btn btn-success"
                              : "btn btn-danger "
                              }`}
                            onClick={() => {
                              HandleEnabled(data.status, data.monitizeId);
                            }}
                          >
                            {data.status ? "Enable" : "Disable"}
                          </button>
                        </div>
                      </div>

                    </div>

                  ))
                ) : (
                  <div className="d-flex w-100 justify-content-center no-data-img">
                    <img src={NoDataImg} alt="no-data" className="w-35" />
                  </div>
                )}
              </div>
              <div className="col-6 cust-col-12 p-2">

                {PermissionCheck("All App List", "Edit App") ? (
                  <div className="d-flex flex-wrap gap-3 justify-content-end">
                    <button
                      className="btn border border-success bg-white  text-secondary d-flex flex-wrap gap-2"
                      onClick={() => {
                        setViewAppJKs(true);
                        setName("JKS");
                      }}
                    >
                      <img src={downloadSvg} />
                      <p className="mb-0 text-success" >JKS</p>
                    </button>

                    <button
                      className="btn bg-white border border-success text-secondary d-flex flex-wrap gap-2 text-start"
                      onClick={() => {
                        setViewAppJKs(true);
                        setName("Source Code");
                      }}
                    >
                      <img src={downloadSvg} />
                      <p className="mb-0 text-success">Source Code</p>
                    </button>

                    <button
                      className="btn bg-white border border-success  text-secondary d-flex flex-wrap gap-2 text-start"
                      onClick={() => {
                        setViewAppJKs(true);
                        setName("Design");
                      }}
                    >
                      <img src={downloadSvg} />
                      <p className="mb-0 text-success ">App Design</p>
                    </button>
                  </div>
                ) : null}

                <div className="row  all_apps_list mt-3">
                  <div className="col-6  mb-2">
                    <div className="block_1 text-white  total-apps-div   border-12  p-3 text-center">
                      <h5 className="m-0 pb-1">Account</h5>
                      <p className="m-0">{allData?.playConsoleId?.pcName || '-'}</p>
                    </div>
                  </div>
                  <div className="col-6  mb-2">
                    <div className="block_1 text-white total-apps-div   border-12  p-3 text-center">
                      <h5 className="m-0 pb-1">Category</h5>
                      <p className="m-0">{allData?.categoryId?.name || '-'}</p>
                    </div>
                  </div>

                  <div className="col-6  mb-2">
                    <div className="block_1 text-white total-apps-div   border-12  p-3 text-center">
                      <h5 className="m-0 pb-1">Created Date</h5>
                      <p className="m-0">
                        {moment(allData?.createdAt).format("YYYY-MM-DD")}
                      </p>
                    </div>
                  </div>
                  <div className="col-6 mb-2">
                    <div className="block_1 text-white total-apps-div   border-12 p-3 text-center">
                      <h5 className="m-0 pb-1">More-App-Group</h5>
                      <p className="m-0">
                        {allData?.moreAppGroupId?.groupName || "-"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row  all_apps_list d-flex justify-content-center ">
                  <div className="col-12 mb-2">
                    <div className="block_1 text-white total-apps-div   border-12 p-3 text-center">
                      <h5 className="m-0 pb-1">Advertise Group</h5>
                      <p className="m-0">-</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>

      <ViewMonetizeSetting
        allData={allData}
        viewMode={viewMode}
        setviewMode={setviewMode}
        getData={getData}
        flag={"monetize"}
      />

      <ViewCode
        getData={getData}
        setViewAppJKs={setViewAppJKs}
        viewAppJKS={viewAppJKS}
        name={name}
      />
    </>
  );
}

export default ViewPlacement;
