import axios from "axios";

const api = `${process.env.REACT_APP_BACKEND_URL}/`;

const authToken = localStorage.getItem("token");
const headers = {
  Authorization: `Bearer ${authToken}`,
  "Content-Type": "application/json",
};
const APIlistFixIt = (data) => {
  return axios
    .get(`${api}app/get-dashboard-approval-request`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIlistVisit = (data) => {
  return axios
    .get(`${api}devices/get-user-visit`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIlistApplication = () => {
  return axios
    .get(`${api}app/get-without-pagination-apps`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIlistChart = (data) => {
  return axios
    .post(`${api}devices/last-ninety-days-download`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIlistMonthlyLive = (data) => {
  return axios
    .get(`${api}app/get-monthly-new-live-apps`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIlistMonthlyConsole = (data) => {
  return axios
    .get(`${api}playConsole/get-monthly-new-playConsole`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIlistConsoleChart = (data) => {
  return axios
    .post(`${api}playConsole/get-monthly-app-playConsole`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIlistCategoryChart = (data) => {
  return axios
    .post(`${api}category/get-top-most-category`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIlistDateWiseTable = (data) => {
  return axios
    .post(`${api}devices/get-date-wise-download-app`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIlistAccountTable = (data) => {
  return axios
    .post(`${api}playConsole/get-top-perfomance-account`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIpublishPlatform = () => {
  return axios
    .get(`${api}platform/get-all-platforms`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
export {
  APIpublishPlatform,
  APIlistAccountTable,
  APIlistDateWiseTable,
  APIlistCategoryChart,
  APIlistConsoleChart,
  APIlistFixIt,
  APIlistVisit,
  APIlistApplication,
  APIlistChart,
  APIlistMonthlyConsole,
  APIlistMonthlyLive,
};
