import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import NoDataImg from "../../images/g8.png";
import {
  CFormSelect,
  Card,
  DataTable,
  Toast,
  Typography,
  NavigateNextIcon,
  Breadcrumbs,
  PermissionCheck,
} from "../../helper/links/Link";
import { APIlist } from "../../api-wrapper/ApiUpdateHistory";
import {
  APIaccountList,
  APIapplicationList,
} from "../../api-wrapper/ApiMoreAppGroup";
import Add from "./Add";
import viewAppUpdateHistorySvg from "../../icons/svgs/viewAppUpdateHistory.svg";
function AppUpdateHistory() {
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);
  const [arg, setArg] = useState({ pageNo: 1, perPage: 10 });
  const [totalRecords, setTotalRecords] = useState(0);
  const [visible, setVisible] = useState(false);
  const [id, setId] = useState("");
  const [playConsole, setPlayConsole] = useState([]);
  const [application, setapplication] = useState([]);
  const [playconsoleId, setplayconsoleId] = useState("");
  const [appId, setappId] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const tHeader = [
    {
      name: "Application Logo",
      selector: "appLogo",
      width: "150px",
      cell: (row) => {
        if (row?.appLogo)
          return <img src={row.appLogo} className="ref-image" />;
        else
          return <div className="default_image"> {row?.appName.charAt(0)}</div>;
      },
    },
    {
      name: "Application Name",
      selector: "appName",
      cell: (row) => row?.appName,
    },

    {
      name: "Developer Name",
      selector: (row) => row?.developerId?.name,
    },
    {
      name: "Old Version",
      selector: "version",
      cell: (row) => row.oldVersion,
    },
    {
      name: "New Version",
      selector: "version",
      cell: (row) => row.version,
    },
    {
      name: "Action",
      selector: "action",
      cell: (row) => (
        <img
          src={viewAppUpdateHistorySvg}
          alt="view svg"
          className="hover-icon"
          onClick={() => {
            setVisible(true);
            setId(row?._id);
          }}
        />
      ),
    },
  ];
  const getData = (page, perPage) => {
    let data = {
      pageNo: page || 1,
      perPage: perPage || rowsPerPage,
      search: search,
      playconsoleId: playconsoleId,
      appId: appId,
    };
    if (PermissionCheck("App Update History", "View Only"))
      APIlist(data)
        .then((e) => {
          if (e?.isSuccess) {
            setTableData(e?.data);
            setcurrentPage(e.currentPageNo);
            setTotalRecords(e.totalRecords);
          } else {
            Toast.error(e?.message);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
  };

  useEffect(() => {
    getData();
  }, [playconsoleId, appId]);

  useEffect(() => {
    let data = [];
    APIaccountList()
      .then((res) => {
        if (res.isSuccess) {
          res.data.map((el) => {
            data.push({
              label: el.pcName,
              value: el._id,
            });
          });
          setPlayConsole(data);
        } else {
          setPlayConsole([]);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  }, []);

  const handleSubAccount = (val) => {
    let data = [];
    setapplication([]);
    APIapplicationList(val)
      .then((res) => {
        if (res.isSuccess) {
          res.data.map((el) => {
            data.push({
              label: el.appName,
              value: el._id,
            });
          });

          setapplication(data);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  return (
    <>
      <Card className="rounded-3 p-1">
        <Card.Header>
          <div>
            <h5 className="card-title">
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="large" />}
                aria-label="breadcrumb"
              >
                <Typography key="1" color="text.primary" className="platform">
                  App Update History
                </Typography>
              </Breadcrumbs>
            </h5>
          </div>
          <div className="grid_item history_item">
            <div className="select-div">
              <CFormSelect
                aria-label="Default select example"
                name="developer"
                className="h-100"
                value={playconsoleId}
                onChange={(e) => {
                  handleSubAccount(e.target.value);
                  setplayconsoleId(e.target.value);
                }}
              >
                <option key="All" value="" selected>
                  All Play Console
                </option>
                {playConsole?.map((data, index) => (
                  <option key={data.value} value={data.value}>
                    {data.label}
                  </option>
                ))}
              </CFormSelect>
            </div>
            <div className="select-div">
              <CFormSelect
                aria-label="Default select example"
                name="developer"
                className="h-100"
                value={appId}
                onChange={(e) => setappId(e.target.value)}
              >
                <option key="category">All Application</option>
                {application?.map((data, index) => (
                  <option key={data.value} value={data.value}>
                    {data.label}
                  </option>
                ))}
              </CFormSelect>
            </div>
            <div className="align-items-center search-box-div">
              <div className="input-group mr-3">
                <input
                  type="text"
                  className="form-control search-box"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />

                <button
                  className="btn btn-outline-secondary border search-btn-icon"
                  type="button"
                  onClick={() => getData()}
                >
                  <SearchIcon />
                </button>
              </div>
            </div>
          </div>
        </Card.Header>
        {PermissionCheck("App Update History", "View Only") && (
          <Card.Body>
            {tableData?.length < 1 ? (
              <div className="d-flex w-100 justify-content-center no-data-img">
                <img src={NoDataImg} alt="no-data" className="w-35" />
              </div>
            ) : (
              <DataTable
                columns={tHeader}
                data={tableData}
                pagination
                paginationServer
                paginationPerPage={rowsPerPage}
                onChangeRowsPerPage={(event) => {
                  setRowsPerPage(parseInt(event));
                  getData(currentPage, event);
                }}
                onChangePage={(page) => {
                  getData(page);
                }}
                paginationDefaultPage={currentPage}
                paginationTotalRows={totalRecords}
                fixedHeader
              />
            )}
          </Card.Body>
        )}
      </Card>
      <Add visible={visible} setVisible={setVisible} id={id} />
    </>
  );
}

export default AppUpdateHistory;
