import React, { useState } from "react";
import CIcon from "@coreui/icons-react";
import { cilTrash, cilColorBorder } from "@coreui/icons";
import SearchIcon from "@mui/icons-material/Search";
import DataTable from "react-data-table-component";
import Card from "react-bootstrap/Card";
import { useEffect } from "react";
import NoDataImg from "../../images/g8.png";
import { DateRangePicker } from "rsuite";
import getCurrentUser from "../../utils/getCurrentUser.util";
import { APIdelete, APIlist } from "../../api-wrapper/ApiMonetizePlatform";
import { predefinedRanges } from "../../helper/calendarValues/calendarValues";
import { Toast, PermissionCheck } from "../../helper/links/Link";
import moment from "moment";
import Add from "./Add";
import DeleteModal from "../../helper/modal/DeleteModal";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";
import AddFormate from "./AddFormate";
function AppMonitizePlatform() {
  const [date, setDate] = useState([]);
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [visible, setVisible] = useState(false);
  const [editData, setEditData] = useState("");
  const [deleteId, setDeleteId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [visibleNewFormat, setVisibleNewFormat] = useState(false);

  const [currentPage, setcurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const tHeader = [
    {
      name: "Profile",
      selector: "image",
      width: "100px",
      cell: (row) => {
        if (row.image)
          return <img src={row.image} className="ref-image" alt="" />;
        else return <div className="default_image"> {row.name.charAt(0)}</div>;
      },
    },
    {
      name: "Platform Name",
      selector: "name",
      sortable: false,
    },
    {
      name: "Platform Url",
      selector: "url",
      sortable: false,
      cell: (row) => (
        <a href={row.url} target="_blank">{row.url}</a>
      ),
    },
    {
      name: "Platform Status",
      selector: "status",
      cell: (row) => {
        if (row.status)
          return <button className="btn active-btn fs-6">Active</button>;
        else
          return (
            <button className="btn text-black inactive-btn fs-6">
              Deactive{" "}
            </button>
          );
      },
    },
    {
      name: "Created Date",
      selector: "date",
      sortable: false,
      cell: (e) => moment(e?.createdAt).format("MM/DD/YYYY hh:mm:ss A z"),
    },
    {
      name: "Actions",
      selector: "actions",
      cell: (row) => (
        <div className="white d-flex gap-3 w-100 justify-content-center">
          {
            PermissionCheck("App Monetize Platform", "Edit Monitize Platform") &&
            <button
              onClick={() => {
                setEditData(row);
                setVisible(true);
              }}
              className="btn edit_icon"
            >
              <CIcon icon={cilColorBorder} />
            </button>
          }
          {
            PermissionCheck("App Monetize Platform", "Remove Monitize Platform") &&
            <button
              className="btn  delete_icon"
              onClick={() => {
                setShowDeleteModal(true);
                setDeleteId(row._id);
              }}
            >
              <CIcon
                icon={cilTrash}
                className="icon-trash text-red"
                color="red"
              />
            </button>
          }


        </div>
      ),
    },
  ];

  const getData = (page, perPage) => {
    let data = {
      pageNo: page || 1,
      perPage: perPage || rowsPerPage,
      fromDate: date.length == 0 ? "" : moment(date?.[0]).format("YYYY-MM-DD"),
      toDate: date.length == 0 ? "" : moment(date?.[1]).format("YYYY-MM-DD"),
      search: search,
    };
    if (PermissionCheck("App Monetize Platform", "View Only"))
      APIlist(data)
        .then((e) => {
          if (e?.isSuccess) {
            setTableData(e?.data);
            setcurrentPage(e.currentPageNo)
            setTotalRecords(e.totalRecords);
          } else {
            Toast.error(e?.message);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
  };

  useEffect(() => {
    getData();
  }, [date]);


  const deleteHandler = () => {
    APIdelete(deleteId)
      .then((res) => {
        if (res.isSuccess) {
          Toast.success(res.message);
          getData();
        } else {
          Toast.error(res.message);
        }
        setDeleteId();
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };



  return (
    <>
      <Card className="rounded-3 p-1">
        <Card.Header>
          <div className="">
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="large" />}
              aria-label="breadcrumb"
            >
              <Typography key="1" color="text.primary" className="platform">
                Platform
              </Typography>
              <Typography
                key="2"
                color="text.primary"
                className=""
                style={{ color: "#A5A5A5" }}
              >
                App Monetize Platform
              </Typography>
            </Breadcrumbs>
          </div>
          <div className="grid_monetize_platform">
            <div className="">
              {PermissionCheck("App Monetize Platform", "Add Monitize Platform") ? (
                <button
                  class="btn btn-primary "
                  onClick={() => {
                    setVisibleNewFormat(true);
                    setEditData("");
                  }}
                >
                  Add New Format
                </button>
              ) : null}
            </div>
            <div>
              {PermissionCheck("App Monetize Platform", "Edit Monitize Platform") ? (
                <button
                  class="btn btn-primary "
                  onClick={() => {
                    setVisible(true);
                    setEditData("");
                  }}
                >
                  Add Monetize
                </button>
              ) : null}
            </div>
            <div className="">
              <DateRangePicker
                className={`rangeDate custom-date-range-picker `}
                ranges={predefinedRanges}
                showOneCalendar
                value={date}
                placeholder="Select Date Range"
                onChange={(e) => setDate(e)}
                placement="bottomEnd"
                format="yyyy-MM-dd"
                cleanable={false}
              />
            </div>
            <div className="">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control search-box"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />

                <button
                  className="btn btn-outline-secondary border search-btn-icon"
                  type="button"
                  onClick={() => getData()}
                >
                  <SearchIcon />
                </button>
              </div>
            </div>
          </div>
        </Card.Header>
        {
          PermissionCheck("App Monetize Platform", "View Only") &&
          (
            <Card.Body>
              {tableData?.length < 1 ? (
                <div className="d-flex w-100 justify-content-center no-data-img">
                  <img src={NoDataImg} alt="no-data" className="w-35" />
                </div>
              ) : (
                <DataTable
                  columns={tHeader}
                  data={tableData}
                  pagination
                  paginationServer
                  paginationPerPage={rowsPerPage}
                  onChangeRowsPerPage={(event) => {
                    setRowsPerPage(parseInt(event));
                    getData(currentPage, event);
                  }}
                  onChangePage={(page) => {
                    getData(page);
                  }}
                  paginationDefaultPage={currentPage}
                  paginationTotalRows={totalRecords}
                  fixedHeader
                />
              )}
            </Card.Body>
          )
        }

      </Card>

      <Add
        visible={visible}
        setVisible={setVisible}
        editData={editData}
        getData={getData}
      />

      <AddFormate
        visible={visibleNewFormat}
        setVisible={setVisibleNewFormat}
        getData={getData}
      />

      {/* delete modal */}

      <DeleteModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        deleteHandler={deleteHandler}
      />
    </>
  );
}

export default AppMonitizePlatform;
