import React from "react";

const PrivacyPolicyIcon = (props) => (
  <svg
    preserveAspectRatio="none"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.87266 0.379687C4.47188 0.569531 3.94453 1.0125 3.7125 1.32891C3.26953 1.91953 3.26953 2.17266 3.20625 13.2469C3.16406 23.7094 3.18516 24.6375 3.52266 25.2914C3.7336 25.6922 4.17656 26.2195 4.49297 26.4516C5.0836 26.8734 5.33672 26.8945 13.5 26.8945C21.6633 26.8945 21.9164 26.8734 22.507 26.4516C23.8359 25.4812 23.8359 25.4602 23.8359 15.757V6.96094H20.8195C16.6641 6.96094 16.875 7.17187 16.875 3.14297V-4.74975e-07H11.243C6.28594 -4.74975e-07 5.50547 0.042187 4.87266 0.379687ZM16.7484 9.59766C17.3391 10.2305 17.8242 10.5469 18.1617 10.5469C18.4359 10.5469 18.9 10.6945 19.1742 10.8844C19.6594 11.2219 19.6594 11.2641 19.5539 14.4281C19.5117 16.1789 19.3641 17.9508 19.2164 18.3516C18.5414 20.5031 16.4742 22.7602 14.4703 23.5195C13.5422 23.8781 13.4578 23.8781 12.5297 23.5195C10.5258 22.7602 8.4586 20.5031 7.7836 18.3516C7.63594 17.9508 7.48828 16.1789 7.4461 14.4281C7.34063 11.2641 7.34063 11.2219 7.82578 10.8844C8.1 10.6945 8.56407 10.5469 8.83828 10.5469C9.19688 10.5469 9.63985 10.2305 10.2094 9.59766L11.0742 8.66953L13.4789 8.64844H15.8836L16.7484 9.59766Z"
      fill="#0643F2"
    />
    <path
      d="M11.1164 10.9898C10.6734 11.4539 10.0406 11.8758 9.70311 11.9602L9.0703 12.0656V14.5336C9.0703 17.7398 9.38671 18.8156 10.7578 20.3133C11.3273 20.925 12.1711 21.6211 12.6351 21.832C13.4789 22.1906 13.5211 22.1906 14.3648 21.832C14.8289 21.6211 15.6726 20.9461 16.2211 20.3555C17.5922 18.8367 17.9297 17.6977 17.9297 14.5547V12.0656L17.2969 11.9602C16.9594 11.8758 16.2844 11.4328 15.8203 10.9688C14.9766 10.125 14.9555 10.125 13.4578 10.1461C11.9601 10.1672 11.8969 10.1883 11.1164 10.9898ZM16.4109 14.5336C16.4742 14.9133 16.1367 15.3984 14.9344 16.6008C12.7828 18.7945 12.8461 18.7734 11.6016 17.5922C10.4414 16.5164 10.2305 15.7359 10.9898 15.5039C11.3062 15.3984 11.6016 15.525 12.0445 15.9469L12.6562 16.5164L14.0273 15.2086C15.525 13.7742 16.2844 13.5633 16.4109 14.5336Z"
      fill="#0643F2"
    />
    <path
      d="M18.5625 2.95312V5.27344H20.925H23.3086L20.9883 2.95312C19.7226 1.66641 18.6469 0.632812 18.6258 0.632812C18.5836 0.632812 18.5625 1.66641 18.5625 2.95312Z"
      fill="#0643F2"
    />
  </svg>
);

export default PrivacyPolicyIcon;
