import axios from "axios";

const api = `${process.env.REACT_APP_BACKEND_URL}/monitize/`;

const authToken = localStorage.getItem("token");
const headers = {
  Authorization: `Bearer ${authToken}`,
  "Content-Type": "application/json",
};
const headersWithForm = {
  Authorization: `Bearer ${authToken}`,
  "Content-Type": "multipart/form-data",
};

const APIlist = (data) => {
  return axios
    .post(`${api}get-monitizes`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIadd = (data) => {
  return axios
    .post(`${api}add-monitize`, data, { headers: headersWithForm })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIedit = (data, id) => {
  return axios
    .put(`${api}modify-monitize/${id}`, data, { headers: headersWithForm })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIdelete = (id) => {
  return axios
    .delete(`${api}delete-monitize/${id}`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APImonetiseFormat = () => {
  return axios
    .get(`${api}get-monitize-format`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIaddMonetize = (data) => {
  return axios
    .post(`${api}add-monitize-format`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIgetMonetize = () => {
  return axios
    .get(`${api}get-monitize-format`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIdeleteMonetize = (id) => {
  return axios
    .delete(`${api}delete-monitize-format/${id}`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

export {
  APIdeleteMonetize,
  APIadd,
  APIedit,
  APIlist,
  APIdelete,
  APImonetiseFormat,
  APIaddMonetize,
  APIgetMonetize,
};
