import React, { useEffect, useState } from "react";
import Frame29Icon from "./Frame29Icon";
import "./mainDiv.css";
import { APIpublishPlatform } from "../../api-wrapper/ApiDashbord";
import Toast from "../../helper/toast/Toast";

const MainDiv = () => {
  const [data, setdata] = useState();
  useEffect(() => {

    APIpublishPlatform()
      .then((res) => {
        if (res.isSuccess) {

          setdata(res.data);
        } else {
          Toast.error(res.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  }, []);
  return (
    <div className="root-div-2">
      <div className="publishPlatformMonetizePlatfor">
        Publish Platform &amp; Monetize Platform
      </div>
      <br />
      <div className="line3 d-none d-md-block"></div>
   
        {/* <Frame29Icon /> */}
        {
          data?.map(el => {

            return (
              <div className="">
                {el.image ?
                  <img src={el.image} className="dash_platform" />
                  :
                  <div className="dash_platform default_image"> {el?.name.charAt(0)}</div>}
              </div>
            )
          }
          )
        }
  
    </div>
  );
};

export default MainDiv;
