import React, { useState } from "react";
import CIcon from "@coreui/icons-react";
import { cilTrash, cilColorBorder } from "@coreui/icons";
import SearchIcon from "@mui/icons-material/Search";
import DataTable from "react-data-table-component";
import Card from "react-bootstrap/Card";
import { useEffect } from "react";
import NoDataImg from "../../images/g8.png";
import getCurrentUser from "../../utils/getCurrentUser.util";
import { APIdelete, APIlist, APIclone } from "../../api-wrapper/ApiCustomeAdd";
import {
	Toast,
	Typography,
	NavigateNextIcon,
	Breadcrumbs,
	PermissionCheck
} from "../../helper/links/Link";
import moment from "moment";
import Add from "./Add";
import DeleteModal from "../../helper/modal/DeleteModal";
import { cilClone } from "@coreui/icons";
import { Rating } from "react-simple-star-rating";

function CreateCustomAd() {
	const [date, setDate] = useState([
		new Date(moment().startOf("month")),
		new Date(),
	]);
	const [search, setSearch] = useState("");
	const [tableData, setTableData] = useState([]);
	const [arg, setArg] = useState({ pageNo: 1, perPage: 10 });
	const [totalRecords, setTotalRecords] = useState(0);
	const [visible, setVisible] = useState(false);
	const [editData, setEditData] = useState();
	const [deleteId, setDeleteId] = useState();
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [currentPage, setcurrentPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const tHeader = [
		{
			name: "Banner",
			selector: "assetsBanner",
			width: "100px",
			cell: (row) => {
				if (row?.assetsBanner) return <img src={row?.assetsBanner} className="ref-image" />;
				else return <div className="default_image"> {row?.assetsName?.charAt(0)}</div>;
			},
		},
		{
			name: "Assets Name",
			selector: "assetsName",
		},
		{
			name: "Action Button Name",
			selector: "actionButtonName",
		},
		{
			name: "Assets Ratings",
			selector: "assetsRating",
			cell: (row) => (
				<Rating
					initialValue={row.assetsRating}
					readonly
				/* Available Props */
				/>
			),
		},
		{
			name: "Assets Format",
			selector: "assetsType",
			cell: (row) => (
				<div className="text-center">
					{row?.monitizeFormatIds?.name} {/* {row?.monitizeFormatIds?.map((el) => el.name).join(',')} */}
				</div>

			)
		},
		{
			name: "Status",
			cell: (row) => (
				<button
					className={`${row.adStatus ? "btn btn-success" : "btn btn-danger"}`}
				>
					{row.adStatus ? "Enable" : "Disable"}
				</button>
			),
		},
		{
			name: "Actions",
			selector: "actions",
			cell: (row) => (
				<div className="white d-flex gap-3 w-100 justify-content-center">
					{PermissionCheck("Create Custom Ad", "Edit Custom Ad") ? (
						<button
							className="btn  edit_icon"
							onClick={() => {
								setEditData(row);
								setVisible(true);
							}}
						>
							<CIcon icon={cilColorBorder} />
						</button>
					) : null}

					<button
						className="btn  bell_icon"
						onClick={() => {
							cloneHandler(row._id);
						}}

					>
						<CIcon icon={cilClone} />
					</button>


					{PermissionCheck("Create Custom Ad", "Remove Custom Ad") ? (
						<button
							className="btn  delete_icon"
							onClick={() => {
								setShowDeleteModal(true);
								setDeleteId(row._id);
							}}
						>
							<CIcon
								icon={cilTrash}
							/>
						</button>
					) : null}
				</div>
			),
		},
	];

	const getData = (page, perPage) => {
		let data = {
			pageNo: page || 1,
			perPage: perPage || rowsPerPage,
			search: search,
		};
		if (PermissionCheck("Create Custom Ad", "View Only"))
			APIlist(data)
				.then((e) => {
					if (e?.isSuccess) {
						setTableData(e?.data);
						setcurrentPage(e.currentPageNo)
						setTotalRecords(e.totalRecords);
					} else {
						Toast.error(e?.message);
					}
				})
				.catch((err) => {
					Toast.error("Something went wrong, please try again!");
				});
	};

	const cloneHandler = (id) => {
		APIclone(id)
			.then((e) => {
				if (e?.isSuccess) {
					getData();
					Toast.success(e?.message);
				} else {
					Toast.error(e?.message);
				}
			})
			.catch((err) => {
				Toast.error("Something went wrong, please try again!");
			});
	};

	useEffect(() => {
		getData();
	}, [date]);

	const deleteHandler = () => {
		APIdelete(deleteId)
			.then((res) => {
				if (res.isSuccess) {
					Toast.success(res.message);
					getData();
				} else {
					Toast.error(res.message);
				}
				setDeleteId();
			})
			.catch((err) => {
				Toast.error("Something went wrong, please try again!");
			});
	};



	return (
		<>
			<Card className="rounded-3 p-1">
				<Card.Header className="align-items-center gap-4 flex-wrap padding-header">
					<h5 className="card-title">
						<Breadcrumbs
							separator={<NavigateNextIcon fontSize="large" />}
							aria-label="breadcrumb"
						>
							<Typography key="1" color="text.primary" className="platform">
								Custom Advertise
							</Typography>
							<Typography
								key="2"
								color="text.primary"
								className=""
								style={{ color: "#A5A5A5" }}
							>
								Create Custom Ad
							</Typography>
						</Breadcrumbs>
					</h5>
					<div className="d-flex gap-4 flex-wrap">
						{PermissionCheck("Create Custom Ad", "Create Custom Ad") ? (
							<button
								class="btn bg-primary add-ref-btn"
								onClick={() => {
									setVisible(true);
									setEditData("");
								}}
							>
								Add Custom Ad
							</button>
						) : null}

						<div className="align-items-center">
							<div className="input-group mr-3 h-100">
								<input
									type="text"
									className="form-control search-box"
									placeholder="Search"
									value={search}
									onChange={(e) => setSearch(e.target.value)}
								/>
								<button
									className="btn btn-outline-secondary border search-btn-icon"
									type="button"
									onClick={() => getData()}
								>
									<SearchIcon />
								</button>
							</div>
						</div>
					</div>
				</Card.Header>
				{
					PermissionCheck("Create Custom Ad", "View Only") && (
						<Card.Body>
							{tableData?.length < 1 ? (
								<div className="d-flex w-100 justify-content-center no-data-img">
									<img src={NoDataImg} alt="no-data" className="w-35" />
								</div>
							) : (
								<DataTable
									columns={tHeader}
									data={tableData}
									pagination
									paginationServer
									paginationPerPage={rowsPerPage}
									onChangeRowsPerPage={(event) => {
										setRowsPerPage(parseInt(event));
										getData(currentPage, event);
									}}
									onChangePage={(page) => {
										getData(page);
									}}
									paginationDefaultPage={currentPage}
									paginationTotalRows={totalRecords}
									fixedHeader
								/>
							)}
						</Card.Body>
					)
				}

			</Card>

			<Add
				visible={visible}
				setVisible={setVisible}
				editData={editData}
				getData={getData}
			/>

			{/* delete modal */}

			<DeleteModal
				showDeleteModal={showDeleteModal}
				setShowDeleteModal={setShowDeleteModal}
				deleteHandler={deleteHandler}
			/>
		</>
	);
}

export default CreateCustomAd;
