import React, { useState, useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";
import { Toast, PermissionCheck } from "../../helper/links/Link";
import LanguageIcon from "@mui/icons-material/Language";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
import {
  APIaccountList,
  APIapplicationList,
} from "../../api-wrapper/ApiMoreAppGroup";
import { APICreate } from "../../api-wrapper/ApiCreateApi";

const CreateNewAPI = () => {
  let navigate = useNavigate();
  const [playConsole, setPlayConsole] = useState([]);
  const [application, setapplication] = useState([]);
  const [appId, setappId] = useState("");
  const [name, setName] = useState("");

  const [jsonResponse, setJsonResponse] = useState("");

  useEffect(() => {
    let data = [];
    APIaccountList()
      .then((res) => {
        if (res.isSuccess) {
          res.data.map((el) => {
            data.push({
              label: el.pcName,
              value: el._id,
            });
          });
          setPlayConsole(data);
        } else {
          setPlayConsole([]);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  }, []);

  const handleSubAccount = (val) => {
    let data = [];
    setapplication([]);
    APIapplicationList(val)
      .then((res) => {
        if (res.isSuccess) {
          res.data.map((el) => {
            data.push({
              label: el.appName,
              value: el._id,
            });
          });

          setapplication(data);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  const handleSave = () => {
    let data = {
      appId: appId,
      name: name,
      method: "GET",
      jsonResponse: jsonResponse,
    };
    if (PermissionCheck("Create New API", "Create API"))
      APICreate(data)
        .then((e) => {
          if (e?.isSuccess) {
            Toast.success(e?.message);
            navigate("/api-list");
          } else {
            Toast.error(e.message);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong!!");
        });
  };

  return (
    <>
      {/* create new api card */}
      <Card className="rounded-3 p-1 mt-4">
        <Card.Header className="align-items-center gap-1 flex-wrap padding-header">
          <h5 className="card-title">
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="large" />}
              aria-label="breadcrumb"
            >
              <Typography key="1" color="text.primary" className="platform">
                Create API
              </Typography>
              <Typography
                key="2"
                color="text.primary"
                className=""
                style={{ color: "#A5A5A5" }}
              >
                Create New API
              </Typography>
            </Breadcrumbs>
          </h5>
        </Card.Header>
        {PermissionCheck("Create New API", "Create API") && (
          <Card.Body>
            <div className="row">
              <div className="col-lg-4 col-sm-12 mb-1 cust-col-2">
                <div className="select-div">
                  <select
                    className="form-select py-2"
                    aria-label="Default select example"
                    onChange={(e) => {
                      handleSubAccount(e.target.value);
                    }}
                  >
                    <option value="" selected>
                      {" "}
                      All Play Console
                    </option>
                    {playConsole?.map((data, index) => (
                      <option key={data.value} value={data.value}>
                        {data.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12 mb-1 cust-col-2">
                <div className="select-div">
                  <select
                    className="form-select py-2"
                    aria-label="Default select example"
                    onChange={(e) => setappId(e.target.value)}
                    value={appId}
                  >
                    <option value="" selected>
                      All Application
                    </option>
                    {application?.map((data, index) => (
                      <option key={data.value} value={data.value}>
                        {data.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-lg-4 col-sm-12 mb-1 cust-col-2">
                <div className="input-group mr-3">
                  <input
                    type="text"
                    className="form-control search-box "
                    placeholder="Enter API Name"
                    onChange={(e) => setName(e.target.value)}
                    name="name"
                    value={name}
                  />
                </div>
              </div>
            </div>

            <div className="row p-3 pt-2">
              <div className="ps-0 pb-1">Response:</div>
              <textarea
                rows="15"
                className="border border-secondary-subtle"
                name="jsonResponse"
                onChange={(e) => setJsonResponse(e.target.value)}
                value={jsonResponse}
              />
            </div>
          </Card.Body>
        )}
      </Card>
      <div className="row mt-3">
        <div className="d-flex flex-wrap gap-2 justify-content-end">
          <button
            className="btn bg-primary"
            onClick={handleSave}
            style={{ color: "white" }}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default CreateNewAPI;
