import axios from "axios";

const api = `${process.env.REACT_APP_BACKEND_URL}/app/`;
const other = `${process.env.REACT_APP_BACKEND_URL}`;
const authToken = localStorage.getItem("token");
const headers = {
  Authorization: `Bearer ${authToken}`,
  "Content-Type": "application/json",
};
const headersWithForm = {
  Authorization: `Bearer ${authToken}`,
  "Content-Type": "multipart/form-data",
};

const APIlist = (data) => {
  return axios
    .post(`${api}get-app-approval-list`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIadd = (data) => {
  return axios
    .post(`${api}fixit-app`, data, { headers: headersWithForm })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIdelete = (id) => {
  return axios
    .delete(`${api}delete-app-request/${id}`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIdeleteAll = () => {
  return axios
    .delete(`${api}delete-all-app-request`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIdeleteMany = (data) => {
  return axios
    .delete(`${api}delete-many-app-request`, { headers: headers, data: data })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIplayConsole = () => {
  return axios
    .get(`${other}/playConsole/get-without-pagination-playConsoles`, {
      headers,
    })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIcategories = () => {
  return axios
    .get(`${other}/category/get-without-pagination-categories`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIdeveloper = () => {
  return axios
    .get(`${other}/developer/get-without-pagination-developers`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIreference = () => {
  return axios
    .get(`${other}/reference/get-without-pagination-references`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIaccepted = (id) => {
  return axios
    .get(`${api}app-request-update/${id}`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
export {
  APIaccepted,
  APIdeleteAll,
  APIdeleteMany,
  APIreference,
  APIlist,
  APIadd,
  APIdelete,
  APIplayConsole,
  APIcategories,
  APIdeveloper,
};
