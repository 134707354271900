import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { APIfieldSave } from "../../api-wrapper/ApiAppList";
import { useParams } from "react-router-dom";
import {
  Toast,
  CFormSelect,
  CModal,
  CModalHeader,
  CModalBody,
  CModalTitle,
  CFormLabel,
  CFormInput,
} from "../../helper/links/Link";
import { HexColorPicker } from "react-colorful";
import ColorPicker from "react-pick-color";
import { rgba } from 'polished';

function ViewField({ getData, allData, modalField, setModalField }) {
  let { id } = useParams();
  const [color, setColor] = useState("#561ecb");

  const [data, setdata] = useState({
    appMoreFieldNativePreLoad: false,
    appMoreFieldNativeAdPlaceholder: false,
    appMoreFieldBackgroundAppOpenAd: false,
    appMoreFieldBannerPreLoad: false,
    appMoreFieldBannerAdPlaceholder: false,
    appMoreFieldInnerMoreAppDataAd: false,
  });

  const handleSave = (values) => {
    let val = {
      appMoreFieldNativePreLoad: data.appMoreFieldNativePreLoad,
      appMoreFieldNativeAdPlaceholder: data.appMoreFieldNativeAdPlaceholder,
      appMoreFieldBackgroundAppOpenAd: data.appMoreFieldBackgroundAppOpenAd,
      appMoreFieldBannerPreLoad: data.appMoreFieldBannerPreLoad,
      appMoreFieldBannerAdPlaceholder: data.appMoreFieldBannerAdPlaceholder,
      appMoreFieldInnerMoreAppDataAd: data.appMoreFieldInnerMoreAppDataAd,
      appMoreFieldAdPlaceholderText: values?.appMoreFieldAdPlaceholderText,
      appMoreFieldAdsButtonColor: values?.appMoreFieldAdsButtonColor,
      appMoreFieldAdsTextColor: values?.appMoreFieldAdsTextColor,
      appMoreFieldAdsBackgroundColor: values?.appMoreFieldAdsBackgroundColor,
      appMoreFieldNativeAdSize: values?.appMoreFieldNativeAdSize,
      appMoreFieldAdsButtonTextColor: values?.appMoreFieldAdsButtonTextColor,
    };
    APIfieldSave(val, id)
      .then((e) => {
        if (e?.isSuccess) {
          setModalField(false);
          Toast.success(e?.message);
          getData();
        } else {
          Toast.error(e?.message);
        }
      })
      .catch((e) => {
        Toast.error("Something Went Wrong!!");
      });
  };
  useEffect(() => {
    setdata({
      appMoreFieldNativePreLoad: allData?.appMoreFieldNativePreLoad,
      appMoreFieldNativeAdPlaceholder: allData?.appMoreFieldNativeAdPlaceholder,
      appMoreFieldBackgroundAppOpenAd: allData?.appMoreFieldBackgroundAppOpenAd,
      appMoreFieldBannerPreLoad: allData?.appMoreFieldBannerPreLoad,
      appMoreFieldBannerAdPlaceholder: allData?.appMoreFieldBannerAdPlaceholder,
      appMoreFieldInnerMoreAppDataAd: allData?.appMoreFieldInnerMoreAppDataAd,
    });
  }, [allData]);

  const rgbaToHex = (r, g, b, a) => {
    const rgbHex = `#${parseInt(r).toString(16).padStart(2, '0')}${parseInt(g).toString(16).padStart(2, '0')}${parseInt(b).toString(16).padStart(2, '0')}`; // Converting RGB to hexadecimal
    const alphaHex = Math.round(a * 255).toString(16).padStart(2, '0'); // Converting alpha to hexadecimal
    return `${rgbHex}${alphaHex}`; // Concatenating RGB hexadecimal with alpha hexadecimal
  };
  return (
    <CModal
      visible={modalField}
      alignment="center"
      scrollable
      keyboard={false}
      backdrop="static"
      onClose={() => {
        // setviewMode(setModalField);
        setModalField(false);
      }}
      size="xl"
    >
      <CModalHeader>
        <CModalTitle>
          <p className="h4 pt-2 fw-bold mt-auto cust-h4">App More Field</p>
        </CModalTitle>
      </CModalHeader>
      <CModalBody>
        <Formik
          initialValues={{
            appMoreFieldAdPlaceholderText:
              allData?.appMoreFieldAdPlaceholderText,
            appMoreFieldNativeAdSize: allData?.appMoreFieldNativeAdSize,
            appMoreFieldAdsButtonColor: allData?.appMoreFieldAdsButtonColor,
            appMoreFieldAdsTextColor: allData?.appMoreFieldAdsTextColor,
            appMoreFieldAdsButtonTextColor:
              allData?.appMoreFieldAdsButtonTextColor,
            appMoreFieldAdsBackgroundColor:
              allData?.appMoreFieldAdsBackgroundColor,
          }}
          onSubmit={(values) => {
            handleSave(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <>
              <div className="row p-1">
                <div className="col-6 cust-col-6-app-more-fields p-1">
                  <div className="native-preload-main-div">
                    <div className="d-flex flex-wrap gap-1 cust-gap-1-app-more-fields">
                      <div>
                        <div className="d-flex flex-wrap align-items-center">
                          <label class="switch">
                            <input
                              type="checkbox"
                              name="appMoreFieldNativePreLoad"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  appMoreFieldNativePreLoad: e.target.checked,
                                })
                              }
                              checked={data?.appMoreFieldNativePreLoad}
                            />
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="apply-tex">
                        <p className="cust-fs-6-app-more-fields fw-semibold">
                          ::Native PreLoad
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 cust-col-6-app-more-fields p-1">
                  <div className="banner-preload-main-div">
                    <div className="d-flex flex-wrap gap-1 cust-gap-1-app-more-fields">
                      <div>
                        <div className="d-flex flex-wrap align-items-center">
                          <label class="switch">
                            <input
                              type="checkbox"
                              name="appMoreFieldBannerPreLoad"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  appMoreFieldBannerPreLoad: e.target.checked,
                                })
                              }
                              checked={data?.appMoreFieldBannerPreLoad}
                            />
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="apply-tex ">
                        <p className="cust-fs-6-app-more-fields fw-semibold">
                          ::Banner PreLoad
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 cust-col-6-app-more-fields p-1">
                  <div className="native-ad-placeholder-main-div">
                    <div className="d-flex flex-wrap gap-1 cust-gap-1-app-more-fields">
                      <div>
                        <div className="d-flex flex-wrap align-items-center">
                          <label class="switch">
                            <input
                              type="checkbox"
                              name="appMoreFieldNativeAdPlaceholder"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  appMoreFieldNativeAdPlaceholder:
                                    e.target.checked,
                                })
                              }
                              checked={data?.appMoreFieldNativeAdPlaceholder}
                            />
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="apply-tex">
                        <p className="cust-fs-6-app-more-fields fw-semibold">
                          ::Native Ad Placeholder
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 cust-col-6-app-more-fields p-1">
                  <div className="banner-ad-placeholder-main-div">
                    <div className="d-flex flex-wrap gap-1 cust-gap-1-app-more-fields">
                      <div>
                        <div className="d-flex flex-wrap align-items-center">
                          <label class="switch">
                            <input
                              type="checkbox"
                              name="appMoreFieldBannerAdPlaceholder"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  appMoreFieldBannerAdPlaceholder:
                                    e.target.checked,
                                })
                              }
                              checked={data?.appMoreFieldBannerAdPlaceholder}
                            />
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="apply-tex">
                        <p className="cust-fs-6-app-more-fields fw-semibold">
                          ::Banner Ad Placeholder
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 cust-col-6-app-more-fields p-1">
                  <div className="background-app-open-ad-main-div">
                    <div className="d-flex flex-wrap gap-1 cust-gap-1-app-more-fields">
                      <div>
                        <div className="d-flex flex-wrap align-items-center">
                          <label class="switch">
                            <input
                              type="checkbox"
                              name="appMoreFieldBackgroundAppOpenAd"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  appMoreFieldBackgroundAppOpenAd:
                                    e.target.checked,
                                })
                              }
                              checked={data?.appMoreFieldBackgroundAppOpenAd}
                            />
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="apply-tex">
                        <p className="cust-fs-6-app-more-fields fw-semibold">
                          ::Background AppOpen Ad
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 cust-col-6-app-more-fields p-1">
                  <div className="inner-moreapp-data-ad-main-div">
                    <div className="d-flex flex-wrap gap-1 cust-gap-1-app-more-fields">
                      <div>
                        <div className="d-flex flex-wrap align-items-center">
                          <label class="switch">
                            <input
                              type="checkbox"
                              name="appMoreFieldInnerMoreAppDataAd"
                              onChange={(e) =>
                                setdata({
                                  ...data,
                                  appMoreFieldInnerMoreAppDataAd:
                                    e.target.checked,
                                })
                              }
                              checked={data?.appMoreFieldInnerMoreAppDataAd}
                            />
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div className="apply-tex">
                        <p className="cust-fs-6-app-more-fields fw-semibold">
                          ::Inner More AppData Ad
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pt-4 pb-1 ps-1 pe-1">
                <div className="main-inputs-div">
                  <div className="inputs-div">
                    <div className="input-res-div-app-more-field">
                      <CFormLabel className=" cust-fs-5-app-more-fields ">
                        Ad Placeholder Text:
                      </CFormLabel>
                      <CFormInput
                        type="text"
                        name="appMoreFieldAdPlaceholderText"
                        aria-label="default input example"
                        onChange={handleChange}
                        value={values?.appMoreFieldAdPlaceholderText}
                      />
                    </div>
                    <div className="input-res-div-app-more-field">
                      <CFormLabel className=" cust-fs-5-app-more-fields">
                        NativeAd size (big/small):
                      </CFormLabel>
                      <CFormSelect
                        aria-label="Default select example"
                        name="appMoreFieldNativeAdSize"
                        value={values?.appMoreFieldNativeAdSize}
                        onChange={handleChange}
                      >
                        <option key="big" value="big">
                          Big
                        </option>
                        <option key="small" value="small">
                          Small
                        </option>
                      </CFormSelect>
                    </div>
                    <div className="input-res-div-app-more-field">
                      <CFormLabel className=" cust-fs-5-app-more-fields ">
                        Ads Button Color:
                      </CFormLabel>

                      <ColorPicker

                        color={values?.appMoreFieldAdsButtonColor}
                        onChange={(e) => {
                          const { r, g, b, a } = e.rgb;
                          const hexColor = rgbaToHex(r, g, b, a);
                          setFieldValue("appMoreFieldAdsButtonColor", hexColor);
                        }}
                      />
                      <button
                        type="button"
                        class="btn btn-dark"
                        onClick={() =>
                          setFieldValue("appMoreFieldAdsButtonColor", "")
                        }
                      >
                        Reset
                      </button>
                    </div>
                    <div className="input-res-div-app-more-field">
                      <CFormLabel className=" cust-fs-5-app-more-fields ">
                        Ads Button Text Color:
                      </CFormLabel>

                      <ColorPicker
                        color={values?.appMoreFieldAdsButtonTextColor}

                        onChange={(e) => {
                          const { r, g, b, a } = e.rgb;
                          const hexColor = rgbaToHex(r, g, b, a);
                          setFieldValue("appMoreFieldAdsButtonTextColor", hexColor);
                        }}
                      />
                      <button
                        type="button"
                        class="btn btn-dark"
                        onClick={() =>
                          setFieldValue("appMoreFieldAdsButtonTextColor", "")
                        }
                      >
                        Reset
                      </button>
                    </div>
                    <div className="input-res-div-app-more-field">
                      <CFormLabel className="fs-6 cust-fs-5-app-more-fields ">
                        Ads Text Color:
                      </CFormLabel>

                      <ColorPicker
                        color={values?.appMoreFieldAdsTextColor}
                        onChange={(e) => {
                          const { r, g, b, a } = e.rgb;
                          const hexColor = rgbaToHex(r, g, b, a);
                          setFieldValue("appMoreFieldAdsTextColor", hexColor);
                        }}
                      />
                      <button
                        type="button"
                        class="btn btn-dark"
                        onClick={() =>
                          setFieldValue("appMoreFieldAdsTextColor", "")
                        }
                      >
                        Reset
                      </button>
                    </div>
                    <div className="input-res-div-app-more-field">
                      <CFormLabel className="fs-6 cust-fs-5-app-more-fields ">
                        Ads Background Color:
                      </CFormLabel>

                      <ColorPicker
                        color={values?.appMoreFieldAdsBackgroundColor}
                        onChange={(e) => {
                          const { r, g, b, a } = e.rgb;
                          const hexColor = rgbaToHex(r, g, b, a);
                          setFieldValue("appMoreFieldAdsBackgroundColor", hexColor);
                        }}
                      />

                      <button
                        type="button"
                        class="btn btn-dark"
                        onClick={() =>
                          setFieldValue("appMoreFieldAdsBackgroundColor", "")
                        }
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row p-2 d-flex justify-content-center">
                <button
                  color="primary"
                  className="btn bg-primary text-light create-apikey-btn"
                  onClick={handleSubmit}
                  style={{ width: "200px" }}
                >
                  Save
                </button>
              </div>
            </>
          )}
        </Formik>
      </CModalBody>
    </CModal>
  );
}

export default ViewField;
