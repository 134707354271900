import axios from "axios";

const api = `${process.env.REACT_APP_BACKEND_URL}/notification/`;
const apiOther = `${process.env.REACT_APP_BACKEND_URL}/`;
const authToken = localStorage.getItem("token");
const headers = {
  Authorization: `Bearer ${authToken}`,
  "Content-Type": "application/json",
};
const headersWithForm = {
  Authorization: `Bearer ${authToken}`,
  "Content-Type": "multipart/form-data",
};

const APIlist = (data) => {
  return axios
    .post(`${api}get-all-notification-group`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIadd = (data) => {
  return axios
    .post(`${api}add-notification-group-wise`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIedit = (data, id) => {
  return axios
    .put(`${api}modify-notification-group/${id}`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIlistAppId = (id) => {
  return axios
    .get(`${apiOther}playConsole/get-playConsole-wise-apps/${id}`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIaddNoti = (data) => {
  return axios
    .post(`${api}send-group-notification`, data, { headers: headersWithForm })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIdelete = (id) => {
  return axios
    .delete(`${api}delete-notification-group/${id}`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

export { APIdelete, APIlist, APIlistAppId, APIadd, APIedit, APIaddNoti };
