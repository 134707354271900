import React, { useEffect } from "react";
import { Formik } from "formik";
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
  CFormLabel,
  CFormInput,
  CFormCheck,
  CFormSelect,
} from "@coreui/react";
import mainLogo from "../../images/mainlogo.png";
import { Monetizeschema } from "../../utility/Validator";
import { Toast } from "../../helper/links/Link";
import {
  APIadd,
  APIedit,
  APIgetMonetize,
  APIdelete,
} from "../../api-wrapper/ApiMonetizePlatform";
import { useState } from "react";
function Add({ visible, setVisible, editData, getData }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [changeImg, setchangeImg] = useState(null);
  const [format, setFormat] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);

  const handleSubmit = (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("url", values.url);
    checkedItems.forEach((item, index) => {
      formData.append(`monitizeFormatIds[${index}]`, item);
    });

    if (changeImg) {
      formData.append("image", changeImg);
    }
    if (editData?._id) {
      formData.append("status", values.status == "Active" ? true : false);
      APIedit(formData, editData?._id)
        .then((e) => {
          if (e?.isSuccess) {
            Toast.success(e?.message);
            setVisible(false);
            setSelectedImage(null);
            setCheckedItems([]);
            setchangeImg(null);
            getData();
          } else {
            Toast.error(e?.message);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
    } else {
      APIadd(formData)
        .then((e) => {
          if (e?.isSuccess) {
            setVisible(false);
            Toast.success(e?.message);
            setSelectedImage(null);
            setchangeImg(null);
            setCheckedItems([]);
            getData();
          } else {
            Toast.error(e?.message);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
    }
  };

  useEffect(() => {
    if (visible) {
      APIgetMonetize()
        .then((res) => {
          if (res.isSuccess) {
            setFormat(res.data);
          } else {
            setFormat([]);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
    }
  }, [visible]);

  useEffect(() => {
    setCheckedItems(
      editData?.monitizeFormatIds?.map((x) => x.monetizeFormatId) || []
    );
    setSelectedImage(editData?.image);
  }, [editData]);

  const handleCheck = (e) => {
    if (e.target.checked) {
      setCheckedItems((prevState) => [...prevState, e.target.value]);
    } else {
      setCheckedItems(checkedItems.filter((x) => x !== e.target.value));
    }
  };

  return (
    <>
      <CModal
        visible={visible}
        alignment="center"
        scrollable
        keyboard={false}
        backdrop="static"
        onClose={() => {
          setVisible(false);
        }}
      >
        <Formik
          validationSchema={Monetizeschema}
          initialValues={{
            url: editData?.url,
            name: editData?.name,
            image: editData?.image,
            checkedItems:
              editData?.monitizeFormatIds?.map((x) => x.monetizeFormatId) || [],
            status: editData?.status == true ? "Active" : "Deactive",
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <>
              <CModalHeader>
                <CModalTitle className="">
                 
                      <p className="h4 pt-2 fw-bold mt-auto text-16">
                        Monetize Platform
                      </p>
                    
                </CModalTitle>
              </CModalHeader>
              <CModalBody>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <CFormLabel>Platform Name</CFormLabel>

                    <CFormInput
                      type="text"
                      name="name"
                      value={values.name}
                      onBlur={handleBlur}
                      aria-label="default input example"
                      onChange={handleChange}
                    />
                    <p className="error">
                      {errors.name && touched.name && errors.name}
                    </p>
                  </div>
                  <div className="mb-3">
                    <CFormLabel>Platform Url</CFormLabel>
                    <CFormInput
                      type="text"
                      name="url"
                      value={values.url}
                      onBlur={handleBlur}
                      aria-label="default input example"
                      onChange={handleChange}
                    />
                    <p className="error">
                      {errors.url && touched.url && errors.url}
                    </p>
                  </div>
                  {editData?._id && (
                    <div className="mb-3">
                      <CFormLabel>Developer Status</CFormLabel>
                      <CFormSelect
                        aria-label="Default select example"
                        name="status"
                        value={values.status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      >
                        <option key="Live" value="Active">
                          Active
                        </option>
                        <option key="Suspended" value="Deactive">
                          Deactive
                        </option>
                      </CFormSelect>
                    </div>
                  )}
                  <div className="mb-3 row">
                    <div className="col-12  col-sm-6">
                      <CFormLabel>Platform Image</CFormLabel>
                      <input
                        type="file"
                        className="form-control-file d-block"
                        accept="image/*"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          const imageFile = e.target.files[0];
                          setSelectedImage(null);
                          setchangeImg(imageFile);
                          setFieldValue("image", imageFile);
                        }}
                      />
                    </div>

                    <div className="col-12 col-sm-6">
                      <div className="d-flex flex-column gap-4 flex-wrap upload-ref-img-div align-items-center align-items-md-end  pe-4 mt-2 mt-md-0">
                        {selectedImage ? (
                          <img
                            src={selectedImage}
                            alt="reference-img"
                            className="upload-ref-img"
                          />
                        ) : (
                          changeImg && (
                            <img
                              src={URL.createObjectURL(changeImg)}
                              className="upload-ref-img"
                            />
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <CFormLabel>Choose Supported Ad Format: </CFormLabel>

                    <br />
                    {format?.map((adFormat) => (
                      <CFormCheck
                        inline
                        type="checkbox"
                        value={adFormat._id}
                        label={adFormat.name}
                        name="checkedItems"
                        checked={checkedItems.includes(adFormat._id)}
                        onChange={(e) => {
                          handleChange(e);
                          handleCheck(e);
                        }}
                      />
                    ))}
                    <p className="error">
                      {errors.checkedItems &&
                        touched.checkedItems &&
                        errors.checkedItems}
                    </p>
                  </div>
                </form>
              </CModalBody>
              <CModalFooter className="add-play-console-modal-footer d-flex justify-content-center gap-2">
                <button
                  className=" btn close-btn bg-white text-black border-danger"
                  onClick={() => {
                    setVisible(false);
                    setchangeImg(null);
                  }}
                >
                  Close
                </button>
                <button
                  className="btn bg-primary text-light create-apikey-btn"
                  onClick={(e) => handleSubmit(e)}
                  type="submit"
                >
                  save
                </button>
              </CModalFooter>
            </>
          )}
        </Formik>
      </CModal>
    </>
  );
}

export default Add;
