import React, { useState, useEffect } from "react";
import getCurrentUser from "../../utils/getCurrentUser.util";
import NoDataImg from "../../images/g8.png";
import { APIdeveloperlist, APIlist } from "../../api-wrapper/ApiApplicationBug";
import Add from "./Add";
import {
  CButton,
  SearchIcon,
  CIcon,
  Card,
  DataTable,
  Toast,
  CFormSelect,
  Typography,
  NavigateNextIcon,
  Breadcrumbs,
  cilColorBorder,
  PermissionCheck,
  moment,
} from "../../helper/links/Link";
import { DateRangePicker } from "rsuite";
import { predefinedRanges } from "../../helper/calendarValues/calendarValues";
const ApplicationBug = () => {
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);
  const [arg, setArg] = useState({ pageNo: 1, perPage: 10 });
  const [totalRecords, setTotalRecords] = useState(0);
  const [visible, setVisible] = useState(false);
  const [editData, setEditData] = useState();
  const [developerList, setDeveloperList] = useState([]);
  const [developrId, setdeveloprId] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [date, setDate] = useState([]);

  const getData = (page, perPage) => {
    let data = {
      pageNo: page || 1,
      perPage: perPage || rowsPerPage,
      search: search,
      developerId: developrId,
      fromDate: date.length == 0 ? "" : moment(date?.[0]).format("YYYY-MM-DD"),
      toDate: date.length == 0 ? "" : moment(date?.[1]).format("YYYY-MM-DD"),
    };
    if (PermissionCheck("Application Bug", "View Only"))
      APIlist(data)
        .then((e) => {
          if (e?.isSuccess) {
            setTableData(e?.data);
            setcurrentPage(e.currentPageNo);
            setTotalRecords(e.totalRecords);
          } else {
            Toast.error(e?.message);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
  };

  useEffect(() => {
    getData();
  }, [developrId, date]);

  useEffect(() => {
    let data = [];
    APIdeveloperlist()
      .then((res) => {
        if (res.isSuccess) {
          res.data.map((el) => {
            data.push({
              label: el.name,
              value: el._id,
            });
          });
          setDeveloperList(data);
        } else {
          Toast.error(res.message);
          setDeveloperList([]);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  }, []);

  const tHeader = [
    {
      name: "Application Logo",
      selector: "appLogo",
      width: "150px",
      cell: (row) => {
        if (row?.appId?.appLogo)
          return <img src={row.appId?.appLogo} className="ref-image" />;
        else
          return (
            <div className="default_image">
              {" "}
              {row?.appId?.appName.charAt(0)}
            </div>
          );
      },
    },
    {
      name: "Application Name",
      selector: (row) => row.appId?.appName,
    },
    {
      name: "Play Console Name",
      selector: (row) => row.playConsoleId.pcName,
    },
    {
      name: "Developer Name",
      selector: (row) => row?.developerId.name,
    },
    {
      name: "Bug Description",
      selector: "bugDescription",
    },
    {
      name: "Status",
      selector: "status",
      cell: (row) => (
        <button
          style={{ width: "100px" }}
          className={`${row.status ? "btn btn-success" : "btn btn-danger"}`}
        >
          {row.status ? "Updated" : "Pending"}
        </button>
      ),
    },
    {
      name: "Action",
      selector: "actions",
      cell: (row) => (
        <div className="d-flex gap-3 justify-content-center">
          {PermissionCheck("Application Bug", "Edit App Bug") ? (
            <button
              onClick={() => {
                setEditData(row);
                setVisible(true);
              }}
              className="btn  edit_icon"
              style={{ color: "white" }}
            >
              <CIcon icon={cilColorBorder} />
            </button>
          ) : null}
        </div>
      ),
    },
  ];

  return (
    <>
      <Card className="rounded-3 p-1">
        <Card.Header>
          <div className="">
            <h5 className="card-title">
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="large" />}
                aria-label="breadcrumb"
              >
                <Typography key="1" color="text.primary" className="platform">
                  Application Bug
                </Typography>
              </Breadcrumbs>
            </h5>
          </div>
          <div className="grid_console">
            <div>
              {PermissionCheck("Application Bug", "Create App Bug") ? (
                <CButton
                  onClick={() => {
                    setVisible(true);
                  }}
                  className="add-btn"
                  style={{ height: "38px" }}
                >
                  Add Bug
                </CButton>
              ) : null}
            </div>
            <div>
              <DateRangePicker
                className={`rangeDate custom-date-range-picker `}
                ranges={predefinedRanges}
                showOneCalendar
                value={date}
                placeholder="Select Date Range"
                onChange={(e) => setDate(e)}
                placement="bottomEnd"
                format="yyyy-MM-dd"
                cleanable={false}
              />
            </div>
            <div>
              <CFormSelect
                aria-label="Default select example"
                name="developer"
                value={developrId}
                onChange={(e) => setdeveloprId(e.target.value)}
              >
                <option key="developer" value="" selected>
                  All Developer
                </option>
                {developerList?.map((data, index) => (
                  <option key={data.value} value={data.value}>
                    {data.label}
                  </option>
                ))}{" "}
                *
              </CFormSelect>
            </div>
            <div>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control search-box"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />

                <button
                  className="btn btn-outline-secondary border search-btn-icon"
                  type="button"
                  onClick={() => getData()}
                >
                  <SearchIcon />
                </button>
              </div>
            </div>
          </div>
        </Card.Header>
        {PermissionCheck("Application Bug", "View Only") && (
          <Card.Body>
            {tableData?.length < 1 ? (
              <div className="d-flex w-100 justify-content-center no-data-img">
                <img src={NoDataImg} alt="no-data" className="w-35" />
              </div>
            ) : (
              <DataTable
                columns={tHeader}
                data={tableData}
                pagination
                paginationServer
                paginationPerPage={rowsPerPage}
                onChangeRowsPerPage={(event) => {
                  setRowsPerPage(parseInt(event));
                  getData(currentPage, event);
                }}
                onChangePage={(page) => {
                  getData(page);
                }}
                paginationDefaultPage={currentPage}
                paginationTotalRows={totalRecords}
                fixedHeader
              />
            )}
          </Card.Body>
        )}
      </Card>

      <Add
        visible={visible}
        setVisible={setVisible}
        editData={editData}
        getData={getData}
      />
    </>
  );
};

export default ApplicationBug;
