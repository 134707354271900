import React, { useState } from "react";
import {
  CAvatar,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
  CFormLabel,
  CFormSelect,
  CFormInput,
} from "@coreui/react";
import { cilLockLocked, cilUser, cilSettings } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import SuperAdmin from "../../images/SuperAdmin.png";
import passwordChangeImg from "../../images/passwordChange.jpg";
import { useNavigate } from "react-router-dom";
import getCurrentUser from "../../utils/getCurrentUser.util";
import {
  useMutation,
  useQuery,
  useQueryClient,
  useQueries,
} from "@tanstack/react-query";
import { apiClient } from "../../utils/request.util";
import { Toast } from "../../helper/links/Link";

const schema = Yup.object().shape({
  oldPassword: Yup.string()
    .min("4", "Old Password must be greater than or equals to 4 digits")
    .required("Old Password is required"),
  newPassword: Yup.string()
    .min("4", "New Password must be greater than or equals to 4 digits")
    .required("New Password is required"),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref("newPassword")],
      "Confirm password not matches with new password"
    )
    .required("Confirm Password is required"),
});

const AppHeaderDropdown = () => {
  const curretUser = getCurrentUser();
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const [loadingObject, setLoadingObject] = useState({
    isLoading: false,
    isDisable: false,
  });
  const changePasswordMutation = useMutation({
    mutationFn: (referencesData) => {
      setLoadingObject({ isLoading: true, isDisable: true });
      return apiClient.put("/admin/change-password", referencesData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    },
    onSuccess: (data) => {
      if (data.data?.success == false) {
        setLoadingObject({ isLoading: false, isDisable: false });
        Toast.error(data?.data?.message);
      }
      setVisible(false);
      setLoadingObject({ isLoading: false, isDisable: false });
      Toast.success("Password Changed Successfully !!");
      localStorage.removeItem("token");
      navigate("/login");
    },
    onError: () => {
      Toast.error("Something went wrong !!");
      setLoadingObject({ isLoading: false, isDisable: false });
    },
  });

  const handleSubmit = (values) => {
    let { oldPassword, newPassword, confirmPassword } = values;

    changePasswordMutation.mutate({
      oldPassword: oldPassword,
      password: newPassword,
      confirmPassword: confirmPassword,
    });
  };

  return (
    <>
      <CDropdown variant="nav-item">
        <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
          <CAvatar
            src={curretUser?.image ? curretUser?.image : SuperAdmin}
            size="md"
          />
        </CDropdownToggle>
        <CDropdownMenu
          className="p-1"
          placement="bottom-end"
          style={{
            position: "absolute",
            inset: "0 0 auto auto",
            transform: "translate(0px, 40px)",
            marginTop: "12px",
          }}
        >
          <CDropdownItem href="#" className="p-1">
            <CIcon icon={cilUser} className="me-2" />
            {curretUser?.name ? curretUser?.name : "Admin"}
          </CDropdownItem>
          <CDropdownItem
            className="p-1"
            style={{ cursor: "pointer" }}
            onClick={() => setVisible(true)}
          >
            <CIcon icon={cilSettings} className="me-2" />
            Change Password
          </CDropdownItem>
          <CDropdownItem
            className="p-1"
            style={{ cursor: "pointer" }}
            onClick={() => {
              localStorage.removeItem("token");
              navigate("/login");
            }}
          >
            <CIcon icon={cilLockLocked} className="me-2" />
            Logout
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
      <CModal
        visible={visible}
        alignment="center"
        scrollable
        keyboard={false}
        backdrop="static"
        onClose={() => {
          setVisible(false);
        }}
      >
        <Formik
          validationSchema={schema}
          initialValues={{
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <>
              <CModalHeader className="d-flex justify-content-center">
                <CModalTitle className="">
                  <div className="d-flex justify-content-center gap-4">
                    <div>
                      <p className="h4 pt-2 fw-bold mt-auto text-16">
                        Change Password
                      </p>
                    </div>
                  </div>
                </CModalTitle>
              </CModalHeader>
              <CModalBody>
                <div className="row">
                  <div className="col-6">
                    <div>
                      <img src={passwordChangeImg} className="img-fluid"></img>
                    </div>
                  </div>
                  <div className="col-6">
                    <form onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <CFormLabel>Enter Old Password</CFormLabel>

                        <CFormInput
                          type="password"
                          name="oldPassword"
                          value={values.oldPassword}
                          onBlur={handleBlur}
                          aria-label="default input example"
                          onChange={handleChange}
                        />
                        <p className="error">
                          {errors.oldPassword &&
                            touched.oldPassword &&
                            errors.oldPassword}
                        </p>
                      </div>
                      <div className="mb-3">
                        <CFormLabel>Enter New Password</CFormLabel>
                        <CFormInput
                          type="password"
                          name="newPassword"
                          value={values.newPassword}
                          onBlur={handleBlur}
                          aria-label="default input example"
                          onChange={handleChange}
                        />
                        <p className="error">
                          {errors.newPassword &&
                            touched.newPassword &&
                            errors.newPassword}
                        </p>
                      </div>

                      <div className="mb-3">
                        <CFormLabel>Confirm New Password</CFormLabel>
                        <CFormInput
                          type="password"
                          name="confirmPassword"
                          aria-label="default input example"
                          value={values.confirmPassword}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                        <p className="error">
                          {errors.confirmPassword &&
                            touched.confirmPassword &&
                            errors.confirmPassword}
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </CModalBody>
              <CModalFooter className="add-play-console-modal-footer d-flex justify-content-center gap-2">
                <button
                  className=" btn close-btn bg-white text-black border-danger"
                  onClick={() => {
                    setVisible(false);
                  }}
                >
                  Close
                </button>
                <button
                  className="btn bg-primary text-light create-apikey-btn"
                  onClick={handleSubmit}
                >
                  Reset Password
                </button>
              </CModalFooter>
            </>
          )}
        </Formik>
      </CModal>
    </>
  );
};

export default AppHeaderDropdown;
