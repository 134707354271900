import React from "react";
import mainLogo from "../../images/mainlogo.png";
import { Formik } from "formik";
import { SatusSchema } from "../../utility/Validator";
import { APIsaveStatus } from "../../api-wrapper/ApiAppList";
import { useParams } from "react-router-dom";
import {
  Toast,
  CModal,
  CModalHeader,
  CModalBody,
  CModalTitle,
  CFormLabel,
} from "../../helper/links/Link";

function ViewAppStatus({ allData, modalStatus, setModalStatus, getData }) {
  let { id } = useParams();
  const handleSave = (values) => {
    let data = {
      appStatus: values.status,
    };
    APIsaveStatus(data, id)
      .then((res) => {
        if (res.isSuccess) {
          Toast.success(res.message);
          getData();
          setModalStatus(false);
        } else {
          Toast.error(res?.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  return (
    <CModal
      visible={modalStatus}
      alignment="center"
      scrollable
      keyboard={false}
      backdrop="static"
      onClose={() => {
        setModalStatus(false);
      }}
    >
      <CModalHeader>
        <CModalTitle>

          <p className="h4 pt-2 fw-bold mt-auto cust-h4">
            Change App status
          </p>

        </CModalTitle>
      </CModalHeader>
      <CModalBody>
        <Formik
          validationSchema={SatusSchema}
          initialValues={{
            status: allData?.appStatus,
          }}
          onSubmit={(values) => {
            handleSave(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <>
              <div className="mb-3 row p-2">
                <CFormLabel className="p-0"> Select App Status</CFormLabel>
                <select
                  className="form-select h-100 "
                  aria-label="Default select example"
                  value={values.status}
                  name="status"
                  onChange={handleChange}
                >
                  <option value="" selected disabled>
                    select status
                  </option>
                  <option value="Live">Live</option>
                  <option value="Suspend">Suspend</option>
                  <option value="InReview">InReview</option>
                  <option value="Rejected">Rejected</option>
                </select>
                <p className="error">
                  {errors.status && touched.status && errors.status}
                </p>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="d-flex flex-wrap justify-content-center w-100">
                    <button
                      className="btn close-btn bg-white text-dark border-danger me-2"
                      onClick={() => {
                        setModalStatus(false);
                      }}
                    >
                      Close
                    </button>
                    <button
                      className="btn bg-primary"
                      onClick={handleSubmit}
                      style={{ width: "200px", color: "white" }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </Formik>
      </CModalBody>
    </CModal>
  );
}

export default ViewAppStatus;
