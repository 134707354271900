import React, { useEffect } from "react";
import { Formik } from "formik";
import mainLogo from "../../images/mainlogo.png";
import { ApprovalSchema } from "../../utility/Validator";
import {
  APIadd,
  APIcategories,
  APIdeveloper,
  APIplayConsole,
  APIreference,
} from "../../api-wrapper/ApiApprovalList";
import { useState } from "react";
import {
  CModalFooter,
  Toast,
  CFormSelect,
  CModal,
  CModalHeader,
  CModalBody,
  CModalTitle,
  CFormLabel,
  CFormInput,
} from "../../helper/links/Link";
function Add({ visible, setVisible, editData, getData, appRequestId }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [changeImg, setchangeImg] = useState(null);
  const [playConsole, setPlayConsole] = useState([]);
  const [categories, setCategories] = useState([]);
  const [developer, setDeveloper] = useState([]);
  const [reference, setReference] = useState([]);

  const handleSubmit = (values) => {
    const formData = new FormData();
    formData.append("playConsoleId", values.playConsoleId);
    formData.append("categoryId", values.categoryId);
    formData.append("developerId", values.developerId);
    formData.append("referenceId", values.referenceId);
    formData.append("appRequestId", appRequestId);

    if (changeImg) {
      formData.append("backupCode", changeImg);
    }

    APIadd(formData)
      .then((e) => {
        if (e?.isSuccess) {
          setVisible(false);
          Toast.success(e?.message);
          setSelectedImage(null);
          setchangeImg(null);
          getData();
        }
      })
      .catch((e) => {
        Toast.error("Something Went Wrong!!");
      });
  };

  useEffect(() => {
    setSelectedImage(editData?.image);
  }, [editData]);

  useEffect(() => {
    if (visible) {
      let data = [];
      APIplayConsole().then((res) => {
        if (res.isSuccess) {
          res.data.map((el) => {
            data.push({
              label: el.pcName,
              value: el._id,
            });
          });
          setPlayConsole(data);
        } else {
          setPlayConsole([]);
        }
      });
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      let data = [];
      APIcategories().then((res) => {
        if (res.isSuccess) {
          res.data.map((el) => {
            data.push({
              label: el.name,
              value: el._id,
            });
          });
          setCategories(data);
        } else {
          setCategories([]);
        }
      });
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      let data = [];
      APIdeveloper().then((res) => {
        if (res.isSuccess) {
          res.data.map((el) => {
            data.push({
              label: el.name,
              value: el._id,
            });
          });
          setDeveloper(data);
        } else {
          setDeveloper([]);
        }
      });
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      let data = [];
      APIreference().then((res) => {
        if (res.isSuccess) {
          res.data.map((el) => {
            data.push({
              label: el.name,
              value: el._id,
            });
          });
          setReference(data);
        } else {
          setReference([]);
        }
      });
    }
  }, [visible]);

  return (
    <>
      <CModal
        visible={visible}
        alignment="center"
        scrollable
        keyboard={false}
        backdrop="static"
        onClose={() => {
          setVisible(false);
        }}
      >
        <Formik
          validationSchema={ApprovalSchema}
          initialValues={{
            playConsoleId: editData?.playConsoleId,
            categoryId: editData?.categoryId,
            developerId: editData?.developerId,
            referenceId: editData?.referenceId,
            backupCode: editData?.backupCode,
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <>
              <CModalHeader>
                <CModalTitle className="">
                  <div className="d-flex justify-content-between gap-4">
                    <div className="img-div-res">
                      <img src={mainLogo} alt="logo" className="img-res" />
                    </div>
                    <div>
                      <p className="h4 pt-2 fw-bold mt-auto text-16">
                        Application
                      </p>
                    </div>
                  </div>
                </CModalTitle>
              </CModalHeader>
              <CModalBody>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <CFormLabel>App Play Console</CFormLabel>
                    <CFormSelect
                      aria-label="Default select example"
                      name="playConsoleId"
                      value={values.playConsoleId}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      <option key="playconsole" selected disabled>
                        Select Play Console
                      </option>
                      {playConsole?.map((data, index) => (
                        <option key={data.value} value={data.value}>
                          {data.label}
                        </option>
                      ))}
                    </CFormSelect>
                    <p className="error">
                      {errors.playConsoleId &&
                        touched.playConsoleId &&
                        errors.playConsoleId}
                    </p>
                  </div>

                  <div className="mb-3">
                    <CFormLabel>App Category</CFormLabel>
                    <CFormSelect
                      aria-label="Default select example"
                      name="categoryId"
                      value={values.categoryId}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      <option key="categoryId" selected disabled>
                        Select Category
                      </option>
                      {categories?.map((data, index) => (
                        <option key={data.value} value={data.value}>
                          {data.label}
                        </option>
                      ))}
                    </CFormSelect>
                    <p className="error">
                      {errors.categoryId &&
                        touched.categoryId &&
                        errors.categoryId}
                    </p>
                  </div>

                  <div className="mb-3">
                    <CFormLabel>App Developer</CFormLabel>
                    <CFormSelect
                      aria-label="Default select example"
                      name="developerId"
                      value={values.developerId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option key="developer" selected disabled>
                        Select Developer
                      </option>
                      {developer?.map((data, index) => (
                        <option key={data.value} value={data.value}>
                          {data.label}
                        </option>
                      ))}
                    </CFormSelect>
                    <p className="error">
                      {errors.developerId &&
                        touched.developerId &&
                        errors.developerId}
                    </p>
                  </div>

                  <div className="mb-3 ">
                    <CFormLabel>App Reference</CFormLabel>
                    <CFormSelect
                      aria-label="Default select example"
                      name="referenceId"
                      value={values.referenceId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option key="reference" selected disabled>
                        Select Reference
                      </option>
                      {reference?.map((data, index) => (
                        <option key={data.value} value={data.value}>
                          {data.label}
                        </option>
                      ))}
                    </CFormSelect>
                    <p className="error">
                      {errors.referenceId &&
                        touched.referenceId &&
                        errors.referenceId}
                    </p>
                  </div>
                  {/* <div className="mb-3">
                    <CFormLabel>App Code Backup</CFormLabel>
                    <CFormInput
                      type="file"
                      name="backupCode"
                      className="form-control-file d-block"
                      accept=".zip"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    ></CFormInput>
                  </div> */}
                  <div className="mb-3 row">
                    <div className="col-12  col-sm-6">
                      <CFormLabel>Developer Profile</CFormLabel>
                      <input
                        type="file"
                        className="form-control-file d-block"
                        accept=".zip"
                        onBlur={handleBlur}
                        name="backupCode"
                        onChange={(e) => {
                          const imageFile = e.target.files[0];
                          setSelectedImage(null);
                          setchangeImg(imageFile);
                          setFieldValue("backupCode", imageFile);
                        }}
                      />
                    </div>
                  </div>
                </form>
              </CModalBody>
              <CModalFooter className="add-play-console-modal-footer d-flex justify-content-center gap-2">
                <button
                  className=" btn close-btn bg-white text-black border-danger"
                  onClick={() => {
                    setVisible(false);
                    setchangeImg(null);
                  }}
                >
                  Close
                </button>
                <button
                  className="btn bg-primary text-light create-apikey-btn"
                  onClick={(e) => handleSubmit(e)}
                  type="submit"
                >
                  save
                </button>
              </CModalFooter>
            </>
          )}
        </Formik>
      </CModal>
    </>
  );
}

export default Add;
