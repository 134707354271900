import React, { useEffect } from "react";
import { Formik } from "formik";
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
  CFormLabel,
  CFormInput,
} from "@coreui/react";
import mainLogo from "../../images/mainlogo.png";
import { MoreAddGroupSchema } from "../../utility/Validator";
import { Toast } from "../../helper/links/Link";
import { APIadd, APIedit, APINewapp } from "../../api-wrapper/ApiMoreAppGroup";
import { useState } from "react";
import Select from "react-select";
function Add({ visible, setVisible, editData, getData }) {
  const [app, setAPP] = useState([]);
  const [selectedApp, setSelectedApp] = useState({
    splash: "",
    exit: "",
    Inner: "",
  });

  const handleSubmit = (values) => {
    let data = {
      groupName: values.name,
    };
    if (editData._id) {
      data = {
        groupName: values.name,
        innerAppIds: selectedApp.Inner,
        splashMoreAppIds: selectedApp.splash,
        exitMoreAppIds: selectedApp.exit,
      };
      APIedit(data, editData._id)
        .then((e) => {
          if (e?.isSuccess) {
            Toast.success(e?.message);
            setVisible(false);
            getData();
          } else {
            Toast.error(e?.message);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
    } else {
      APIadd(data)
        .then((e) => {
          if (e?.isSuccess) {
            setVisible(false);
            Toast.success(e?.message);
            getData();
          } else {
            Toast.error(e?.message);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
    }
  };

  useEffect(() => {
    if (visible && editData?._id) {
      let data = [];
      APINewapp(editData?._id)
        .then((res) => {
          if (res.isSuccess) {
            res.data.map((el) => {
              data.push({
                label: el.appName,
                value: el._id,
              });
            });
            setAPP(data);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
    }
  }, [visible, editData]);

  useEffect(() => {
    if (editData?._id) {
      setSelectedApp({
        splash: editData?.splashMoreAppIds?.map((x) => x._id),
        exit: editData?.exitMoreAppIds?.map((x) => x._id),
        Inner: editData?.innerAppIds?.map((x) => x._id),
      });
    }
  }, [editData]);

  return (
    <>
      <CModal
        visible={visible}
        alignment="center"
        scrollable
        keyboard={false}
        backdrop="static"
        onClose={() => {
          setVisible(false);
        }}
      >
        <Formik
          validationSchema={MoreAddGroupSchema}
          initialValues={{
            name: editData?.groupName,
            innerAppIds: editData?.innerAppIds?.map((x) => ({
              label: x.appName,
              value: x._id,
            })),
            splashMoreAppIds: editData?.splashMoreAppIds?.map((x) => ({
              label: x.appName,
              value: x._id,
            })),
            exitMoreAppIds: editData?.exitMoreAppIds?.map((x) => ({
              label: x.appName,
              value: x._id,
            })),
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <>
              <CModalHeader>
                <CModalTitle className="">

                  <p className="h4 pt-2 fw-bold mt-auto text-16">
                    More App Group
                  </p>

                </CModalTitle>
              </CModalHeader>
              <CModalBody>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <CFormLabel>Group Name</CFormLabel>
                    <CFormInput
                      type="text"
                      name="name"
                      value={values.name}
                      onBlur={handleBlur}
                      aria-label="default input example"
                      onChange={handleChange}
                    />
                    <p className="error">
                      {errors.name && touched.name && errors.name}
                    </p>
                  </div>
                  {editData._id && (
                    <>
                      <div className="mb-3">
                        <div>
                          <CFormLabel>Splash More Apps</CFormLabel>
                          <br />
                          <Select
                            classNamePrefix="form_dropdown"
                            placeholder="Select Splash More Apps"
                            options={app}
                            isMulti
                            defaultValue={values.splashMoreAppIds}
                            onChange={(e) => {
                              setSelectedApp({
                                ...selectedApp,
                                splash: e.map((x) => x.value),
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <div>
                          <CFormLabel>Exit More Apps</CFormLabel>
                          <br />
                          <Select
                            classNamePrefix="form_dropdown"
                            placeholder="Select Exit More Apps"
                            options={app}
                            isMulti
                            defaultValue={values.exitMoreAppIds}
                            onChange={(e) => {
                              setSelectedApp({
                                ...selectedApp,
                                exit: e.map((x) => x.value),
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <div>
                          <CFormLabel>Inner More Apps</CFormLabel>
                          <br />
                          <Select
                            classNamePrefix="form_dropdown"
                            placeholder="Select Inner More Apps"
                            options={app}
                            isMulti
                            defaultValue={values.innerAppIds}
                            onChange={(e) => {
                              setSelectedApp({
                                ...selectedApp,
                                Inner: e.map((x) => x.value),
                              });
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </form>
              </CModalBody>
              <CModalFooter className="add-play-console-modal-footer d-flex justify-content-center gap-2">
                <button
                  className=" btn close-btn bg-white text-black border-danger"
                  onClick={() => {
                    setVisible(false);
                  }}
                >
                  Close
                </button>
                <button
                  className="btn bg-primary text-light create-apikey-btn"
                  onClick={(e) => handleSubmit(e)}
                  type="submit"
                >
                  save
                </button>
              </CModalFooter>
            </>
          )}
        </Formik>
      </CModal>
    </>
  );
}

export default Add;
