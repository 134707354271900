import React, { useEffect } from "react";
import { Formik } from "formik";
import Card from "react-bootstrap/Card";
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
  CFormLabel,
  CFormInput,
  CFormSelect,
} from "@coreui/react";
import { PushNotificationSchema } from "../../../utility/Validator";
import { useState } from "react";
import { APIaddNotification } from "../../../api-wrapper/ApiNotification";
import { useParams } from "react-router-dom";
import Toast from "../../../helper/toast/Toast";

function PushNotification({ setflag }) {
  let { appId } = useParams();
  const [selectedImage, setSelectedImage] = useState(null);
  const [changeImg, setchangeImg] = useState(null);
  const handleSendNotificationData = (value) => {
    const formData = new FormData();
    formData.append("message", value.message);

    formData.append("title", value.title);
    formData.append("appId", appId);

    if (value.notificationUrl) {
      formData.append("notificationUrl", value.notificationUrl);
    }

    if (changeImg) {
      formData.append("image", changeImg);
    }

    APIaddNotification(formData)
      .then((e) => {
        if (e?.isSuccess) {
          Toast.success(e?.message);
          setSelectedImage(null);
          setchangeImg(null);
          setflag(true);
        } else {
          Toast.error(e?.message);
        }
      })
      .catch((e) => {
        Toast.error("Something Went Wrong!!");
      });
  };

  return (
    <>
      <Card.Body>
        <Formik
          validationSchema={PushNotificationSchema}
          initialValues={{ title: "", message: "", url: "" }}
          onSubmit={(values) => {
            handleSendNotificationData(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
          }) => (
            <>
              <div className="row mt-2 ">
                <div className="col-6 title-col-6 p-1">
                  <div>
                    <CFormLabel>Title</CFormLabel>
                    <CFormInput
                      type="text"
                      name="title"
                      value={values.title}
                      //   onBlur={handleBlur}
                      aria-label="default input example"
                      onChange={handleChange}
                    />
                    <p className="error">
                      {errors.title && touched.title && errors.title}
                    </p>
                  </div>
                </div>
                <div className="col-6 url-col-6 p-1">
                  <div>
                    <CFormLabel>URL (Optional)</CFormLabel>
                    <CFormInput
                      type="text"
                      name="notificationUrl"
                      value={values.notificationUrl}
                      //   onBlur={handleBlur}
                      aria-label="default input example"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-6 message-col-6 p-1">
                  <div>
                    <CFormLabel>Message</CFormLabel>
                    <CFormInput
                      type="text"
                      name="message"
                      value={values.message}
                      //   onBlur={handleBlur}
                      aria-label="default input example"
                      onChange={handleChange}
                    />
                    <p className="error">
                      {errors.message && touched.message && errors.message}
                    </p>
                  </div>
                </div>
                <div className="col-6 image-col-6 p-1">
                  <div className="col-12  col-sm-6">
                    <CFormLabel>Image (Optional)</CFormLabel>
                    <input
                      type="file"
                      className="form-control-file d-block"
                      accept="image/*"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        const imageFile = e.target.files[0];
                        setSelectedImage(null);
                        setchangeImg(imageFile);
                        setFieldValue("image", imageFile);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row row-save-w-50 mt-2 p-2 d-flex justify-content-center">
                <div className="col-12">
                  <div className="d-flex flex-wrap justify-content-end w-100">
                    <button
                      color="primary"
                      className="btn bg-primary text-light create-apikey-btn "
                      onClick={handleSubmit}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </Formik>
      </Card.Body>
    </>
  );
}

export default PushNotification;
