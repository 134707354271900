import React, { useEffect } from "react";
import { Formik } from "formik";
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
  CFormLabel,
} from "@coreui/react";
import mainLogo from "../../images/mainlogo.png";
import { Toast } from "../../helper/links/Link";
import {
  APIaccountList,
  APIaddAccount,
} from "../../api-wrapper/ApiMoreAppGroup";
import { useState } from "react";
import Select from "react-select";
import { useParams } from "react-router-dom";

function AddAccount({ visible, setVisible, getData }) {
  const { moreAppGroupId } = useParams();
  const [accountData, setaccountData] = useState([]);
  const [seletedApp, setseletedApp] = useState([]);

  const handleSubmit = () => {
    let data = {
      appIds: seletedApp,
    };

    APIaddAccount(data, moreAppGroupId)
      .then((e) => {
        if (e?.isSuccess) {
          setVisible(false);
          Toast.success(e?.message);
          getData();
        } else {
          Toast.error(e?.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  useEffect(() => {
    if (visible) {
      let data = [];
      APIaccountList()
        .then((res) => {
          if (res.isSuccess) {
            res.data.map((el) => {
              data.push({
                label: el.pcName,
                value: el._id,
              });
            });
            setaccountData(data);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
    }
  }, [visible]);

  return (
    <CModal
      visible={visible}
      alignment="center"
      scrollable
      keyboard={false}
      backdrop="static"
      onClose={() => {
        setVisible(false);
      }}
    >
      <Formik
        initialValues={{
          status: "",
        }}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <>
            <CModalHeader>
              <CModalTitle className="">
                <div className="d-flex justify-content-between gap-4">
                  <div className="img-div-res">
                    <img src={mainLogo} alt="logo" className="img-res" />
                  </div>
                  <div>
                    <p className="h4 pt-2 fw-bold mt-auto text-16">
                      Assign Account List
                    </p>
                  </div>
                </div>
              </CModalTitle>
            </CModalHeader>
            <CModalBody>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <CFormLabel>Play Console</CFormLabel>
                  <br />
                  <div className="checkpickeer col-12">
                    <Select
                      classNamePrefix="form_dropdown"
                      placeholder="Select Play Console"
                      options={accountData}
                      isMulti
                      onChange={(e) => {
                        setseletedApp(e.map((x) => x.value));
                      }}
                    />
                  </div>
                </div>
              </form>
            </CModalBody>
            <CModalFooter className="add-play-console-modal-footer d-flex justify-content-center gap-2">
              <button
                className=" btn close-btn bg-white text-black border-danger"
                onClick={() => {
                  setVisible(false);
                }}
              >
                Close
              </button>
              <button
                className="btn bg-primary text-light create-apikey-btn"
                onClick={(e) => handleSubmit(e)}
                type="submit"
              >
                save
              </button>
            </CModalFooter>
          </>
        )}
      </Formik>
    </CModal>
  );
}

export default AddAccount;
