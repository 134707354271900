import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilBell, cilMenu } from "@coreui/icons";
import AppHeaderDropdown from "./header/appHeaderDropdown";
import Vector from "../icons/svgs/Vector.svg";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { apiClient } from "../utils/request.util";
import Badge from "@mui/material/Badge";
import { CModal, CModalHeader, CModalBody, CModalTitle } from "@coreui/react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import NoDataImg from "../images/g8.png";
import { handleSidebar } from "../helper/redux/action";
import { moment } from "../helper/links/Link";
const AppHeader = () => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.data.sidebarShow);

  const [appStatusNotificationData, setAppStatusNotificationData] = useState(
    []
  );
  const [visible, setVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loadingObject, setLoadingObject] = useState({
    isLoading: false,
    isDisable: false,
  });
  const [notificationData, setNotificationData] = useState({
    appId: "",
    viewAppMode: "",
    notificationfor: "",
  });

  const handleClick = (event) => {
    setVisible(true ? true : false);
  };
  const navigate = useNavigate();

  const AppPageProps = {
    appId: notificationData.appId,
    viewAppMode: notificationData.viewAppMode,
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["AppStatusNotification"],
    queryFn: () => {
      apiClient
        .get("/app/get-app-status-notifications", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          const response = res.data.data;
          setAppStatusNotificationData(response);
        })
        .catch((err) => { });
    },
  });

  const queryClient = useQueryClient();

  const readNotificationMutation = useMutation({
    mutationFn: (notificationId) => {
      setLoadingObject({ isLoading: true, isDisable: true });
      return apiClient.put(
        `/app/modify-app-status-notification/${notificationId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["AppStatusNotification"]);
      setLoadingObject({ isLoading: false, isDisable: false });
      if (notificationData.notificationfor === "Application") {
        navigate("/all-apps");
        setVisible(false);
      } else {
        navigate("/play-console");
        setVisible(false);
      }
    },
    onError: () => {
      setLoadingObject({ isLoading: false, isDisable: false });
    },
  });

  const readAllNotificationMutation = useMutation({
    mutationFn: (notificationId) => {
      setLoadingObject({ isLoading: true, isDisable: true });
      return apiClient.put(`/app/clear-all-app-status-notifications`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["AppStatusNotification"]);
      setLoadingObject({ isLoading: false, isDisable: false });
    },
    onError: () => {
      setLoadingObject({ isLoading: false, isDisable: false });
    },
  });

  const handleReadNotificationMutation = (notificationId, data) => {

    readNotificationMutation.mutate(notificationId);

    window.location.href = `/all-apps/${data?.appId?._id}`
  };

  const handleReadAllNotificationMutation = () => {
    readAllNotificationMutation.mutate();

  };

  return (
    <>
      <CHeader position="sticky" className="">
        <CContainer fluid>
          <CHeaderToggler
            className="ps-1"
            onClick={() => dispatch(handleSidebar(!sidebarShow))}
          >
            <CIcon icon={cilMenu} size="lg" />
          </CHeaderToggler>
          <CHeaderBrand className="mx-auto d-md-none" to="/">
            <CIcon icon={Vector} height={48} alt="Logo" />
          </CHeaderBrand>
          <CHeaderNav className="d-none d-md-flex me-auto"></CHeaderNav>
          <CHeaderNav>
            <CNavItem className="cursor-pointer">
              <CNavLink onClick={handleClick} style={{ cursor: "pointer" }}>
                <Badge
                  color="error"
                  badgeContent={appStatusNotificationData?.length}
                  max={15}
                >
                  <CIcon icon={cilBell} size="lg" />
                </Badge>
              </CNavLink>
            </CNavItem>
          </CHeaderNav>
          <CHeaderNav className="ms-3">
            <AppHeaderDropdown />
          </CHeaderNav>
        </CContainer>
      </CHeader>

      <CModal
        visible={visible}
        scrollable
        onClose={() => {
          setVisible(false);
        }}
        className="header-model"
      >
        <CModalHeader>
          <CModalTitle className="">
            <div className="d-flex justify-content-between gap-4 align-items-center">
              <div className="">
                <p className="h5 mb-0 pt-2 fw-bold mt-auto text-14">
                  Notifications
                </p>
              </div>
              {appStatusNotificationData?.length > 1 && (
                <div className="pt-2">
                  <Link
                    className="h6"
                    style={{ color: "#1675e0", textDecoration: "none" }}
                    onClick={() => handleReadAllNotificationMutation()}
                  >
                    Read All
                  </Link>
                </div>
              )}
            </div>
          </CModalTitle>
        </CModalHeader>
        <CModalBody className="p-0">
          <div>
            {appStatusNotificationData?.length > 0 ? (
              appStatusNotificationData?.map((data) => (
                <div
                  className="bg-white border-bottom cursor-pointer ps-4 pe-4 pt-2 pb-2"
                  onClick={() => {
                    setNotificationData({
                      appId: data?.appId?._id,
                      viewAppMode: true,
                      notificationfor: data?.statusNotificationFor,
                    });
                    handleReadNotificationMutation(data._id, data);
                  }}
                >
                  <div className="row align-items-center">
                    <div className="col-10">
                      <div className="d-block p-0">
                        <p className="text-dark mb-0 fw-semibold">
                          {data?.heading}
                        </p>
                        <p className="mb-0 mt-0">{data?.message}</p>
                      </div>
                      <div className="d-block p-0">
                        <p className="mb-0">
                          {" "}
                          {moment(data?.date).format("YYYY-MM-DD / hh:mm:ss A")}
                        </p>
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="notification_badger"></div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="d-flex w-100 justify-content-center no-data-img">
                <img src={NoDataImg} alt="no-data" className="w-50" />
              </div>
            )}
          </div>
        </CModalBody>
      </CModal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingObject.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default AppHeader;
