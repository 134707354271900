import React, { useEffect } from "react";
import { Formik } from "formik";
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
  CFormLabel,
  CFormInput,
  CFormSelect,
} from "@coreui/react";
import mainLogo from "../../../images/mainlogo.png";
import { SendNotificationSchema } from "../../../utility/Validator";
import { Toast } from "../../../helper/links/Link";
import { APIaddNoti } from "../../../api-wrapper/ApiNotificationGroupWise";
import { useState } from "react";
function AddNotification({ notiVisible, setNotiVisible, getData, notiId }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [changeImg, setchangeImg] = useState(null);

  const handleSubmit = (values) => {
    const formData = new FormData();
    formData.append("message", values.message);
    formData.append("title", values.title);
    formData.append("notificationUrl", values.notificationUrl);
    formData.append("notificationGroupId", notiId);
    if (changeImg) {
      formData.append("image", changeImg);
    }

    APIaddNoti(formData)
      .then((e) => {
        if (e?.isSuccess) {
          setNotiVisible(false);
          Toast.success(e?.message);
          setSelectedImage(null);
          setchangeImg(null);
          getData();
        } else {
          Toast.error(e?.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  return (
    <>
      <CModal
        visible={notiVisible}
        alignment="center"
        scrollable
        keyboard={false}
        backdrop="static"
        onClose={() => {
          setNotiVisible(false);
        }}
      >
        <Formik
          validationSchema={SendNotificationSchema}
          initialValues={{
            message: "",
            title: "",
            notificationUrl: "",
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <>
              <CModalHeader>
                <CModalTitle className="">
                  <div className="d-flex justify-content-between gap-4">
                    <div className="img-div-res">
                      <img src={mainLogo} alt="logo" className="img-res" />
                    </div>
                    <div>
                      <p className="h4 pt-2 fw-bold mt-auto text-16">
                        Send Notification
                      </p>
                    </div>
                  </div>
                </CModalTitle>
              </CModalHeader>
              <CModalBody>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <CFormLabel>Title</CFormLabel>
                    <CFormInput
                      type="text"
                      name="title"
                      value={values.title}
                      onBlur={handleBlur}
                      aria-label="default input example"
                      onChange={handleChange}
                    />
                    <p className="error">
                      {errors.title && touched.title && errors.title}
                    </p>
                  </div>
                  <div className="mb-3">
                    <CFormLabel>message</CFormLabel>
                    <CFormInput
                      type="text"
                      name="message"
                      value={values.message}
                      onBlur={handleBlur}
                      aria-label="default input example"
                      onChange={handleChange}
                    />
                    <p className="error">
                      {errors.message && touched.message && errors.message}
                    </p>
                  </div>
                  <div className="mb-3">
                    <CFormLabel>URL (Optional)</CFormLabel>
                    <CFormInput
                      type="text"
                      name="notificationUrl"
                      value={values.notificationUrl}
                      aria-label="default input example"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mb-3 row">
                    <div className="col-12  col-sm-6">
                      <CFormLabel>Image (Optional)</CFormLabel>
                      <input
                        type="file"
                        className="form-control-file d-block"
                        accept="image/*"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          const imageFile = e.target.files[0];
                          setSelectedImage(null);
                          setchangeImg(imageFile);
                          setFieldValue("image", imageFile);
                        }}
                      />
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="d-flex flex-column gap-4 flex-wrap upload-ref-img-div align-items-center align-items-md-end  pe-4 mt-2 mt-md-0">
                        {selectedImage ? (
                          <img
                            src={selectedImage}
                            alt="reference-img"
                            className="upload-ref-img"
                          />
                        ) : (
                          changeImg && (
                            <img
                              src={URL.createObjectURL(changeImg)}
                              className="upload-ref-img"
                            />
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </CModalBody>
              <CModalFooter className="add-play-console-modal-footer d-flex justify-content-center gap-2">
                <button
                  className=" btn close-btn bg-white text-black border-danger"
                  onClick={() => {
                    setNotiVisible(false);
                    setchangeImg(null);
                  }}
                >
                  Close
                </button>
                <button
                  className="btn bg-primary text-light create-apikey-btn"
                  onClick={(e) => handleSubmit(e)}
                  type="submit"
                >
                  Send
                </button>
              </CModalFooter>
            </>
          )}
        </Formik>
      </CModal>
    </>
  );
}

export default AddNotification;
