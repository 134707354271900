import React, { useState } from "react";
import { useEffect } from "react";
import NoDataImg from "../../images/g8.png";
import { DateRangePicker } from "rsuite";
import getCurrentUser from "../../utils/getCurrentUser.util";
import {
  APIdelete,
  APIlist,
  APIdeleteAll,
  APIdeleteMany,
  APIaccepted,
} from "../../api-wrapper/ApiApprovalList";
import { predefinedRanges } from "../../helper/calendarValues/calendarValues";
import Add from "./Add";
import DeleteModal from "../../helper/modal/DeleteModal";
import {
  moment,
  cilTrash,
  SearchIcon,
  CIcon,
  cilCheckAlt,
  cilPlus,
  Card,
  DataTable,
  Toast,
  Typography,
  NavigateNextIcon,
  Breadcrumbs,
  Link,
  CModal,
  CModalHeader,
  CModalBody,
  CModalTitle,
  PermissionCheck
} from "../../helper/links/Link";


import ReactPaginate from "react-paginate";
function AppApprovelList() {
  const [date, setDate] = useState([]);
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);
  const [arg, setArg] = useState({ pageNo: 1, perPage: 10 });
  const [totalRecords, setTotalRecords] = useState(0);
  const [visible, setVisible] = useState(false);
  const [editData, setEditData] = useState();
  const [deleteId, setDeleteId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [appRequestId, setAppRequestId] = useState("");
  const [selectedDataIds, setSelectedDataIds] = useState([]);

  const [currentPage, setcurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [ListcurrentPage, setListCurrentPage] = useState(1);

  const tHeader = [
    {
      name: "Application Logo",
      selector: "appLogo",
      width: "150px",
      cell: (row) => {
        if (row.appLogo) return <img src={row.appLogo} className="ref-image" />;
        else
          return <div className="default_image"> {row.appName.charAt(0)}</div>;
      },
    },
    {
      name: "Application Name",
      selector: "appName",
      sortable: false,
    },

    {
      name: "Package Name",
      selector: "packageName",
      sortable: false,
    },
    {
      name: "Location",
      selector: "ipAddressCity",
      cell: (row) => {
        return `${row.ipAddressCity},${row.ipAddressState},${row.ipAddressCountry}`;
      },
    },
    {
      name: "App Mode",
      width: "100px",
      selector: "appMode",
      sortable: false,
    },
    {
      name: "Created Date",
      selector: "date",
      width: "250px",
      sortable: false,
      cell: (e) => moment(e?.createdAt).format("MM/DD/YYYY hh:mm:ss A z"),
    },
    {
      name: "Actions",
      selector: "actions",
      cell: (row) => (
        <div className="white d-flex gap-3 w-100 justify-content-center">
          {
            row.isAccepted ?
              PermissionCheck("App Approval List", "Create Application") &&
              <button
                onClick={() => {
                  handLeAccept(row._id)
                }}
                className="btn  add_icon"
              >
                <CIcon icon={cilCheckAlt} />
              </button>

              :
              PermissionCheck("App Approval List", "Create Application") &&
              <button
                onClick={() => {
                  setEditData(row);
                  setAppRequestId(row._id);
                  setVisible(true);
                }}
                className="btn  add_icon"
              >
                <CIcon icon={cilPlus} />
              </button>
          }

          {
            PermissionCheck("App Approval List", "Remove Application") &&
            <button
              className="btn  delete_icon"
              onClick={() => {
                setShowDeleteModal(true);
                setDeleteId(row._id);
              }}
            >
              <CIcon
                icon={cilTrash}
                className="icon-trash text-red"
                color="red"
              />
            </button>
          }

        </div>
      ),
    },
  ];

  const getData = (page, perPage) => {
    
    let data = {
      pageNo: page || 1,
      perPage: perPage || rowsPerPage,
      fromDate: date.length == 0 ? "" : moment(date?.[0]).format("YYYY-MM-DD"),
      toDate: date.length == 0 ? "" : moment(date?.[1]).format("YYYY-MM-DD"),
      search: search,
    };
    if (PermissionCheck("App Approval List", "View Only"))
    APIlist(data)
      .then((e) => {
        if (e?.isSuccess) {
          setTableData(e?.data);
          setcurrentPage(e.currentPageNo);
          setTotalRecords(e.totalRecords);
        } else {
          Toast.error(e?.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  const handLeAccept = id => {
    APIaccepted(id)
      .then((res) => {
        if (res.isSuccess) {
          Toast.success(res.message);
          getData();
        } else {
          Toast.error(res.message);
        }

      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  }

  useEffect(() => {
    
    getData();
  }, [date]);



  const deleteHandler = () => {
    APIdelete(deleteId)
      .then((res) => {
        if (res.isSuccess) {
          Toast.success(res.message);
          getData();
        } else {
          Toast.error(res.message);
        }
        setDeleteId();
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  const customDeleteHandler = () => {
    if (selectedDataIds.length == 0) {
      APIdeleteAll()
        .then((res) => {
          if (res.isSuccess) {
            Toast.success(res.message);
            getData();
          } else {
            Toast.error(res.message);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
    } else {
      let data = {
        appRequestIds: selectedDataIds,
      };
      APIdeleteMany(data)
        .then((res) => {
          if (res.isSuccess) {
            Toast.success(res.message);
            setSelectedDataIds([]);
            getData();
          } else {
            Toast.error(res.message);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
    }
  };
  const handleSelectedRowsChange = (state) => {
    const ids = state?.selectedRows.map((row) => row._id);
    setSelectedDataIds(ids);
  };

  

  return (
    <>
      <Card className="rounded-3 p-1">
        <Card.Header>
          <div>
            <h5 class="card-title">App Approval List</h5>
          </div>

          <div className="grid_item">
            <div>
              {tableData.length != 0 && PermissionCheck("App Approval List", "Remove Application")  ? (
                <button
                  className="btn btn-danger"
                  onClick={() => customDeleteHandler()}
                >
                  {selectedDataIds.length == 0 ? "Delete All" : "Delete"}
                </button>
              ) : null}
            </div>
            <div>
              <DateRangePicker
                className={`rangeDate custom-date-range-picker `}
                ranges={predefinedRanges}
                showOneCalendar
                value={date}
                placeholder="Select Date Range"
                onChange={(e) => setDate(e)}
                placement="bottomEnd"
                format="yyyy-MM-dd"
                cleanable={false}
              />
            </div>
            <div>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control search-box"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />

                <button
                  className="btn btn-outline-secondary border search-btn-icon"
                  type="button"
                  onClick={() => { getData(1, rowsPerPage); }}
                >
                  <SearchIcon />
                </button>
              </div>
            </div>
          </div>
        </Card.Header>
        {
          PermissionCheck("App Approval List", "View Only") &&
          (<Card.Body>
            {tableData?.length < 1 ? (
              <div className="d-flex w-100 justify-content-center no-data-img">
                <img src={NoDataImg} alt="no-data" className="w-35" />
              </div>
            ) : (
              <>
                <DataTable
                  columns={tHeader}
                  data={tableData}
                  pagination
                  paginationServerpaginationPerPage={rowsPerPage}
                  onChangeRowsPerPage={(event) => {
                    setRowsPerPage(parseInt(event));
                    getData(currentPage, event);
                  }}
                  onChangePage={(page) => {
                    getData(page);
                  }}
                  paginationDefaultPage={currentPage}
                  paginationTotalRows={totalRecords}
                  selectableRows
                  onSelectedRowsChange={handleSelectedRowsChange}
                  selectableRowsNoSelectAll={false}
                  fixedHeader

                />
              </>
            )}
          </Card.Body>)
        }

      </Card>

      <Add
        visible={visible}
        setVisible={setVisible}
        editData={editData}
        getData={getData}
        appRequestId={appRequestId}
      />

      {/* delete modal */}

      <DeleteModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        deleteHandler={deleteHandler}
      />
    </>
  );
}

export default AppApprovelList;
