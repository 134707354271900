import React, { useState } from "react";
import CIcon from "@coreui/icons-react";
import { cilTrash, cilColorBorder } from "@coreui/icons";
import SearchIcon from "@mui/icons-material/Search";
import DataTable from "react-data-table-component";
import Card from "react-bootstrap/Card";
import { useEffect } from "react";
import NoDataImg from "../../images/g8.png";
import { DateRangePicker } from "rsuite";
import getCurrentUser from "../../utils/getCurrentUser.util";
import {
  APIappCount,
  APIdelete,
  APIlist,
} from "../../api-wrapper/ApiReference";
import { predefinedRanges } from "../../helper/calendarValues/calendarValues";
import { Toast, PermissionCheck } from "../../helper/links/Link";
import moment from "moment";
import Add from "./Add";
import DeleteModal from "../../helper/modal/DeleteModal";
import AppCount from "../../components/appCount/AppCount";
function ReferencesPage() {
  const [date, setDate] = useState([]);
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [visible, setVisible] = useState(false);
  const [editData, setEditData] = useState();
  const [deleteId, setDeleteId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [appFlag, setAppFlag] = useState(false);
  const [app, setApp] = useState([]);

  const [currentPage, setcurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const tHeader = [
    {
      name: "Profile",
      selector: "image",
      width: "100px",
      cell: (row) => {
        if (row.image) return <img src={row.image} className="ref-image" />;
        else return <div className="default_image"> {row.name.charAt(0)}</div>;
      },
    },
    {
      name: "Company Name",
      selector: "name",
      sortable: false,
    },
    {
      name: "Apps",
      selector: "appCount",
      width: "100px",
      cell: (row) => (
        <button
          className="btn bg-primary live-app-btn text-white"
          onClick={() => handleAPP(row._id)}
        >
          {row?.appCount}
        </button>
      ),
    },

    {
      name: "Company Email",
      selector: "email",
      sortable: false,
    },
    {
      name: "Company Mobile",
      selector: "mobile",
      sortable: false,
    },
    {
      name: "Created Date",
      selector: "date",
      sortable: false,
      cell: (e) => moment(e?.createdAt).format("MM/DD/YYYY hh:mm:ss A z"),
    },
    {
      name: "Actions",
      selector: "actions",
      cell: (row) => (
        <div className="white d-flex gap-3 w-100 justify-content-center">
          {PermissionCheck("Reference By", "Edit Reference") && (
            <button
              onClick={() => {
                setEditData(row);
                setVisible(true);
              }}
              className="btn edit_icon"
            >
              <CIcon icon={cilColorBorder} />
            </button>
          )}
          {PermissionCheck("Reference By", "Remove Reference") && (
            <button
              className="btn  delete_icon"
              onClick={() => {
                setShowDeleteModal(true);
                setDeleteId(row._id);
              }}
            >
              <CIcon
                icon={cilTrash}
                className="icon-trash text-red"
                color="red"
              />
            </button>
          )}
        </div>
      ),
    },
  ];

  const getData = (page, perPage) => {
    let data = {
      pageNo: page || 1,
      perPage: perPage || rowsPerPage,
      fromDate: date.length == 0 ? "" : moment(date?.[0]).format("YYYY-MM-DD"),
      toDate: date.length == 0 ? "" : moment(date?.[1]).format("YYYY-MM-DD"),
      search: search,
    };
    if (PermissionCheck("Reference By", "View Only"))
      APIlist(data)
        .then((e) => {
          if (e?.isSuccess) {
            setTableData(e?.data);
            setcurrentPage(e.currentPageNo);
            setTotalRecords(e.totalRecords);
          } else {
            Toast.error(e?.message);
          }
        })
        .catch((err) => {
          Toast.error("Something went  wrong!!");
        });
  };

  const handleAPP = (id) => {
    APIappCount(id)
      .then((res) => {
        if (res.isSuccess) {
          setApp(res.data);
          setAppFlag(true);
        } else {
          Toast.error(res?.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went  wrong!!");
      });
  };

  useEffect(() => {
    getData();
  }, [date]);

  const deleteHandler = () => {
    APIdelete(deleteId)
      .then((res) => {
        if (res.isSuccess) {
          Toast.success(res.message);
          getData();
        } else {
          Toast.error(res.message);
        }
        setDeleteId();
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  return (
    <>
      <Card className="rounded-3 p-1">
        <Card.Header>
          <div>
            <h5 className="card-title">Reference By</h5>
          </div>
          <div className="grid_item">
            <div>
              {PermissionCheck("Reference By", "Remove Reference") ? (
                <button
                  className="btn btn-primary btn-block"
                  onClick={() => {
                    setVisible(true);
                    setEditData("");
                  }}
                >
                  Add Reference
                </button>
              ) : null}
            </div>
            <div>
              <DateRangePicker
                className={`rangeDate custom-date-range-picker`}
                ranges={predefinedRanges}
                showOneCalendar
                value={date}
                placeholder="Select Date Range"
                onChange={(e) => setDate(e)}
                placement="bottomEnd"
                format="yyyy-MM-dd"
                cleanable={false}
              />
            </div>
            <div>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control search-box"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <button
                  className="btn btn-outline-secondary border search-btn-icon"
                  type="button"
                  onClick={() => getData()}
                >
                  <SearchIcon />
                </button>
              </div>
            </div>
          </div>
        </Card.Header>
        {PermissionCheck("Reference By", "View Only") && (
          <Card.Body>
            {tableData?.length < 1 ? (
              <div className="d-flex w-100 justify-content-center no-data-img">
                <img src={NoDataImg} alt="no-data" className="w-35" />
              </div>
            ) : (
              <DataTable
                columns={tHeader}
                data={tableData}
                pagination
                paginationServer
                paginationPerPage={rowsPerPage}
                onChangeRowsPerPage={(event) => {
                  setRowsPerPage(parseInt(event));
                  getData(currentPage, event);
                }}
                onChangePage={(page) => {
                  getData(page);
                }}
                paginationDefaultPage={currentPage}
                paginationTotalRows={totalRecords}
                fixedHeader
              />
            )}
          </Card.Body>
        )}
      </Card>

      {/* Add Modal */}
      <Add
        visible={visible}
        setVisible={setVisible}
        editData={editData}
        getData={getData}
      />

      {/* delete modal */}

      <DeleteModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        deleteHandler={deleteHandler}
        name={"Budget Rules"}
      />

      <AppCount
        appFlag={appFlag}
        setAppFlag={setAppFlag}
        app={app}
        setApp={setApp}
      />
    </>
  );
}

export default ReferencesPage;
