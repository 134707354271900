import React from "react";
import { CModal, CModalHeader, CModalBody, CModalTitle } from "@coreui/react";
import mainLogo from "../../images/mainlogo.png";
import { useState } from "react";
import { APIview } from "../../api-wrapper/ApiUpdateHistory";
import { useEffect } from "react";
import Toast from "../../helper/toast/Toast";

function Add({ visible, setVisible, id }) {
  const [fetchSingleAppUpdateHistory, setfetchSingleAppUpdateHistory] =
    useState();

  useEffect(() => {
    if (id)
      APIview(id)
        .then((res) => {
          if (res.isSuccess) {
            setfetchSingleAppUpdateHistory(res.data);
          } else {
            setfetchSingleAppUpdateHistory();
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
  }, [id, visible]);

  return (
    <>
      <CModal
        visible={visible}
        alignment="center"
        scrollable
        keyboard={false}
        backdrop="static"
        onClose={() => {
          setVisible(false);
          setfetchSingleAppUpdateHistory();
        }}
      >
        <>
          <CModalHeader>
            <CModalTitle className="">
              <div className="d-flex justify-content-between gap-4">
                <div>
                  <img src={mainLogo} alt="logo" />
                </div>
                <div>
                  <p className="h5 pt-3 fw-bold mt-auto">
                    {" "}
                    App Update History Details
                  </p>
                </div>
              </div>
            </CModalTitle>
          </CModalHeader>
          <CModalBody>
            <div className="row">
              <div className="col-5 border-end">
                <div className="app-bg-img text-center">
                  {fetchSingleAppUpdateHistory?.appLogo ? (
                    <img
                      src={fetchSingleAppUpdateHistory?.appLogo}
                      className="app-bg"
                    />
                  ) : (
                    <div
                      className="default_image app-bg"
                      style={{ width: "120px", height: "120px" }}
                    >
                      {" "}
                      {fetchSingleAppUpdateHistory?.appName.charAt(0)}
                    </div>
                  )}
                </div>
                <div className="version p-2 d-flex flex-wrap justify-content-center">
                  <p className="text-black fw-semibold">
                    Version: {fetchSingleAppUpdateHistory?.version}
                  </p>
                </div>
              </div>
              <div className="col-7">
                <div className="appname">
                  <p>
                    Application Name: {fetchSingleAppUpdateHistory?.appName}
                  </p>
                </div>
                <div className="devname">
                  <p>
                    Developer Name:
                    {fetchSingleAppUpdateHistory?.developerId?.name}
                  </p>
                </div>
              </div>
            </div>
          </CModalBody>
        </>
      </CModal>
    </>
  );
}

export default Add;
