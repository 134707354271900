import React from "react";
import mainLogo from "../../images/mainlogo.png";
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalTitle,
} from "../../helper/links/Link";
function Response({ visible, setVisible, editData }) {
  return (
    <CModal
      visible={visible}
      alignment="center"
      scrollable
      keyboard={false}
      size="xl"
      backdrop="static"
      onClose={() => {
        setVisible(false);
      }}
    >
      <>
        <CModalHeader>
          <CModalTitle className="">
            <div className="d-flex justify-content-between gap-4">
              <div>
                <img src={mainLogo} alt="logo" />
              </div>
              <div>
                <p className="h5 pt-3 fw-bold mt-auto"> View Response</p>
              </div>
            </div>
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div className="row">
            <pre>{JSON.stringify(editData, null, 2)}</pre>
          </div>
        </CModalBody>
      </>
    </CModal>
  );
}

export default Response;
