import React, { useEffect } from "react";
import { Formik } from "formik";
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
  CFormLabel,
  CFormInput,
  CFormSelect,
} from "@coreui/react";
import mainLogo from "../../images/mainlogo.png";
import { CustomAdSchema } from "../../utility/Validator";
import { Toast } from "../../helper/links/Link";
import {
  APIadd,
  APIedit,
  APImonitizelist,
} from "../../api-wrapper/ApiCustomeAdd";
import { useState } from "react";
import {
  APIaccountList,
  APIapplicationList,
} from "../../api-wrapper/ApiMoreAppGroup";
function Add({ visible, setVisible, editData, getData }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [changeImg, setchangeImg] = useState(null);
  const [type, setType] = useState("Application");
  const [accountData, setaccountData] = useState([]);
  const [application, setapplication] = useState([]);
  const [adFormat, setadFormat] = useState([]);
  const [monetizeID, setmonetizeID] = useState("");
  // const [field, meta] = useField("monitizeFormatIds");

  const handleSubmit = (values) => {
    let flag = false;

    if (monetizeID == "") {
      flag = true;
      Toast.error("Assets AdFormat is required!!");
    }
    if (type == "Application") {
      if (values?.playConsoleId == undefined || values?.playConsoleId == "") {
        flag = true;
        Toast.error("playConsole is required!!");
      }
      if (values?.appId == undefined || values?.appId == "") {
        flag = true;
        Toast.error("Application is required!!");
      }
    } else {
      if (values?.assetsUrl == undefined || values?.assetsUrl == "") {
        flag = true;
        Toast.error("URL is required!!");
      }
    }

    if (!flag) {
      const formData = new FormData();

      formData.append("assetsName", values.assetsName);
      formData.append("actionButtonName", values.actionButtonName);
      formData.append("assetsRating", values.assetsRating);
      formData.append("assetsDownload", values.assetsDownload);
      formData.append("asstesType", type);
      formData.append("monitizeFormatIds", monetizeID);

      if (type == "Application") {
        formData.append("playConsoleId", values.playConsoleId);
        formData.append("appId", values.appId);
      } else {
        formData.append("assetsUrl", values.assetsUrl);
      }
      if (changeImg) {
        formData.append("assetsBanner", changeImg);
      }
      if (editData._id) {
        APIedit(formData, editData._id)
          .then((e) => {
            if (e?.isSuccess) {
              Toast.success(e?.message);
              setVisible(false);
              setSelectedImage(null);
              setchangeImg(null);
              getData();
            }
          })
          .catch((err) => {
            Toast.error("Something went wrong, please try again!");
          });
      } else {
        APIadd(formData)
          .then((e) => {
            if (e?.isSuccess) {
              setVisible(false);
              Toast.success(e?.message);
              setSelectedImage(null);
              setchangeImg(null);
              getData();
            }
          })
          .catch((err) => {
            Toast.error("Something went wrong, please try again!");
          });
      }
    }
  };

  useEffect(() => {
    setSelectedImage(editData?.image);
  }, [editData]);

  useEffect(() => {
    if (visible) {
      let data = [];

      APIaccountList()
        .then((res) => {
          if (res.isSuccess) {
            res.data.map((el) => {
              data.push({
                label: el.pcName,
                value: el._id,
              });
            });
            setaccountData(data);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
    }
  }, [visible, editData?.playConsoleId?._id]);

  const handleSubAccount = (val) => {
    let data = [];
    setapplication([]);
    APIapplicationList(val)
      .then((res) => {
        if (res.isSuccess) {
          res.data.map((el) => {
            data.push({
              label: el.appName,
              value: el._id,
            });
          });

          setapplication(data);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  useEffect(() => {
    if (visible) {
      APImonitizelist()
        .then((res) => {
          if (res.isSuccess) {
            setadFormat(res.data);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
    }
  }, [visible]);

  const monitizeHandler = (e, el) => {
    if (e.target.checked) {
      setmonetizeID(el._id);
    } else {
      setmonetizeID("");
    }
  };

  useEffect(() => {
    if (editData?._id) {
      console.log(editData, "hhhhhhhhhhhhh");
      setmonetizeID(editData?.monitizeFormatIds?._id);
      handleSubAccount(editData?.playConsoleId);
    }
  }, [editData?._id]);

  return (
    <>
      <CModal
        size="xl"
        visible={visible}
        alignment="center"
        scrollable
        keyboard={false}
        backdrop="static"
        onClose={() => {
          setVisible(false);
        }}
      >
        <Formik
          validationSchema={CustomAdSchema}
          initialValues={{
            assetsName: editData?.assetsName,
            actionButtonName: editData?.actionButtonName,
            assetsRating: editData?.assetsRating,
            assetsDownload: editData?.assetsDownload,
            asstesType: type,
            playConsoleId: editData?.playConsoleId,
            appId: editData?.appId,
            monitizeFormatIds: monetizeID,
            assetsUrl: editData?.assetsUrl,
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <>
              <CModalHeader>
                <CModalTitle className="">
                  <p className="h4 text-center pt-2 fw-bold mt-auto text-16">
                    Custom Ad
                  </p>
                </CModalTitle>
              </CModalHeader>
              <CModalBody>
                <form onSubmit={handleSubmit} className="p-3">
                  <div className="row mt-2">
                    <div className="col-6 p-1">
                      <CFormLabel>Advertising Assets Name</CFormLabel>
                      <CFormInput
                        type="text"
                        name="assetsName"
                        value={values.assetsName}
                        aria-label="default input example"
                        onChange={handleChange}
                      />
                      <p className="error">
                        {errors.assetsName &&
                          touched.assetsName &&
                          errors.assetsName}
                      </p>
                    </div>
                    <div className="col-6 p-1 p-1">
                      <CFormLabel>Action Button Name</CFormLabel>
                      <CFormInput
                        type="text"
                        name="actionButtonName"
                        value={values.actionButtonName}
                        aria-label="default input example"
                        onChange={handleChange}
                      />
                      <p className="error">
                        {errors.actionButtonName &&
                          touched.actionButtonName &&
                          errors.actionButtonName}
                      </p>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6 p-1">
                      <CFormLabel>Assets Rating</CFormLabel>
                      <CFormInput
                        type="number"
                        name="assetsRating"
                        value={values.assetsRating}
                        aria-label="default input example"
                        onChange={handleChange}
                      />
                      <p className="error">
                        {errors.assetsRating &&
                          touched.assetsRating &&
                          errors.assetsRating}
                      </p>
                    </div>
                    <div className="col-6 p-1">
                      <CFormLabel>Assets Download</CFormLabel>
                      <CFormInput
                        type="text"
                        name="assetsDownload"
                        value={values.assetsDownload}
                        aria-label="default input example"
                        onChange={handleChange}
                      />
                      <p className="error">
                        {errors.assetsDownload &&
                          touched.assetsDownload &&
                          errors.assetsDownload}
                      </p>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6  p-1">
                      <CFormLabel>Assets Banner</CFormLabel>
                      <input
                        type="file"
                        className="form-control-file d-block"
                        accept="image/*"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          const imageFile = e.target.files[0];
                          setSelectedImage(null);
                          setchangeImg(imageFile);
                          setFieldValue("image", imageFile);
                        }}
                      />
                    </div>
                    <div className="col-6  p-1">
                      <div className="d-flex flex-column gap-4 flex-wrap upload-ref-img-div  pe-4 mt-2 mt-md-0">
                        {selectedImage ? (
                          <img
                            src={selectedImage}
                            alt="reference-img"
                            className="upload-ref-img"
                          />
                        ) : (
                          changeImg && (
                            <img
                              src={URL.createObjectURL(changeImg)}
                              className="upload-ref-img"
                            />
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6 p-1">
                      <div>
                        <CFormLabel>Advertise Assets AdFormat</CFormLabel>
                        <br />
                        <div className="">
                          {adFormat?.map((el) => (
                            <div className="me-2">
                              {console.log(monetizeID, el._id, "ddddd")}
                              <input
                                type="radio"
                                id={el.id}
                                name="monitizeFormatIds"
                                defaultChecked={monetizeID == el._id}
                                onChange={(e) => {
                                  monitizeHandler(e, el);
                                }}
                              />
                              <label for="vehicle1" className="ps-1">
                                {" "}
                                {el.name}
                              </label>
                              <br></br>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-6 p-1">
                      <input
                        type="radio"
                        id="html"
                        className="me-1"
                        onChange={(e) => setType("Application")}
                        checked={type == "Application"}
                        name="asstesType"
                        value="Application"
                      />
                      <label className="me-3" for="html">
                        Application{" "}
                      </label>
                      <input
                        type="radio"
                        checked={type == "URL"}
                        onChange={(e) => setType("URL")}
                        className="me-1"
                        id="css"
                        name="asstesType"
                        value="URL"
                      />
                      <label for="css">URL</label>
                      <br />
                    </div>
                  </div>

                  {type == "Application" ? (
                    <div className="row mt-2">
                      <div className="col-6 p-1">
                        <div>
                          <CFormLabel>Play Console</CFormLabel>
                          <CFormSelect
                            aria-label="Default select example"
                            name="playConsoleId"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleSubAccount(e.target.value);
                              setFieldValue("playConsoleId", e.target.value);
                              setFieldValue("appId", "");
                            }}
                            value={values?.playConsoleId}
                          >
                            <option
                              key="playconsole"
                              value=""
                              selected
                              disabled
                            >
                              Select Play Console
                            </option>
                            {accountData?.map((data, index) => (
                              <option key={data.value} value={data.value}>
                                {data.label}
                              </option>
                            ))}
                          </CFormSelect>
                        </div>
                      </div>
                      <div className="col-6 p-1">
                        <div>
                          <CFormLabel>Application</CFormLabel>
                          <CFormSelect
                            aria-label="Default select example"
                            name="appId"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values?.appId}
                          >
                            <option key="" value="" selected disabled>
                              Select Application
                            </option>
                            {application?.map((data, index) => (
                              <option key={data.value} value={data.value}>
                                {data.label}
                              </option>
                            ))}
                          </CFormSelect>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="row mt-2">
                      <div className="col-6 p-1 cust-col-6-custom-ad">
                        <div>
                          <CFormLabel>Assets Url</CFormLabel>
                          <CFormInput
                            type="text"
                            name="assetsUrl"
                            onChange={handleChange}
                            value={values?.assetsUrl}
                            aria-label="default input example"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </form>
              </CModalBody>
              <CModalFooter className="add-play-console-modal-footer d-flex justify-content-center gap-2">
                <button
                  className=" btn close-btn bg-white text-black border-danger"
                  onClick={() => {
                    setVisible(false);
                    setchangeImg(null);
                  }}
                >
                  Close
                </button>
                <button
                  className="btn bg-primary text-light create-apikey-btn"
                  onClick={(e) => handleSubmit(e)}
                  type="submit"
                >
                  save
                </button>
              </CModalFooter>
            </>
          )}
        </Formik>
      </CModal>
    </>
  );
}

export default Add;
