import React from "react";
import Card from "react-bootstrap/Card";
import moment from "moment";
import { useState } from "react";
import { DateRangePicker } from "rsuite";
import { predefinedRanges } from "../../helper/calendarValues/calendarValues";
import NoDataImg from "../../images/g8.png";
import DataTable from "react-data-table-component";
import { APIlistPlatform } from "../../api-wrapper/ApiPlayConsole";
import { APIplayConsole } from "../../api-wrapper/ApiApprovalList";
import Toast from "../../helper/toast/Toast";
import { useEffect } from "react";
import { APIlistDateWiseTable } from "../../api-wrapper/ApiDashbord";
import { useNavigate } from "react-router";

function DateWiseTable() {
  let navigate = useNavigate();
  const [date, setDate] = useState([
    new Date(moment().startOf("month")),
    new Date(),
  ]);
  const [playConsoleData, setPlayConsoleData] = useState([]);
  const [platform, setPlatform] = useState([]);
  const [arg, setArg] = useState({ pageNo: 1, perPage: 10 });
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedData, setselectedData] = useState({
    console: "",
    platform: "",
  });
  const [tableData, setTableData] = useState([]);

  const tHeader = [
    {
      name: "Application Logo",
      selector: "appLogo",
      cell: (row) => {
        if (row.appLogo)
          return <img src={row.appLogo} className="ref-image" alt="" />;
        else
          return <div className="default_image"> {row.appName.charAt(0)}</div>;
      },
    },
    {
      name: "Application Name",
      selector: "appName",
      sortable: false,
    },
    {
      name: "Download/Active User",
      selector: "totalActiveDevices",
      cell: (row) => ` ${row.countDownload} / ${row.countActiveUser}`,
    },
  ];

  useEffect(() => {
    let data = [];
    APIlistPlatform()
      .then((res) => {
        if (res.isSuccess) {
          res.data.map((el) => {
            data.push({
              label: el.name,
              value: el._id,
            });
          });
          setPlatform(data);
        } else {
          setPlatform([]);
        }
      })
      .catch((e) => {
        Toast.error("Something went wrong, please try again!");
      });
  }, []);

  useEffect(() => {
    let data = [];
    APIplayConsole()
      .then((res) => {
        if (res.isSuccess) {
          res.data.map((el) => {
            data.push({
              label: el.pcName,
              value: el._id,
            });
          });
          setPlayConsoleData(data);
        } else {
          setPlayConsoleData([]);
        }
      })
      .catch((e) => {
        Toast.error("Something went wrong, please try again!");
      });
  }, []);

  const getData = () => {
    let data = {
      playConsoleId: selectedData.console,
      platformId: selectedData.platform,
      fromDate: date.length == 0 ? "" : moment(date?.[0]).format("YYYY-MM-DD"),
      toDate: date.length == 0 ? "" : moment(date?.[1]).format("YYYY-MM-DD"),
      ...arg,
    };
    APIlistDateWiseTable(data)
      .then((e) => {
        if (e?.isSuccess) {
          setTotalRecords(e?.totalRecords);
          setTableData(e?.data);
        } else {
          Toast.error(e?.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  useEffect(() => {
    getData();
  }, [date, selectedData, arg]);

  const handleRowClick = (row) => {
    navigate(`/all-apps/${row.appId}`);
  };

  return (
    <Card className="rounded-3 mt-4">
      <Card.Body className="dynamic_card">
        <div className="row">
          <p className="fw-semibold">Date Wise App Download</p>
        </div>
        <div className="row">
          <div className="col-4 p-1 cust-col-3-date-picker">
            <DateRangePicker
              className={`rangeDate custom-date-range-picker px-lg-2 pb-1`}
              ranges={predefinedRanges}
              showOneCalendar
              value={date}
              placeholder="Select Date Range"
              onChange={(e) => setDate(e)}
              placement="right"
              format="yyyy-MM-dd"
              cleanable={false}
              style={{ width: "100%", display: "inline-block" }}
            />
          </div>
          <div className="col-4 p-1 cust-col-3-account">
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(e) =>
                setselectedData({ ...selectedData, console: e.target.value })
              }
              value={selectedData.console}
            >
              <option value="" selected>
                All Play Console
              </option>
              {playConsoleData?.map((data, index) => (
                <option key={data.value} value={data.value}>
                  {data.label}
                </option>
              ))}
            </select>
          </div>
          <div className="col-4 p-1 cust-col-3-platform">
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(e) =>
                setselectedData({ ...selectedData, platform: e.target.value })
              }
              value={selectedData.platform}
            >
              <option value="" selected>
                All Platform
              </option>
              {platform?.map((data, index) => (
                <option key={data.value} value={data.value}>
                  {data.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div>
          {tableData?.length == 0 ? (
            <>
              <div className="d-flex w-100 justify-content-center no-data-img">
                <img src={NoDataImg} alt="no-data" className="w-50" />
              </div>
            </>
          ) : (
            <DataTable
              className="scroll_table"
              columns={tHeader}
              data={tableData}
              pagination
              paginationServer
              paginationPerPage={10}
              onRowClicked={handleRowClick}
              onChangePage={(page) =>
                setArg({ pageNo: page, perPage: arg.perPage })
              }
              onChangeRowsPerPage={(newPerPage, page) =>
                setArg({ pageNo: page, perPage: newPerPage })
              }
              paginationTotalRows={totalRecords}
              fixedHeader
            />
          )}
        </div>
      </Card.Body>
    </Card>
  );
}

export default DateWiseTable;
