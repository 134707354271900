import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { CSidebar, CSidebarBrand, CSidebarNav } from "@coreui/react";
import CIcon from "@coreui/icons-react";

import { AppSidebarNav } from "./appSidebarNav";

import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import navigation from "./navbar";
import { mainLogo2 } from "../icons/mainLogo2.icon";
import { handleSidebar } from "../helper/redux/action";
import Logo from "../images/manger-logo.svg";
import { ApiGetPermission } from "../api-wrapper/ApiLogin";
import getCurrentUser from "../utils/getCurrentUser.util";
import { CNavGroup, CNavItem } from "@coreui/react";
const AppSidebar = () => {
  const dispatch = useDispatch();
  const unfoldable = useSelector((state) => state.sidebarUnfoldable);
  const sidebarShow = useSelector((state) => state.data.sidebarShow);
  const [arr, setArr] = useState(navigation);
  const curretUser = getCurrentUser();
  useEffect(() => {
    ApiGetPermission(curretUser?.adminId)
      .then((res) => {
        localStorage.setItem('permission', JSON.stringify(res.data.pages))
        const curretUser = getCurrentUser();
        let filteredNavbar = []
        if (curretUser) {
          let permission = res.data.pages;

          if (curretUser?.adminRole !== 0) {
            const allowedPages = permission?.map((page) =>
              page?.pageName?.toLowerCase().replace(" ", "-")
            );

            filteredNavbar = navigation.flatMap((item) => {
              if (item.component === CNavItem) {
                return allowedPages?.includes(item.name.toLowerCase().replace(" ", "-"))
                  ? [item]
                  : [];
              } else if (item.component === CNavGroup) {
                const subItems = item.items.filter((subItem) =>
                  allowedPages?.includes(subItem.name.toLowerCase().replace(" ", "-"))
                );
                if (subItems.length > 0) {
                  const newNavGroup = { ...item, items: subItems };
                  return [newNavGroup];
                }
              }
              return [];
            });
          } else if (curretUser?.adminRole === 0) {
            filteredNavbar = navigation;
          }
          setArr(filteredNavbar)
        }

      }).catch((err) => {
      });
  }, []);

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch(handleSidebar(visible));
      }}
    >
      <CSidebarBrand
        className="d-none d-md-flex "
        to="/"
      // style={{ background: "white" }}
      >
        {/* <img className="sidebar-brand-full" src={mainlogo} alt="logo" /> */}
        <img className="sidebar-brand-full" alt="logo" src={Logo} />
        <CIcon className="sidebar-brand-narrow" icon={mainLogo2} height={35} />
      </CSidebarBrand>
      <div className="border-after"></div>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={arr} />
        </SimpleBar>
      </CSidebarNav>
      {/* <CSidebarToggler
        className="d-none d-lg-flex "
        onClick={() =>
          dispatch({ type: "set", sidebarUnfoldable: !unfoldable })
        }
      /> */}
    </CSidebar>
  );
};

export default AppSidebar;
