import React, { useState } from "react";
import DataTable from "react-data-table-component";
import Card from "react-bootstrap/Card";
import { useEffect } from "react";
import NoDataImg from "../../../images/g8.png";
import getCurrentUser from "../../../utils/getCurrentUser.util";
import {
  APIauthPage,
  APIdelete,
  APIlist,
  APIlistAuth,
  APIsavePage,
  APIlistPage,
  APIUpdatePermission,
  APIauthorize,
} from "../../../api-wrapper/ApiAdminAccess";
import { Toast, PermissionCheck } from "../../../helper/links/Link";
import moment from "moment";
import Add from "./Add";
import DeleteModal from "../../../helper/modal/DeleteModal";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";
import deleteSvg from "../../../icons/svgs/delete-bin-line.svg";
import viewLineSvg from "../../../icons/svgs/view-line.svg";
import Carousel from "react-multi-carousel";
import { CButton } from "@coreui/react";
import allocatedActionsImag from "../../../icons/svgs/allocatedActionImg.svg";
import notAllocatedActionsImg from "../../../icons/svgs/notAllocatedActionImg.svg";
import { MultiSelect } from "react-multi-select-component";

function AdminAccess() {
  const [tableData, setTableData] = useState([]);
  const [arg] = useState({ pageNo: 1, perPage: 10 });
  const [setTotalRecords] = useState(0);
  const [visible, setVisible] = useState(false);
  const [editData] = useState();
  const [deleteId, setDeleteId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [activeCard, setActiveCard] = useState(null);
  const [pageData, setPageData] = useState([]);
  const [permission, setPermission] = useState([]);
  const [pageId, setPageId] = useState("");
  const [customer, setCustomer] = useState();
  const [selectedCustomAds, setSelectedcustomAds] = useState([]);
  const [pagesData, setPagesData] = useState([]);
  const getData = () => {
    if (PermissionCheck("Admin Access", "View Only"))
      APIlist()
        .then((e) => {
          if (e?.isSuccess) {
            setTableData(e?.data);
            // setTotalRecords(e.totalRecords);
          } else {
            Toast.error(e?.message);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
  };

  useEffect(() => {
    getData();
  }, [arg]);

  const deleteHandler = () => {
    APIdelete(deleteId)
      .then((res) => {
        if (res.isSuccess) {
          Toast.success(res.message);
          setCustomer()
          getData();
        } else {
          Toast.error(res.message);
        }
        setDeleteId();
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  const HandlePages = (id) => {
    let data = [];
    APIlistAuth(id)
      .then((res) => {
        if (res.isSuccess) {
          res.data.map((el) => {
            data.push({
              label: el.pageName,
              value: el._id,
            });
          });
          setPageData(data);
        } else {
          setPageData([]);
          Toast.error(res.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  // APIauthPage
  const HandleGetPermission = (id) => {
    setSelectedcustomAds([]);
    setPermission([]);
    let arr = [];
    let data = {
      pageId: id,
      adminId: customer._id,
    };
    APIauthPage(data)
      .then((res) => {
        if (res.isSuccess) {
          res.data.map((el) => {
            arr.push({
              label: el.actionType,
              value: el._id,
            });
          });
          setPermission(arr);
        } else {
          Toast.error(res.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  const handleSubpage = (selectedOptions) => {
    setSelectedcustomAds(selectedOptions);
  };

  const handleSavePage = () => {
    let data = {
      pages: {
        pageId: pageId,
        allocatedActions: selectedCustomAds.map((x) => x.value),
      },
    };

    APIsavePage(data, customer._id)
      .then((res) => {
        if (res.isSuccess) {
          Toast.success(res.message);
          setPageId("");
          setSelectedcustomAds([]);
          handleListpage(customer._id);
          HandlePages(customer._id);
        } else {
          Toast.error(res.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  const handleListpage = (id) => {
    APIlistPage(id)
      .then((res) => {
        if (res.isSuccess) {
          setPagesData(res.data);
        } else {
          Toast.error(res?.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };


  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 662 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 661 },
      items: 1,
    },
  };

  const tHeader = [
    {
      name: "Page Name",
      selector: "pageName",
      cell: (row) => row.pageName,
    },

    {
      name: "Allocated Actions",
      selector: (row) => row.allocatedAction,
      cell: (e) => {
        if (e && e.allocatedActions) {
          return (
            <div className="d-flex flex-wrap flex-column gap-2">
              {e.allocatedActions.map((x, index) => (
                <div
                  className={`${x.status
                    ? "border-success text-success"
                    : "border-danger text-danger"
                    } border  p-2   cursor-pointer w-max-content`}
                  onClick={() => handleAllocatedAction(x, e)}
                >
                  {x.actionType}
                </div>
              ))}
            </div>
          );
        } else {
          return "No actions";
        }
      },
    },
    {
      name: "Allocated Date",
      selector: "allocatedOn",
      cell: (e) => {
        return (
          <div>
            {moment(e.allocatedOn).format("YYYY-MM-DD") +
              " " +
              moment(e.allocatedOn).format("hh:mm:ss A") || "-"}
          </div>
        );
      },
    },
    {
      name: "Action",
      selector: "actions",
      cell: (row) =>
        PermissionCheck("Admin Access", "Remove Authorized Pages") ? (
          <button
            onClick={() => HandleAuthPermission(row)}
            className="border-danger text-danger p-2"
          >
            Remove Authorized Page
          </button>
        ) : null,
    },
  ];
  const HandleAuthPermission = (row) => {
    let data = {
      pageId: row.pageId,
    };
    APIauthorize(data, customer._id)
      .then((e) => {
        if (e?.isSuccess) {
          handleListpage(customer._id);
          Toast.success(e?.message);
        } else {
          Toast.error(e?.message);
        }
      })
      .catch((e) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  const handleAllocatedAction = (el, row) => {
    let data = {
      allocatedActionId: el.actionId,
      status: el.status === true ? false : el.status === false ? true : null,
      pageId: row.pageId,
    };
    APIUpdatePermission(data, customer._id)
      .then((e) => {
        if (e?.isSuccess) {
          handleListpage(customer._id);
          Toast.success(e?.message);
        } else {
          Toast.error(e?.message);
        }
      })
      .catch((e) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  return (
    <>
      <Card className="rounded-3 p-1">
        <Card.Header className="align-items-center gap-4 flex-wrap padding-header border-bottom-0">
          <h5 className="card-title">
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="large" />}
              aria-label="breadcrumb"
            >
              <Typography key="1" color="text.primary" className="platform">
                Settings
              </Typography>
              <Typography
                key="2"
                color="text.primary"
                className="sub-platform"
                style={{ color: "#A5A5A5" }}
              >+
                Admin Access
              </Typography>
            </Breadcrumbs>
          </h5>
          {PermissionCheck("Admin Access", "Add Users") ? (
            <div className="d-flex gap-4 flex-wrap justify-content-end">
              <button
                class="btn bg-primary add-ref-btn"
                onClick={() => setVisible(true)}
              >
                Add Admin User
              </button>
            </div>
          ) : null}
        </Card.Header>
      </Card>

      <div className="row mt-3 p-3">
        <Card className="rounded-3 p-1">
          {
            PermissionCheck("Admin Access", "View Only") &&
            <Card.Body>
              <div className="app-publish-cosole-div margin-cust1">
                <div className="row">
                  {tableData?.length > 0 ? (
                    <Carousel
                      responsive={responsive}
                      ssr={true}
                      className="slider_data"
                    >
                      {tableData?.map((data, index) => (
                        <div
                          className="me-3"
                          onClick={() => {
                            HandlePages(data._id);
                            handleListpage(data._id);
                            setActiveCard(index);
                            setCustomer(data);
                          }}
                        >
                          <div
                            className={` card-box position-relative  ${activeCard === index ? "selectedDiv" : ""
                              }`}
                          >
                            <div className="member-card pb-2 pt-2">
                              {data?.image ? (
                                <div
                                  className="thumb-lg member-thumb mx-auto position-relative"
                                  style={{
                                    backgroundImage: `url(${data?.image})`,
                                  }}
                                ></div>
                              ) : (
                                <div className="thumb-lg member-thumb mx-auto position-relative default_image_platform">
                                  {" "}
                                  {data.name.charAt(0)}
                                </div>
                              )}

                              <div className="mt-3 text-center sub_member">
                                <h5>{data?.name} </h5>
                              </div>

                              <div className="mt-4">
                                <div className="row p-1">
                                  <p className="mb-0 fs-6 text-muted d-flex flex-wrap gap-2">
                                    Email:
                                    <p className=" fs-6 text-black word-break sub_member">
                                      {data?.email}
                                    </p>
                                  </p>
                                </div>

                                <div className="row p-1">
                                  <p className="mb-0 fs-6 text-muted d-flex flex-wrap gap-2">
                                    Role:
                                    <p className=" fs-6 text-black">

                                      {data.role == 1 ? "Admin" : data.role == 2 ? "Partner" : data.role == 3 ? "Developer" : "Super Admin"}
                                    </p>{" "}
                                  </p>
                                </div>
                              </div>
                            </div>
                            {
                              PermissionCheck("Admin Access", "Remove Users") &&
                              <div className="over-img d-flex rounded flex-wrap justify-content-center">
                                <div className="d-flex flex-wrap justify-content-center align-items-center gap-4">

                                  <img
                                    src={viewLineSvg}
                                    alt="view svg"
                                    className="hover-icon"

                                  />

                                  <img
                                    src={deleteSvg}
                                    alt="delete svg"
                                    className="hover-icon"
                                    onClick={() => {
                                      setShowDeleteModal(true);
                                      setDeleteId(data._id);
                                    }}
                                  />
                                </div>
                              </div>
                            }

                          </div>
                        </div>
                      ))}
                    </Carousel>
                  ) : (
                    <div className="d-flex w-100 justify-content-center no-data-img">
                      <img src={NoDataImg} alt="no-data" className="w-35" />
                    </div>
                  )}
                </div>
              </div>
            </Card.Body>
          }

        </Card>
      </div>

      {customer !== undefined && (
        <Card className="rounded-3 p-3">
          {PermissionCheck("Admin Access", "Add Authorized Pages") ? (
            <div className="row">
              <div className="col-4 title-col-2">
                <h5 className="card-title">Authorized Page</h5>
              </div>
              <div className="col-3 page-col-4 p-2">
                <div className="select-div">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={pageId}
                    // value={pageId}
                    onChange={(e) => {
                      setPageId(e.target.value);
                      HandleGetPermission(e.target.value);
                    }}
                  >
                    <option disabled selected value={""}>
                      Select Page
                    </option>
                    {pageData.map((page) => (
                      <option value={page.value}>{page.label}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-3 action-col-4 p-2">
                <MultiSelect
                  options={permission}
                  value={selectedCustomAds}
                  onChange={handleSubpage}
                  labelledBy={"Select"}
                  hasSelectAll={false}
                  disableSearch={true}
                // valueRenderer={renderSelectedOptionsForSplashMoreAppIds}
                />
              </div>
              <div className="col-2 btn-col-4 p-2">
                <CButton
                  color="primary"
                  className="create-apikey-btn"
                  onClick={handleSavePage}
                >
                  Save
                </CButton>
              </div>
            </div>
          ) : null}
          <div className="d-flex p-2 gap-4">
            <div className="d-flex gap-3">
              <div>
                {" "}
                <img
                  src={allocatedActionsImag}
                  alt="delete svg"
                  className="hover-icon"
                />
              </div>
              <p>Allocated Actions</p>
            </div>
            <div className="d-flex gap-3">
              <div>
                {" "}
                <img
                  src={notAllocatedActionsImg}
                  alt="delete svg"
                  className="hover-icon"
                />
              </div>
              <p> Not Allocated Actions</p>
            </div>
          </div>
          <div className="row mt-1 z-0">
            {pagesData?.pages?.length < 1 ? (
              <div className="d-flex w-100 justify-content-center no-data-img">
                <img src={NoDataImg} alt="no-data" className="w-35" />
              </div>
            ) : (
              <DataTable
                columns={tHeader}
                data={pagesData?.pages}
                fixedHeader
              // defaultSortField="title"
              />
            )}
          </div>
        </Card>
      )}

      <Add
        visible={visible}
        setVisible={setVisible}
        editData={editData}
        getData={getData}
      />

      {/* delete modal */}

      <DeleteModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        deleteHandler={deleteHandler}
      />
    </>
  );
}

export default AdminAccess;
