import React, { useState } from "react";
import CIcon from "@coreui/icons-react";
import { cilPlus } from "@coreui/icons";
import SearchIcon from "@mui/icons-material/Search";
import DataTable from "react-data-table-component";
import Card from "react-bootstrap/Card";
import { useEffect } from "react";
import NoDataImg from "../../images/g8.png";
import getCurrentUser from "../../utils/getCurrentUser.util";
import { APIlist } from "../../api-wrapper/ApiAppList";
import moment from "moment";
import {
  Toast,
  Typography,
  NavigateNextIcon,
  Breadcrumbs,
  PermissionCheck,
  Link,
} from "../../helper/links/Link";
import { useParams } from "react-router-dom";
import { APIPlayConsole } from "../../api-wrapper/ApiAppList";

function AppWiseCustomAd() {
  const [date, setDate] = useState([
    new Date(moment().startOf("month")),
    new Date(),
  ]);
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [visible, setVisible] = useState(false);
  const [editData, setEditData] = useState();
  const [deleteId, setDeleteId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [filter, setFilter] = useState({ playConsole: "" });
  const [playConsole, setPlayConsole] = useState([]);
  const [status, setStatus] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const tHeader = [
    {
      name: "Logo",
      selector: "appLogo",
      cell: (row) => {
        if (row.appLogo) return <img src={row.appLogo} className="ref-image" />;
        else
          return <div className="default_image"> {row.appName.charAt(0)}</div>;
      },
    },
    {
      name: "Application List",
      selector: "appList",
      cell: (item) => {
        return (
          <div>
            <div className="d-block">
              <p className="text-center m-0 fw- pb-1">{item.appName}</p>
            </div>
            <div className=" d-block">
              <p className="text-center m-0" style={{ color: "darkgray" }}>
                {item.packageName}
              </p>
            </div>
          </div>
        );
      },
    },
    {
      name: "Download",
      selector: "download",
      cell: (e) => (
        <div>
          <div className=" d-block ">
            <p className="text-center m-0 pb-1">
              {" "}
              {moment(e?.createdAt).format("MM/DD/YYYY ")}
            </p>
          </div>
          <div className=" d-block ">
            <p className="text-center m-0">{e?.downloadCount}</p>
          </div>
        </div>
      ),
    },
    {
      name: "Other Details",
      selector: "otherDetails",
      sortable: (row) => row.otherDetails,
      cell: (row) => (
        <div className=" d-block  ">
          <p style={{ textWrap: "wrap" }} className="platform-text m-0">
            Platform:{" "}
            {row?.playConsoleId?.platformId
              ? row?.playConsoleId?.platformId?.name
              : " Not assigned Yet"}
          </p>
        </div>
      ),
    },
    {
      name: "Status",
      selector: "status",
      cell: (item) => (
        <div className="d-block ">
          {item?.appStatus == "Live" ? (
            <button className="btn active-btn fs-6">{item.appStatus}</button>
          ) : item?.appStatus == "InReview" ? (
            <button className="btn text-black inactive-btn fs-6">
              {item.appStatus}
            </button>
          ) : item?.appStatus == "Rejected" ? (
            <button className="btn text-black reject-btn fs-6">
              {item.appStatus}
            </button>
          ) : item?.appStatus == "Suspend" ? (
            <button className="btn text-black remove-btn fs-6">
              {item.appStatus}
            </button>
          ) : null}
        </div>
      ),
    },
    {
      name: "Actions",
      selector: "actions",
      cell: (row) => (
        <div className="d-flex gap-2 justify-content-center ">
          {PermissionCheck("App Wise Custom Ad", "Custom Ad List") ? (
            <Link to={`/app-wise-custom-ad/${row?._id}`}>
              <button
                onClick={() => { }}
                className="btn  add_icon"
                style={{ color: "white" }}
              >
                <CIcon icon={cilPlus} />
              </button>
            </Link>
          ) : null}
        </div>
      ),
    },
  ];

  const getData = (page, perPage) => {
    let data = {
      pageNo: page || 1,
      perPage: perPage || rowsPerPage,
      search: search,
      playConsoleId: filter.playConsole,
      appStatus: status,
    };
    if (PermissionCheck("App Wise Custom Ad", "View Only"))
      APIlist(data)
        .then((e) => {
          if (e?.isSuccess) {
            setTableData(e?.data);
            setcurrentPage(e.currentPageNo)
            setTotalRecords(e.totalRecords);
          } else {
            Toast.error(e?.message);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
  };
  useEffect(() => {
    getData();
  }, [filter, status]);

  useEffect(() => {
    let data = [];
    APIPlayConsole()
      .then((res) => {
        if (res.isSuccess) {
          res.data.map((el) => {
            data.push({
              label: el.pcName,
              value: el._id,
            });
          });
          setPlayConsole(data);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  }, []);


  return (
    <>
      <Card className="rounded-3 p-1">
        <Card.Header className="">
          <h5 className="card-title">
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="large" />}
              aria-label="breadcrumb"
            >
              <Typography key="1" color="text.primary" className="platform">
                Custom Advertise
              </Typography>
              <Typography
                key="2"
                color="text.primary"
                className=""
                style={{ color: "#A5A5A5" }}
              >
                App Wise Custom Ad
              </Typography>
            </Breadcrumbs>
          </h5>

          <div className="history_item grid_console">
            <div className="">
              <select
                className="form-select h-100"
                aria-label="Default select example"
                onChange={(e) =>
                  setFilter({ ...filter, playConsole: e.target.value })
                }
              >
                <option value="" selected>
                  All Play Console
                </option>
                {playConsole?.map((data, index) => (
                  <option key={data.value} value={data.value}>
                    {data.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="">
              <select
                className="form-select h-100"
                aria-label="Default select example"
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="" selected>
                  All App Status
                </option>

                <option value="Live"> Live</option>
                <option value="InReview"> InReview</option>
                <option value="Rejected"> Rejected</option>
                <option value="Suspend"> Suspend</option>
              </select>
            </div>
            <div className="">
              <div className="input-group mr-3 h-100">
                <input
                  type="text"
                  className="form-control search-box"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />

                <button
                  className="btn btn-outline-secondary border search-btn-icon"
                  type="button"
                  onClick={() => getData()}
                >
                  <SearchIcon />
                </button>
              </div>
            </div>
          </div>
        </Card.Header>
        {
          PermissionCheck("App Wise Custom Ad", "View Only") &&
          <Card.Body>
            {tableData?.length < 1 ? (
              <div className="d-flex w-100 justify-content-center no-data-img">
                <img src={NoDataImg} alt="no-data" className="w-35" />
              </div>
            ) : (
              <DataTable
                columns={tHeader}
                data={tableData}
                pagination
                paginationServer
                paginationPerPage={rowsPerPage}
                onChangeRowsPerPage={(event) => {
                  setRowsPerPage(parseInt(event));
                  getData(currentPage, event);
                }}
                onChangePage={(page) => {
                  getData(page);
                }}
                paginationDefaultPage={currentPage}
                paginationTotalRows={totalRecords}
                fixedHeader
              />
            )}
          </Card.Body>
        }

      </Card>
    </>
  );
}

export default AppWiseCustomAd;
