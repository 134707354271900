import axios from "axios";

const api = `${process.env.REACT_APP_BACKEND_URL}/customAPI/`;
const authToken = localStorage.getItem("token");
const headers = {
  Authorization: `Bearer ${authToken}`,
  "Content-Type": "application/json",
};

const APIlist = (data) => {
  return axios
    .post(`${api}get-custom-apis`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APICreate = (data) => {
  return axios
    .post(`${api}create-custom-api`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIresponse = (id) => {
  return axios
    .get(`${api}get-app-response-for-custom-api/${id}`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIedit= (data,id) => {
	return axios
		.post(`${api}update-custom-api/${id}`, data, { headers })
		.then((res) => res.data)
		.catch((res) => res.data);
};

export { APIedit, APIlist, APICreate, APIresponse };
