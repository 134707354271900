import React, { useEffect } from "react";
import { CModal, CModalHeader, CModalBody, CModalTitle } from "@coreui/react";
import DataTable from "react-data-table-component";
import mainLogo from "../../images/mainlogo.png";
import { Toast } from "../../helper/links/Link";
import { useState } from "react";
import NoDataImg from "../../images/g8.png";
import SearchIcon from "@mui/icons-material/Search";
import { APILocarionList } from "../../api-wrapper/ApiLocationWise";
function Add({ visible, setVisible }) {
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);
  const [arg, setArg] = useState({ pageNo: 1, perPage: 10 });
  const [totalRecords, setTotalRecords] = useState(0);
  const tHeader = [
    {
      name: "State Name",
      selector: "state",
    },
    {
      name: "State Code",
      selector: "stateCode",
      cell: (row) => row.stateCode,
    },
    {
      name: "Country Name",
      selector: "country",
    },
    {
      name: "Country Code",
      selector: "countryCode",
      cell: (row) => row.countryCode,
    },
  ];
  const getData = () => {
    let data = {
      ...arg,
      search: search,
    };
    APILocarionList(data)
      .then((e) => {
        if (e?.isSuccess) {
          setTableData(e?.data);
          setTotalRecords(e.totalRecords);
        } else {
          Toast.error(e?.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };
  useEffect(() => {
    getData();
  }, [arg, visible]);

  return (
    <>
      <CModal
        visible={visible}
        alignment="center"
        scrollable
        keyboard={false}
        backdrop="static"
        size="lg"
        onClose={() => {
          setVisible(false);
        }}
      >
        <CModalHeader>
          <CModalTitle className="">
            <div className="d-flex justify-content-between gap-3">

              <div>
                <p className="h5 pt-3 text-center fw-bold mt-auto">
                  Location List
                </p>
              </div>
              <div className="align-items-center pt-2 px-4">
                <div className="input-group mr-3">
                  <input
                    type="text"
                    className="form-control search-box"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />

                  <button
                    className="btn btn-outline-secondary border search-btn-icon"
                    type="button"
                    onClick={() => getData()}
                  >
                    <SearchIcon />
                  </button>
                </div>
              </div>
            </div>
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          {tableData?.length < 1 ? (
            <div className="d-flex w-100 justify-content-center no-data-img">
              <img src={NoDataImg} alt="no-data" className="w-50" />
            </div>
          ) : (
            <DataTable
              columns={tHeader}
              data={tableData}
              pagination
              paginationServer
              paginationPerPage={10}
              onChangePage={(page) =>
                setArg({ pageNo: page, perPage: arg.perPage })
              }
              onChangeRowsPerPage={(newPerPage, page) =>
                setArg({ pageNo: page, perPage: newPerPage })
              }
              paginationTotalRows={totalRecords}
              fixedHeader
            />
          )}
        </CModalBody>
      </CModal>
    </>
  );
}

export default Add;
