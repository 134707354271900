import React from "react";
import { Card } from "../../helper/links/Link";
function TotalBlock({ totalData }) {
  return (
    <>
      <div className="all_apps_list">
        <Card.Body>
          <div className="block_list">
            <div className="p-1">
              <div className="remove-div linear-gradiant-bg  border-12  h-auto p-2 text-center block_1">
                <h4 className="">{totalData?.totalApps || 0}</h4>
                <p className="">Total App</p>
              </div>
            </div>
            <div className="p-1">
              <div className="total-apps-div linear-gradiant-bg  border-12 h-auto p-2 text-center block_1">
                <h4 className="">{totalData?.inReviewApps || 0}</h4>
                <p className="">InReview App</p>
              </div>
            </div>

            <div className="p-1">
              <div className="reject-div linear-gradiant-bg  border-12 h-auto p-2 text-center block_1">
                <h4 className="">{totalData?.liveApps || 0}</h4>
                <p className="">Live App</p>
              </div>
            </div>
            <div className="p-1">
              <div className="suspend-div linear-gradiant-bg  border-12 h-auto p-2 text-center block_1">
                <h4 className="">{totalData?.rejectedApps || 0}</h4>
                <p className="">Rejected App</p>
              </div>
            </div>
            <div className="p-1">
              <div className="remove-div linear-gradiant-bg  border-12 h-auto p-2 text-center block_1">
                <h4 className="">{totalData?.suspendedApps || 0}</h4>
                <p className="">Suspend App</p>
              </div>
            </div>
          </div>
        </Card.Body>
      </div>
    </>
  );
}

export default TotalBlock;
