import CIcon from "@coreui/icons-react";
import { cilPlus } from "@coreui/icons";
import SearchIcon from "@mui/icons-material/Search";
import Card from "react-bootstrap/Card";
import NoDataImg from "../../images/g8.png";
import getCurrentUser from "../../utils/getCurrentUser.util";
import {
  APIdelete,
  APIlist,
  APIlistPlatform,
} from "../../api-wrapper/ApiPlayConsole";
import { Toast, PermissionCheck } from "../../helper/links/Link";
import moment from "moment";
import Add from "./Add";
import DeleteModal from "../../helper/modal/DeleteModal";
import React, { useState, useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";
import editSvg from "../../icons/svgs/edit-line.svg";
import deleteSvg from "../../icons/svgs/delete-bin-line.svg";
import ReactPaginate from "react-paginate";
import { predefinedRanges } from "../../helper/calendarValues/calendarValues";
import { DateRangePicker } from "rsuite";
import viewLineSvg from "../../icons/svgs/view-line.svg";
import View from "./View";

function PlayConsole() {
  const [date, setDate] = useState([]);
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);

  const [totalRecords, setTotalRecords] = useState(0);
  const [visible, setVisible] = useState(false);
  const [editData, setEditData] = useState();
  const [deleteId, setDeleteId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [activeCard, setActiveCard] = useState(null);
  const [appPlatformData, setAppPlatformData] = useState([]);
  const [cardData, setCardData] = useState();
  const [totalData, setTotalData] = useState(5);
  const [ListcurrentPage, setListCurrentPage] = useState(1);
  const [status, setStatus] = useState("");
  const [viewMode, setviewMode] = useState(false);
  const [viewId, setviewId] = useState("");
  useEffect(() => {
    getData();
  }, [status, date]);

  const deleteHandler = () => {
    APIdelete(deleteId)
      .then((res) => {
        if (res.isSuccess) {
          Toast.success(res.message);
          getData();
        } else {
          Toast.error(res.message);
        }
        setDeleteId();
      })
      .catch((e) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  const getData = (id, index, no) => {

    setActiveCard(index);
    let data = {
      search: search,
      pageNo: no ? no : ListcurrentPage,
      perPage: 5,
      fromDate: date?.length == 0 ? "" : moment(date?.[0]).format("YYYY-MM-DD"),
      toDate: date?.length == 0 ? "" : moment(date?.[1]).format("YYYY-MM-DD"),
      status,
    };
    if (PermissionCheck("Play Console", "View Only"))
      APIlist(data, id)
        .then((res) => {
          if (res.isSuccess) {
            setCardData(res);
            setTotalData(res.totalRecords);
            setListCurrentPage(res.currentPageNo);
          } else {
            Toast.error(res?.message);
          }
        })
        .catch((e) => {
          Toast.error("Something went wrong, please try again!");
        });
  };

  const handlePageChange = (event) => {
    setListCurrentPage(event.selected + 1);
    getData(cardData?.dataPlatform?._id, activeCard, event.selected + 1);
  };


  return (
    <>
      <Card className="rounded-3 p-1 mt-3">
        <Card.Header>
          <div>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="large" />}
              aria-label="breadcrumb"
            >
              <Typography key="1" color="text.primary" className="platform">
                All Apps
              </Typography>
              <Typography
                key="2"
                color="text.primary"
                className=""
                style={{ color: "#A5A5A5" }}
              >
                Play Console
              </Typography>
            </Breadcrumbs>
          </div>
          <div className="grid_console">
            <div>
              {PermissionCheck("Play Console", "Add Play Console") ? (
                <button
                  class="btn btn-primary "
                  onClick={() => {
                    setVisible(true);
                    setEditData("");
                  }}
                >
                  Add Console
                </button>
              ) : null}
            </div>
            <div>
              <DateRangePicker
                className={`rangeDate custom-date-range-picker `}
                ranges={predefinedRanges}
                showOneCalendar
                value={date}
                placeholder="Select Date Range"
                onChange={(e) => setDate(e)}
                placement="bottomEnd"
                format="yyyy-MM-dd"
                cleanable={false}
              />
            </div>
            <div>
              <select
                className="form-select h-100"
                aria-label="Default select example"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="">All Console Status</option>
                <option value="Live">Live Console</option>
                <option value="Suspend">Suspended Console</option>
              </select>
            </div>
            <div>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control search-box"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />

                <button
                  className="btn btn-outline-secondary border search-btn-icon"
                  type="button"
                  onClick={() => getData()}
                >
                  <SearchIcon />
                </button>
              </div>
            </div>
          </div>
        </Card.Header>
        {
          PermissionCheck("Play Console", "View Only") && (
            <Card.Body>
              <div className="app-publish-cosole-div margin-cust1">
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5 gy-3 g-2 gy-3">
                  {cardData?.data?.length > 0 ? (
                    cardData?.data?.map((data, index) => (
                      <div
                        className={`d-block rounded main-div position-relative `}
                      >
                        <div className={`${data.status == "Suspend" ? "suspend_bg" : data.status == "Live" ? "live_bg" : ""} text-center card-box mb-0`}>
                          <div className="member-card pb-2 pt-2">
                            {data.image ? (
                              <div
                                className="thumb-lg member-thumb mx-auto"
                                style={{ backgroundImage: `url(${data.image})` }}
                              ></div>
                            ) : (
                              <div className="thumb-lg member-thumb mx-auto default_image_platform">
                                {" "}
                                {data.pcName.charAt(0)}
                              </div>
                            )}

                            <div className="mt-3">
                              <h5>{data.pcName}</h5>
                            </div>

                            {data.status == 'Suspend' &&
                              <div className="suspend_line">Suspended</div>
                            }

                            <div className="mt-3">
                              <div className="console_bg_btn">
                                <div className=" reviewbtn_bg depend_btn">
                                  <div className="inner-div">
                                    <h4> {data?.countInReview}</h4>
                                    <p className="mb-0 text-muted ">In-Review </p>
                                  </div>
                                </div>
                                <div className="  rejectbtn_bg depend_btn">
                                  <div className="inner-div">
                                    <h4>{data?.countReject}</h4>
                                    <p className="mb-0 text-muted ">Reject</p>
                                  </div>
                                </div>
                                <div className=" livebtn_bg depend_btn">
                                  <div className="inner-div">
                                    <h4>{data?.countLive}</h4>
                                    <p className="mb-0 text-muted ">Live</p>
                                  </div>
                                </div>
                                <div className=" suspendbtn_bg depend_btn" >
                                  <div className="inner-div">
                                    <h4>{data?.countSuspend}</h4>
                                    <p className="mb-0 text-muted ">Suspend</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="over-img d-flex rounded flex-wrap justify-content-center">
                            <div className="d-flex flex-wrap justify-content-center align-items-center gap-4">

                              <img
                                src={viewLineSvg}
                                alt="view svg"
                                className="hover-icon"
                                onClick={() => {
                                  setviewMode(true);
                                  setviewId(data?._id);
                                }}
                              />

                              {PermissionCheck("Play Console", "Edit Play Console") ? (
                                <img
                                  src={editSvg}
                                  alt="edit svg"
                                  className="hover-icon"
                                  onClick={() => {
                                    setVisible(true);
                                    setEditData(data);
                                  }}
                                />
                              ) : null}

                              {PermissionCheck("Play Console", "Remove Play Console") ? (
                                <img
                                  src={deleteSvg}
                                  alt="delete svg"
                                  className="hover-icon"
                                  onClick={() => {
                                    setShowDeleteModal(true);
                                    setDeleteId(data._id);
                                  }}
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="d-flex w-100 justify-content-center no-data-img">
                      <img src={NoDataImg} alt="no-data" className="w-35" />
                    </div>
                  )}
                </div>

                {cardData?.dataPlayConsole?.length != 0 && (
                  <ReactPaginate
                    previousLabel="<<"
                    nextLabel=">>"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    pageCount={totalData / 5}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageChange}
                    containerClassName="pagination platform_pagination"
                    activeClassName="active"
                  />
                )}
              </div>
            </Card.Body>
          )
        }

      </Card>
      <Add
        visible={visible}
        setVisible={setVisible}
        editData={editData}
        getData={getData}
      />

      <View
        viewId={viewId}
        setviewId={setviewId}
        viewMode={viewMode}
        setviewMode={setviewMode}
      />

      {/* delete modal */}

      <DeleteModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        deleteHandler={deleteHandler}
      />
    </>
  );
}

export default PlayConsole;
