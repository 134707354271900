export const mainLogo2 = [
  `
 
<g clip-path="url(#clip0_1_7280)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M38.5709 31.8484C38.4401 31.2096 38.1973 30.5885 37.8451 30.0163C38.1771 29.5523 38.7756 28.9135 38.5793 28.7124L37.4537 27.5513C37.2573 27.3491 36.6017 27.9273 36.1281 28.2473C35.5665 27.8761 34.9514 27.6168 34.316 27.4657C34.2232 26.903 34.1946 26.0274 33.9138 26.0238L32.2968 26C32.016 25.9964 31.9601 26.8708 31.8506 27.43C31.2104 27.5609 30.5905 27.8035 30.017 28.1569C29.553 27.8238 28.9152 27.2266 28.7129 27.4229L27.5516 28.5483C27.3493 28.7446 27.9288 29.4013 28.2477 29.8736C27.8776 30.4351 27.617 31.0489 27.4671 31.683C26.9043 31.7758 26.0274 31.8056 26.0238 32.0863L26 33.7043C25.9953 33.985 26.871 34.041 27.429 34.1492C27.5611 34.7893 27.8027 35.4103 28.1561 35.9825C27.8229 36.4453 27.2256 37.0841 27.4207 37.2864L28.5475 38.4475C28.6249 38.5272 28.7415 38.5141 28.8331 38.4522L29.873 37.7527C30.4346 38.1227 31.0486 38.3832 31.684 38.5331C31.7756 39.0958 31.8054 39.9714 32.0862 39.9762L33.7044 40C33.9852 40.0036 34.0399 39.128 34.1494 38.57C34.7895 38.4391 35.4095 38.1965 35.9818 37.8431C36.4447 38.175 37.0848 38.7734 37.2859 38.5783L38.4472 37.4517C38.6495 37.2566 38.07 36.5987 37.7511 36.1252C38.1224 35.5637 38.3818 34.9499 38.5329 34.3158C39.0957 34.223 39.9714 34.1944 39.9762 33.9137L40 32.2957C40.0059 32.0138 39.1302 31.959 38.5709 31.8484ZM35.4 35.4888C34.0245 36.8141 31.8363 36.7736 30.5108 35.3984C29.1853 34.0231 29.2257 31.8353 30.6012 30.51C31.9767 29.1847 34.1649 29.2252 35.4904 30.5993C36.8147 31.9745 36.7754 34.1635 35.4 35.4888Z" fill="#232526"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M34.3882 34.4399C33.593 35.2064 32.3266 35.1833 31.5601 34.3882C30.7936 33.5919 30.8167 32.3266 31.6118 31.5601C32.407 30.7936 33.6734 30.8167 34.4399 31.6118C35.2064 32.407 35.1833 33.6734 34.3882 34.4399Z" fill="#232526"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M44.7423 16.8285L43.16 16.5197C42.992 15.6981 42.6808 14.8988 42.2264 14.163L43.165 12.8533C43.2484 12.7375 43.2708 12.5894 43.17 12.486L41.7209 10.9933C41.6214 10.89 41.472 10.9086 41.3537 10.9883L40.0179 11.8884C39.2959 11.4116 38.5066 11.0767 37.69 10.8837L37.4285 9.29266C37.4049 9.15197 37.3153 9.03246 37.1721 9.02997L35.0931 9.00009C34.95 8.99635 34.8566 9.11462 34.8304 9.25531L34.5205 10.8377C33.6988 11.007 32.8996 11.3182 32.1639 11.7727L30.8542 10.8327C30.7385 10.7493 30.5891 10.7281 30.487 10.8277L28.9944 12.2756C28.8923 12.3765 28.9085 12.5246 28.9894 12.6441L29.8894 13.9812C29.4139 14.7033 29.079 15.4926 28.8861 16.3093L27.2951 16.5708C27.1544 16.5932 27.0337 16.6828 27.0312 16.8273L27 18.9064C26.9975 19.0495 27.1158 19.1429 27.2565 19.1703L28.8387 19.4791C29.0068 20.3007 29.318 21.1 29.7724 21.8358L28.8338 23.1443C28.7504 23.2613 28.728 23.4094 28.8288 23.5115L30.2779 25.0043C30.3774 25.1076 30.5268 25.0889 30.6451 25.0092L31.9821 24.1104C32.7042 24.586 33.4934 24.9208 34.3101 25.1138L34.5715 26.7049C34.5939 26.8468 34.6848 26.9676 34.828 26.9688L36.9082 27C37.0513 27.0025 37.1447 26.8842 37.1721 26.7435L37.4808 25.1624C38.3025 24.9931 39.1004 24.6818 39.8374 24.2274L41.1458 25.1661C41.2616 25.2495 41.411 25.2707 41.513 25.1723L43.0057 23.7244C43.1078 23.6236 43.0916 23.4742 43.0107 23.3572L42.1106 22.0201C42.5861 21.298 42.921 20.5087 43.114 19.6919L44.705 19.4305C44.8456 19.4068 44.9664 19.3172 44.9689 19.174L45 17.0949C45 16.9493 44.883 16.8559 44.7423 16.8285ZM39.1552 21.2756C37.3451 23.0198 34.4644 22.9675 32.7203 21.1573C30.9762 19.3471 31.0298 16.4662 32.8386 14.722C34.6487 12.9765 37.5294 13.0301 39.2735 14.8403C41.0188 16.6492 40.9665 19.5301 39.1552 21.2756Z" fill="#232526"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M38.0814 20.1608C36.889 21.31 34.989 21.2744 33.8398 20.0819C32.6906 18.8895 32.725 16.9895 33.9174 15.8403C35.1111 14.6898 37.0099 14.7255 38.1604 15.9179C39.3095 17.1116 39.2752 19.0116 38.0814 20.1608Z" fill="#232526"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M27.9366 24.933C28.0438 24.3662 28.0558 23.7782 27.9591 23.1915C29.3299 22.3359 29.1789 22.8233 28.4677 21.046C28.375 20.8155 27.6452 21.0672 27.1525 21.1678C26.8174 20.6765 26.4028 20.258 25.9353 19.9216C26.3008 18.3442 26.5366 18.7998 24.7776 18.0462C24.5498 17.9469 24.2094 18.6449 23.9339 19.0634C23.3671 18.9561 22.779 18.9442 22.1909 19.0408C21.3353 17.6701 21.824 17.8211 20.0466 18.5323C19.8161 18.625 20.0678 19.356 20.1697 19.8487C19.6784 20.1838 19.2598 20.597 18.9234 21.0658C17.3433 20.7003 17.8016 20.4646 17.0453 22.2233C16.9473 22.4511 17.6439 22.7902 18.0638 23.0683C17.9565 23.6338 17.9446 24.2232 18.0413 24.8085C16.6678 25.6654 16.8228 25.1754 17.5327 26.954C17.5684 27.0454 17.6678 27.0732 17.7645 27.0547L18.8492 26.8322C19.183 27.3235 19.5962 27.7421 20.0651 28.0784C19.6996 29.6571 19.4638 29.2002 21.2227 29.9551C21.4519 30.0531 21.7909 29.3565 22.0664 28.9366C22.6333 29.0439 23.2214 29.0558 23.8081 28.9592C24.6637 30.3299 24.175 30.1789 25.9538 29.4677C26.1843 29.375 25.9326 28.644 25.8306 28.1513C26.322 27.8162 26.7405 27.403 27.077 26.9342C28.6531 27.2984 28.1988 27.5368 28.9538 25.7767C29.0518 25.5489 28.3564 25.2112 27.9366 24.933ZM24.1313 26.86C22.5512 27.4851 20.7631 26.7104 20.1393 25.1304C19.5154 23.5504 20.289 21.7638 21.8691 21.1387C23.4479 20.5136 25.2359 21.2883 25.8598 22.8683C26.4849 24.4483 25.7101 26.2362 24.1313 26.86Z" fill="#232526"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M23.7351 25.8593C22.707 26.2655 21.5455 25.7619 21.1408 24.7352C20.7347 23.7085 21.2367 22.5453 22.2649 22.1407C23.2915 21.7345 24.453 22.2381 24.8592 23.2648C25.2653 24.2915 24.7633 25.4532 23.7351 25.8593Z" fill="#232526"/>
<path d="M31.7391 41.8376C31.7391 45.4757 31.7453 49.1458 31.7677 52.7802C31.7715 53.4259 31.5934 53.9314 31.2386 54.2819C30.8875 54.6288 30.3546 54.8194 29.737 54.8194H29.7183C26.4724 54.7997 23.1928 54.7948 19.9469 54.7948C18.1776 54.7948 16.417 54.796 14.6776 54.7985C12.8187 54.7997 10.9586 54.801 9.09963 54.801C7.64412 54.801 7.05022 54.2143 7.05022 52.779V34.5417L7.56817 33.5762V33.5393C11.6321 31.8125 12.0131 26.2471 8.21188 24V28.1092L5.42537 29.6982L2.64011 28.1092V24C-1.16115 26.2483 -0.780157 31.8137 3.28382 33.5393V33.5762H3.30374L3.81672 34.5417C3.81672 40.7393 3.81547 46.9368 3.81672 53.1344C3.81796 55.8575 5.97446 57.9914 8.72611 57.9951C11.6097 57.9988 14.522 58 17.4517 58C21.6402 58 25.8623 57.9963 30.0769 57.9902C30.5575 57.9889 31.0618 57.9151 31.5374 57.7749C33.7051 57.1354 35 55.3003 35 52.8651C35 49.1888 35 45.5126 35 41.8363H31.7391V41.8376Z" fill="#0643F2"/>
<path d="M26.5136 4.22739C26.5248 4.1892 26.5347 4.14981 26.5421 4.11042C26.6795 3.33345 26.7897 3.17711 26.8305 3.14607C26.9036 3.09117 27.3096 3.09595 27.6339 3.09953C27.7341 3.10072 27.8393 3.10191 27.9532 3.10191C28.1166 3.10191 28.28 3.10549 28.4434 3.10908C28.8481 3.11743 29.2677 3.12698 29.6936 3.09237C30.3112 3.04224 30.8299 3.18665 31.195 3.51248C31.5886 3.86337 31.7941 4.41954 31.7891 5.12132C31.783 6.08567 31.7792 7.0548 31.7743 8.02273H35C35 7.01302 35 6.00451 35 4.99481C35 4.71434 34.9926 4.49831 34.9752 4.29542C34.8106 2.36672 33.4565 0.756681 31.5267 0.194541C31.4141 0.162316 31.3027 0.125318 31.1925 0.0883192L30.9227 0H8.00978L7.89838 0.0405791C7.85134 0.0572882 7.80555 0.0751907 7.75851 0.0930932C7.68548 0.121737 7.61245 0.150381 7.53694 0.171864C5.38936 0.787712 4.00055 2.57081 4.00055 4.71553C3.99931 10.5136 4.00055 16.3116 4.00055 22.1085L5.60103 23L7.21635 22.1001V5.07358C7.21635 3.6748 7.80431 3.10311 9.24511 3.10191H9.8343C10.5349 3.10072 11.2367 3.10072 11.9373 3.10311C12.4201 3.1055 12.4287 3.1055 12.5339 3.58051C12.5488 3.64854 12.5624 3.71776 12.576 3.78579C12.6057 3.93498 12.6354 4.09014 12.6812 4.25006C12.935 5.14042 13.6752 5.6954 14.6122 5.69778C17.8268 5.70614 21.1713 5.70733 24.5542 5.69898C25.5284 5.69778 26.2426 5.16071 26.5136 4.22739Z" fill="#0643F2"/>
</g>
<defs>
<clipPath id="clip0_1_7280">
<rect width="45" height="58" fill="white"/>
</clipPath>
</defs>


    `,
];
