import React from "react";
import Card from "react-bootstrap/Card";
import moment from "moment";
import { useState } from "react";
import { DateRangePicker } from "rsuite";
import { predefinedRanges } from "../../helper/calendarValues/calendarValues";
import NoDataImg from "../../images/g8.png";
import DataTable from "react-data-table-component";
import { APIlistAccountTable } from "../../api-wrapper/ApiDashbord";
import Toast from "../../helper/toast/Toast";
import { useEffect } from "react";
import { useNavigate } from "react-router";

function AccountTable() {
  let navigate = useNavigate()
  const [date, setDate] = useState([new Date(moment().startOf("month")), new Date()]);
  const [tableData, setTableData] = useState([]);
  const [arg, setArg] = useState({ pageNo: 1, perPage: 10 });
  const [totalRecords, setTotalRecords] = useState(0);
  const getData = () => {
    let data = {
      fromDate: date.length == 0 ? "" : moment(date?.[0]).format("YYYY-MM-DD"),
      toDate: date.length == 0 ? "" : moment(date?.[1]).format("YYYY-MM-DD"),
      ...arg
    };
    APIlistAccountTable(data)
      .then((e) => {
        if (e?.isSuccess) {
          setTotalRecords(e?.totalRecords);
          setTableData(e?.data);
        } else {
          Toast.error(e?.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  useEffect(() => {
    getData();
  }, [date, arg]);

  const tHeader = [
    {
      name: "Play Console Name",
      selector: "playConsoleName",
    },
    {
      name: "Download/Active User",
      cell: (row) => ` ${row.countDownload} / ${row.countActiveUser}`,
    },
  ];

  const handleRowClick = (row) => {

    navigate(`/play-console`);
  };


  return (
    <Card className="rounded-3 mt-4">
      <Card.Body className="dynamic_card">
        <div className="row">
          <p className="fw-semibold">Top Perfomance Accounts</p>
        </div>
        <div className="row d-flex justify-content-end">
          <div className="col-6 p-1 cust-col-3-date-picker">
            <DateRangePicker
              className={`rangeDate custom-date-range-picker px-lg-2 pb-1`}
              ranges={predefinedRanges}
              showOneCalendar
              value={date}
              placeholder="Select Date Range"
              onChange={(e) => setDate(e)}
              placement="bottomEnd"
              format="yyyy-MM-dd"
              cleanable={false}
              style={{ width: "100%", display: "inline-block" }}
            />
          </div>
        </div>
        <div>
          {tableData?.length === 0 ? (
            <>
              <div className="d-flex w-100 justify-content-center no-data-img">
                <img src={NoDataImg} alt="no-data" className="w-50" />
              </div>
            </>
          ) : (
            <DataTable columns={tHeader} data={tableData}
              className="scroll_table"
              pagination
              paginationServer
              paginationPerPage={10}
              onChangePage={(page) =>
                setArg({ pageNo: page, perPage: arg.perPage })
              }
              onChangeRowsPerPage={(newPerPage, page) =>
                setArg({ pageNo: page, perPage: newPerPage })
              }
              paginationTotalRows={totalRecords}
              fixedHeader
              onRowClicked={handleRowClick}
            />
          )}
        </div>
      </Card.Body>
    </Card>
  );
}

export default AccountTable;
