import { memo } from "react";

const IconOutlineArrowUpIcon = (props) => (
  <svg
    preserveAspectRatio="none"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.5 5L6 1.5M6 1.5L9.5 5M6 1.5V10.5"
      stroke="#04A03E"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const Memo = memo(IconOutlineArrowUpIcon);
export { Memo as IconOutlineArrowUpIcon };
