import React from "react";
import "./layout.css";
import { AppHeader, AppContent, AppSidebar } from "../components/index";
import Loader from "../helper/loader/Loader";
import { useSelector } from "react-redux";
const DefaultLayout = () => {
  const Loading = useSelector((state) => state.data.loader);

  return (
    <div>
      {Loading === true && <Loader />}
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <AppContent />
      </div>
    </div>
  );
};

export default DefaultLayout;
