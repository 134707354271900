import React, { useState } from "react";
import { Accordion, Form, FormLabel, Tab, Tabs } from "react-bootstrap";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import {
  APIsequenceMonitize,
  APIsaveMonitize,
} from "../../api-wrapper/ApiAppList";
import { FormControlLabel } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Card, Toast, CFormLabel, CFormInput } from "../../helper/links/Link";
function TabSecond({ viewMode, getData, allData, setviewMode }) {
  let { id } = useParams();
  const [allDataDummy, setallDataDummy] = useState([]);
  const [showAdInApp, setShowAdInApp] = useState(false);
  const [showTestAdInDebugApp, setShowTestAdInDebugApp] = useState(false);
  const [splashAd, setsplashAd] = useState(false);
  const [splashAdType, setsplashAdType] = useState("AppOpen");
  const [backPressAd, setBackPressAd] = useState(false);
  const [backPressType, setBackPressType] = useState("AppOpen");
  const [adApply, setadApply] = useState("allAdFormat");
  const [app, setAPP] = useState([]);
  const [visitedTabIds, setVisitedTabIds] = useState([]);
  const [howToShowAd, sethowToShowAd] = useState("fixSequence");
  const [adPlatformSequenceIds, setadPlatformSequenceIds] = useState([]);
  const [
    howToShowAdInterstitialAndOthers,
    sethowToShowAdInterstitialAndOthers,
  ] = useState("fixSequence");
  const [
    adPlatformSequenceForInterstitialAndOtherIds,
    setadPlatformSequenceForInterstitialAndOtherIds,
  ] = useState([]);
  const [howToShowAdNative, sethowToShowAdNative] = useState("fixSequence");
  const [adPlatformSequenceForNativeIds, setadPlatformSequenceForNativeIds] =
    useState([]);
  const [howToShowAdBannerNativeBanner, sethowToShowAdBannerNativeBanner] =
    useState("fixSequence");
  const [
    adPlatformSequenceForBannerNativeBannerIds,
    setadPlatformSequenceForBannerNativeBannerIds,
  ] = useState([]);
  const [mainPageAdClickCount, setmainPageAdClickCount] = useState(0);
  const [innerPageAdClickCount, setinnerPageAdClickCount] = useState(0);
  const [adsOnEveryclick, setadsOnEveryclick] = useState(0);
  const [loadAdIds, setloadAdIds] = useState("Random");

  useEffect(() => {
    setallDataDummy(allData?.monitizes);
  }, [allData]);

  const handleTabChange = (tabId) => {
    if (!visitedTabIds.includes(tabId)) {
      setVisitedTabIds((prevVisitedTabIds) => [...prevVisitedTabIds, tabId]);
    }
  };

  useEffect(() => {
    setShowAdInApp(allData?.showAdInApp);
    setShowTestAdInDebugApp(allData?.showTestAdInDebugApp);
    setsplashAd(allData?.splashAd);
    setBackPressAd(allData?.backPressAd);
    setadApply(allData?.adApply);
    sethowToShowAd(allData?.howToShowAd);
    setadPlatformSequenceIds(allData?.adPlatformSequenceIds);
    setmainPageAdClickCount(allData?.mainPageAdClickCount);
    setinnerPageAdClickCount(allData?.innerPageAdClickCount);
    setadsOnEveryclick(allData?.adsOnEveryclick);
    sethowToShowAdInterstitialAndOthers(
      allData?.howToShowAdInterstitialAndOthers
    );
    setadPlatformSequenceForInterstitialAndOtherIds(
      allData?.adPlatformSequenceForInterstitialAndOtherIds
    );
    sethowToShowAdNative(allData?.howToShowAdNative);
    setadPlatformSequenceForNativeIds(allData?.adPlatformSequenceForNativeIds);
    sethowToShowAdBannerNativeBanner(allData?.howToShowAdBannerNativeBanner);
    setadPlatformSequenceForBannerNativeBannerIds(
      allData?.adPlatformSequenceForBannerNativeBannerIds
    );
    setloadAdIds(allData?.loadAdIds);
    setsplashAdType(allData?.splashAdType)
    setBackPressType(allData?.backPressAdType)
   
  }, [allData]);

  useEffect(() => {
    if (viewMode) {
      let data = [];
      APIsequenceMonitize()
        .then((res) => {
          if (res.isSuccess) {
            res.data.map((el) => {
              data.push({
                label: el.name,
                value: el._id,
              });
            });
            setAPP(data);
          } else {
            Toast.error(res.message);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
    }
  }, [viewMode]);

  const HandleSave = () => {
    let data = {
      showAdInApp: showAdInApp,
      showTestAdInDebugApp: showTestAdInDebugApp,
      splashAd: splashAd,
      splashAdType: splashAdType,
      backPressAd: backPressAd,
      backPressAdType: backPressType,
      adApply: adApply,
      loadAdIds: loadAdIds,
      monitizes: allDataDummy,
      mainPageAdClickCount: Number(mainPageAdClickCount),
      innerPageAdClickCount: Number(innerPageAdClickCount),
      adsOnEveryclick: Number(adsOnEveryclick),
    };

    if (adApply == "allAdFormat") {
      data = {
        ...data,
        howToShowAd: howToShowAd,
        adPlatformSequenceIds: adPlatformSequenceIds,
      };
    } else {
      data = {
        ...data,
        howToShowAdInterstitialAndOthers: howToShowAdInterstitialAndOthers,
        adPlatformSequenceForInterstitialAndOtherIds:
          adPlatformSequenceForInterstitialAndOtherIds,
        howToShowAdNative: howToShowAdNative,
        adPlatformSequenceForNativeIds: adPlatformSequenceForNativeIds,
        howToShowAdBannerNativeBanner: howToShowAdBannerNativeBanner,
        adPlatformSequenceForBannerNativeBannerIds:
          adPlatformSequenceForBannerNativeBannerIds,
      };
    }
    APIsaveMonitize(data, id ? id : allData?._id)
      .then((res) => {
        if (res.isSuccess) {
          Toast.success(res.message);
          getData();
          setviewMode(false);
        } else {
          Toast.error(res.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };
  const handleInputChange = (e, index, subindex) => {
    const { name, value } = e.target;
    const updatedData = [...allDataDummy];
    updatedData[index].monitizeFormatIds[subindex].value = value;

    setallDataDummy(updatedData);
  };

  return (
    <>
      <div className="row">
        <div className=" h-100 show-ad-in-app-div ">
          <div className="row mt-2 mb-2">
            <div className="col-6 d-flex flxe-wrap gap-4 align-items-center">
              <CFormLabel className="mb-0 fw-semibold">
                Show Ad in a app?
              </CFormLabel>
              <div className="d-flex flex-wrap align-items-center">
                <div className="d-flex flex-wrap align-items-center">
                  <label class="switch">
                    <input
                      type="checkbox"
                      checked={showAdInApp}
                      onChange={(e) => setShowAdInApp(e.target.checked)}
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="col-6 d-flex flxe-wrap gap-4">
              <CFormLabel className="fw-semibold">
                Show Test Ad in Debug App?
              </CFormLabel>
              <div className="d-flex flex-wrap align-items-center">
                <div className="d-flex flex-wrap align-items-center">
                  <label class="switch">
                    <input
                      type="checkbox"
                      checked={showTestAdInDebugApp}
                      onChange={(e) =>
                        setShowTestAdInDebugApp(e.target.checked)
                      }
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6 cust-main-splash-ad-col-4">
          <div className=" h-100 main-splash-radio-div">
            <div className="d-flex flex-wrap gap-3 align-items-center pb-2">
              <CFormLabel className="fs-6 cust-fs-5 fw-semibold mb-0">
                Splash Ad?
              </CFormLabel>

              <div className="d-flex flex-wrap align-items-center">
                <label class="switch">
                  <input
                    type="checkbox"
                    checked={splashAd}
                    onChange={(e) => setsplashAd(e.target.checked)}
                  />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>

            <div className="aplash-ad-radios-div">
              <div>
                <CFormLabel className="me-2 fs-6 cust-fs-5 fw-semibold">
                  Ad Format:
                </CFormLabel>
                <Form.Check
                  inline
                  label="AppOpen"
                  name="splashAdType"
                  type="radio"
                  value="AppOpen"
                  checked={splashAdType === "AppOpen"}
                  onChange={(e) => {
                    setsplashAdType("AppOpen");
                  }}
                  id={`AppOpen`}
                />
                <Form.Check
                  inline
                  label="Interstitial"
                  name="splashAdType"
                  type="radio"
                  value="Interstitial"
                  checked={splashAdType === "Interstitial"}
                  onChange={(e) => {
                    setsplashAdType("Interstitial");
                  }}
                  id={`Interstitial`}
                />
                <Form.Check
                  inline
                  label="None"
                  name="splashAdType"
                  type="radio"
                  value="None"
                  checked={splashAdType === "None"}
                  onChange={(e) => {
                    setsplashAdType("None");
                  }}
                  id={`None`}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 cust-main-backpress-ad-col-4">
          <div className=" h-100 main-backpress-ad-radio-div">
            <div className="d-flex flex-wrap gap-4 align-items-center pb-2">
              <CFormLabel className="fs-6 cust-fs-5 fw-semibold mb-0">
                BackPress Ad?
              </CFormLabel>
              <div className="d-flex flex-wrap align-items-center">
                <label class="switch">
                  <input
                    type="checkbox"
                    checked={backPressAd}
                    onChange={(e) => setBackPressAd(e.target.checked)}
                  />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>

            <div className="aplash-ad-radios-div">
              <div>
                <CFormLabel className="me-2 fs-6 cust-fs-5 fw-semibold">
                  Ad Format:
                </CFormLabel>
                <Form.Check
                  inline
                  label="AppOpen"
                  name="backPressAdType"
                  type="radio"
                  value="AppOpen"
                  id={`splash-AppOpen`}
                  checked={backPressType === "AppOpen"}
                  onChange={(e) => setBackPressType("AppOpen")}
                />
                <Form.Check
                  inline
                  label="Alternate"
                  name="backPressAdType"
                  type="radio"
                  value="Alternate"
                  id={`splash-Alternate`}
                  onChange={(e) => setBackPressType("Alternate")}
                  checked={backPressType === "Alternate"}
                />
                <Form.Check
                  inline
                  label="Interstitial"
                  name="backPressAdType"
                  type="radio"
                  value="Interstitial"
                  id={`splash-Interstitial`}
                  checked={backPressType === "Interstitial"}
                  onChange={(e) => setBackPressType("Interstitial")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3 p-3">
        <Card className="rounded-1 p-1">
          <Card.Body>
            <div className="apply-main-div">
              <div className="d-flex flex-wrap gap-4">
                <div className="apply-tex">
                  <p className="fs-6 fw-semibold">Apply?</p>
                </div>
                <div>
                  <Form.Check
                    inline
                    label="All Ad Format"
                    name="adApply"
                    type="radio"
                    value="allAdFormat"
                    className="fs-6"
                    checked={adApply === "allAdFormat"}
                    id={`apply-ad`}
                    onChange={(e) => setadApply("allAdFormat")}
                  />
                  <Form.Check
                    inline
                    className="fs-6"
                    label="Ad Format Wise"
                    name="adApply"
                    type="radio"
                    value="adFormatWise"
                    id={`apply-format`}
                    checked={adApply === "adFormatWise"}
                    onChange={(e) => setadApply("adFormatWise")}
                  />
                </div>
              </div>

              {adApply === "allAdFormat" ? (
                <div className="mt-4">
                  <div className="d-flex flex-wrap gap-4">
                    <div className="apply-tex">
                      <p className="fs-6 fw-semibold">How to show Ad?</p>
                    </div>
                    <div>
                      <Form.Check
                        inline
                        label="Fix Sequence"
                        name="howToShowAd"
                        type="radio"
                        value="fixSequence"
                        className="fs-6"
                        checked={howToShowAd === "fixSequence"}
                        onChange={(e) => sethowToShowAd("fixSequence")}
                        id={`ad-fixSequence`}
                      />
                      <Form.Check
                        inline
                        className="fs-6"
                        label="Alternate Ad Show"
                        name="howToShowAd"
                        type="radio"
                        value="alternateAdShow"
                        checked={howToShowAd === "alternateAdShow"}
                        onChange={(e) => sethowToShowAd("alternateAdShow")}
                        id={`ad-alternateAdShow`}
                      />
                    </div>
                  </div>

                  <div className="mt-4 mb-2 ad-placement-sequence-div">
                    <CFormLabel className="fs-6 cust-fs-5 fw-semibold">
                      Select Ad Platform Sequence:
                    </CFormLabel>

                    <Select
                      classNamePrefix="form_dropdown"
                      placeholder="Select Permision"
                      options={app}
                      name="adPlatformSequenceIds"
                      isMulti
                      getOptionLabel={(option) => option.label}
                      value={adPlatformSequenceIds?.map((value) => ({
                        value,
                        label: app.find((option) => option.value === value)
                          ?.label,
                      }))}
                      onChange={(e) => {
                        setadPlatformSequenceIds(e.map((x) => x.value));
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className="row mt-4">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Interstitial & Other</Accordion.Header>
                      <Accordion.Body>
                        <div className="main-div mt-3">
                          <div className="d-flex flex-wrap gap-4">
                            <div className="apply-tex">
                              <p className="fs-6 fw-semibold">
                                How to show Ad?
                              </p>
                            </div>
                            <div>
                              <Form.Check
                                inline
                                label="Fix Sequence"
                                name="howToShowAdInterstitialAndOthers"
                                type="radio"
                                value="fixSequece"
                                className="fs-6"
                                checked={
                                  howToShowAdInterstitialAndOthers ===
                                  "fixSequence"
                                }
                                onChange={(e) =>
                                  sethowToShowAdInterstitialAndOthers(
                                    "fixSequence"
                                  )
                                }
                                id={`Other-fixSequece`}
                              />
                              <Form.Check
                                inline
                                className="fs-6"
                                label="Alternate Ad Show"
                                name="howToShowAdInterstitialAndOthers"
                                type="radio"
                                value="alternateAdShow"
                                checked={
                                  howToShowAdInterstitialAndOthers ===
                                  "alternateAdShow"
                                }
                                onChange={(e) =>
                                  sethowToShowAdInterstitialAndOthers(
                                    "alternateAdShow"
                                  )
                                }
                                id={`Other-alternateAdShow1`}
                              />
                            </div>
                          </div>
                          <div className="mb-3">
                            <div>
                              <CFormLabel>
                                {" "}
                                <b>Select Ad Platform Sequence </b>
                              </CFormLabel>
                              <br />
                              <Select
                                classNamePrefix="form_dropdown"
                                placeholder="Select Permision"
                                options={app}
                                name="adPlatformSequenceForInterstitialAndOtherIds"
                                isMulti
                                value={adPlatformSequenceForInterstitialAndOtherIds?.map(
                                  (value) => ({
                                    value,
                                    label: app.find(
                                      (option) => option.value === value
                                    )?.label,
                                  })
                                )}
                                onChange={(e) => {
                                  setadPlatformSequenceForInterstitialAndOtherIds(
                                    e.map((x) => x.value)
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Native</Accordion.Header>
                      <Accordion.Body>
                        <div className="main-div mt-3">
                          <div className="d-flex flex-wrap gap-4">
                            <div className="apply-tex">
                              <p className="fs-6 fw-semibold">
                                How to show Ad?
                              </p>
                            </div>
                            <div>
                              <Form.Check
                                inline
                                label="Fix Sequence"
                                name="howToShowAdNative"
                                type="radio"
                                value="fixSequece"
                                className="fs-6"
                                checked={howToShowAdNative === "fixSequence"}
                                onChange={(e) =>
                                  sethowToShowAdNative("fixSequence")
                                }
                                id={`Native-fixSequece`}
                              />
                              <Form.Check
                                inline
                                className="fs-6"
                                label="Alternate Ad Show"
                                name="howToShowAdNative"
                                type="radio"
                                value="alternateAdShow"
                                checked={
                                  howToShowAdNative === "alternateAdShow"
                                }
                                onChange={(e) =>
                                  sethowToShowAdNative("alternateAdShow")
                                }
                                id={`Native-alternateAdShow1`}
                              />
                            </div>
                          </div>
                          <div className="mb-3">
                            <div>
                              <CFormLabel>
                                {" "}
                                <b>Select Ad Platform Sequence </b>
                              </CFormLabel>
                              <br />
                              <Select
                                classNamePrefix="form_dropdown"
                                placeholder="Select Permision"
                                options={app}
                                name="adPlatformSequenceForNativeIds"
                                isMulti
                                value={adPlatformSequenceForNativeIds?.map(
                                  (value) => ({
                                    value,
                                    label: app.find(
                                      (option) => option.value === value
                                    )?.label,
                                  })
                                )}
                                onChange={(e) => {
                                  setadPlatformSequenceForNativeIds(
                                    e.map((x) => x.value)
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Banner,Native Banner</Accordion.Header>
                      <Accordion.Body>
                        <div className="main-div mt-3">
                          <div className="d-flex flex-wrap gap-4">
                            <div className="apply-tex">
                              <p className="fs-6 fw-semibold">
                                How to show Ad?
                              </p>
                            </div>
                            <div>
                              <Form.Check
                                inline
                                label="Fix Sequence"
                                name="howToShowAdBannerNativeBanner"
                                type="radio"
                                value="fixSequece"
                                className="fs-6"
                                checked={
                                  howToShowAdBannerNativeBanner ===
                                  "fixSequence"
                                }
                                onChange={(e) =>
                                  sethowToShowAdBannerNativeBanner(
                                    "fixSequence"
                                  )
                                }
                                id={`Banner-fixSequece`}
                              />
                              <Form.Check
                                inline
                                className="fs-6"
                                label="Alternate Ad Show"
                                name="howToShowAdBannerNativeBanner"
                                type="radio"
                                value="alternateAdShow"
                                checked={
                                  howToShowAdBannerNativeBanner ===
                                  "alternateAdShow"
                                }
                                onChange={(e) =>
                                  sethowToShowAdBannerNativeBanner(
                                    "alternateAdShow"
                                  )
                                }
                                id={`Banner-alternateAdShow1`}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <div>
                            <CFormLabel>
                              {" "}
                              <b>Select Ad Platform Sequence </b>
                            </CFormLabel>
                            <br />
                            <Select
                              classNamePrefix="form_dropdown"
                              placeholder="Select Permision"
                              options={app}
                              name="adPlatformSequenceForBannerNativeBannerIds"
                              isMulti
                              value={adPlatformSequenceForBannerNativeBannerIds?.map(
                                (value) => ({
                                  value,
                                  label: app.find(
                                    (option) => option.value === value
                                  )?.label,
                                })
                              )}
                              onChange={(e) => {
                                setadPlatformSequenceForBannerNativeBannerIds(
                                  e.map((x) => x.value)
                                );
                              }}
                            />
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              )}
            </div>
          </Card.Body>
        </Card>
      </div>

      <div className="row mt-4 ad-click-count-row">
        <div className="col-4 cust-ad-count-col-4">
          <div className="main-home-page-ad-count">
            <div className="main-home-apge-ad-click-count">
              <CFormLabel className="fs-6 cust-fs-5 fw-semibold">
                Ad Click Count (Main Home Page):
              </CFormLabel>

              <input
                type="number"
                name="mainPageAdClickCount"
                value={mainPageAdClickCount}
                onChange={(e) => setmainPageAdClickCount(e.target.value)}
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="col-4 cust-ad-count-col-4">
          <div className="inner-page-ad-count">
            <div className="inner-page-ad-count-div">
              <CFormLabel className="fs-6 cust-fs-5 fw-semibold">
                Ad Click Count (Inner Page):
              </CFormLabel>

              <input
                type="number"
                name="innerPageAdClickCount"
                className="form-control"
                value={innerPageAdClickCount}
                onChange={(e) => setinnerPageAdClickCount(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="col-4 cust-ad-count-col-4">
          <div className="ads-on-every-page-ad-count">
            <div className="ads-on-every-div">
              <CFormLabel className="fs-6 cust-fs-5 fw-semibold">
                Back Press Ad Limit (ads on every [ X ] click):
              </CFormLabel>

              <input
                type="number"
                name="adsOnEveryclick"
                className="form-control"
                value={adsOnEveryclick}
                onChange={(e) => setadsOnEveryclick(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row p-3">
        <Tabs className="" onSelect={(tabId) => handleTabChange(tabId)}>

          {allDataDummy
            ?.filter((data) => data.status)
            .map((data, index) => (
              <Tab
                eventKey={data?._id}
                key={data?._id}
                title={data?.name}
                className="tab-bg-color"
              >
                <div className="row mt-2">
                  <Card className="rounded-3 p-1">
                    <Card.Body>
                      <div className="row ">
                        <div className="load-ad-ids-main-div">
                          <div className="">
                            <div className="apply-tex">
                              <FormLabel id="demo-row-radio-buttons-group-label">
                                Load Ad Ids?
                              </FormLabel>
                            </div>
                            <div>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="loadAdIds"
                              >
                                <FormControlLabel
                                  value="random"
                                  control={<Radio />}
                                  label="Random"
                                  checked={loadAdIds === "Random"}
                                  onChange={(e) => setloadAdIds("Random")}
                                />
                                <FormControlLabel
                                  value="fix"
                                  control={<Radio />}
                                  label="Fix"
                                  checked={loadAdIds === "Fix"}
                                  onChange={(e) => setloadAdIds("Fix")}
                                />
                              </RadioGroup>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="main-inputs-div">
                          <div className="inputs-div">
                            {data?.monitizeFormatIds?.map((format, subindex) => (
                              <div className="input-res-div">
                                <CFormLabel className="fs-6 cust-fs-5 ">
                                  {format?.name}
                                </CFormLabel>
                                <CFormInput
                                  type="text"
                                  name={format?.name}
                                  value={format?.value}
                                  aria-label="default input example"
                                  onChange={(e) =>
                                    handleInputChange(e, index, subindex)
                                  }
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </Tab>
            ))}
        </Tabs>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="d-flex flex-wrap justify-content-center w-100">
            <button
              className="btn  bg-primary"
              onClick={HandleSave}
              style={{ width: "200px", color: "white" }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default TabSecond;
