import React from "react";
import mainLogo from "../../images/mainlogo.png";
import { Tab, Tabs } from "react-bootstrap";
import { CModal, CModalHeader, CModalBody, CModalTitle } from "@coreui/react";
import { useParams } from "react-router-dom";
import TabFirst from "./TabFirst";
import TabSecond from "./TabSecond";
function ViewMonetizeSetting({
  allData,
  setviewMode,
  viewMode,
  getData,
  flag,
}) {
  return (
    <>
      <CModal
        visible={viewMode}
        alignment="center"
        scrollable
        keyboard={false}
        backdrop="static"
        onClose={() => {
          setviewMode(false);
        }}
        size="xl"
      >
        <CModalHeader>
          <CModalTitle>
           
                <p className="h4 pt-2 fw-bold mt-auto cust-h4">
                  Add Publish Platform
                </p>
              
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <Tabs
            defaultActiveKey={
              flag == "application" ? "Application Details" : "Monitize Details"
            }
            id="justify-tab-example"
            justify
          >
            <Tab
              eventKey="Application Details"
              title="Application Details"
              className="tab-bg-color "
            >
              <TabFirst
                setviewMode={setviewMode}
                viewMode={viewMode}
                getData={getData}
                allData={allData}
              />
            </Tab>
            <Tab
              eventKey="Monitize Details"
              title="Monitize Details"
              className="tab-bg-color"
            >
              <TabSecond
                setviewMode={setviewMode}
                viewMode={viewMode}
                getData={getData}
                allData={allData}
              />
            </Tab>
          </Tabs>
        </CModalBody>
      </CModal>
    </>
  );
}

export default ViewMonetizeSetting;
