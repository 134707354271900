import moment from "moment";
import React from "react";
import { useState } from "react";
import { DateRangePicker } from "rsuite";
import Card from "react-bootstrap/Card";
import { predefinedRanges } from "../../helper/calendarValues/calendarValues";
import {
  APIlistApplication,
  APIlistChart,
  APIlistMonthlyConsole,
  APIlistMonthlyLive,
} from "../../api-wrapper/ApiDashbord";
import { useEffect } from "react";
import Toast from "../../helper/toast/Toast";
import ReactApexChart from "react-apexcharts";
import "apexcharts/dist/apexcharts.css";
function Chart() {
  const [date, setDate] = useState([new Date(moment().startOf("month")), new Date()]);
  const [applicationData, setApplicationData] = useState([]);
  const [appName, setAppName] = useState("");
  const [chartdata, setChartData] = useState([]);
  const [console, setConsole] = useState(0);
  const [live, setLive] = useState(0);

  const chartOptions = {
    chart: {
      id: "line-chart",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: chartdata?.map((x) => x.currentDate) || [],
    },
    yaxis: {
      title: {
        text: "Daily Downloads",
      },
    },
  };

  useEffect(() => {
    let data = [];
    APIlistApplication()
      .then((res) => {
        if (res.isSuccess) {
          res.data.map((el) => {
            data.push({
              label: el.appName,
              value: el._id,
            });
          });
          setApplicationData(data);
        } else {
          Toast.error(res.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  }, []);

  useEffect(() => {
    let data = {
      fromDate: date.length == 0 ? "" : moment(date?.[0]).format("YYYY-MM-DD"),
      toDate: date.length == 0 ? "" : moment(date?.[1]).format("YYYY-MM-DD"),
      appId: appName,
    };
    APIlistChart(data)
      .then((res) => {
        if (res.isSuccess) {
          setChartData(res.data);
        } else {
          Toast.error(res.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  }, [appName, date]);

  useEffect(() => {
    APIlistMonthlyConsole()
      .then((res) => {
        if (res.isSuccess) {
          setConsole(res.data.monthlyNewConsole);
        } else {
          Toast.error(res.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  }, []);
  useEffect(() => {
    APIlistMonthlyLive()
      .then((res) => {
        if (res.isSuccess) {
          setLive(res.data.monthlyNewLiveApp);
        } else {
          Toast.error(res.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  }, []);
  return (
    <>
      <Card className="rounded-3 mt-3">
        <div className="align-items-center flex-wrap  p-3 ">
          <div className="row">
            <div className="col-6 cust-col-3-download-chart">
              <p className="h6 p-2 w-100  fw-semibold">
                Date Wise Download Chart
              </p>
            </div>
            <div className="col-3 cust-col-3-download-chart">
              <DateRangePicker
                className={`rangeDate custom-date-range-picker px-lg-2 pb-1`}
                ranges={predefinedRanges}
                showOneCalendar
                value={date}
                placeholder="Select Date Range"
                onChange={(e) => setDate(e)}
                placement="bottomTop"
                format="yyyy-MM-dd"
                cleanable={false}
                style={{ width: "100%", display: "inline-block" }}
              />
            </div>
            <div className="col-3 cust-col-3-download-chart">
              <div className="select-div">
                <select
                  className="form-select w-100"
                  aria-label="Default select example"
                  onChange={(e) => setAppName(e.target.value)}
                  value={appName}
                >
                  <option value="" selected>
                    All Application
                  </option>
                  {applicationData?.map((e) => (
                    <option value={e.value}>{e.label}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <Card.Body>
          <ReactApexChart
            options={chartOptions}
            series={[
              {
                name: "Daily Downloads",
                data: chartdata?.map((x) => x.download) || [],
              },
            ]}
            type="line"
            height={395}
          />
        </Card.Body>
      </Card>
      <div className="row">
        <div className="col-6 cust-col-6-monthly">
          <Card className="rounded-3 mt-2">
            <div className="p-3">
              <div className="row  align-items-center">
                <div className="col-3">
                  <p className="text-center fs-5 mb-0 text-primary fw-bolder cust-fs-4-15">
                    {console}
                  </p>
                </div>
                <div className="col-9">
                  <p className="text-center fs-5 fs-md-5 mb-0 fw-semibold cust-fs-4-15">
                    Monthly New Console
                  </p>
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="col-6 cust-col-6-monthly">
          <Card className="rounded-3 mt-2">
            <div className="p-3">
              <div className="row  align-items-center">
                <div className="col-2 cust-col-2">
                  <p className="text-center fs-5 mb-0 text-success fw-bolder cust-fs-4-15">
                    {live}
                  </p>
                </div>
                <div className="col-10 cust-col-10">
                  <p className="text-center fs-5 fs-md-5 mb-0 fw-semibold cust-fs-4-15">
                    Monthly New App Live
                  </p>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}

export default Chart;
