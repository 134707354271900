import React, { useState } from "react";
import { Formik } from "formik";
import { APIReference } from "../../api-wrapper/ApiAppList";
import {
  APIdeveloper,
  APIcategories,
  APIplayConsole,
} from "../../api-wrapper/ApiApprovalList";
import { CFormTextarea } from "@coreui/react";
import { useEffect } from "react";
import { APIsaveStatus } from "../../api-wrapper/ApiAppList";
import { useParams } from "react-router-dom";
import {
  Toast,
  CFormSelect,
  CFormLabel,
  CFormInput,
} from "../../helper/links/Link";
import { TabFirstSchema } from "../../utility/Validator";
function TabFirst({ viewMode, getData, allData, setviewMode }) {
  let { id } = useParams();
  const [selectedImage, setSelectedImage] = useState(null);
  const [changeImg, setchangeImg] = useState(null);
  const [reference, setReference] = useState([]);
  const [categories, setCategories] = useState([]);
  const [developer, setDeveloper] = useState([]);
  const [playConsole, setPlayConsole] = useState([]);
  const [dialog, setDialog] = useState({
    version: false,
    dialog: false,
  });

  useEffect(() => {
    if (viewMode) {
      let data = [];
      APIReference()
        .then((res) => {
          if (res.isSuccess) {
            res.data.map((el) => {
              data.push({
                label: el.name,
                value: el._id,
              });
            });
            setReference(data);
          } else {
            setReference([]);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
    }
  }, [viewMode]);
  useEffect(() => {
    if (viewMode) {
      let data = [];
      APIdeveloper()
        .then((res) => {
          if (res.isSuccess) {
            res.data.map((el) => {
              data.push({
                label: el.name,
                value: el._id,
              });
            });
            setDeveloper(data);
          } else {
            setDeveloper([]);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
    }
  }, [viewMode]);
  useEffect(() => {
    if (viewMode) {
      let data = [];
      APIcategories()
        .then((res) => {
          if (res.isSuccess) {
            res.data.map((el) => {
              data.push({
                label: el.name,
                value: el._id,
              });
            });
            setCategories(data);
          } else {
            setCategories([]);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
    }
  }, [viewMode]);
  useEffect(() => {
    if (viewMode) {
      let data = [];
      APIplayConsole()
        .then((res) => {
          if (res.isSuccess) {
            res.data.map((el) => {
              data.push({
                label: el.pcName,
                value: el._id,
              });
            });
            setPlayConsole(data);
          } else {
            setPlayConsole([]);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
    }
  }, [viewMode]);

  useEffect(() => {
    setDialog({
      version: allData?.versionUpdateDialog,
      dialog: allData?.redirectToOtherApp,
    });
  }, [allData]);

  const handleUpdateMonitizeSettingsForAppDetails = (values) => {
    const formData = new FormData();
    if (changeImg) {
      formData.append("appLogo", changeImg);
    }

    formData.append("categoryId", values.categoryId);
    formData.append("developerId", values.developerId);
    formData.append("referenceId", values.referenceId);
    formData.append("appName", values.appName);
    formData.append("packageName", values.packageName);
    formData.append("apiKeyText", values.apiKeyText);
    formData.append("playConsoleId", values.playConsoleId);
    formData.append("versionUpdateDialog", dialog.version);
    formData.append("redirectToOtherApp", dialog.dialog);
    formData.append("appAccountLink", values.appAccountLink);
    formData.append("appExtraData", values.appExtraData);
    formData.append("versionUpdateDialogText", values.versionUpdateDialogText);
    formData.append("redirectToOtherAppText", values.redirectToOtherAppText);
    formData.append("appPrivacyPolicyLink", values.appPrivacyPolicyLink);
    formData.append("appNote", values.appNote);

    APIsaveStatus(formData, id ? id : allData?._id)
      .then((e) => {
        if (e?.isSuccess) {
          setviewMode(false);
          Toast.success(e?.message);
          setSelectedImage(null);
          setchangeImg(null);
          getData();
        } else {
          Toast.error(e?.message);
        }
      })
      .catch((e) => {
        Toast.error("Something Went Wrong!!");
      });
  };
  return (
    <Formik
      validationSchema={TabFirstSchema}
      initialValues={{
        appLogo: allData?.appLogo,
        referenceId: allData?.referenceId?._id,
        developerId: allData?.developerId?._id,
        categoryId: allData?.categoryId?._id,
        appName: allData?.appName,
        packageName: allData?.packageName,
        apiKeyText: allData?.apiKeyText,
        appNote: allData?.appNote,
        playConsoleId: allData?.playConsoleId?._id,
        versionUpdateDialog: dialog.version,
        redirectToOtherApp: dialog.dialog,
        appAccountLink: allData?.appAccountLink,
        appExtraData: allData?.appExtraData,
        versionUpdateDialogText: allData?.versionUpdateDialogText,
        redirectToOtherAppText: allData?.redirectToOtherAppText,
        appPrivacyPolicyLink: allData?.appPrivacyPolicyLink,
      }}
      onSubmit={(values) => {
        handleUpdateMonitizeSettingsForAppDetails(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <>
          <div className="row">
            <div className="app-bg-img d-flex flex-wrap justify-content-center mt-2">
              {values?.appLogo ? (
                <div
                  className="app-bg d-flex flex-wrap rounded-3"
                  style={{
                    backgroundImage: `url(${values?.appLogo})`,
                    width: "90px",
                    height: "90px",
                  }}
                ></div>
              ) : (
                <div
                  className="default_image tab_image rounded-3"
                  style={{ width: "90px", height: "90px", fontSize: "25px" }}
                >
                  {" "}
                  {values?.appName?.charAt(0)}
                </div>
              )}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-6 app-details-sec-1">
              <div className="upload-file mb-3">
                <CFormLabel>Upload File</CFormLabel>
                <input
                  type="file"
                  className="form-control-file d-block"
                  accept="image/*"
                  onChange={(e) => {
                    const imageFile = e.target.files[0];
                    setSelectedImage(null);
                    setchangeImg(imageFile);
                    setFieldValue("appLogo", imageFile);
                  }}
                />
              </div>
              <div className="reference-by mb-3">
                <CFormLabel>References By</CFormLabel>
                <CFormSelect
                  aria-label="Default select example"
                  name="referenceId"
                  value={values.referenceId}
                  onChange={handleChange}
                >
                  <option key="reference" selected disabled>
                    Select Reference
                  </option>
                  {reference?.map((data, index) => (
                    <option key={data.value} value={data.value}>
                      {data.label}
                    </option>
                  ))}
                </CFormSelect>
              </div>
              <div className="select-dev mb-3">
                <CFormLabel>Select Developer</CFormLabel>
                <CFormSelect
                  aria-label="Default select example"
                  name="developerId"
                  value={values?.developerId}
                  onChange={handleChange}
                >
                  <option key="developer" selected disabled>
                    Select Developer
                  </option>
                  {developer?.map((data, index) => (
                    <option key={data.value} value={data.value}>
                      {data.label}
                    </option>
                  ))}
                </CFormSelect>
              </div>
              <div className="app-category mb-3">
                <CFormLabel>Application Category</CFormLabel>
                <CFormSelect
                  aria-label="Default select example"
                  name="categoryId"
                  value={values?.categoryId}
                  onChange={handleChange}
                >
                  <option key="categoryId" selected disabled>
                    Select Category
                  </option>
                  {categories?.map((data, index) => (
                    <option key={data.value} value={data.value}>
                      {data.label}
                    </option>
                  ))}
                </CFormSelect>
              </div>
              <div className="app-name-div mb-3">
                <CFormLabel>Application Name</CFormLabel>
                <CFormInput
                  type="text"
                  name="appName"
                  aria-label="default input example"
                  onChange={handleChange}
                  value={values?.appName}
                />
              </div>
              <div className="package-name mb-3">
                <CFormLabel>Package Name</CFormLabel>
                <CFormInput
                  type="text"
                  name="packageName"
                  aria-label="default input example"
                  onChange={handleChange}
                  value={values?.packageName}
                />
              </div>
              <div className="api-key mb-3">
                <CFormLabel>API Key</CFormLabel>

                <CFormInput
                  type="text"
                  name="apiKeyText"
                  aria-label="default input example"
                  onChange={handleChange}
                  value={values?.apiKeyText}
                />
              </div>
              <div className="app-note mb-3">
                <CFormLabel>App Note</CFormLabel>
                <CFormTextarea
                  type="text"
                  name="appNote"
                  aria-label="default input example"
                  onChange={handleChange}
                  value={values?.appNote}
                />
              </div>
            </div>
            <div className="col-6 app-details-sec-2">
              <div className="platform-div mb-2">
                <CFormLabel> Play Console</CFormLabel>
                <CFormSelect
                  aria-label="Default select example"
                  name="playConsoleId"
                  value={values.playConsoleId}
                  onChange={handleChange}
                >
                  <option key="playconsole" selected disabled>
                    Select Play Console
                  </option>
                  {playConsole?.map((data, index) => (
                    <option key={data.value} value={data.value}>
                      {data.label}
                    </option>
                  ))}
                </CFormSelect>
              </div>
              <div className="version-up-dialog mb-3">
                <div className="d-flex flxe-wrap gap-4">
                  <CFormLabel>Version Update Dialog</CFormLabel>
                  <div className="d-flex flex-wrap align-items-center">
                    <label class="switch">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setDialog({ ...dialog, version: e.target.checked })
                        }
                        checked={dialog?.version}
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
                <CFormInput
                  type="number"
                  name="versionUpdateDialogText"
                  aria-label="default input example"
                  onChange={handleChange}
                  value={values?.versionUpdateDialogText}
                  placeholder="Enter version code like. 1,2,3..."
                />
              </div>
              <div className="redirect-other-app mb-3">
                <div className="d-flex flxe-wrap gap-4">
                  <CFormLabel>Redirect Other App?</CFormLabel>
                  <div className="d-flex flex-wrap align-items-center">
                    <label class="switch">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setDialog({ ...dialog, dialog: e.target.checked })
                        }
                        checked={dialog?.dialog}
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>

                <CFormInput
                  type="text"
                  name="redirectToOtherAppText"
                  aria-label="default input example"
                  onChange={handleChange}
                  value={values?.redirectToOtherAppText}
                  placeholder="Enter below package if you wnat to redirect to other page"
                />
                <p className="error">
                  {errors.redirectToOtherAppText &&
                    touched.redirectToOtherAppText &&
                    errors.redirectToOtherAppText}
                </p>
              </div>
              <div className="privacy-policy-link mb-3">
                <CFormLabel>Privacy Policy Link</CFormLabel>
                <CFormInput
                  type="text"
                  name="appPrivacyPolicyLink"
                  aria-label="default input example"
                  onChange={handleChange}
                  value={values?.appPrivacyPolicyLink}
                  placeholder="Enter account privacy policy"
                />
              </div>
              <p className="error">
                {errors.appPrivacyPolicyLink &&
                  touched.appPrivacyPolicyLink &&
                  errors.appPrivacyPolicyLink}
              </p>
              <div className="app-account-link mb-3">
                <CFormLabel>App Account Link</CFormLabel>
                <CFormInput
                  type="text"
                  name="appAccountLink"
                  aria-label="default input example"
                  onChange={handleChange}
                  value={values.appAccountLink}
                  placeholder="Enter app account link"
                />
              </div>
              <p className="error">
                {errors.appAccountLink &&
                  touched.appAccountLink &&
                  errors.appAccountLink}
              </p>
              <div className="extra-data mb-3">
                <CFormLabel>Extra Data: Json format data only...!!!</CFormLabel>
                <textarea
                  class="form-control"
                  id="appExtraData"
                  rows="9"
                  name="appExtraData"
                  onChange={handleChange}
                  value={values?.appExtraData}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="d-flex flex-wrap justify-content-center w-100">
                <button
                  className="btn bg-primary"
                  onClick={handleSubmit}
                  style={{ width: "200px", color: "white" }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
}

export default TabFirst;
