import React, { useState } from "react";
import CIcon from "@coreui/icons-react";
import { cilPlus, cilTrash } from "@coreui/icons";
import SearchIcon from "@mui/icons-material/Search";
import DataTable from "react-data-table-component";
import Card from "react-bootstrap/Card";
import { useEffect } from "react";
import NoDataImg from "../../images/g8.png";
import { APIdelete } from "../../api-wrapper/ApiAppWiseAdvertise";
import { APIInnerlist } from "../../api-wrapper/ApiAppWiseAdvertise";
import moment from "moment";
import DeleteModal from "../../helper/modal/DeleteModal";
import { Toast, Link } from "../../helper/links/Link";
import { useParams } from "react-router-dom";
import Add from "./Add";
import { Rating } from "react-simple-star-rating";
function AssignAd() {
  let { appId } = useParams();
  const [date, setDate] = useState([
    new Date(moment().startOf("month")),
    new Date(),
  ]);
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);
  const [arg, setArg] = useState({ pageNo: 1, perPage: 10 });
  const [totalRecords, setTotalRecords] = useState(0);
  const [visible, setVisible] = useState(false);
  const [editData, setEditData] = useState();
  const [deleteId, setDeleteId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const tHeader = [
    {
      name: "Banner",
      selector: "appLogo",
      cell: (row) => {
        if (row.assetsBanner)
          return <img src={row.assetsBanner} className="ref-image" />;
        else
          return (
            <div className="default_image"> {row.appId.appName.charAt(0)}</div>
          );
      },
    },
    {
      name: "Assets  Name",
      selector: "assetsName",
    },
    {
      name: " Button Name",
      selector: "actionButtonName",
    },

    {
      name: "Assets  Rating",
      selector: "assetsRating",
      cell: (row) => (
        <Rating
          initialValue={row.assetsRating}
          readonly
          /* Available Props */
        />
      ),
    },
    {
      name: " Assets Type",
      selector: "assetsType",
    },
    {
      name: "Status",
      selector: "status",
      cell: (item) => (
        <div className="d-block p-3 ">
          {item.adStatus ? (
            <button className="btn btn-success">Enable</button>
          ) : (
            <button className="btn btn-danger">Disable</button>
          )}
        </div>
      ),
    },
    {
      name: "Actions",
      selector: "actions",
      cell: (row) => (
        <button
          className="btn  bg-white border border-secondary-subtle"
          onClick={() => {
            setShowDeleteModal(true);
            setDeleteId(row._id);
          }}
        >
          <CIcon icon={cilTrash} className="icon-trash text-red" color="red" />
        </button>
      ),
    },
  ];

  const getData = () => {
    let data = {
      ...arg,
      search: search,
    };
    APIInnerlist(data, appId)
      .then((e) => {
        if (e?.isSuccess) {
          setTableData(e?.data);
          setTotalRecords(e.totalRecords);
        } else {
          Toast.error(e?.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };
  useEffect(() => {
    getData();
  }, [arg]);

  const deleteHandler = () => {
    APIdelete(appId, deleteId)
      .then((res) => {
        if (res.isSuccess) {
          Toast.success(res.message);
          getData();
        } else {
          Toast.error(res.message);
        }
        setDeleteId();
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };
  return (
    <>
      <Card className="rounded-3 p-1">
        <Card.Header className="align-items-center gap-4 flex-wrap padding-header">
          <h5 className="card-title">Assign Custom Ad</h5>

          <div className="d-flex gap-4 flex-wrap">
            <div className="align-items-center">
              <div className="input-group mr-3 h-100">
                <input
                  type="text"
                  className="form-control search-box"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />

                <button
                  className="btn btn-outline-secondary border search-btn-icon"
                  type="button"
                  onClick={() => getData()}
                >
                  <SearchIcon />
                </button>
              </div>
            </div>

            <button
              class="btn bg-primary add-ref-btn"
              onClick={() => {
                setVisible(true);
              }}
            >
              <CIcon icon={cilPlus} className="" style={{ color: "white" }} />
            </button>
            <Link to="/app-wise-custom-ad" class="btn bg-info add-ref-btn">
              Back
            </Link>
          </div>
        </Card.Header>
        <Card.Body>
          {tableData?.length < 1 ? (
            <div className="d-flex w-100 justify-content-center no-data-img">
              <img src={NoDataImg} alt="no-data" className="w-50" />
            </div>
          ) : (
            <DataTable
              columns={tHeader}
              data={tableData}
              pagination
              paginationServer
              paginationPerPage={10}
              onChangePage={(page) =>
                setArg({ pageNo: page, perPage: arg.perPage })
              }
              onChangeRowsPerPage={(newPerPage, page) =>
                setArg({ pageNo: page, perPage: newPerPage })
              }
              paginationTotalRows={totalRecords}
              fixedHeader
            />
          )}
        </Card.Body>
      </Card>

      <Add visible={visible} setVisible={setVisible} getData={getData} />

      {/* delete modal */}

      <DeleteModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        deleteHandler={deleteHandler}
      />
    </>
  );
}

export default AssignAd;
