import { memo } from "react";

const IconOutlineArrowUpIcon2 = (props) => (
  <svg
    preserveAspectRatio="none"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.5 7L6 10.5M6 10.5L2.5 7M6 10.5V1.5"
      stroke="#FF1E00"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const Memo = memo(IconOutlineArrowUpIcon2);
export { Memo as IconOutlineArrowUpIcon2 };
