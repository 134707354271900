import React, { useEffect } from "react";
import { Formik } from "formik";
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CModalTitle,
  CFormLabel,
  CFormInput,
  CFormSelect,
} from "@coreui/react";
import mainLogo from "../../../images/mainlogo.png";
import { GroupwiseSchema } from "../../../utility/Validator";
import { Toast } from "../../../helper/links/Link";
import {
  APIlistAppId,
  APIadd,
  APIedit,
} from "../../../api-wrapper/ApiNotificationGroupWise";
import { useState } from "react";
import { APIPlayConsole } from "../../../api-wrapper/ApiAppList";
import Select from "react-select";
function Add({ visible, setVisible, editData, getData }) {
  const [playConsole, setPlayConsole] = useState([]);
  const [appId, setAPPID] = useState([]);
  const handleSubmit = (values) => {
    let data = {
      playConsoleId: values?.playConsoleId,
      appIds: values?.appIds.map((x) => x.value),
      groupName: values?.groupName,
      oneSignalRestAPIKey: values?.oneSignalRestAPIKey,
      oneSignalAppId: values?.oneSignalAppId,
      groupDetails: values?.groupDetails,
    };

    if (editData._id) {
      APIedit(data, editData._id)
        .then((e) => {
          if (e?.isSuccess) {
            Toast.success(e?.message);
            setVisible(false);
            getData();
          } else {
            Toast.error(e?.message);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
    } else {
      APIadd(data)
        .then((e) => {
          if (e?.isSuccess) {
            setVisible(false);
            Toast.success(e?.message);
            getData();
          } else {
            Toast.error(e?.message);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
    }
  };

  useEffect(() => {
    if (visible) {
      let data = [];
      APIPlayConsole()
        .then((res) => {
          if (res.isSuccess) {
            res.data.map((el) => {
              data.push({
                label: el.pcName,
                value: el._id,
              });
            });
            setPlayConsole(data);
          } else {
            setPlayConsole([]);
          }
        })
        .catch((err) => {
          Toast.error("Something Went wrong!!");
        });
    }
  }, [visible]);

  useEffect(() => {
    if (editData?.playConsoleId) handleAppId(editData?.playConsoleId);
  }, [visible, editData?.playConsoleId]);

  const handleAppId = (e) => {
    let data = [];
    APIlistAppId(e)
      .then((res) => {
        if (res.isSuccess) {
          res.data.map((el) => {
            data.push({
              label: el.appName,
              value: el._id,
            });
          });
          setAPPID(data);
        } else {
          setAPPID([]);
        }
      })
      .catch((err) => {
        Toast.error("Something Went wrong!!");
      });
  };

  return (
    <>
      <CModal
        visible={visible}
        alignment="center"
        scrollable
        keyboard={false}
        backdrop="static"
        onClose={() => {
          setVisible(false);
        }}
      >
        <Formik
          validationSchema={GroupwiseSchema}
          initialValues={{
            playConsoleId: editData?.playConsoleId,
            appIds: editData?.appIds?.map((x) => ({
              label: x.appName,
              value: x._id,
            })),
            groupName: editData?.groupName,
            oneSignalRestAPIKey: editData?.oneSignalRestAPIKey,
            oneSignalAppId: editData?.oneSignalAppId,
            groupDetails: editData?.groupDetails,
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <>
              <CModalHeader>
                <CModalTitle className="">
                  <p className="h4 pt-2 fw-bold mt-auto text-16">
                    Notification Group
                  </p>
                </CModalTitle>
              </CModalHeader>
              <CModalBody>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <CFormLabel>Play Console</CFormLabel>
                    <CFormSelect
                      aria-label="Default select example"
                      name="playConsoleId"
                      value={values.playConsoleId}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        handleAppId(e.target.value);
                        setFieldValue("appIds", []);
                      }}
                    >
                      <option value="All" disabled selected>
                        Select Account
                      </option>
                      {playConsole?.map((data, index) => (
                        <option key={data.value} value={data.value}>
                          {data.label}
                        </option>
                      ))}
                    </CFormSelect>
                    <p className="error">
                      {errors.playConsoleId &&
                        touched.playConsoleId &&
                        errors.playConsoleId}
                    </p>
                  </div>
                  <div className="mb-3">
                    <CFormLabel>Applications</CFormLabel>
                    <br />

                    <Select
                      classNamePrefix="form_dropdown"
                      placeholder="Select"
                      options={appId}
                      value={values.appIds}
                      isMulti
                      onBlur={handleBlur}
                      name="appIds"
                      onChange={(selectedOptions) =>
                        setFieldValue("appIds", selectedOptions)
                      }
                    />
                    <p className="error">
                      {errors.appIds && touched.appIds && errors.appIds}
                    </p>
                  </div>

                  <div className="mb-3">
                    <CFormLabel>Group Name</CFormLabel>

                    <CFormInput
                      type="text"
                      name="groupName"
                      value={values.groupName}
                      aria-label="default input example"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <p className="error">
                      {errors.groupName &&
                        touched.groupName &&
                        errors.groupName}
                    </p>
                  </div>
                  <div className="mb-3">
                    <CFormLabel>One Signal API Key</CFormLabel>

                    <CFormInput
                      type="text"
                      name="oneSignalRestAPIKey"
                      aria-label="default input example"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.oneSignalRestAPIKey}
                    />
                    <p className="error">
                      {errors.oneSignalRestAPIKey &&
                        touched.oneSignalRestAPIKey &&
                        errors.oneSignalRestAPIKey}
                    </p>
                  </div>
                  <div className="mb-3">
                    <CFormLabel>One Signal App ID</CFormLabel>

                    <CFormInput
                      type="text"
                      name="oneSignalAppId"
                      aria-label="default input example"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.oneSignalAppId}
                    />
                    <p className="error">
                      {errors.oneSignalAppId &&
                        touched.oneSignalAppId &&
                        errors.oneSignalAppId}
                    </p>
                  </div>

                  <div className="mb-3">
                    <CFormLabel>Group details</CFormLabel>

                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="6"
                      name="groupDetails"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.groupDetails}
                    ></textarea>
                    <p className="error">
                      {errors.groupDetails &&
                        touched.groupDetails &&
                        errors.groupDetails}
                    </p>
                  </div>
                </form>
              </CModalBody>
              <CModalFooter className="add-play-console-modal-footer d-flex justify-content-center gap-2">
                <button
                  className=" btn close-btn bg-white text-black border-danger"
                  onClick={() => {
                    setVisible(false);
                  }}
                >
                  Close
                </button>
                <button
                  className="btn bg-primary text-light create-apikey-btn"
                  onClick={(e) => handleSubmit(e)}
                  type="submit"
                >
                  save
                </button>
              </CModalFooter>
            </>
          )}
        </Formik>
      </CModal>
    </>
  );
}

export default Add;
