import React from "react";
import Card from "react-bootstrap/Card";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import NoDataImg from "../../../images/g8.png";
import { DateRangePicker } from "rsuite";
import { predefinedRanges } from "../../../helper/calendarValues/calendarValues";
import SearchIcon from "@mui/icons-material/Search";
import {
  APIlistSetting,
  APIdelete,
  APIdeleteMany,
} from "../../../api-wrapper/ApiNotification";
import Toast from "../../../helper/toast/Toast";
import { useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import { cilPlus, cilTrash, cilBell } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import DeleteModal from "../../../helper/modal/DeleteModal";
function NotificationList({ flag }) {
  let { appId } = useParams();
  const [date, setDate] = useState([
    new Date(moment().startOf("month")),
    new Date(),
  ]);
  const [search, setSearch] = useState("");
  const [arg, setArg] = useState({ pageNo: 1, perPage: 10 });
  const [totalRecords, setTotalRecords] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [status, setStatus] = useState("");
  const [deleteId, setDeleteId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDataIds, setSelectedDataIds] = useState([]);
  const [deleteAll, setdeleteAll] = useState(false);

  const [currentPage, setcurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const getData = (page, perPage) => {
    let data = {
      pageNo: page || 1,
      perPage: perPage || rowsPerPage,
      fromDate: moment(date?.[0]).format("YYYY-MM-DD"),
      toDate: moment(date?.[1]).format("YYYY-MM-DD"),
      search: search,
      isSend: status,
    };
    APIlistSetting(data, appId)
      .then((e) => {
        if (e?.isSuccess) {
          setTableData(e?.data);
          setcurrentPage(e.currentPageNo)
          setTotalRecords(e.totalRecords);
        } else {
          Toast.error(e?.message);
        }
      })
      .catch((err) => {
        Toast.error("Something went wrong, please try again!");
      });
  };

  useEffect(() => {
    getData();
  }, [date, status, flag]);

  const tHeader = [
    {
      name: "Notification",
      selector: "title",
    },
    {
      name: "Redirect URL",
      selector: (row) => (row.notificationUrl ? row.notificationUrl : "-"),
    },
    {
      name: "Image",
      cell: (row) => {
        if (row.notificationImage)
          return <img src={row.notificationImage} className="ref-image" />;
        else return <div className="default_image"> {row.title.charAt(0)}</div>;
      },
    },
    {
      name: "Sent",
      selector: "isSend",
      cell: (row) => {
        if (row.isSend)
          return <button className="btn active-btn fs-6">Success</button>;
        else
          return (
            <button className="btn text-black inactive-btn fs-6">
              Pending{" "}
            </button>
          );
      },
    },
    {
      name: "Date",
      selector: "createdAt",
      cell: (e) => moment(e?.createdAt).format("MM/DD/YYYY"),
    },
    {
      name: "Actions",
      selector: "actions",
      cell: (row) => (
        <div className="d-flex gap-2 justify-content-center p-1">
          <button
            onClick={() => {
              setShowDeleteModal(true);
              setDeleteId(row._id);
              setdeleteAll(false);
            }}
            className="btn bg-white border border-secondary-subtle"
          >
            <CIcon icon={cilTrash} className="icon-trash text-red" />
          </button>
        </div>
      ),
    },
  ];

  const deleteHandler = () => {
    if (deleteAll) {
      let data = {
        appNotificationIds: selectedDataIds,
      };
      APIdeleteMany(data)
        .then((res) => {
          if (res.isSuccess) {
            Toast.success(res.message);
            getData();
          } else {
            Toast.error(res.message);
          }
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
    } else {
      APIdelete(deleteId)
        .then((res) => {
          if (res.isSuccess) {
            Toast.success(res.message);
            getData();
          } else {
            Toast.error(res.message);
          }
          setDeleteId();
        })
        .catch((err) => {
          Toast.error("Something went wrong, please try again!");
        });
    }
  };
  const handleSelectedRowsChange = (state) => {
    const ids = state?.selectedRows.map((row) => row._id);
    setSelectedDataIds(ids);
  };

  return (
    <>
      <Card className="rounded-3 p-1 mt-4">
        <Card.Header>
          <h5 class="card-title">Notification List</h5>
          <div className="grid_console">
            <div>
              {selectedDataIds.length != 0 ? (
                <button className="btn bg-white border border-secondary-subtle icon-trash">
                  <CIcon
                    onClick={() => {
                      setShowDeleteModal(true);
                      setdeleteAll(true);
                    }}
                    icon={cilTrash}
                    className="text-red"
                  />
                </button>
              ) : null}
            </div>
            <div>
              <DateRangePicker
                className={`rangeDate custom-date-range-picker px-lg-2 pb-1`}
                ranges={predefinedRanges}
                showOneCalendar
                value={date}
                placeholder="Select Date Range"
                onChange={(e) => setDate(e)}
                placement="bottomEnd"
                format="yyyy-MM-dd"
                cleanable={false}
              />
            </div>

            <div>
              <select
                className="form-select "
                aria-label="Default select example"
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="All" selected disabled>
                  Select Status
                </option>
                <option key="Success" value="Success">
                  Success
                </option>
                <option key="sent" value="Pending">
                  Pending
                </option>
              </select>
            </div>

            <div className="input-group">
              <input
                type="text"
                className="form-control search-box"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />

              <button
                className="btn btn-outline-secondary border search-btn-icon"
                type="button"
                onClick={() => getData()}
              >
                <SearchIcon />
              </button>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          {tableData?.length < 1 ? (
            <div className="d-flex w-100 justify-content-center no-data-img">
              <img src={NoDataImg} alt="no-data" className="w-50" />
            </div>
          ) : (
            <DataTable
              columns={tHeader}
              data={tableData}
              pagination
              paginationServer
              paginationPerPage={rowsPerPage}
              onChangeRowsPerPage={(event) => {
                setRowsPerPage(parseInt(event));
                getData(currentPage, event);
              }}
              onChangePage={(page) => {
                getData(page);
              }}
              paginationDefaultPage={currentPage}
              paginationTotalRows={totalRecords}
              selectableRows
              onSelectedRowsChange={handleSelectedRowsChange}
              selectableRowsNoSelectAll={false}
              fixedHeader
            />
          )}
        </Card.Body>
      </Card>

      {/* delete modal */}

      <DeleteModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        deleteHandler={deleteHandler}
      />
    </>
  );
}

export default NotificationList;
