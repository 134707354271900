import axios from "axios";

const api = `${process.env.REACT_APP_BACKEND_URL}/admin/`;
const apiPage = `${process.env.REACT_APP_BACKEND_URL}/pages/`;
const authToken = localStorage.getItem("token");
const headers = {
  Authorization: `Bearer ${authToken}`,
  "Content-Type": "application/json",
};
const headersWithForm = {
  Authorization: `Bearer ${authToken}`,
  "Content-Type": "multipart/form-data",
};

const APIlist = () => {
  return axios
    .get(`${api}get-admin-users`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIadd = (data) => {
  return axios
    .post(`${api}add-admin`, data, { headers: headersWithForm })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIdelete = (id) => {
  return axios
    .delete(`${api}remove-admin-users/${id}`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIlistAuth = (id) => {
  return axios
    .get(`${apiPage}listRemainingPages/${id}`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIauthPage = (data) => {
  return axios
    .post(`${apiPage}getPageById`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIsavePage = (data, id) => {
  return axios
    .put(`${api}manage-page-access/${id}`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIlistPage = (id) => {
  return axios
    .get(`${apiPage}getPermissionByAdminUser/${id}`, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

const APIUpdatePermission = (data, id) => {
  return axios
    .put(`${api}manage-page-allocated-action/${id}`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};
const APIauthorize = (data, id) => {
  return axios
    .put(`${api}remove-admin-page-access/${id}`, data, { headers })
    .then((res) => res.data)
    .catch((res) => res.data);
};

export {
  APIauthorize,
  APIlistPage,
  APIsavePage,
  APIlist,
  APIadd,
  APIdelete,
  APIlistAuth,
  APIauthPage,
  APIUpdatePermission,
};
